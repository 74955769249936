
export enum TableColumnAttributes {
    isCalculated = 'is-calculated-column',
    isPromoted = 'PMT',
    isHiddenSm = 'is-hidden-mobile',
    isHiddenMd = 'is-hidden-tablet',
    isHiddenLg = 'is-hidden-desktop',
}

export enum TableColumnStack {
    stackOne = 1,
    stackTwo = 2
}


export interface TableColumnDTO {
    label: string;
    primaryMessage: string;
    secondaryMessage: string;
    additionalInfo: string;
    sortOrder: number;
    description: string;
    attributes: string[];
    stackNumber: TableColumnStack | null;
}