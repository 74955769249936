import { CompareItemDTO } from './../../../models/generated/item/CompareValueDTO';
import { CompareDTO } from 'src/app/models/generated/item/CompareDTO';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { TableService } from 'src/app/services/api/table.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { AnalyticsService, BasicDataLayer, TableDataLayer } from 'src/app/services/analytics.service';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { Utils } from 'src/app/models/shared/Utils';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { ApplyComponent } from '../../buttons/apply/apply.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'fc-compare-details',
    templateUrl: './compare-details.component.html',
    styleUrls: ['./compare-details.component.scss', '../../../modal.component.scss'],
    standalone: true,
    imports: [NgFor, ApplyComponent]
})
export class CompareDetailsComponent implements OnInit {
  @Input() items: TableItemDTO[];
  @Input() pageDetails: CategoryDTO | GroupDTO | BrandDto;
  @Input() paging: PagingParameterDto;
  @Output() onClose = new EventEmitter();

  headerItems: CompareItemDTO[];
  comparisonItems: CompareDTO[];
  expandedGroup?: CompareDTO;

  constructor(private tableService: TableService, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {

    const dlItems: BasicDataLayer[] = [];
    const dlTable: TableDataLayer = {
      id: this.paging.tableId,
      pageNumber: this.paging.pageNumber,
      pageSize: this.paging.pageSize,
    };

    this.items.forEach(x => dlItems.push({
      brand: x.brand,
      product: x.product,
    }));

    this.tableService.GetComparison(this.items).subscribe(x => {

      this.comparisonItems = x;
      this.headerItems = x[0].rows[0].items;
      this.expandedGroup = x[0];

    });

    this.analyticsService.onCompare(this.pageDetails.navigation, dlItems, dlTable, null);

  }

  calculateColspan(): number {
    return 1 + this.items.length;
  }

  onGroupToggle(group: CompareDTO): void {
    if (this.expandedGroup === group) {
      delete this.expandedGroup;
    } else {
      this.expandedGroup = group;
    }
  }

  getApplySettings(compareItem: CompareItemDTO): ApplySettings {
    const item = this.items.find(x => x.product.id === compareItem.id);

    if (item == null) {
      throw new Error(('Cannot find the correct item for apply button'));
    } else {
      compareItem.tableItem = item;
      return {
        placement: 'compare',
        page:  PageTypeHelper.getPageDataLayer(this.pageDetails),
        brand: item.brand,
        product: item.product,
        moreDetailsRoute: Utils.getRoute(this.pageDetails, item.brand.slug, item.product.slug),
        table: null,
        columns: null,
        slug: this.pageDetails.navigation.slug
      };
    }
  }

  close(): void {
    this.onClose.emit();
  }
}
