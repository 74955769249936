import { EnvironmentType } from 'src/environments/environmentDefinitions';

export const environment: EnvironmentType =
{
  environment: 'production',
  logoSrc: 'https://ik.imagekit.io/fairaus/faircomparison/fc_logo_grey.svg',
  client: {
    alwaysApply: true,
    code: 'savrr-gb',
    setup: {
      theme: 'savrr-theme',
      header: 'fc-savrr-header',
      footer: 'fc-savrr-footer'
    },
    favicon: 'https://ik.imagekit.io/fairaus/savrr/icons/favicon-32x32.png',
    showSideFilter: false,
    schema: {
      logo: '',
      url: ''
    },
    api: {
      key: 'DGYfXKDGt0BIz+51gE/riW7QtDxgbxFURQILn0wKXKaXvlvLZPHagUrBxdqt0jTY',
      version: '1.0'
    },
    disclaimers: {
      linkMessage: 'Learn More',
      target: '_blank',
      showGenericLink: true,
      showHeaderLink: true,
      showTableLink: false,
      includeCategory: false
    },
    marked: {
      defaultTarget: 'external_blank',
      articleTarget: 'external_blank',
      comparisonTarget: 'external_blank'
    },
    masking: {
      hasLandingPage: false,
      enable301: true,
      defaultRoute: '',
      baseHref: '/',
      sector: 'compare',
      brands: 'brands'
    }
  }
};