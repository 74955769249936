
import { Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { CompareService } from 'src/app/services/compare.service';
import { NgFor } from '@angular/common';


@Component({
    selector: 'fc-compare-footer',
    templateUrl: './compare-footer.component.html',
    styleUrls: ['./compare-footer.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class CompareFooterComponent implements OnInit, OnDestroy {
  @Input() pageDetails: CategoryDTO | GroupDTO | BrandDto;
  @Input() paging: PagingParameterDto;

  @HostBinding('class.fc-show-compare-footer') showModal: boolean;

  constructor(public compareService: CompareService) { }
  closeResult: string;

  ngOnInit(): void {
    this.compareService.onShowHideEvent().subscribe(x => {
      this.showModal = x;
    });
  }

  ngOnDestroy(): void {
    this.compareService.removeAllItems();
  }

  remainingItems(): number[] {
    const spaces = [1, 2, 3, 4];
    for (const i of this.compareService.items) {
      spaces.splice(0, 1);
    }
    return spaces;
  }

  onRemoveAll(): void {
    this.compareService.removeAllItems();
  }

  onRemoveSingle(item: TableItemDTO): void {
    this.compareService.removeItem(item);
  }

  onCompare(): void {
    this.compareService.showCompare(this.pageDetails, this.paging);
  }
}
