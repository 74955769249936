import { Component, Input } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { FCRouterLinkDirective } from '../../directives/fc-router-link.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, MarkedInlinePipe]
})
export class DisclaimerComponent {
  @Input() message: string;
  @Input() showLink = true;

  target = environment.client.disclaimers.target;
  linkMessage = environment.client.disclaimers.linkMessage;

  @Input() scrollLocation: string;

  constructor(private seoService: SeoService) {}

  getShowLink() {
    return this.showLink && this.message && this.message.length > 3;
  }
  getAdvertiserDisclosure(): string {
    return this.seoService.getAdvertiserDisclosurePage();
  }

  scrollTo(): void {
    let el = document.getElementById(this.scrollLocation);
    el?.scrollIntoView(
      {behavior: 'smooth'}
    );
  }
}
