import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { IScriptInitilizer } from 'src/app/services/script.service';

@Injectable({ providedIn: 'root' })
export class DefaultScriptInitilizer implements IScriptInitilizer {
    helper: RenderHelper;

    constructor(@Inject(DOCUMENT) doc: Document) {
        this.helper = new RenderHelper(doc);
    }
    renderStyleSheet(): void { }
    renderProrityStyle(): void {}
    renderPreLoad(): void { }
    renderPreFetch(): void { }
    renderScripts(): void { }
    renderTemplates(): void { }
}

