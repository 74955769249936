import { Component } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { PartnerAppSettings } from 'src/environments/partnerAppSettings';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';

@Component({
    selector: 'fc-advertiser-disclosure',
    templateUrl: './advertiser-disclosure.component.html',
    styleUrls: ['./advertiser-disclosure.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective, TooltipModule]
})
export class AdvertiserDisclosureComponent  {
  message: string = PartnerAppSettings.advertiserDisclosure?.message ?? 'Advertiser Disclosure';
  target = environment.client.disclaimers.target;

  constructor(public seoService: SeoService) {
  }
}
