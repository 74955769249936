import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { NavigationOutlineStageDto } from 'src/app/models/generated/navigation/NavigationStageDto';
import { Utils, SectorParams, ArticleParams } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ArticleService } from 'src/app/services/api/article.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({ providedIn: 'root' })
export class ArticleResolver  {
    public static HandleArticle(
        breadcrumbService: BreadcrumbService,
        seoService: SeoService,
        analyticsService: AnalyticsService,
        article: ArticleDTO,
        sector: string,
        category: string,

    ): void {
        breadcrumbService.setBreadCrumbAllLinks(article.navigation, article.navigation.sector, article.navigation.category, article.navigation.group, 'article');
        seoService.setStandard(sector, category, article.navigation.article, article.navigation.root, null);
        analyticsService.onArticlePageView(article);
    }

    constructor(private articleService: ArticleService, private breadcrumbService: BreadcrumbService, private seoService: SeoService,
                private analyticsService: AnalyticsService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ArticleDTO> {
        const params = Utils.GetParams<ArticleParams>(route);
        return this.articleService.GetArticle(params, params.previewCode).pipe(map(article => {
            try {
                ArticleResolver.HandleArticle(this.breadcrumbService, this.seoService, this.analyticsService, article, params.sector, params.category);
            } catch (error) {
                console.error(`Unable to resolve article (${params.articleSlug}):`, error);
            }
            return article;
        }));
    }
}
