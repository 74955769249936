
<div class="fc-compare-overlay"></div>
<div class="fc-compare-products">
    <button class="fc-compare-clear-all" (click)="onRemoveAll()"> Clear All</button>
    <div class="fc-compare-box" *ngFor="let i of compareService.items">
        <img [src]="i.image.src">
        <i (click)="onRemoveSingle(i)"></i>
    </div>
    <div class="fc-compare-box" *ngFor="let i of remainingItems()">{{i}}</div>
    <button class="fc-compare-show-modal" (click)="onCompare()"  [disabled]="compareService.items.length <= 1" >Compare</button>
</div>