import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NgChanges, Utils } from 'src/app/models/shared/Utils';
import { FormsModule } from '@angular/forms';


export interface OptionInputNumberSelect {
  value: number | null,
  label: string;
};

export interface OptionInputNumber {
  disabled: () => boolean,
  options: OptionInputNumberSelect[]
};



export interface OptionInputSettings {
  disabled: () => boolean,
  elementOne: {
    value: boolean | number, 
    label: string
  };
  elementTwo: {
    value: boolean | number, 
    label: string
  };
}

@Component({
    selector: 'fc-option-input',
    templateUrl: './option-input.component.html',
    styleUrls: ['./option-input.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class OptionInputComponent implements OnChanges  {
  @Input() settings: OptionInputSettings; 
  @Input() value: boolean | number | null;
  @Output() valueChange: EventEmitter<boolean | number | null> = new EventEmitter<boolean | number | null>();

  private previousValue: boolean | number | null = null;

  ngOnChanges(changes: NgChanges<OptionInputComponent>): void {
    if (changes.value.isFirstChange() || this.previousValue !== changes.value.currentValue) {
        this.previousValue =changes.value.currentValue;
        this.elementOneValue = false;
        this.elementTwoValue = false;

        if (changes.value.currentValue == null) {
          this.elementOneValue = true;
          this.elementTwoValue = true;
        } else if (changes.value.currentValue == this.settings.elementOne.value) {
          this.elementOneValue = true;
        } else if (changes.value.currentValue == this.settings.elementTwo.value) {
          this.elementTwoValue = true;
        }
    }
  }

  elementOneId: string = "elementone_" + Utils.GetRandomId();
  elementTwoId: string = "elementtwo_" + Utils.GetRandomId();

  elementOneValue: boolean = false;
  elementTwoValue: boolean = false;

  onChange() {
    setTimeout(() => {
      if ((this.elementOneValue && this.elementTwoValue) || (!this.elementOneValue && !this.elementTwoValue))
      {
        this.valueChange.emit(null);
      } else if (this.elementOneValue) {
        this.valueChange.emit(this.settings.elementOne.value);
      } else if (this.elementTwoValue) {
        this.valueChange.emit(this.settings.elementTwo.value);
      }
    }, 150);
  }
}
