<div class="fc-sort-action">
    <i class="fc-sort-icon" (click)="toggleExpanded()"></i>
    <button type="button" (click)="toggleExpanded()" [innerHtml]="settings.placeHolder"></button>
    <i class="fc-sort-expand" (click)="toggleExpanded()"></i>
    <ul>
        <li *ngFor="let i of items"  [ngClass]="i.class" (click)="onOptionSelected(i.option)" [fcAnalyticsSort]="i" [innerHtml]="i.label"></li>
    </ul>
</div>
<div class="fc-sort-action-background" (click)="toggleExpanded()"></div>
   
