import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component,  Inject, OnDestroy } from '@angular/core';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { ScreenSizeService } from 'src/app/services/screenSize.service';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';

@Component({
    selector: 'fc-content-html',
    templateUrl: './content-html.component.html',
    styleUrls: ['./content-html.component.scss'],
    standalone: true,
    imports: [MarkedPipe]
})
export class ContentHtmlComponent extends ContentBaseComponent implements AfterViewInit, OnDestroy  {
  helper: RenderHelper;
  constructor(@Inject(DOCUMENT) doc: Document, screenSizeService: ScreenSizeService) {
    super(screenSizeService);
    this.helper = new RenderHelper(doc);
  }

  ngOnDestroy(): void {
     for (const script of this.content.scripts) {
      this.helper.removeScriptSource(script.src);
    }
  }

  ngAfterViewInit(): void {
    for (const script of this.content.scripts) {
      this.helper.renderScriptSource(script);
    }
  }

  
}
