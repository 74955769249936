import { Component, Input } from '@angular/core';
import { BrandCategoryDto } from 'src/app/models/generated/client/brand/BrandCategoryDto';
import { CategoryParams } from 'src/app/models/shared/Utils';
import { BrandService } from 'src/app/services/api/brand.service';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { BrandCardComponent } from '../brand-card/brand-card.component';
import { NgFor, NgIf } from '@angular/common';
import { FCRouterLinkDirective } from '../../directives/fc-router-link.directive';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'fc-brands-summary',
    templateUrl: './brands-summary.component.html',
    styleUrls: ['./brands-summary.component.scss'],
    standalone: true,
    imports: [
        FCRouterLinkDirective,
        NgFor,
        BrandCardComponent,
        NgIf,
        MarkedInlinePipe,
    ],
})
export class BrandsSummaryComponent {
  details: BrandCategoryDto;

  _params: CategoryParams;
  get params(): CategoryParams {
      return this._params;
  }
  @Input() set params(params: CategoryParams) {
    this._params = params;

    if (params) {
      this.brandService.GetTopBrands(this._params.sector, this._params.category).subscribe(x => {
        this.details = x;
        if (this.details?.footer) {
          this.details.footer = this.details.footer.replace('[REDIRECT-ALL-BRANDS]', this.getAllBrandsUrl());
        } 
      });
    }
  }

  constructor(private brandService: BrandService) { }

  getAllBrandsUrl(): string {
    return `/${this.params.sector}/${this.params.category}/${environment.client.masking.brands}`;
  }

}
