import { ActivatedRoute, ActivatedRouteSnapshot, Params } from '@angular/router';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { PageTypes } from 'src/app/models/shared/helpers/PageTypesHelper';
import { AnalyticsModeType } from 'src/app/services/analytics.service';
import { environment } from 'src/environments/environment';
import { RichContentLinkType } from 'src/environments/environmentDefinitions';
import { v4 as uuidv4 } from 'uuid';

export type ComparisonPage = CategoryDTO | GroupDTO | BrandDto;
export type ComparisonParams = CategoryParams | GroupParams | BrandsParams;
export type AliasCallback = (key: string) => string;

export interface SectorParams extends Params {
    sector: string;
}
export interface CategoryParams extends SectorParams {
    category: string;
}
export interface GroupParams extends CategoryParams {
    group: string;
}
export interface BrandsParams extends CategoryParams {
    brand: string;
}

export interface ProductParams extends CategoryParams {
    group: string;
    brand: string;
    product: string;
}

export interface ArticleParams extends GroupParams {
    articleSlug: string;
    articleId: number;
    previewCode: string;
}

export interface AuthorParams extends GroupParams {
    authorSlug: string;
}
export interface StaticPageParams extends CategoryParams {
    key: string;
}

export class Utils {
    static FlattenObject(loaderParams: { [key: string]: any; }) {
        const values = [];
        for (const [key, value] of Object.entries(loaderParams)) {
            values.push(`${key}-${value}`);
        }
        return values.join(",");
    }

    static isUrl(href: string | null) {
        return href?.startsWith('https://') || href?.startsWith('http://');
    }

    static isSameDomain(href: string | null): boolean {
        return !((href?.startsWith('https://') || href?.startsWith('http://') && !href?.startsWith(environment.client.schema.url))) ?? false;
    }

    private static getLinkMode(analyticsMode: AnalyticsModeType): RichContentLinkType {
        switch (analyticsMode) {
            case 'Comparison':
                return environment.client.marked?.comparisonTarget ?? 'self';
            case 'Article':
                return environment.client.marked?.articleTarget ?? 'self';
            case 'None':
                return environment.client.marked?.defaultTarget ?? 'self';
        }
    }

    static getLinkTarget(analyticsMode: AnalyticsModeType, href: string | null): '_self' | '_blank' {
        let linkTarget: '_self' | '_blank' = '_self';
        switch (this.getLinkMode(analyticsMode)) {
            case 'all_blank':
                linkTarget = '_blank';
                break;
            case 'external_blank':
                if (!this.isSameDomain(href)) {
                    linkTarget = '_blank';
                }
                break;
        }
        return linkTarget;
    }

    static combinePaths(pathOne: string, pathTwo: string): string {
        let fullPath = pathOne;
        const partsOne = pathTwo.split('/');
        for (const p of partsOne) {
            if (!fullPath.endsWith(p)) {
                fullPath += `/${p}`;
            }
        }
        return fullPath;
    }

    static getMaskedCommand(commands: string): string {
        let maskedCommand = commands;

        if (environment.client.masking.defaultRoute.length === 0) {

            if (environment.client.masking.categories) {
                for (const categoryMask of environment.client.masking.categories) {
                    const maskUrl = `/${environment.client.masking.sector}/${categoryMask}`;
                    if (commands?.startsWith(maskUrl)) {
                        maskedCommand = commands.replace(maskUrl, '/' + categoryMask);
                        break;
                    }
                }
            }
            

            if (environment.client.masking.sector) {
                let maskUrl = `/${environment.client.masking.sector}`;
                if (commands?.startsWith(maskUrl)) {
                    maskedCommand = commands.replace(maskUrl, '');
                }
                maskUrl = `${environment.client.masking.sector}`;
                if (commands?.startsWith(maskUrl)) {
                    maskedCommand = commands.replace(maskUrl, '');
                }
            }
        }

        return maskedCommand;
    }

    static getRoute(page: PageTypes, brand: string, product: string): string | null {
        if (page.navigation.group) {
            return `/${page.navigation.sector.url}/${page.navigation.category.url}/${page.navigation.group.url}/product/${brand}/${product}`;
        }
        return `/${page.navigation.sector?.url}/${page.navigation.category?.url}/product/${brand}/${product}`;
    }

    static getRoutePath(sectorSlug: string, categorySlug: string, groupSlug: string | null | undefined, brandSlug: string, productSlug: string): string {
        if (groupSlug) {
            return `/${sectorSlug}/${categorySlug}/${groupSlug}/product/${brandSlug}/${productSlug}`;
        }
        return `/${sectorSlug}/${categorySlug}/product/${brandSlug}/${productSlug}`;
    }



    static GetStaticPageRoute(activatedRoute: ActivatedRoute, path: string): string {
        let params: CategoryParams | SectorParams;
        if (environment.client.masking.categories) {
            params = Utils.GetParams<CategoryParams>(activatedRoute.snapshot);
            return `/${params.sector}/${params.category}/${path}`;
        }
        else {
            params = Utils.GetParams<SectorParams>(activatedRoute.snapshot);
            return `/${params.sector}/${path}`;
        }
    }

    static GetParams<T>(snapshot: ActivatedRouteSnapshot): T {
        const sector = snapshot.data?.maskedSector || snapshot.params.sector;
        const category = snapshot.data?.maskedCategory || snapshot.params.category;
        return {
            sector,
            category,
            group: snapshot.params.group,
            brand: snapshot.params.brand,
            product: snapshot.params.product,
            articleSlug: snapshot.params.articleSlug,
            articleId: snapshot.params.articleId,
            previewCode: snapshot.params.previewCode,
            authorSlug: snapshot.params.authorSlug,
            key: snapshot.params.key
        } as unknown as T;
    }
    static GetButtonClass(accentTheme: string): string {
        if (accentTheme == 'light') {
            return 'btn-light';
        } else {
            return 'btn-dark';
        }
    }
    // static GetTextClass(accentTheme: string): string {
    //     if (accentTheme == 'light') {
    //         return 'text-light';
    //     } else {
    //         return 'text-dark';
    //     }
    // }

    public static isNull(input: any): boolean {
        if (typeof input === undefined || input == null) {
            return true;
        }

        return false;
    }

    public static isNullOrWhitespace(input: string | undefined): boolean {
        if (input == null) {
            return true;
        }

        return input.replace(/\s/g, '').length < 1;
    }

    public static isNumber(n: any): boolean {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }

    // public static SafeToLowerCase(input: string): string {
    //     if (this.isNull(input)) {
    //         return input;
    //     }

    //     return input.toLowerCase();
    // }

    public static getType(element: unknown): 'Primitive' | 'Object' | 'NotSupported' {
        if (!Utils.isNumber(element)) {
            return 'Primitive';
        }

        const type = typeof element;
        switch (type) {
            case 'string':
            case 'number':
            case 'boolean':
            case 'bigint':
                return 'Primitive';
            case 'object':
                return 'Object';
            default:
                return 'NotSupported';
        }
    }


    private static encodeURIComponentAlias(key: string, aliasFn: null | AliasCallback): string {
        if (!aliasFn) {
            return encodeURIComponent(key);
        }
        return encodeURIComponent(aliasFn(key) || key);
    };

    private static encodeString(input: DynamicSupport, aliasFn: null | AliasCallback ): string[] {
        const str: string[] = [];
        for (const key of Object.keys(input)) {
            if (!Utils.isNull(input[key])) {
                if (!Array.isArray(input[key])) {
                    const type = Utils.getType(input[key]);
                    if (type === 'Primitive') {
                        if (!this.isNull(input[key])) {
                            str.push(this.encodeURIComponentAlias(key, aliasFn) + '=' + encodeURIComponent(input[key]));
                        }

                    } else if (type === 'Object') {
                        const childStr = Utils.encodeString(input[key], aliasFn);
                        for (const c of childStr) {
                            str.push(encodeURIComponent(key + '.') + c);
                        }
                    }
                } else {
                    let counter = 0;
                    for (const i of input[key]) {
                        const type = Utils.getType(input[key]);
                        if (type === 'Primitive') {
                            str.push(this.encodeURIComponentAlias(key, aliasFn) + '=' + encodeURIComponent(i));
                        } else if (type === 'Object') {
                            const childStr = Utils.encodeString(i, aliasFn);
                            for (const c of childStr) {
                                str.push(encodeURIComponent(key + '[' + counter + '].') + c);
                            }
                        }
                        counter++;
                    }
                }
            }
        }
        return str;
    }

    public static ToQueryString(input: DynamicSupport, aliasFn: null | AliasCallback): string {
        return Utils.encodeString(input, aliasFn).join('&');
    }

    public static GetRandomId(): number {
        return Math.floor((Math.random() * 346) + 1);
    }

    public static GetRandomGuid(): string {
       return uuidv4().toUpperCase();
    }

    static DetectPlatform(): 'web' | 'mobile' {
        if (window?.matchMedia !== undefined) {
            return window?.matchMedia('only screen and (max-width: 760px)').matches ? 'mobile' : 'web';
        }

        return 'web';
    }

    public static isBrandComparisonPage(page: ComparisonPage): page is BrandDto {
        return (page as BrandDto)?.categoryId !== undefined && (page as BrandDto)?.compareAllText !== undefined;
    }
    public static isGroupComparisonPage(page: ComparisonPage): page is GroupDTO {
        return  (page as BrandDto)?.categoryId !== undefined;
    }
    public static hasColumns(value: TableItemDTO | ProductPageDTO): value is TableItemDTO {
        return  (value as TableItemDTO)?.columns !== undefined;
    }


}

export interface DynamicSupport {
    [key: string]: any;
}

type MarkFunctionProperties<Component> = {
    [Key in keyof Component]: Component[Key] extends Function ? never : Key;
};
type ExcludeFunctionPropertyNames<T> = MarkFunctionProperties<T>[keyof T];
type ExcludeFunctions<T> = Pick<T, ExcludeFunctionPropertyNames<T>>;

export type NgChanges<Component, Props = ExcludeFunctions<Component>> = {
    [Key in keyof Props]: {
        previousValue: Props[Key];
        currentValue: Props[Key];
        firstChange: boolean;
        isFirstChange(): boolean;
    }
};

