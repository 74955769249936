
<div *ngIf="numberOfReviews > 0" class="fc-star-rating">
    <div class="fc-star-rating-compact" *ngIf="compact">{{rating | number:'1.1' }} </div>
    <div class="fc-star-rating-back">
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
    
        <div class="fc-star-rating-front" [ngStyle]="getWidthPercentage()">
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
        </div>
    </div>  
    <div class="fc-star-rating-review-count" *ngIf="compact"> ({{numberOfReviews| number:'1.0':'en-GB'}}) </div>

    <span *ngIf="!compact" style="padding-left: 8px;">
        <span style="font-weight: 700;">{{rating | number:'1.1' }}</span> from {{numberOfReviews| number:'1.0':'en-GB'}} reviews.&nbsp;
        <a [href]="url" *ngIf="url && message">{{message}}</a>
    </span>
</div>
<div *ngIf="!numberOfReviews || numberOfReviews == 0">
    No reviews yet
</div>