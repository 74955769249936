import { Component, Input } from '@angular/core';
import { BrandSummaryDto } from 'src/app/models/generated/client/brand/BrandSummaryDto';
import { FCRouterLinkDirective } from '../../directives/fc-router-link.directive';

@Component({
    selector: 'fc-brand-card',
    templateUrl: './brand-card.component.html',
    styleUrls: ['./brand-card.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective]
})
export class BrandCardComponent {
  @Input() brand: BrandSummaryDto;
  @Input() allBrandsUrl: string;
  @Input() category: string;

  getAltText(brand: BrandSummaryDto): string {
    return `${ brand.title} Brand Logo | ${ this.category}`;
  }

  getUrl(brand: BrandSummaryDto): string {
    return this.allBrandsUrl ?  `${this.allBrandsUrl}/${brand.url}` : `${brand.url}`;
  }
}
