import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgIf, NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'fc-icon',
    templateUrl: './icon.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgStyle, NgClass]
})
export class IconComponent {

  isIllistration = false;

  @Input() altText: string;
  @Input() style: any;

  private _src: string;

  @Input() set src(value: string) {
    this._src = value;
    this.isIllistration = value?.endsWith('.svg') === true ? true : false;
  }

  get src(): string {
    return this._src;
  }
}
