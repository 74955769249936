import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationRootDto } from 'src/app/models/generated/navigation/NavigationRootDto';
import { Breadcrumb, BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fc-default-breadcrumb',
    templateUrl: './default-breadcrumb.component.html',
    styleUrls: ['./default-breadcrumb.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FCRouterLinkDirective]
})
export class DefaultBreadcrumbComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input() rootLabel = 'Home';
  @Input() showOnRoot = true;
  @Input() showExternal = true;
  @Input() showPreviousOnly = false;
  @Input() showLowest = true;

  showBreadcrumb = true;
  breadcrumb: Breadcrumb[] = [];
  root: NavigationRootDto | null | undefined;
  mobileBreadcrumb: Breadcrumb;

  constructor(public breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.subscription = this.breadcrumbService.onBreadcrumbChanged().subscribe((t) => {
      if (t != null) {
        const trail = JSON.parse(JSON.stringify(t));

        this.root = trail.navigation?.root;
        // If we are showing the external we do not want to show the category;
        if (this.showExternal) {
          trail.breadcrumb.shift();
        }

        // If we are to show the previus only then any time the breadcrumb trail is still above two then we need to remvoe it;
        if (this.showPreviousOnly && trail.breadcrumb.length > 2) {
          do {
            trail.breadcrumb.shift();
          } while (trail.breadcrumb.length > 2);
        }

        this.breadcrumb = trail.breadcrumb;

        // Workout what is the mobile breadcrumb;
        if (this.breadcrumb.length >= 1) {
          this.mobileBreadcrumb = this.breadcrumb[this.breadcrumb.length - 2];
        }

        // If we want to show the breadcrumb on the root then any length is fine, otherwise it must be at lease one level higher;
        this.showBreadcrumb = this.showOnRoot || this.breadcrumb.length !== 1;

        if (!this.showLowest) {
          this.breadcrumb.pop();
        }
      }
    });
  }

  ngOnDestroy(): void  {
    this.subscription.unsubscribe();
  }

}
