import { Component, Input } from '@angular/core';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { ApplyComponent } from '../../../shared/buttons/apply/apply.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-product-seperator',
    templateUrl: './product-seperator.component.html',
    styleUrls: ['./product-seperator.component.scss'],
    standalone: true,
    imports: [NgIf, ApplyComponent]
})
export class ProductSeperatorComponent {
  @Input() applySettings: ApplySettings;
  @Input() page: ProductPageDTO;
}
