import { Injectable, Optional, Inject, ComponentRef, EmbeddedViewRef } from "@angular/core";
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import UAParser from 'ua-parser-js';


@Injectable({ providedIn: 'root' })
export class ScreenSizeService {
    constructor(@Optional() @Inject(REQUEST) private request: Request) { }

    estimateSize(): 'sm' | 'md' | 'lg' {
        const width = window.innerWidth; // Where possible use innerWidth;
        if (!width) {
            const uaString = this.request.headers['user-agent'];
            if (uaString) {
            const parser = new UAParser(uaString);
            const deviceType = parser.getResult().device.type;

            if (deviceType === 'mobile') {
                return 'sm';
            } else if (deviceType === 'tablet') {
                return 'md';
            }
            }
            return 'lg';
        } else {
            if (width < 768) {
                return 'sm';
            } else if (width < 1024) {
                return 'md';
            } else {
                return 'lg';
            }
        }
    }
}
