export class AccordianBaseComponent {
    constructor(private readonly accodianClass: string, private readonly expandedClass: string) {}
  
    private collapseAll(items: HTMLDivElement[]): void {
      for (const i of items) {
          if (i.classList.contains(this.expandedClass)) {
            i.classList.remove(this.expandedClass);
            const panels = i.getElementsByClassName(this.accodianClass);
            if (panels.item(0)) {
                (panels.item(0) as any).style.maxHeight = null;
            }
          }
      }
    }
  
    toggleAccordian(event: any): void {
      const panel = event.target.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
        event.target.parentElement.classList.remove(this.expandedClass);
      } else {
        this.collapseAll(event.target.parentElement.parentElement.children);
        panel.style.maxHeight = panel.scrollHeight + 'px';
        event.target.parentElement.classList.add(this.expandedClass);
      }
    }
  }