import { SectorDTO } from '../../models/generated/SectorDTO';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({ providedIn: 'root' })
export class SectorService {
  url =  AppConfigService.AppEnvironment.apiEndPoint + '/Sector';
  constructor(private http: HttpClient) { }

  public GetSectorDetails(sector: string): Observable<SectorDTO> {
    return this.http.get<SectorDTO>(`${this.url}/${sector}`);
  }
}
