
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BalanceTransferInput } from 'src/app/models/shared/inputs/BalanceTransferInputDTO';
import { DefaultInputDto } from 'src/app/models/shared/inputs/defaults/DefaultInputDto';
import { InputType } from 'src/app/models/shared/inputs/InputType';
import { StandardInputDto } from 'src/app/models/shared/inputs/StandardInputDto';
import { PersonalLoanInput } from 'src/app/models/shared/inputs/PersonalLoanInputDto';
import { MortgagesInput } from 'src/app/models/shared/inputs/MortgagesInputDto';
import { CreditCardsInput } from 'src/app/models/shared/inputs/CreditCardsInputDto';
import { SavingsAccountInput } from '../models/shared/inputs/SavingsAccountInputDto';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class InputService {

  private input?: StandardInputDto = undefined;
  private previousInputs?:  { [url: string] : StandardInputDto; } = {};
  
  private defaultInput: DefaultInputDto;
  private isCategory: boolean;
  private refreshTable: Subject<boolean> = new Subject();
  private showAll: Subject<boolean> = new Subject();

  private inputChange: BehaviorSubject<StandardInputDto> = new BehaviorSubject(StandardInputDto.Initialise());

  private showMobileMenuChange: Subject<boolean> = new Subject();


  constructor(private route: ActivatedRoute, private router: Router) { }

  initiliseDefault(defaultInput: DefaultInputDto | null | undefined, isCategory: boolean): StandardInputDto {
    if (!defaultInput) {
      return StandardInputDto.Initialise();
    }

    if (this.previousInputs) {
      var previous = this.previousInputs[this.getUrl()]
      if (previous) {
        return previous;
      }
    }

    const queryParams = this.route.snapshot.queryParams;

    switch (defaultInput.kind) {
      case InputType.CreditCards: {
        return CreditCardsInput.Initialise(defaultInput, queryParams);
      }
      case InputType.BalanceTransfer: {
        return BalanceTransferInput.Initialise(defaultInput, queryParams);
      }
      case InputType.Mortgages: {
        return MortgagesInput.Initialise(defaultInput, queryParams);
      }
      case InputType.PersonalLoans: {
        return PersonalLoanInput.Initialise(defaultInput, queryParams);
      }
      case InputType.SavingsAccounts: {
        return SavingsAccountInput.Initialise(defaultInput, isCategory, queryParams);
      }
      default: {
        return StandardInputDto.Initialise(defaultInput);
      }
    }
  }

  clearDefault(): void {
    delete this.input;
  }

  setDefault(defaultInput: DefaultInputDto | null, isCategory: boolean): void {
      if (defaultInput) {
        this.defaultInput = defaultInput;
        this.isCategory = isCategory;
        this.input = this.initiliseDefault(defaultInput, isCategory);
      } else {
        this.input = StandardInputDto.Initialise();
      }

      this.inputChange.next(this.input);
  }

  setShowAll(showAllOptions: boolean) {
    this.showAll.next(showAllOptions);
  }

  resetToDefault() {
    this.setDefault(this.defaultInput, this.isCategory);
    this.instructTableRefresh();
  }


  setBrands(brands:Set<number>) {
    if (this.input) {
      this.input.brands = [...brands];
    }
  }

  private getUrl() : string {
    const i = this.router.url.indexOf('?') < 0 ? this.router.url.length : this.router.url.indexOf('?');
    return this.router.url.substring(1, i);
  }

  instructTableRefresh() {
    if (this.previousInputs) {
      this.previousInputs[this.getUrl()] = JSON.parse(JSON.stringify(this.input));
    }
    this.closeFilterMenu();
    this.refreshTable.next(true);
  }

  onRefreshTableEvent() {
    return this.refreshTable.asObservable();
  }

  getInput() {
    return this.input;
  }

  onInputChangeEvent() {
    return this.inputChange.asObservable();
  }

  onShowAllEvent() {
    return this.showAll.asObservable();
  }

  showFilterMenu(){
    this.showMobileMenuChange.next(true);
  }
  closeFilterMenu(){
    this.showMobileMenuChange.next(false);
  }
  onShowFilterMenu() {
    return this.showMobileMenuChange.asObservable();
  }
}
