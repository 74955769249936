import { Component, Input,OnChanges } from '@angular/core';
import { NgChanges, Utils } from 'src/app/models/shared/Utils';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { GroupOptionListDTO } from 'src/app/models/generated/group/GroupOptionListDTO';
import { ButtonListStyleSettings, DropDownListItem } from 'src/app/models/shared/ButtonListStyleSettings';
import { ActivatedRoute } from '@angular/router';
import { GroupOptionDTO } from 'src/app/models/generated/group/GroupOptionDTO';

@Component({
    template: ''
  })
export abstract class BaseGroupAction implements OnChanges {
  @Input() pageDetails: CategoryDTO | GroupDTO | BrandDto;
  @Input() options: GroupOptionListDTO;

  @Input() settings: ButtonListStyleSettings = {
    placeHolder: 'Select a Feature',
    class: 'btn-dark',
  };

  @Input() accentTheme = 'light';

  popularItems: DropDownListItem<GroupOptionDTO>[] = [];
  otherItems: DropDownListItem<GroupOptionDTO>[] = [];
  popularColSizeClass: string[];

  constructor(private activatedRoute: ActivatedRoute) {
  }


  ngOnChanges(changes: NgChanges<BaseGroupAction>): void {
    if (changes.options.currentValue != null) {
      this.settings.placeHolder = this.options.dropDownPlaceholder;
      this.settings.class = Utils.GetButtonClass(this.accentTheme);
      this.popularItems = [];
      this.otherItems = [];

      const currentUrl = this.activatedRoute.snapshot.url.pop()?.path;
      let counter = 1;
      for (const opt of this.options.options) {

        const activeClass = (currentUrl === opt.url) ? 'fc-dropdown-item active' : 'fc-dropdown-item';

        const item: DropDownListItem<GroupOptionDTO> = { label: opt.title, class: activeClass, option: opt, order: counter++ };
        opt.isPopular ? this.popularItems.push(item) : this.otherItems.push(item);

      }

      this.popularColSizeClass = [];
      this.popularColSizeClass.push('col-md-' + 12 / this.popularItems.length);
      this.popularColSizeClass.push('col-sm-12');


    }
  }

  getRouterLink(option: DropDownListItem<GroupOptionDTO>): string {
    if (option.option?.url) {
        return '/' + this.pageDetails.navigation.sector.url + '/' + this.pageDetails.navigation.category.url + '/' + option.option?.url;
    }
    return '';
  }
}