import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CategoryNavType, MenuColumnDto, PartnerMenuDto } from 'src/app/models/shared/helpers/MenuHelper';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { NgFor, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'fc-desktop-menu',
    templateUrl: './desktop-menu.component.html',
    styleUrls: ['./desktop-menu.component.scss'],
    standalone: true,
    imports: [NgFor, FCRouterLinkDirective, NgTemplateOutlet]
})
export class DesktopMenuComponent {

  @Input() menuItem: PartnerMenuDto;
  @Input() menuCount: number;

  private columnCount(): number
  {
    return this.menuItem.columns?.length || 0;
  }

  private getColumnWidth(column: MenuColumnDto): number {
    switch (column.type) {
      case CategoryNavType.Comparison:
        return 210;
      case CategoryNavType.Article:
        return 270;
      case CategoryNavType.Product:
        return 300;
    }
  }

  @HostBinding('style.--fc-dmc-columns') get gridColumns(): string {
    let columns = '';
    this.menuItem.columns.forEach((column) => {
      columns += `${this.getColumnWidth(column)}px `;
    });
    return columns;
  }

  @HostBinding('style.--fc-dmc-top') get top(): string {
    return this.menuItem.bottom + 'px' ?? '50px';
  }

 

  @HostBinding('style.display') get display(): 'grid' | 'none'  {
    return  this.columnCount() > 0 && this.menuItem.visible ? 'grid' : 'none';
  }

  @HostBinding('style.left') get left(): string {
    if (this.menuItem.centerX) {
      const subMenuWidth = this.getEstimatedWidth();
      let left = this.menuItem.centerX - this.getLeftOffset(subMenuWidth);

      // Ensure we don't go off the left  & right side of the screen;
      if (this.menuItem.parentDOMRect) {
        if (left < this.menuItem.parentDOMRect.left) {
          left = this.menuItem.parentDOMRect.left;
        }
        const parentEndX = this.menuItem.parentDOMRect.width + this.menuItem.parentDOMRect.x;
        const menuEndX = subMenuWidth + left;

        if (menuEndX > parentEndX) {
          left = left - (menuEndX - parentEndX);
        }

        return `${left}px`;
      }
    }

    return '0px';
  }

  private getEstimatedWidth(): number { 
    // Try to get the actual width of the sub menu.
    const subMenu = document.getElementById('nca-sub-menu-' + this.menuItem.index);
    if (subMenu) {
      const subMenuRect = subMenu.getBoundingClientRect();
      if (subMenuRect.width > 0) {
        return subMenuRect.width;
      }
    }

    // This won't be accurate but it's better than nothing.
    let columnWidth = 210;
    this.menuItem.columns.forEach((column) => {
      columnWidth += this.getColumnWidth(column);
    });

    return columnWidth;
  }

  private getLeftOffset(width: number): number {
    return width / 2;
  }

  closeMenu(): void {
    this.menuItem.visible = false;
  }
}
