import { SortOptionDTO } from '../../../models/generated/sort/SortOptionDTO';
import { ButtonListStyleSettings, DropdownItemTypes, DropDownListItem } from '../../../models/shared/ButtonListStyleSettings';
import { SortDTO } from 'src/app/models/generated/sort/SortDTO';
import { Component, Input, Output, EventEmitter, OnChanges, HostBinding } from '@angular/core';
import { NgChanges } from 'src/app/models/shared/Utils';
import { InputService } from 'src/app/services/input.service';
import { AnalyticsSortDirective } from '../../../directives/analytics/analytics-sort.directive';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'fc-sort',
    templateUrl: './sort.component.html',
    styleUrls: ['./sort.component.scss'],
    standalone: true,
    imports: [NgFor, AnalyticsSortDirective, NgClass]
})
export class DefaultSortComponent implements OnChanges {
  @Input() options: SortDTO;
  @Input() settings: ButtonListStyleSettings = {
    placeHolder: 'Sort',
    class: 'btn-light'
  };

  @Output() sortOptionChanged = new EventEmitter<SortOptionDTO>();

  items: DropDownListItem<SortOptionDTO>[] = [];
  activeSort: SortOptionDTO;

  @HostBinding('class.expanded') exanded = false;


  constructor(private inputService: InputService) {}

  ngOnChanges(changes: NgChanges<DefaultSortComponent>): void {
    if (changes.options.currentValue != null) {

      if (!this.activeSort) {
        this.activeSort = this.options.defaultOption;
      }

      this.items = [
        {
          label: this.options.defaultOption.label,
          order: 0,
          option: this.options.defaultOption,
          class: this.getActiveClass(this.options.defaultOption),
        },
        { label: '', class: 'fc-dropdown-divider', order: 1 }
      ];

      let counter = 2;
      for (const o of this.options.otherOptions) {
        this.items.push({
          label: o.label,
          order: counter++,
          option: o,
          class: this.getActiveClass(o),
        });
      }
    }
  }

  getActiveClass(option: SortOptionDTO): DropdownItemTypes {
    return (option === this.activeSort) ? 'fc-dropdown-item active' : 'fc-dropdown-item';
  }

  onOptionSelected(option: SortOptionDTO): void {
    this.activeSort = option;

    this.items.forEach(x => {
      if (x.option) {
        x.class = this.getActiveClass(x.option);
      }
    });

    this.exanded = false;
    this.sortOptionChanged.emit(option);
  }

  onShowFilter(): void {
    this.inputService.showFilterMenu();
  }

  toggleExpanded(): void {
    this.exanded = !this.exanded;
  }
}
