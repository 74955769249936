import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationDto, SearchResultsDto } from 'src/app/models/generated/navigation/NavigationDto';
import { FullNavigationDto } from 'src/app/models/generated/PartnerSiteDTO';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private data: FullNavigationDto[];
  private navigationChange: BehaviorSubject<FullNavigationDto[]> = new BehaviorSubject([]);
  private pageChange: BehaviorSubject<NavigationDto | null> = new BehaviorSubject(null);
  public categoryExpanded = false;

  private endPoint: string;

  constructor(private http: HttpClient) { }

  onStart(endPoint: string): void {
    this.endPoint = endPoint;
    if (!this.data) {
      // TODO: Bring back this code, depending on some setting, so it is not triggered by all sites but only those that need it.
      // this.GetFullNavigation(endPoint).subscribe(x => {
      //   this.data = x;
      //   this.navigationChange.next(x);
      // });
    }
  }

  private GetFullNavigation(endPoint: string): Observable<FullNavigationDto[]> {
    return this.http.get<FullNavigationDto[]>(endPoint + '/Navigation');
  }

  onNavigationChangeEvent(): Observable<FullNavigationDto[]> {
    return this.navigationChange.asObservable();
  }

  public setNextPage(page: NavigationDto): void {
    this.pageChange.next(page);
  }

  onPageChangeEvent(): Observable<NavigationDto | null> {
    return this.pageChange.asObservable();
  }

  performSearch(keywords: string, currentCategory: number | undefined): Observable<SearchResultsDto[]> {
     const limit = 5;
     if (currentCategory) {
       return this.http.get<SearchResultsDto[]>(this.endPoint + `/Navigation/Search?keywords=${encodeURIComponent(keywords)}&currentCategory=${currentCategory}&limit=${limit}`);
     } else {
       return this.http.get<SearchResultsDto[]>(this.endPoint + `/Navigation/Search?keywords=${encodeURIComponent(keywords)}&limit=${limit}`);
     }
  }
}
