import { Component, HostBinding, Input } from '@angular/core';
import { TableSettingsDto } from 'src/app/models/generated/sort/SortDTO';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { CommercialStatus } from 'src/app/models/generated/table/TableWeightingDTO';
import { PageType, PageTypeHelper, PageTypes } from 'src/app/models/shared/helpers/PageTypesHelper';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { Utils } from 'src/app/models/shared/Utils';
import { PageDataLayer } from 'src/app/services/analytics.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { environment } from 'src/environments/environment';
import { CompareService } from '../../../services/compare.service';


export interface TableSettings {
  pageDetails: PageTypes;
  page: PageType;
  paging: PagingParameterDto;
  sourceUid?: string | undefined;
  sectorUrl: string;
  categoryUrl: string;
  groupUrl?: string;
  tableSettings: TableSettingsDto | null;
  visibility: {
    showCompare: boolean;
  };
}

export interface ItemSettings {
  content: TableItemDTO;
  url: string | null;
  position: number;
  classes: string[];
}

@Component({ template: '' })
export abstract class ItemBaseComponent {
  constructor(private compareService: CompareService) { }

  @HostBinding('style.--fc-col-lg-columns') columnLgColumns: string;
  @HostBinding('style.--fc-col-lg-areas') columnLgAreas: string;

  @Input() item: ItemSettings;
  @Input() settings: TableSettings;

  @HostBinding('class')
  get classes(): string[] {
    const classes = this.item.classes;
    const tableSize = this.item.content.columns.length > 5 ? 'fc-table-large' : 'fc-table-small';
    classes.push(tableSize);

    const compact = this.settings.tableSettings?.tableTemplate.includes('compact') ? 'fc-compact-table' : 'fc-standard-table';


    classes.push(compact);
    return classes;
  };

  onCompareItem(e: any, item: TableItemDTO): void {
    if (e.target.checked) {
      this.compareService.addItem(item);
    } else {
      this.compareService.removeItem(item);
    }
  }

  getItemOrder(column: TableColumnDTO, index: number): string[] {
    const cssClass: string[] = [];
    cssClass.push('fc-shade-' + (index + 1));
    column.attributes.forEach(x => { cssClass.push(x); });
    return cssClass;
  }

  getApplySettings(): ApplySettings | null {
    let page: PageDataLayer| null = null; 
    if (PageTypeHelper.isArticle( this.settings.pageDetails)) {
      page = {
        id:  this.settings.pageDetails.shortUid,
        title: this.settings.pageDetails.title,
        type: 'Article',
      };
    } else if (PageTypeHelper.isComparisonPage( this.settings.pageDetails)) {
      page = {
        id:  this.settings.pageDetails.id.toString(),
        type: 'Category',
        title: this.settings.pageDetails.title ?? this.settings.pageDetails.heading,
      };
      if (Utils.isBrandComparisonPage(this.settings.pageDetails)) {
        page.type = 'Brand';
      } else if (Utils.isGroupComparisonPage(this.settings.pageDetails)) {
        page.type = 'Group';
      } 
    } else if (PageTypeHelper.isSector(this.settings.pageDetails)) {
      page = {
        id:  this.settings.pageDetails.id.toString(),
        title: this.settings.pageDetails.title,
        type: 'Sector',
      };
    }

    if (page) {
      return {
        placement: 'table',
        page: page,
        brand: this.item.content.brand,
        product: this.item.content.product,
        table: {
          id: this.settings.paging.tableId,
          position: this.item.content.weighting.position,
          promotedPosition: this.item.content.weighting.promotedPosition,
          promotedId: this.item.content.weighting.promotedId,
          sortOptionId: this.settings.paging.sortOptionId,
          pageNumber: this.settings.paging.pageNumber,
          pageSize: this.settings.paging.pageSize,
        },
        moreDetailsRoute: this.item.url,
        columns: this.item.content.columns,
        slug: this.settings.pageDetails.navigation.slug,
      }
    }

    return null;
  }

  getButtonType(): 'Apply' | 'MoreDetails' | 'None' {
    if ( environment.client.alwaysApply ||
         this.item.content?.product.status === CommercialStatus.Product ||
         this.item.content?.weighting.promotedId) {
      return 'Apply';
    }
    return (this.item.url) ? 'MoreDetails' : 'None';
  }

  getPrimaryToolTip(column: TableColumnDTO): 'Not Available' | '' {
    if (column.primaryMessage === 'N/A') {
      return 'Not Available';
    }
    return '';
  }

  getToolTipClass(column: TableColumnDTO): 'fc-na' | null {
    if (column.primaryMessage === 'N/A') {
      return 'fc-na';
    }
    return null;
  }

}
