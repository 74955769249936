import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollerService {
    constructor(@Inject(DOCUMENT) private document: Document) { }
    scrollIntoView(target: string | null): void {
        if (target && this.document) {
            const hTag = this.document.getElementById(target);
            if (hTag != null) {
                const y = hTag.getBoundingClientRect().top + window.pageYOffset + -16;
                window.scrollTo({ top: y });
            }
        }
    }
}
