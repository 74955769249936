
import { Component } from '@angular/core';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { RichcontentService } from 'src/app/services/richcontent.service';
import { ScreenSizeService } from 'src/app/services/screenSize.service';
import { AccordianBaseComponent } from 'src/app/shared/accordian-base-compontent';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';
import { NgFor } from '@angular/common';
import { ContentHeaderSummaryComponent } from '../utils/content-header-summary/content-header-summary.component';

@Component({
    selector: 'fc-content-accordion',
    templateUrl: './content-accordion.component.html',
    styleUrls: ['./content-accordion.component.scss'],
    standalone: true,
    imports: [ContentHeaderSummaryComponent, NgFor, MarkedPipe]
})
export class ContentAccordionComponent extends ContentBaseComponent {
  private accodianHandler: AccordianBaseComponent;
  constructor(private richContentService: RichcontentService, screenSizeService: ScreenSizeService) {
    super(screenSizeService);
    this.accodianHandler = new AccordianBaseComponent('fc-accordion-content', 'fc-accordian-expanded');

  }

  onContentChanged(value: ContentContainerDto): void {
     this.richContentService.generateFAQ(value);
  }

  onClick(event: any): void {
    this.accodianHandler.toggleAccordian(event);
  }
}
