import { Component, Input } from '@angular/core';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { AnalyticsService, ProductDataLayer, TableDataLayer } from 'src/app/services/analytics.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';

@Component({
    selector: 'fc-more-details',
    templateUrl: './more-details.component.html',
    styleUrls: ['./more-details.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective]
})
export class MoreDetailsComponent  {
  @Input() content: TableItemDTO;
  @Input() paging: PagingParameterDto;
  @Input() route: string;

  constructor(private analyticsService: AnalyticsService) { }

  onMoreDetails(): void {
    const settings: ApplySettings =  {
      placement: 'table',
      brand: this.content.brand,
      product: this.content.product,
      table: {
        id: this.paging.tableId,
        position: this.content.weighting.position,
        promotedId: this.content.weighting.promotedId,
        promotedPosition: this.content.weighting.promotedPosition,
        sortOptionId: this.paging.sortOptionId,
        pageNumber: this.paging.pageNumber,
        pageSize: this.paging.pageSize,
      },
      columns: this.content.columns
    };
    this.analyticsService.onMoreDetails(settings, this.content.columns);
  }
}
