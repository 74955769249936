<fc-promoted-label [product]="item?.content"></fc-promoted-label>
<div class="fc-table-container" *ngIf="item.content">
    <fc-table-image [product]="item.content"></fc-table-image>
    <fc-table-header [route]="item.url" [content]="item.content"></fc-table-header>
    <fc-apply [settings]="getApplySettings()"></fc-apply>
    <fc-compare-checkbox [item]="item.content" [paging]="settings.paging"></fc-compare-checkbox>
    <fc-more-details [route]="item.url" [content]="item.content" *ngIf="getButtonType() != 'MoreDetails'"  [paging]="settings.paging"></fc-more-details> 
    <div class="fc-columns">
        <fc-column-container class="fc-columns-flat" [columns]="stack.columns"></fc-column-container>
        <fc-column-container class="fc-columns-stack-one" *ngIf="stack.stackOne.length > 0"  [columns]="stack.stackOne" [stackNumber]="1"></fc-column-container> 
        <fc-column-container class="fc-columns-stack-two" *ngIf="stack.stackTwo.length > 0"  [columns]="stack.stackTwo" [stackNumber]="2"></fc-column-container>
    </div>
    <div class="fc-table-summary" *ngIf="item.content.product.summary" [innerHtml]="item.content.product.summary | markedInline"></div>
</div>