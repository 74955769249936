

<div class="fc-article-header" *ngIf="content.heading" [attr.id]="getId(content.heading)">
    <h2 *ngIf="!content.internalRoute || content.internalRoute === '-'">{{content.heading}}</h2>
    <h2 class="fc-header" *ngIf="content.internalRoute && content.internalRoute !== '-'">
        <a [FCRouterLink]="content.internalRoute" [innerHtml]="content.heading"></a>
    </h2>
    <ul class="fc-tags">
        <li *ngFor="let t of content.tags" ><a [FCRouterLink]="t.route">{{t.label}}</a></li>
    </ul>
</div>
<div class="fc-article-summary" *ngIf="content.summary" [innerHtml]="content.summary | markedInline: analyticsMode" ></div>
<ng-content></ng-content>
<div class="fc-article-footer"  *ngIf="content.footer"  [innerHtml]="content.footer | markedInline: analyticsMode" ></div>