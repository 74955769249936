<div class="fc-country-container" *ngIf="countries && currentCountry">
    <button class="fc-country-btn" (click)="showDropdown()" [ngClass]="[showDiv, position]">
        <label>
            <span class="fi" [ngClass]="currentCountry.icon"></span>
            {{currentCountry.label}}
        </label>
        <i class="fas fa-chevron-down"></i>
    </button>
    <div class="fc-country-dropdown" [ngClass]="[showDiv, position]">
        <ul class="fc-ul">
            <li *ngFor="let c of countries">
                <a [href]="c.href" (click)="showDropdown()">
                    <span [ngClass]="c.flag"></span>
                    <label>{{c.label}}</label>
                </a>
            </li>
        </ul>
    </div>
</div>