import { DefaultInputDto } from 'src/app/models/shared/inputs/defaults/DefaultInputDto';
import { InputType } from './InputType';

export class  StandardInputDto {
    kind: InputType;
    includeAllProducts: boolean;
    brands: number[];

    static Initialise(defaultInput: DefaultInputDto | null = null): StandardInputDto {
        return {
            kind: InputType.None,
            includeAllProducts: defaultInput?.includeAllProducts ?? true,
            brands: []
        };
    }
}
