import { Component, Host, HostBinding } from '@angular/core';
import { BaseGroupAction } from 'src/app/shared/group-actions/base-group';
import { AnalyticsGroupfilterDirective } from '../../../directives/analytics/analytics-groupfilter.directive';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgFor, NgClass, NgIf } from '@angular/common';


@Component({
    selector: 'fc-group-dropdown',
    templateUrl: './group-dropdown.component.html',
    styleUrls: ['./group-dropdown.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, FCRouterLinkDirective, AnalyticsGroupfilterDirective, NgIf]
})
export class GroupDropdownComponent extends BaseGroupAction {

  @HostBinding('class.expanded') exanded = false;

  toggleExpanded(): void {
    this.exanded = !this.exanded;
  }
}

