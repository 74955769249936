import { Component, ComponentFactoryResolver, ComponentRef, Directive, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { InputService } from 'src/app/services/input.service';
import { FilterSettings, InputComponent } from 'src/app/shared/filters/base-filters';
import { MortgagesDetailedInputComponent } from 'src/app/shared/filters/mortgages-detailed-input/mortgages-detailed-input.component';
import { MortgagesInputComponent } from 'src/app/shared/filters/mortgages-input/mortgages-input.component';
import { PersonalLoansInputComponent } from 'src/app/shared/filters/personal-loans-input/personal-loans-input.component';
import { NgIf } from '@angular/common';



// Directive Used to inject item at appriate time.
@Directive({
    selector: '[fcInputHost]',
    standalone: true
})
export class InputHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}



@Component({
    selector: 'fc-input-switcher',
    templateUrl: './input-switcher.component.html',
    styleUrls: [
        './input-switcher.component.scss',
        './shared-styles/btn-more-options.scss',
        './shared-styles/btn-update-results.scss'
    ],
    standalone: true,
    imports: [InputHostDirective, NgIf]
})
export class InputSwitcherComponent {
  _templateName: string;
  get templateName(): string {
      return this._templateName;
  }
  @Input() set templateName(value: string) {
      this._templateName = value;
      this.renderComponent(value);


  }
  showAllOptions: boolean;
  @Input() showVertical = false;
  @Input() enableCategoryBrand = false;

  availableTemplates: { [id: string]: Type<InputComponent>; } = {
    'fc-personal-loans-input': PersonalLoansInputComponent,
    'fc-mortgages-input': MortgagesInputComponent,
    'fc-mortgages-detailed-input': MortgagesDetailedInputComponent,
  };

  @ViewChild(InputHostDirective, { static: true }) fcInputHost!: InputHostDirective;
  
  constructor(private inputService: InputService, private componentFactoryResolver: ComponentFactoryResolver) { }


  onToggleRefine(): void {
    this.showAllOptions = !this.showAllOptions;
    this.inputService.setShowAll(this.showAllOptions);
  }

  onSelectedBrands(selectedBrands: Set<number>): void {
    this.inputService.setBrands(selectedBrands);
  }

  onUpdateResults(): void {
    this.inputService.instructTableRefresh();
  }

  closeMobileFilter(): void {
    this.inputService.closeFilterMenu();
  }

  getOrientation(): 'fc-filter-vertical' | 'fc-filter-horizontal' {
    return this.showVertical ? 'fc-filter-vertical' : 'fc-filter-horizontal';
  }

  private getSettings(): FilterSettings {
    return {
      showVertical: this.showVertical
    }

  }

  renderComponent(templateName: string): void {
    const viewContainerRef = this.fcInputHost.viewContainerRef;
    viewContainerRef.clear();

    if (templateName) {

      const componentAttribute = this.availableTemplates[templateName]
      if (componentAttribute) {
        const factory: any = this.componentFactoryResolver.resolveComponentFactory(componentAttribute);
        const component: ComponentRef<InputComponent> = viewContainerRef.createComponent(factory);
        component.instance.settings = this.getSettings();
      }
    }
  }
}