import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { IScriptInitilizer } from 'src/app/services/script.service';
import { environment } from 'src/environments/environment';
import { EnvironmentOptionsType } from 'src/environments/environmentDefinitions';


@Injectable({ providedIn: 'root' })
export class NewsAuScriptInitilizer implements IScriptInitilizer {
    helper: RenderHelper;

    constructor(@Inject(DOCUMENT) private doc: Document, private httpClient: HttpClient, private sanitizer: DomSanitizer) {
        this.helper = new RenderHelper(doc);
    }

    renderStyleSheet(): void { }
    
    renderProrityStyle(): void {
        let style = '#third-party-footer #fixed-ad,#third-party-header .header_ads-container{display:none}';
        style += '#third-party-header{min-height:96px}';
        style += '@media only screen and (min-width:900px){#third-party-header{min-height:182px}}';
        style += '.navigation { margin-bottom: 0 !important }';
        style += '.header.g_wrapper { margin-bottom: 0 !important }';
        this.helper.renderPriorityStyle('news-au-css', style);
    }

    renderPreLoad(): void {
      this.helper.renderPreload('news-au-font', 'https://resourcesssl.newscdn.com.au/cs/ts2020/assets/fonts/eva-bold.woff2');
    }

    renderPreFetch(): void {
        this.helper.renderPrefetch('newsau-tig', 'https://tags.tiqcdn.com/');
        this.helper.renderPrefetch('newsau-tags', 'https://tags.news.com.au/');
        this.helper.renderPrefetch('newsau-metrics', 'https://metrics.news.com.au/');
    }

    renderScripts(): void {
        this.renderGooglePixel();
        this.renderYahooPixel();
        this.renderFacebookPixel();
    }

    renderTemplates(e: EnvironmentOptionsType): void {
        // let source: 'sit' | 'uat' | 'prod' = 'sit';
        // if (e === 'production') {
        //     source = 'prod';
        // } else if (e === 'staging') {
        //     source = 'uat';
        // }

        // const placement = this.doc.querySelector(`meta[name="twitter:site"]`);
        // if (placement) {
        //     const headers = new HttpHeaders({
        //         Accept: 'text/plain'
        //     });
        //     this.httpClient.get(`https://tags.news.com.au/${source}/data-esi/top/all.esi?url=$(HTTP_HOST)$(REQUEST_PATH)`, {
        //         headers,
        //         responseType: 'text'
        //     }).subscribe(x => {
        //         placement.insertAdjacentHTML('afterend', x);
        //     });
        // }
    }

    private renderGooglePixel(): void {
        this.helper.renderSource('newsau-floodlight-pixel', 'https://www.googletagmanager.com/gtag/js?id=DC-12588586');
        this.helper.renderSource('newsau-googleads-pixel', 'https://www.googletagmanager.com/gtag/js?id=AW-10998708370');

        let scriptTag = 'window.dataLayer = window.dataLayer || [];';
        scriptTag += 'function gtag(){dataLayer.push(arguments);}';
        scriptTag += 'gtag(\'js\', new Date());';
        scriptTag += 'gtag(\'config\', \'DC-12588586\');';
        scriptTag += 'gtag(\'config\', \'AW-10998708370\');';

        this.helper.renderScript('google-globaltag', scriptTag);
    }

    private renderYahooPixel(): void {
        const functionTag = `(function(w,d,t,r,u){w[u]=w[u]||
            [];w[u].push({'projectId':'10000','properties':{'pixelId':'10190205','he': '<email_address>','auid':
            '<sha256_hashed_user_id>'}});var
            s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var
            y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded")
            {return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e)
            {}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})
            (window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");`;

        this.helper.renderScript('yahoo-pixel', functionTag);
        this.helper.renderScript('yahoo-globaltag', 'window.dotq = window.dotq || [];');
    }

    private renderFacebookPixel(): void {
        const functionTag = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');`;

        this.helper.renderScript('facebook-pixel', functionTag);
    }
}

