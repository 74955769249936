import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrandCategoryDto } from 'src/app/models/generated/client/brand/BrandCategoryDto';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({ providedIn: 'root' })
export class BrandService {
    url =  AppConfigService.AppEnvironment.apiEndPoint + '/Brands';

    constructor(private http: HttpClient) { }
  
    GetAllBrands(sector:string, category: string ): Observable<BrandCategoryDto> {
      return this.http.get<BrandCategoryDto>( `${this.url}/All/${sector}/${category}`);
    }

    GetTopBrands(sector:string, category: string): Observable<BrandCategoryDto> {
      return this.http.get<BrandCategoryDto>( `${this.url}/Top/${sector}/${category}`);
    }

    GetDetails(sector:string, category:string, brand: string): Observable<BrandDto> {
      return this.http.get<BrandDto>(`${this.url}/Details/${sector}/${category}/${brand}`);
    }

}
