import { CompareDetailsComponent } from './../shared/compare/compare-details/compare-details.component';
import { ComponentRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { ModalService } from 'src/app/services/modal.service';

@Injectable({ providedIn: 'root' })
export class CompareService {
  public items: TableItemDTO[] = [];
  private showHide: Subject<boolean> = new Subject();
  modalRef: ComponentRef<CompareDetailsComponent>;


  constructor(private modalService: ModalService) { }

  public addItem(item: TableItemDTO): void {
    if (this.items.length <= 4) {
      this.items.push(item);
      this.showHide.next(true);
    }
  }

  public removeItem(item: TableItemDTO): void {
    item.compare = false;
    const index = this.items.indexOf(item, 0);
    if (index > -1) {
       this.items.splice(index, 1);
    }

    if (this.items.length === 0) {
      this.showHide.next(false);
    }
  }

  public removeAllItems(): void {
    for (const i of this.items) {
      i.compare = false;
    }
    this.items = [];
    this.showHide.next(false);
  }


  public showCompare(pageDetails: CategoryDTO | GroupDTO | BrandDto, paging: PagingParameterDto): void {
    this.modalRef  = this.modalService.openModal(CompareDetailsComponent);
    this.modalRef.instance.items = this.items;
    this.modalRef.instance.pageDetails = pageDetails;
    this.modalRef.instance.paging = paging;
    this.modalRef.instance.onClose.subscribe(x => this.hideCompare());
  }

  public hideCompare(): void {
    if (this.modalRef) {
      this.modalRef.instance.onClose.unsubscribe();
      this.modalRef.destroy();
      this.removeAllItems();
    }
  }

  public onShowHideEvent(): Observable<boolean> {
    return this.showHide.asObservable();
  }
}
