
<div class="nca-menu-background"></div>
<div class="nca-header" id="nca-secondary-menu">
    <a class="nca-icon-container" [FCRouterLink]="'/compare-money'">
        <img src="https://ik.imagekit.io/fairaus/newsau/icons/newsau_compare_money.svg" alt="Compare Money" width="190" height="30" loading="eager">
    </a>
    <nav class="nca-nav">
        <ul>
            <li *ngFor="let mi of menuItems; let i = index" 
                (mouseenter)="showMenu(mi, $event, i)" 
                (mouseleave)="hideMenu(mi)" 
                >
                <a [FCRouterLink]="mi.route">{{mi.label}}</a>
                <fc-desktop-menu [menuItem]="mi" [menuCount]="menuItems.length" [attr.id]="'nca-sub-menu-' + i"></fc-desktop-menu>
            </li>
        </ul>
    </nav>
    <div class="nca-mobile-action">
        <a (click)="toggleMobileMenu($event)">Categories</a>
    </div>
</div>

<div class="nca-breadcrumb">
    <fc-default-breadcrumb [showOnRoot]="false" [showExternal]="false" [showPreviousOnly]="false" [showLowest]="false"></fc-default-breadcrumb>
</div>

<div class="nca-mobile-background" [ngStyle]="mobile.background" (click)="toggleMobileMenu()"></div>
<div class="nca-mobile-menu" [ngStyle]="mobile.menu">
    <div class="nca-mobile-header">
        <div><i class="fas fa-times" (click)="toggleMobileMenu()"></i></div>
        <a [FCRouterLink]="'/compare-money'" (click)="toggleMobileMenu()">
            <img src="https://ik.imagekit.io/fairaus/newsau/icons/newsau_compare_money.svg" alt="Compare Money" width="190" height="30" loading="eager">
        </a>
    </div>
    

    <fc-mobile-menu [settings]="mobileSettings" (onItemExpanded)="onMobileExpand($event)" (onMenuClosed)="onMobileClosed()"></fc-mobile-menu>
</div>