import { Component, Input } from '@angular/core';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CompareService } from 'src/app/services/compare.service';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'fc-compare-checkbox',
    templateUrl: './compare-checkbox.component.html',
    styleUrls: ['./compare-checkbox.component.scss'],
    standalone: true,
    imports: [NgClass, FormsModule]
})
export class CompareCheckboxComponent {
  @Input() item: TableItemDTO;
  @Input() paging: PagingParameterDto;
  constructor(public compareService: CompareService, private analyticsService: AnalyticsService) { }

  onCompareItem(e: any, item: TableItemDTO): void {
    if (e.target.checked) {
         this.compareService.addItem(item);
         this.analyticsService.onItemCompareChecked({ id: this.paging.tableId, pageSize: this.paging.pageSize, pageNumber: this.paging.pageNumber});
    } else {
        this.compareService.removeItem(item);
    }
 }

  isDisabled(): boolean {
    return this.item.compare ? false : this.compareService.items.length >= 4;
  }

  getClass(): 'fc-compare-promoted' | '' {
    return this.item.weighting.promotedId > 0 ? 'fc-compare-promoted' : '';
  }
}
