import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PartnerSiteTemplateDTO } from '../../models/generated/PartnerSiteDTO';
import { map, tap } from 'rxjs/operators';
import { PartnerMenuDto } from 'src/app/models/shared/helpers/MenuHelper';


@Injectable({providedIn: 'root' })
export class PartnerSiteService {
  private template: BehaviorSubject<PartnerSiteTemplateDTO | null> = new BehaviorSubject(null);
  private partnerMenu: PartnerMenuDto[]| null;

  constructor(private http: HttpClient) { }

  fetchTemplate(endpoint: string): Observable<PartnerSiteTemplateDTO> {
    return this.http.get<PartnerSiteTemplateDTO>(`${endpoint}/PartnerSite/template`)
      .pipe(
        tap(t => {
          this.template.next(t);
        })
      );
  }
  
  onTemplateChanged(): Observable<PartnerSiteTemplateDTO | null> {
    return this.template.asObservable();
  }

  getPartnerMenu(url: string): Observable<PartnerMenuDto[] | null> {
    if (this.partnerMenu = null) {
      return of(this.partnerMenu);
    } else {
      return this.http.get<PartnerMenuDto[]>(`${url}/partnersite/GetPartnerMenu`).pipe(
        map((response: any) => {
          this.partnerMenu = response;
          return response;
        })
      );
    }
  }
}
