export enum CategoryNavType
{
  Comparison = 1,
  Article,
  Product
}

export interface MenuItemDto {
  label: string;
  route: string;
  isExternal?: boolean;
}

export interface MenuColumnDto extends MenuItemDto  {
  items: MenuItemDto[];
  type: CategoryNavType;
}

export interface PartnerMenuDto extends MenuItemDto {
  columns: MenuColumnDto[];

  index?: number;
  centerX?: number;
  bottom?: number;
  parentDOMRect?: DOMRect;
  visible?: boolean;
}

export interface LowestLevelMenuDto extends MenuItemDto {
  imageSrc?: string;
  suffix?: string;
}


export type MenuType = PartnerMenuDto | MenuColumnDto | LowestLevelMenuDto;

export class MenuUtils {

    static isVisialMenu(item: LowestLevelMenuDto): boolean {
      return item.imageSrc !== undefined;
    }

    static isPartnerMenu(sp: MenuType): sp is PartnerMenuDto {
        return (sp as PartnerMenuDto).columns !== undefined;
    }

    static isMenuColumn(sp: MenuType): sp is MenuColumnDto {
        return (sp as MenuColumnDto).items !== undefined;
    }
    
}
