import { LocationStrategy } from '@angular/common';
import { Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ContentLinkDto } from 'src/app/models/generated/content/ContentComponentDto';
import { Utils } from 'src/app/models/shared/Utils';
import { AnalyticsModeType, AnalyticsService } from 'src/app/services/analytics.service';

@Directive({
    selector: 'a[fcContentLink]',
    standalone: true
})
export class ContentLinkDirective extends RouterLink  implements OnChanges, OnDestroy  {
  constructor( private analyticsService: AnalyticsService, router: Router, route: ActivatedRoute, renderer: Renderer2, protected elRef: ElementRef, locationStrategy: LocationStrategy) {
    super(router, route,null, renderer, elRef, locationStrategy);
  }

  @Input('fcContentLink') settings: ContentLinkDto;
  @Input() analyticsMode: AnalyticsModeType;
  @Input() mode: 'RouteText' | 'Block'  = 'RouteText'

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setupValues(changes.settings.currentValue, changes.analyticsMode.currentValue)) {
      super.ngOnChanges(changes);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private nativeElement(): HTMLAnchorElement  {
    return this.elRef.nativeElement as HTMLAnchorElement;
  }

  private setupValues(link: ContentLinkDto, analyticsMode: AnalyticsModeType): boolean {
    this.nativeElement().classList.add('fc-content-link');

    // If we don't have ant settings then return;
    if (!link.internalRoute && !link.externalUrl) {
      return  false;
    }

    let isInternalRoute = false;

    if (link.internalRoute) {
      this.routerLink = Utils.getMaskedCommand(link.internalRoute);
      this.target = Utils.getLinkTarget(analyticsMode, link.internalRoute);
      isInternalRoute = true;
    } else if (link.externalUrl) {
      this.href = link.externalUrl;
      this.target = Utils.getLinkTarget(analyticsMode, link.externalUrl);
    }

    if (this.mode == 'RouteText') {
      this.nativeElement().innerHTML = link.internalRouteText ?? 'Read More';
      this.setDataTags(link, analyticsMode, this.nativeElement().innerHTML);
    } else {
      this.setDataTags(link, analyticsMode, link.heading);
    }

    return isInternalRoute;
  }


  private setDataTags(link: ContentLinkDto, analyticsMode: AnalyticsModeType, label: string) {
    const dataTags = this.analyticsService.getAnalyticsModeTags(analyticsMode, {
      label: label,
      href: link.externalUrl ?? link.internalRoute
    });

    this.analyticsService.setDataTags(dataTags, this.nativeElement());
  }

  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean, altKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey || altKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    if (this.settings.externalUrl) {
      return true;
    }

    this.analyticsService.setLastClickedElement(this.elRef.nativeElement);
    return super.onClick(button, ctrlKey, shiftKey, altKey, metaKey);
  }
}
