import { Component, ComponentFactoryResolver, Directive, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { DefaultTopHeaderComponent } from 'src/app/templates/headers/default-top-header/default-top-header.component';
import { ExecutivetravellerHeaderComponent } from 'src/app/templates/headers/executivetraveller-header/executivetraveller-header.component';
import { IselectTopHeaderComponent } from 'src/app/templates/headers/iselect-top-header/iselect-top-header.component';
import { NewscorpTopHeaderComponent } from 'src/app/templates/headers/newscorp-top-header/newscorp-top-header.component';
import { OthHeaderComponent } from 'src/app/templates/headers/oth-header/oth-header.component';
import { SavrrHeaderComponent } from 'src/app/templates/headers/savrr-header/savrr-header.component';
import { environment } from 'src/environments/environment';
import { HeaderTypeName } from 'src/environments/environmentDefinitions';

// Header Component
// tslint:disable-next-line: no-empty-interface
export interface HeaderComponent { }


// Directive Used to inject item at appriate time.
@Directive({
    selector: '[fcHeaderHost]',
    standalone: true
})
export class HeaderDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
    selector: 'fc-top-header-switcher',
    template: `<ng-template fcHeaderHost></ng-template>`,
    standalone: true,
    imports: [HeaderDirective]
})
export class TopHeaderSwitcherComponent implements OnInit {
  availableTemplates: { [id: string]: Type<HeaderComponent>; } = {
    'fc-newscorp-top-header': NewscorpTopHeaderComponent,
    'fc-iselect-top-header': IselectTopHeaderComponent,
    'fc-executivetraveller-header': ExecutivetravellerHeaderComponent,
    'fc-default-top-header': DefaultTopHeaderComponent,
    'fc-oth-header': OthHeaderComponent, 
    'fc-savrr-header': SavrrHeaderComponent
  };

  @ViewChild(HeaderDirective, { static: true }) fcHeaderHost!: HeaderDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.renderComponent(environment.client.setup.header);
  }

  renderComponent(templateName: HeaderTypeName): void {
    const viewContainerRef = this.fcHeaderHost.viewContainerRef;
    viewContainerRef.clear();

    if (templateName) {
      const component = this.availableTemplates[templateName];
      if (component) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        viewContainerRef.createComponent<HeaderComponent>(factory);
      }
    }
  }
}
