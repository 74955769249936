import { Component, HostBinding, Input } from '@angular/core';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { NgClass } from '@angular/common';
import { ToolTipComponent } from '../../../../shared/tool-tip/tool-tip.component';

@Component({
    selector: 'fc-column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss'],
    standalone: true,
    imports: [ToolTipComponent, NgClass, MarkedInlinePipe]
})
export class ColumnComponent {
  @Input() column: TableColumnDTO;
  @Input() index: number;
  @Input() stackNumber: number;

  @HostBinding('class.fc-col-stacked') get stackedCol(): boolean {
    return this.stackNumber > 0;
  }
  @HostBinding('class.fc-col-flat') get flatCol(): boolean {
    return this.stackNumber === 0;
  }

  @HostBinding('class.fc-column-tool-tip') get hasToolptip(): boolean {
    return (this.column.additionalInfo || this.column.description) ? true : false;
  }

  @HostBinding('class') get getColumnClass(): string[] {
    const cssClass: string[] = [];

    cssClass.push('fc-shade-' + (this.index + 1));

    this.column.attributes.forEach(x => {
      cssClass.push(x);
    });

    if (!this.column.secondaryMessage || this.column.secondaryMessage === ' ') {
      cssClass.push('fc-no-secondary-message');
    }

    if (this.stackNumber === 1) {
      cssClass.push('fc-stack-1');
    }
    if (this.stackNumber === 2) {
      cssClass.push('fc-stack-2');
    }

    return cssClass;
  }

  getPrimaryToolTip(column: TableColumnDTO): 'Not Available' | '' {
    return (column.primaryMessage === 'N/A') ?  'Not Available' : '';
  }

  getToolTipClass(column: TableColumnDTO): 'fc-na' | null {
    return (column.primaryMessage === 'N/A') ? 'fc-na' : null;
  }
}
