import { Component, Input } from '@angular/core';
import { ContentImageDto } from 'src/app/models/generated/content/ContentComponentDto';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { NgIf } from '@angular/common';

export type AspectRatioType = '16-9' | '4-3';
export type ImageDimensionType = 'Dynamic' | AspectRatioType;


@Component({
    selector: 'fc-content-image',
    templateUrl: './content-image.component.html',
    styleUrls: ['./content-image.component.scss'],
    standalone: true,
    imports: [NgIf, MarkedInlinePipe]
})
export class ContentImageComponent {
  values: ContentImageDto;

  private _details: ContentImageDto;
  private _imageDimensions: ImageDimensionType = 'Dynamic';
  private _width: number | null = null;

  get details(): ContentImageDto {
    return this._details;
  }
  @Input() set details(value: ContentImageDto) {
    this._details = value;
    this.updateDimensions();
  }

  get imageDimensions(): ImageDimensionType {
    return this._imageDimensions;
  }
  @Input() set imageDimensions(value: ImageDimensionType) {
    this._imageDimensions = value;
    this.updateDimensions();
  }

  updateDimensions(): void {
    switch (this._imageDimensions) {
      case 'Dynamic':
        this.values = this._details;
        break;
      case '16-9':
        this.values = this.generateSixteenByNine(this._details.imageSrc, this._details.altText);
        break;
      case '4-3':
        this.values = this.generateFourByThree(this._details.imageSrc, this._details.altText);
        break;

      // case 'LargeArticle':
      //   this.values = this.generateLargeArticle(this._details.imageSrc, this._details.altText);
      //   break;
      // case 'Standard':
      //   this.values = this.generateStandardArticle(this._details.imageSrc, this._details.altText);
      //   break;
    }
  }

  private trimImageSrc(imageSrc: string): string {
    if (imageSrc.indexOf('?') > 0) {
      return imageSrc.substring(0, imageSrc.indexOf('?'));
    }
    return imageSrc;
  }


  generateFourByThree(imageSrc: string, altText: string): ContentImageDto {
    imageSrc = this.trimImageSrc(imageSrc);

    let srcSet = '';
    srcSet += `${imageSrc}?tr=ar-4-3,w-150 150w, `;
    srcSet += `${imageSrc}?tr=ar-4-3,w-320 320w, `;
    srcSet += `${imageSrc}?tr=ar-4-3,w-650 650w, `;
    srcSet += `${imageSrc}?tr=ar-4-3,w-1024 1024w, `;


    return {
      imageSrc,
      altText,
      width: 1024,
      height: 768 / 4 * 3,
      srcSet,
      sizes: '70vmin',
      caption: null,
      priority: false,
    };
  }

 
  private generateSixteenByNine(imageSrc: string, altText: string): ContentImageDto {
    imageSrc = this.trimImageSrc(imageSrc);

    let srcSet = '';
    srcSet += `${imageSrc}?tr=ar-16-9,w-150 150w, `;
    srcSet += `${imageSrc}?tr=ar-16-9,w-320 320w, `;
    srcSet += `${imageSrc}?tr=ar-16-9,w-650 650w, `;
    srcSet += `${imageSrc}?tr=ar-16-9,w-1024 1024w, `;


    return  {
      imageSrc,
      altText,
      width: 1280,
      height: 1280 / 16 * 9,
      srcSet,
      sizes: '70vmin',
      caption: null,
      priority: false,
    };
  }

  // private generateLargeArticle(imageSrc: string, altText: string): ContentImageDto {
  //   imageSrc = this.setAspectRatio(imageSrc, 1024, '4-3');
  //   return  {
  //     imageSrc,
  //     altText,
  //     width: 1024,
  //     height: 768,
  //     srcSet: this.generateSrcSet(imageSrc),
  //     sizes: '(max-width: 600px) 100vw, (max-width: 1000px) 50vw, 650px',
  //     caption: null,
  //   };
  // }

  // private generateStandardArticle(imageSrc: string, altText: string): ContentImageDto {
  //   imageSrc = this.setAspectRatio(imageSrc, 150,'4-3');
  //   return  {
  //     imageSrc,
  //     altText,
  //     width: 150,
  //     height: 113,
  //     srcSet: this.generateSrcSet(imageSrc),
  //     sizes: '150px',
  //     caption: null
  //   };
  // }

}
