import { Component, HostBinding, HostListener, Inject, Input, OnInit, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ContentComponentDto } from 'src/app/models/generated/content/ContentComponentDto';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { PageTypes } from 'src/app/models/shared/helpers/PageTypesHelper';
import { AnalyticsModeType } from 'src/app/services/analytics.service';
import { ScreenSizeService } from 'src/app/services/screenSize.service';

@Component(
  { 
    template: '',
 }
)
export abstract class ContentBaseComponent implements OnInit {
  @Input() analyticsMode: AnalyticsModeType;

  @Input() page: PageTypes;

  _content: ContentContainerDto;
  get content(): ContentContainerDto {
      return this._content;
  }
  @Input() set content(value: ContentContainerDto) {
      this._content = value;

      this.setPositioning();
      this.variant =  value.accentTheme;

      if (this.onContentChanged) {
        this.onContentChanged(value);
      }
  }

  _index: number;
  get index(): number {
      return this._index;
  }
  @Input() set index(value: number) {
      this._index = value;
      this.setPositioning();
  }

  @HostBinding('class') variant: string;
  @HostBinding('style.grid-row') gridRow: string;
  @HostBinding('style.grid-column') gridColumn: string;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.setPositioning();
  }

  constructor(private screenSizeService: ScreenSizeService) { }

  ngOnInit(): void {
    this.setPositioning();
  }

  setPositioning(): void
  {
    const screenSize = this.screenSizeService.estimateSize();
    if (screenSize === 'sm' ) {
      this.gridRow = `${this.index + 1}  / span 1`;
      this.gridColumn = `col-start 1 / span 1`;
    } else if (screenSize === 'md') {
      this.gridRow = `${this.index + 1}  / span 1`;
      this.gridColumn = `col-start 1 / span 1`;
    } else {
      this.gridRow = `${this._content.y + 1}  / span ${this._content.rows}`;

      if (this.content.x + 1 === 1) {
        this.gridColumn = `col-start ${this._content.x + 1}  / span ${this._content.cols}`;
      } else {
        this.gridColumn = `${this._content.x + 2}  / span ${this._content.cols}`;
      }


    }
  }

  protected onContentChanged?(value: ContentContainerDto): void;

  HasImage(item: ContentComponentDto): boolean {
    return item.image?.imageSrc?.length > 0;
  }
}
