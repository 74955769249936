import { Component, Input } from '@angular/core';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { TableColumnAttributes, TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { CommercialStatus } from 'src/app/models/generated/table/TableWeightingDTO';
import { PageType, PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { Utils, ComparisonPage } from 'src/app/models/shared/Utils';
import { PageDataLayer, TableDataLayer } from 'src/app/services/analytics.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { MarkedInlinePipe } from '../../../pipes/marked.pipe';
import { ApplyComponent } from '../../buttons/apply/apply.component';
import { ColumnContainerComponent } from '../../../templates/tables/components/column-container/column-container.component';
import { TableImageComponent } from '../../../templates/tables/components/table-image/table-image.component';
import { PromotedLabelComponent } from '../../../templates/tables/components/promoted-label/promoted-label.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-advertised-product',
    templateUrl: './advertised-product.component.html',
    styleUrls: ['./advertised-product.component.scss'],
    standalone: true,
    imports: [NgIf, PromotedLabelComponent, TableImageComponent, ColumnContainerComponent, ApplyComponent, MarkedInlinePipe]
})
export class AdvertisedProductComponent {
  columns: TableColumnDTO[];
  table: TableDataLayer | null = null;
  commercialStatus: CommercialStatus;

  @Input() pageDetails: ComparisonPage | ProductPageDTO;
  @Input() showHeading: boolean;
  @Input() showPromoted: boolean;

  private _paging: PagingParameterDto;
  @Input() set paging(paging: PagingParameterDto) {
    this._paging = paging;
    this.updateFields();
  }

  _product: TableItemDTO | ProductPageDTO;
  get product(): TableItemDTO | ProductPageDTO {
      return this._product;
  }
  @Input() set product(value: TableItemDTO | ProductPageDTO) {
      this._product = value;
      this.updateFields();
  }

  private updateFields(): void {
    if (Utils.hasColumns(this._product)) {
      this.columns = this._product.columns.filter(x => x.attributes.includes(TableColumnAttributes.isPromoted));
      this.table = {
        id: this._paging?.tableId,
        position: this._product.weighting.position,
        promotedPosition: this._product.weighting.promotedPosition,
        promotedId: this._product.weighting.promotedId,
        sortOptionId: this._paging?.sortOptionId,
        pageNumber: this._paging?.pageNumber,
        pageSize: this._paging?.pageSize
      };
    }
  }

  GetHeading(): string {
    return PageTypeHelper.getBrandProductTitle(this.product.brand, this.product.product);
  }

  GetApplySettings(): ApplySettings {
      const details: ApplySettings = {
        placement: 'header',
        page: PageTypeHelper.getPageDataLayer(this.pageDetails),
        brand: this.product.brand,
        product: this.product.product,
        table: this.table,
        columns: this.columns
      };
      return details;
  }
}
