import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication-service.service';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { FooterComponent } from 'src/app/templates/footers/footer-switcher.component';

@Component({
    selector: 'fc-newcorp-footer',
    templateUrl: './newcorp-footer.component.html',
    styleUrls: ['./newcorp-footer.component.scss'],
    standalone: true
})
export class NewcorpFooterComponent implements FooterComponent  {
  constructor(public authenticationService: AuthenticationService) { }

  @Input() links: SectorStaticFooterDto[];

}
