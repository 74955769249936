import { Component, Input } from '@angular/core';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { AnalyticsModeType } from 'src/app/services/analytics.service';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fc-article-header',
    templateUrl: './article-header.component.html',
    styleUrls: ['./article-header.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, NgFor, MarkedInlinePipe]
})
export class ArticleHeaderComponent {
  @Input() content: ContentContainerDto;
  @Input() analyticsMode: AnalyticsModeType;


  getId(heading: string): string {
    return heading.replace(/\s+/g, '-').toLowerCase();
  }

}
