import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {makeStateKey, TransferState} from '@angular/core'
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState, private injector: Injector, private router: Router,@Inject(PLATFORM_ID) private platformId:object, private sessionStorageService: SessionStorageService) {
  }



  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET') {
      const storedResponse = this.transferState.get(makeStateKey(request.url), null);
      if (storedResponse) {
        const response = new HttpResponse({ body: storedResponse, status: 200 });
        return of(response);
      }
    }


    const tipEnvironment = this.sessionStorageService.getConfiguration().tipEnvironment;
    let header: any;

    if (tipEnvironment) {
      header = {
        'x-api-key': environment.client.api.key,
        'x-api-version': environment.client.api.version,
        'fc-tip-environment': tipEnvironment
      };
    } else {
      header = {
        'x-api-key': environment.client.api.key,
        'x-api-version': environment.client.api.version
      };
    }

    const authRequest = request.clone({
      setHeaders: header
    });

    return next.handle(authRequest)
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (isPlatformBrowser(this.platformId) && event instanceof HttpResponse && event.body?.landingPageOverride) {
              window.location = event.body?.landingPageOverride;
            }
          }
        ),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (isPlatformBrowser(this.platformId) && error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }

          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.router.navigateByUrl('/404', { skipLocationChange: true, replaceUrl: false });
          }
          return throwError(errorMessage);
        })
      );
  }
}

