import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgIf, NgClass, NgFor, JsonPipe } from '@angular/common';

interface CountryDto {
  flag: string;
  label: string;
  href: string;
}

@Component({
    selector: 'fc-country-picker',
    templateUrl: './country-picker.component.html',
    styleUrls: ['./country-picker.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        JsonPipe
    ],
})
export class CountryPickerComponent implements OnInit {
  @Input() openTop: boolean = false;
 
  countries: CountryDto[];
  showDiv: 'fc-show' |'fc-hidden' = 'fc-hidden';
  position: 'fc-top' | 'fc-bottom' = 'fc-bottom';
  currentCountry: null |  { 
    icon: 'fi-au' | 'fi-vn' | 'fad fa-globe-asia'
    label: string
  };

  constructor() {
    switch (environment.client.code) {
      case 'savrr-au':
          this.currentCountry =  {
            icon: 'fi-au',
            label: 'Australia'
          }
          break;
        case 'savrr-vn':
          this.currentCountry =  {
            icon: 'fi-vn',
            label: 'Vietnam'
          }
          break;
          case 'savrr-gb':
            this.currentCountry =  {
              icon: 'fad fa-globe-asia',
              label: 'Country'
            }
            break;
    }
    
    switch (environment.environment) {
      case 'production': {
        this.countries = [
          { flag: 'fad fa-globe-asia',  label: 'Country', href: 'https://www.savrr.com' },
          { flag: 'fi fi-au',  label: 'Australia', href: 'https://www.savrr.com/au/' }, 
          { flag: 'fi fi-vn',  label: 'Vietnam',  href: 'https://www.savrr.com/vn/'}
        ];      
        break;
      }
      case  'staging': {
        this.countries = [
          { flag: 'fad fa-globe-asia',  label: 'Country', href: 'https://www.staging.savrr.com' },
          { flag: 'fi fi-au',  label: 'Australia', href: 'https://staging-au.savrr.com/au/' }, 
          { flag: 'fi fi-vn',  label: 'Vietnam',  href: 'https://staging-vn.savrr.com/vn/'}
        ];
        break   
      }
      default: {
        this.countries = [
          { flag: 'fad fa-globe-asia',  label: 'Country', href: 'https://dev.savrr.com' }, 
          { flag: 'fi fi-au',  label: 'Australia', href: 'https://dev-au.savrr.com/au/' }, 
          { flag: 'fi fi-vn',  label: 'Vietnam',  href: 'https://dev-vn.savrr.com/vn/'}
        ];
      }
    }
  }

  ngOnInit(): void {
    this.position = this.openTop ? 'fc-top' : 'fc-bottom';
  }

  toggleHiddenDiv() {
    this.showDiv = this.showDiv == 'fc-show' ? 'fc-hidden' : 'fc-show';
  }

  showDropdown() {
    this.toggleHiddenDiv();
  }

}
