
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { AppConfigService } from 'src/app/services/app-config.service';
import { TopicDto } from 'src/app/models/article/TopicDto';

@Injectable({ providedIn: 'root' })
export class GroupService {
  url =  AppConfigService.AppEnvironment.apiEndPoint + '/group';
  constructor(private http: HttpClient) { }

  public GetDetails(sector: string, category: string, group: string): Observable<GroupDTO | TopicDto> {
    return this.http.get<GroupDTO | TopicDto>(`${this.url}/${sector}/${category}/${group}`);
  }
}
