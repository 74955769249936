import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { IScriptInitilizer } from 'src/app/services/script.service';


@Injectable({ providedIn: 'root' })
export class SavrrInitilizer implements IScriptInitilizer {
    helper: RenderHelper;
    constructor(@Inject(DOCUMENT) private doc: Document) {
        this.helper = new RenderHelper(doc);
    }

    renderStyleSheet(): void {
        this.helper.renderStyleSheet('flags','https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.9.2/css/flag-icons.min.css', true);
    }
    renderProrityStyle(): void {}
    renderPreLoad(): void {
        
    }
    renderPreFetch(): void {}
    renderTemplates(): void {}
    renderScripts(): void {}

}

