<div class="fc-input-four-columns">
    <fc-input-column label="Estimated Property Price">
        <fc-slider-input [value]="values.estimatedPropertyPrice" (valueChange)="reCalculateValue($event, 'estimatedPropertyPrice')" prefix="$" min="50000" max="5000000" step="10000"></fc-slider-input>
    </fc-input-column>
    <fc-input-column label="Deposit Amount">
        <fc-slider-input [value]="values.depositAmount" (valueChange)="reCalculateValue($event, 'depositAmount')" prefix="$" min="10000" [max]="getMax()" step="10000"></fc-slider-input>
    </fc-input-column>
    <fc-input-column label="Deposit Percentage">
        <fc-slider-input [value]="values.depositRate" (valueChange)="reCalculateValue($event, 'depositRate')" suffix="%" min="1" max="100"></fc-slider-input>
    </fc-input-column>
    <fc-input-column label="Borrow Amount">
        <fc-slider-input [value]="values.borrowingAmount"(valueChange)="reCalculateValue($event,'borrowingAmount')"  prefix="$" min="10000" [max]="getMax()" step="10000"></fc-slider-input>
    </fc-input-column>
</div>
<div class="fc-input-four-columns" *ngIf="isVisible()">
    <fc-input-column label="Repayment Term">
        <fc-slider-input [(value)]="values.loanTerm" suffix="Years" min="1" max="30"></fc-slider-input>
    </fc-input-column>
    <fc-input-column label="Loan Type" >
        <select class="fc-form-control" [(ngModel)]="values.loanPurpose">
            <option *ngFor="let p of loanPurposes.options" [ngValue]="p.value">{{p.label}}</option>
        </select>
    </fc-input-column>
    <fc-input-column label="Repayment Type">
        <select class="fc-form-control" [(ngModel)]="values.repaymentType">
            <option *ngFor="let p of repaymentTypes.options" [ngValue]="p.value">{{p.label}}</option>
        </select>
    </fc-input-column>
    <fc-input-column label="Fixed Period" *ngIf="values.interestType != 2">
        <select class="fc-form-control" [(ngModel)]="values.fixedTermPeriod">
            <option *ngFor="let p of fixedTermPeriods.options" [ngValue]="p.value">{{p.label}}</option>
        </select>
    </fc-input-column>
</div>
<div class="fc-input-four-columns" *ngIf="isVisible()">
    <fc-input-column label="Interest Type" >
        <fc-option-input [(value)]="values.interestType" [settings]="interestTypeSettings"></fc-option-input>
    </fc-input-column>
    <fc-input-column label="Allows Extra Repayments">
        <fc-option-input [(value)]="values.extraRepayments" [settings]="yesNoSettings"></fc-option-input>
    </fc-input-column>
    <fc-input-column label="Has Redraw Facility">
        <fc-option-input [(value)]="values.redrawFacility" [settings]="yesNoSettings"></fc-option-input>
    </fc-input-column>
    <fc-input-column label="Has Offset Account">
        <fc-option-input [(value)]="values.offsetAccount" [settings]="yesNoSettings"></fc-option-input>
    </fc-input-column>
</div>