import { Component, Input } from '@angular/core';
import { ItemGroupAttributeDTO } from 'src/app/models/generated/item/ItemGroupAttributeDTO';
import { ElementDisplayPipe } from 'src/app/pipes/element-display.pipe';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { ToolTipComponent } from '../../../../shared/tool-tip/tool-tip.component';
import { NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'fc-standard-element-display',
    templateUrl: './standard-element-display.component.html',
    styleUrls: ['./standard-element-display.component.scss'],
    standalone: true,
    imports: [NgClass, NgFor, ToolTipComponent, MarkedInlinePipe, ElementDisplayPipe]
})
export class StandardElementDisplayComponent {
  @Input() value: ItemGroupAttributeDTO;
  @Input() accentTheme: string;
}
