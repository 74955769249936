
<fc-content-header-summary [content]="content" [analyticsMode]="analyticsMode">
    <div class="fc-content-grid-template">
        <div class="fc-content-grid-template-item" *ngFor="let c of content.components; index as i;">
            <h3 *ngIf="c.heading">
                <fc-icon *ngIf="c.iconSrc" [src]="c.iconSrc" [altText]="c.heading"></fc-icon>
                <span [innerHtml]="c.heading"></span>
            </h3>
            <div [innerHtml]="c.content | marked: analyticsMode"></div>
            <a *ngIf="c.externalUrl || c.internalRoute" [fcContentLink]="c" [analyticsMode]="analyticsMode"></a>
        </div>
    </div>
</fc-content-header-summary>
