import { PartnerAppSettingsType } from 'src/environments/environmentDefinitions';

export const PartnerAppSettings: PartnerAppSettingsType =
{
  loginDetails: {
    username: 'AustraliaDemo',
    password: 'no-password-set'
  },
  dev: {
    environment: 'dev',
    partnerDomainRoot: 'http://localhost:4000',
    enableLogin: false
  },
  publicDev: {
    environment: 'public-dev',
    partnerDomainRoot: 'https://dev-au.savrr.com',
    enableLogin: false
  },
  staging: {
    environment: 'staging',
    partnerDomainRoot: 'https://staging-au.savrr.com',
    enableLogin: false
  },
  production: {
    environment: 'production',
    partnerDomainRoot: 'https://www.savrr.com',
    enableLogin: false
  }
}