import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { StaticPageParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ContentService } from 'src/app/services/api/content-service.ts.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({  providedIn: 'root'})
export class SectorStaticPageResolver  {
  constructor(private contentService: ContentService, private breadcrumbService: BreadcrumbService, private seoService: SeoService, private analyticsService: AnalyticsService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<SectorStaticPageDto> {
    const params: StaticPageParams = Utils.GetParams<StaticPageParams>(route);
    const path = params.key ?? route.routeConfig?.path?.substring(route.routeConfig?.path?.lastIndexOf('/') + 1,  route.routeConfig?.path?.length);
    return this.contentService.GetStaticPage(params.sector, params.category, path).pipe(map(sp => {
      try {
        sp.navigation.sectorStaticPage =  path;
        let cannonical = sp.navigation.sector.canonicalLink;
        if (sp.navigation.category) {
          cannonical += `/${sp.navigation.category.url}`;
          this.breadcrumbService.setBreadCrumb(sp.navigation, sp.navigation.sector, sp.navigation.category, sp.title);
        } else {
          this.breadcrumbService.setBreadCrumb(sp.navigation, sp.navigation.sector, sp.title);
        }

        cannonical += `/disclaimers/${path}`;

        this.seoService.setBasicNoIndex(sp.heading, params.sector, null, 'noIndex, nofollow', cannonical);

        this.analyticsService.onStaticPageView(sp);


      } catch (error) {
        console.error(`Unable to resolve product (${params.sector}/${params.category}/${params.brand}/${params.product}):`, error);
      }
      return sp;
    }));
  }
}
