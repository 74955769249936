import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { TopicDto } from 'src/app/models/article/TopicDto';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { GroupOptionDTO } from 'src/app/models/generated/group/GroupOptionDTO';
import { CompareItemDTO } from 'src/app/models/generated/item/CompareValueDTO';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { SectorDTO } from 'src/app/models/generated/SectorDTO';
import { SortOptionDTO } from 'src/app/models/generated/sort/SortOptionDTO';
import { TableBrandDto, TableItemDTO, TableProductDto } from 'src/app/models/generated/table/TableItemDTO';
import { PromotedPosition } from 'src/app/models/generated/table/TableWeightingDTO';
import { DropDownListItem } from 'src/app/models/shared/ButtonListStyleSettings';
import { PageTypeHelper, PageTypes } from 'src/app/models/shared/helpers/PageTypesHelper';
import { StandardInputDto } from 'src/app/models/shared/inputs/StandardInputDto';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { Utils } from 'src/app/models/shared/Utils';
import { BasicDataLayer, BrandDataLayer, DataTags, IAnalyticsClientService, PageDataLayer, ProductDataLayer, TableDataLayer } from 'src/app/services/analytics.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { CardSettings } from 'src/app/shared/category-card/category-card.component';

interface iSelectPageViewDataLayer {
  analytics: {
    site: {
      name: 'iSelect'
    },
    vertical: {
      name: string,
      pagetitle: string,
      section: 'home page' | 'get informed' | string | null,
      step: 'results' | 'product detail' | 'product comparison' | string | null,
      sessionId?: string
    }
  };
}

interface iAdobeWindow {
  eVar40: string | null;
  eVar76: string | null;
  pageURL: string;
  events: string;
  products: string | null;
  eVar92: string | null;
  eVar61: string | null;
  eVar113: eVar113 | null;
  clearVars(): void;
//  eVar132: eVar132 | null;
  tl(element: Element | null): void;
  t(): void;
}

interface AdobeWindow {
  s: iAdobeWindow;
  iselect: iSelectPageViewDataLayer | null;
  _satellite: any;
}


type eVar113 = 'test' | 'control';
type eVar132 = 'promoted'  | 'sponsored' | 'default';

class iSelectEVarSettings {
  eVar92: string | null;
  eVar61: string | null;
  eVar113: eVar113 | null;
 // eVar132: eVar132 | null;
}

@Injectable({ providedIn: 'root' })
export class ISelectAdobeTracking implements IAnalyticsClientService {
  constructor(@Inject(DOCUMENT) private doc: Document, private sessionStorageSer: SessionStorageService) { }
 
 
  
  getSessionId(): string | null  {
    return (window as unknown as AdobeWindow)?.s?.eVar40 || null;
  }

  getClientId(): string | null  {
    return (window as unknown as AdobeWindow)?.s?.eVar76 || null;
  }

  private createElementScript(dataLayer: iSelectPageViewDataLayer): void {
    const existingScript = this.doc.getElementById('iselect-adobe-tag');

    if (!existingScript) {
      const sc = this.doc.createElement('script');
      sc.type = 'text/javascript';
      sc.id = 'iselect-adobe-tag';

      const comamndText = 'window.iselect = ' + JSON.stringify(dataLayer);

      const scriptText = this.doc.createTextNode(comamndText);
      sc.appendChild(scriptText);
      this.doc.getElementsByTagName('head')[0].appendChild(sc);
    }
  }

  private updateDataLayer(dataLayer: iSelectPageViewDataLayer, pageUrl: string, events: string, products: string | null, eVarSettings: iSelectEVarSettings | null, lastClickedElement: any | null): void {
    if (window && (window as any).s) {
      const adobeWindow = window as unknown as AdobeWindow;

      if (adobeWindow.s?.clearVars) {
        adobeWindow.s.clearVars();
      }
      adobeWindow.iselect = dataLayer;

      if (pageUrl) {
        adobeWindow.s.pageURL = pageUrl;
      }

      adobeWindow.s.events = events;

      if (products) {
        adobeWindow.s.products = products;
      }

      if (eVarSettings?.eVar92) {
        adobeWindow.s.eVar92 = eVarSettings.eVar92;
      }

      if (eVarSettings?.eVar61) {
        adobeWindow.s.eVar61 = eVarSettings.eVar61;
      }


      if (eVarSettings?.eVar113) {
        adobeWindow.s.eVar113 = eVarSettings.eVar113;
      }

     // if (eVarSettings?.eVar132) {
     //   adobeWindow.s.eVar132 = eVarSettings?.eVar132;
     // }


      if (adobeWindow.s?.t) {
        adobeWindow.s.t();
      }

      if (adobeWindow._satellite)  {
        adobeWindow._satellite.track('fair-comparison-event');
      }

      return;
    } else {
      this.createElementScript(dataLayer);
    }
  }

  private GetUrl(categoryUrl: string, groupUrl?: string | null, brandUrl?: string, productUrl?: string): string {
    let url = `https://www.iselect.com.au/${categoryUrl}`;

    if (groupUrl) {
      url += `/${groupUrl}`;
    }

    if (brandUrl && !productUrl) {
      url += `/all-brands/${brandUrl}`;
    } else if (brandUrl && productUrl) {
      url += `/product/${brandUrl}/${productUrl}`;
    }

    return url;
  }

  onSectorPageView(pageDetails: SectorDTO, lastClickedElement: any): void {
  }

  onCategoryPageView(pageDetails: CategoryDTO, lastClickedElement: any): void {
    const url = this.GetUrl(pageDetails.navigation.category.url);
    const events = `event1,event5:${ this.sessionStorageSer.getOrSetSessionId()},event74:${this.sessionStorageSer.getOrSetSessionId()}`;

    const eVars: iSelectEVarSettings = {
      eVar61: null,
      eVar92: null,
      eVar113: this.getEnvironment(),
    //  eVar132: null
    };

    this.updateDataLayer({
      analytics: {
        site: {
          name: 'iSelect'
        },
        vertical: {
          name: pageDetails.navigation.category.url,
          pagetitle: pageDetails.navigation.category.url,
          section: 'home page',
          step: 'results',
          sessionId: this.sessionStorageSer.getOrSetSessionId()
        }
      }
    }, url, events, null, eVars, null);
  }

  onGroupPageView(pageDetails: GroupDTO, lastClickedElement: any): void {
    const url = this.GetUrl(pageDetails.navigation.category.url, pageDetails.navigation.group.url);
    const events = `event1,event5:${this.sessionStorageSer.getOrSetSessionId()},event74:${this.sessionStorageSer.getOrSetSessionId()}`;

    const eVars: iSelectEVarSettings = {
      eVar61: null,
      eVar92: pageDetails.title.toLowerCase(),
      eVar113: this.getEnvironment(),
  //    eVar132: null
    };

    this.updateDataLayer({
      analytics: {
        site: {
          name: 'iSelect'
        },
        vertical: {
          name: pageDetails.navigation.category.url,
          pagetitle: pageDetails.navigation.group.url,
          section: `get informed:${pageDetails.title}`,
          step: 'results',
          sessionId: this.sessionStorageSer.getOrSetSessionId()
        }
      }
    }, url, events, null, eVars, lastClickedElement);
  }

  onBrandPageView(pageDetails: BrandDto, lastClickedElement: any): void {
    const url = this.GetUrl(pageDetails.navigation.category.url, null, pageDetails.navigation.brandUrl);
    const events = `event1,event5:${this.sessionStorageSer.getOrSetSessionId()},event74:${this.sessionStorageSer.getOrSetSessionId()}`;

    const eVars: iSelectEVarSettings = {
      eVar61: null,
      eVar92: null,
      eVar113: this.getEnvironment(),
    };

    this.updateDataLayer({
      analytics: {
        site: {
          name: 'iSelect'
        },
        vertical: {
          name: pageDetails.navigation.category.url,
          pagetitle: pageDetails.heading,
          section: `get informed:${pageDetails.heading}`,
          step: 'results',
          sessionId: this.sessionStorageSer.getOrSetSessionId()
        }
      }
    }, url, events, null, eVars, lastClickedElement);
  }

  onProductPageView(page: ProductPageDTO, lastClickedElement: any): void {
    const url = this.GetUrl(page.navigation.category.url, page.navigation.group?.url, page.navigation.brandUrl, page.product.slug);
    const events = `event1,prodView,event79:${this.sessionStorageSer.getOrSetSessionId()}`;
    let title = page.product.title;
    if (page.product.varianceTitle) {
      title += ' - ' + page.product.varianceTitle;
    }
    const products = `;${page.brand.name}|${title};1;;prodView;`;

    const eVars: iSelectEVarSettings = {
      eVar61: null,
      eVar92: null,
      eVar113: this.getEnvironment(),
      // eVar132: null
    };

    this.updateDataLayer({
      analytics: {
        site: {
          name: 'iSelect',
        },
        vertical: {
          name: page.navigation.category.url,
          pagetitle: `${page.brand.name} ${page.product.title}`,
          section: `get informed:${page.brand.name} ${page.product.title}`,
          step: 'product detail',
          sessionId: this.sessionStorageSer.getOrSetSessionId()
        }
      },
    }, url, events, products, eVars, lastClickedElement);
  }

  onArticleListPageView(articles: TopicDto, lastClickedElement: any): void {}
  onArticlePageView(article: ArticleDTO, lastClickedElement: any): void { }
  onStaticPageView(disclaimer: SectorStaticPageDto, lastClickedElement: any): void { }


  getCategoryDataTags(category: CardSettings): DataTags { return null; }
  getSortDataTags(item: DropDownListItem<SortOptionDTO>): DataTags { return null; }
  getGroupDataTags(item: DropDownListItem<GroupOptionDTO>): DataTags { return null; }

  getGoToSiteDataTags(settings: ApplySettings): DataTags {
    return [
      { key: 'data-product', value: this.getDataProductString(settings.brand, settings.product) },
      { key: 'data-environment', value: this.getEnvironment() ?? '' },
      { key: 'data-promoted-type', value: this.getProductType(settings) }
    ];
  }

  getArticleLinkTags(label: string, href: string): DataTags { return null; }
  getComparisonLinkTags(label: string, href: string): DataTags { return null; }



  private getDataProductString(brand: BrandDataLayer, product: ProductDataLayer): string {
    if (!brand || !product) {
      return '';
    }
    return `;${brand.name}|${PageTypeHelper.getProductTitle(product)};1;;;`;
  }

  private getProductType(settings: ApplySettings): string {
    if (!settings.table?.promotedPosition) {
      return 'default';
    } else if (settings.table?.promotedPosition === PromotedPosition.Headline) {
      return 'sponsored';
    } else {
      return 'promoted';
    }
  }

  private getEnvironment(): eVar113 | null {
    if (this.sessionStorageSer.getConfiguration().tipEnvironment === 'B-Promoted') {
      return 'test';
    } else if (this.sessionStorageSer.getConfiguration().tipEnvironment === 'A-NoPromoted') {
      return 'control';
    }
    return null;
  }


  onCompare(navigation: NavigationDto, items: BasicDataLayer[], table: TableDataLayer, lastClickedElement: any): void {
    const events = 'event1,event66';

    let products = '';
    items.forEach((p) => {
      products += `;${p.brand.name}|${PageTypeHelper.getProductTitle(p.product)};1;;event66;,`;
    });

    const eVars: iSelectEVarSettings = {
      eVar61: items.length.toString(),
      eVar92: null,
      eVar113: this.getEnvironment(),
     // eVar132: null
    };

    this.updateDataLayer({
      analytics: {
        site: {
          name: 'iSelect'
        },
        vertical: {
          name: navigation.category.url,
          pagetitle: 'product comparison',
          section: 'sales funnel',
          step: 'product comparison',
          sessionId: this.sessionStorageSer.getOrSetSessionId()
        }
      }
    }, navigation.slug, events, products, eVars,  lastClickedElement);
  }

  onInitialTableLoad(paging: TableDataLayer): void {}
  onLoadMore(paging: TableDataLayer): void {}
  onUpdateResults(paging: TableDataLayer, inputs: StandardInputDto | undefined): void {}
  onMoreDetails(settings: ApplySettings): void {}
  onItemCompareChecked(table: TableDataLayer): void {}
  onGoToSite(settings: ApplySettings): void {}

}
