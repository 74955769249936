import { Component } from '@angular/core';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
@Component({
    selector: 'fc-default-top-header',
    templateUrl: './default-top-header.component.html',
    standalone: true,
    imports: [FCRouterLinkDirective, DefaultBreadcrumbComponent]
})
export class DefaultTopHeaderComponent implements HeaderComponent {
}
