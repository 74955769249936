import { Component, ComponentRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { CommercialStatus } from 'src/app/models/generated/table/TableWeightingDTO';
import { ClickEventRecordDto, EncodeClickEventRecord } from 'src/app/models/generated/tracking/ClickEventRecordDto';
import { Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService, BrandDataLayer, GoToSiteDataLayer, GoToSitePlacement, PageDataLayer, ProductDataLayer, TableDataLayer } from 'src/app/services/analytics.service';
import { CompareService } from 'src/app/services/compare.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SeoService } from 'src/app/services/seo.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { environment } from 'src/environments/environment';
import { AnalyticsGoToSiteDirective } from '../../../directives/analytics/analytics-go-to-site.directive';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgIf, NgClass } from '@angular/common';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmApplyComponent } from 'src/app/shared/buttons/confirm-apply/confirm-apply.component';


export interface ApplySettings
{
  placement: GoToSitePlacement;
  page?: PageDataLayer;
  brand: BrandDataLayer;
  product: ProductDataLayer;
  moreDetailsRoute?: string | null;
  table: TableDataLayer | null;
  columns: TableColumnDTO[] | null;
  slug?: string | null
}

export interface ButtonSettings {
  target: '_self' | '_blank';
  buttonType: 'Apply' | 'MoreDetails' | 'None';
  rel: string;
  label: string;
  url: string;
  queryParams?: {
    c: number, 
    q: string
  };
  id: string;
  class: 'btn-apply-advertised' | 'btn-apply-promoted' | 'btn-apply' | 'btn-apply-more-details';
  analyticSettings: ApplySettings | null;
}
@Component({
    selector: 'fc-apply',
    templateUrl: './apply.component.html',
    styleUrls: ['./apply.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, AnalyticsGoToSiteDirective, NgClass, ConfirmApplyComponent]
})
export class ApplyComponent implements OnInit, OnDestroy {
  _settings: ApplySettings;
  get settings(): ApplySettings {
    return this._settings;
  }
  @Input() set settings(value: ApplySettings) {
    this._settings = value;

    if (value.slug) {
      this.slug = value.slug;
    }
    this.settingsHaveChanged();
  }

  

  clientClickId: string;
  buttonSettings: ButtonSettings;
  applyVisible = false;
  confirmApplyVisible = false;

  slug: string;
  goToSite: GoToSiteDataLayer | null;


  subscriptions: Subscription[] = [];

  constructor(private analyticsService: AnalyticsService, private localStorageService: LocalStorageService,
              private sessionStorageService: SessionStorageService, private compareService: CompareService,
              private activatedRoute: ActivatedRoute, private seoService: SeoService, private modalService: ModalService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
  ngOnInit(): void {
    
    this.subscriptions.push(this.activatedRoute.data.subscribe(x => {
      const slug = x.details?.navigation?.slug;
      if (this.slug !== slug) {
        this.slug = slug;
        this.settingsHaveChanged();
      }
    }));
  }

  private getButtonType(): 'Apply' | 'MoreDetails' | 'None' {
    if (environment.client.alwaysApply || this.settings?.product?.status > CommercialStatus.None || this.settings?.table?.promotedId) {
      return 'Apply';
    }
    return (this.settings?.moreDetailsRoute) ? 'MoreDetails' : 'None';
  }

  private settingsHaveChanged(): void {
    this.goToSite = {
      uid: Utils.GetRandomGuid(),
      sourceSlug: this.slug,
      placement: this.settings.placement,
      referrer: this.sessionStorageService.getClientReferer(),
    };

    const buttonType = this.getButtonType();

    if (this._settings.page) {
      this._settings.page.tip = this.sessionStorageService.getConfiguration().tipEnvironment;
    }

    switch (buttonType) {
      case 'Apply':
        this.confirmApplyVisible = environment.client.confirmApply ?? false;
        this.applyVisible = !this.confirmApplyVisible ;
        this.buttonSettings = this.applySettings();
        break;
      case 'MoreDetails':
        this.applyVisible = true;
        this.confirmApplyVisible = false;
        this.buttonSettings = this.moreDetailsSettings();
        break;
      case 'None':
        this.applyVisible = false;
        this.confirmApplyVisible = false;
        break;
    }
  }

  private applySettings(): ButtonSettings {

    var btnClass: 'btn-apply-advertised' | 'btn-apply-promoted' | 'btn-apply' = 'btn-apply';
    if (this.settings?.table?.promotedPosition === 1) {
      btnClass = 'btn-apply-advertised';
    } else if (this.settings?.table?.promotedId) {
      btnClass = 'btn-apply-promoted';
    }


    return {
      buttonType: 'Apply',

      url: this.seoService.getClickPage(),
      queryParams: {
        c: this.settings.product.status, 
        q: this.generateEncodedQuery()
      },
      label: environment.client.buttonLabel ?? 'Go To Site',
      target: '_blank',
      rel: 'nofollow noopener',
      id: `fc-go-to-site-${this.settings.placement}-${this.settings.product.id}`,
      analyticSettings: this.settings,
      class: btnClass 
    };
  }

  private generateEncodedQuery(): string {
    if (this.settings) {
      const clickEvent: ClickEventRecordDto = {
        brand_id: this.settings.brand.id.toString(),
        product_id: this.settings.product.id.toString(),
        product_status: this.settings.product.status,
        product_value: this.settings.product.value,
        page_id: this.settings.page?.id.toString(),
        page_title: this.settings.page?.title,
        page_type: this.settings.page?.type,
        page_tip: this.settings.page?.tip,
        table_id: this.settings.table?.id,
        table_sort: this.settings.table?.sortOptionId,
        table_position: this.settings.table?.position,
        table_promoted_id: this.settings.table?.promotedId,
        table_promoted_position: this.settings.table?.promotedPosition,
        go_to_site_uid: this.goToSite?.uid,
        go_to_site_sourceSlug: this.goToSite?.sourceSlug,
        go_to_site_placement: this.goToSite?.placement,
        go_to_site_referrer: this.goToSite?.referrer,
        user_client_id: this.localStorageService.getOrSetUserId(),
        user_session_id: this.sessionStorageService.getOrSetSessionId(),
        user_ip_address: this.sessionStorageService.getClientIp(),
        user_partner_client_id: this.analyticsService.getClientId(),
        user_partner_session_id: this.analyticsService.getSessionId(),
      };
      return EncodeClickEventRecord.alisRecord(clickEvent);
    }
    return '';
  }

  private moreDetailsSettings(): ButtonSettings {
    return {
      buttonType: 'MoreDetails',
      url:  this.settings.moreDetailsRoute ?? '',
      label: 'More Details',
      target: '_self',
      rel: '',
      id: `fc-more-details-${this.settings.product.id}`,
      analyticSettings: null,
      class: 'btn-apply-more-details'
    };
  }


  onTrackClick(): void {
    if (this.buttonSettings.buttonType === 'Apply') {
      this.analyticsService.onGoToSite(this.settings, this.clientClickId, this._settings.columns);
    } else if (this.buttonSettings.buttonType === 'MoreDetails') {
      this.compareService.hideCompare();
      if (this.settings.page) {
         this.analyticsService.onMoreDetails(this.settings, this._settings.columns);
      }
    }
  }


  modalRef: ComponentRef<ConfirmApplyComponent>;

  onConfirm(): void {
    this.modalRef  = this.modalService.openModal(ConfirmApplyComponent);
    this.modalRef.instance.brand = this._settings.brand.name;
    this.modalRef.instance.requirements = [
      'You are 18 years and over',
      'You are a resident of Vietnam '];
    this.modalRef.instance.buttonSettings = this.buttonSettings;


    this.modalRef.instance.onTrackClick.subscribe(() => {
      this.onTrackClick();
      this.disposeConfirm();
    });

    this.modalRef.instance.onClose.subscribe(() => {
      this.disposeConfirm();
    })
  }

  private disposeConfirm() {
    this.modalRef.instance.onClose.unsubscribe();
    this.modalRef.destroy();
  }


}
