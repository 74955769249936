
import { ElementRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from "marked";

import { Renderer, Slugger } from 'marked';
import { Utils } from 'src/app/models/shared/Utils';
import { AnalyticsModeType, AnalyticsService, DataTags } from 'src/app/services/analytics.service';




class FCMarkedOptions  {
  ɵcmp: string;
  analyticsMode: AnalyticsModeType;
  analyticsService: AnalyticsService;
  headerIds: boolean;
  breaks: boolean;
  gfm: boolean;
  smartypants: boolean;
  mangle: boolean;
  renderer?: FCRenderer;
}

class FCRenderer extends Renderer {
  ɵcmp: string;
  analyticsMode: AnalyticsModeType;
  analyticsService: AnalyticsService;

  constructor(options: FCMarkedOptions) {
    super(options);
    this.ɵcmp = options.ɵcmp;
    this.analyticsMode = options.analyticsMode;
    this.analyticsService = options.analyticsService;
  }

  heading(text: string, level: 1 | 2 | 3 | 4 | 5 | 6, raw: string, slugger: Slugger): string {
    return super.heading(text, level, raw, slugger).replace(`<h${level}`, `<h${level} ${this.ɵcmp}`);
  }

  paragraph(text: string): string {
    return super.paragraph(text).replace('<p>', `<p ${this.ɵcmp}>`);
  }

  strong(text: string): string {
    return super.strong(text).replace('<strong>', `<strong ${this.ɵcmp}>`);
  }

  list(body: string, ordered: boolean, start: number): string {
    return super.list(body, ordered, start)
    .replace('<ul>', `<ul ${this.ɵcmp}>`)
    .replace('<ol>', `<ol ${this.ɵcmp}>`);
  }

  listitem(text: string, task: boolean, checked: boolean): string {
    return super.listitem(text,task, checked).replace('<li>', `<li ${this.ɵcmp}>`);
  }

  link(href: string | null, title: string | null, text: string): string {
    const linkTarget =  Utils.getLinkTarget(this.analyticsMode, href);

    const params = { label: title ?? text, href: href ?? ''};

    const tags: DataTags = this.analyticsService.getAnalyticsModeTags(this.analyticsMode, params);
    const dataTags = this.renderTags(tags);
    const custom = `<a href='${params.href}' ${this.ɵcmp} ${dataTags} target='${linkTarget}'>${params.label}</a>`;
    return custom;
  }

  table(header: string, body: string): string {
    return super.table(header, body);
  }

  tablecell(content: string, flags: { header: boolean; align: 'center' | 'left' | 'right' | null; }): string {
    return super.tablecell(content, flags);
  }

  private renderTags(tags: DataTags): string {
    let dataTags = '';
    if (tags && tags.length > 0) {
      for (const tag of tags) {
        dataTags += `${tag.key}='${tag.value}'`;
      }
    }
    return dataTags;
  }
}

class MarkedHandler {
  constructor(private hostRef: ElementRef, private sanitizer: DomSanitizer, private analyticsService: AnalyticsService) {

  
   }

  getECMP(): string {
    const attrs = this.hostRef.nativeElement.attributes;
    for (let i = 0, l = attrs.length; i < l; i++) {
      if (attrs[i].name.startsWith('_ngcontent') ||
          attrs[i].name.startsWith('_nghost'))
      {
        return attrs[i].name;
      }
    }

    return '';
  }

  internalTransform(value: any, inline: boolean, analyticsMode: AnalyticsModeType): SafeHtml {
    const options: FCMarkedOptions = {
      ɵcmp: this.getECMP(),
      analyticsMode,
      analyticsService: this.analyticsService,
      headerIds: true,
      breaks: true,
      gfm: true,
      mangle: false,
      smartypants: false,
    };

    options.renderer = new FCRenderer(options);

    if (value && value.length > 0) {
      const content: any  = inline ? marked.parseInline(value, options) : marked.parse(value, options);
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }
    return value;
  }

}


@Pipe({
    name: 'markedInline',
    standalone: true
})
export class MarkedInlinePipe extends MarkedHandler implements PipeTransform  {
  constructor(hostRef: ElementRef, sanitizer: DomSanitizer, analyticsService: AnalyticsService) {
    super(hostRef, sanitizer, analyticsService);
  }

  transform(value: any, analyticsMode: AnalyticsModeType = 'None'): SafeHtml  {
    return this.internalTransform(value, true, analyticsMode);
  }
}


@Pipe({
    name: 'marked',
    standalone: true
})
export class MarkedPipe extends MarkedHandler implements PipeTransform {
  constructor(hostRef: ElementRef, sanitizer: DomSanitizer, analyticsService: AnalyticsService) {
    super(hostRef, sanitizer, analyticsService);
  }

  transform(value: any, analyticsMode: AnalyticsModeType = 'None'): SafeHtml  {
    return this.internalTransform(value, false, analyticsMode);
  }
}