import { Component } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ImageDimensionType } from 'src/app/templates/content/utils/content-image/content-image.component';
import { NgFor, SlicePipe } from '@angular/common';
import { ArticleSideBySideComponent } from '../components/article-sidebyside/article-sidebyside.component';
import { ArticleHeaderComponent } from '../../content/utils/article-header/article-header.component';

@Component({
    selector: 'fc-article-headline',
    templateUrl: './article-headline.component.html',
    styleUrls: ['./article-headline.component.scss'],
    standalone: true,
    imports: [ArticleHeaderComponent, ArticleSideBySideComponent, NgFor, SlicePipe]
})
export class ArticleHeadlineComponent extends ContentBaseComponent {
    promotedDimension: ImageDimensionType =  '4-3';

    onContentChanged(): void {
        this.promotedDimension = this.content.accentTheme === 'fc-sixteen-by-three' ?  '16-9' : '4-3';

    }
 }
