import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { GroupOptionDTO } from 'src/app/models/generated/group/GroupOptionDTO';
import { DropDownListItem } from 'src/app/models/shared/ButtonListStyleSettings';
import { AnalyticsService } from 'src/app/services/analytics.service';
@Directive({
    selector: '[fcAnalyticsGroupfilter]',
    standalone: true
})
export class AnalyticsGroupfilterDirective implements OnInit {
  @Input('fcAnalyticsGroupfilter') item: DropDownListItem<GroupOptionDTO>;

  constructor(private element: ElementRef, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    if (this.item.class !== 'fc-dropdown-divider' && this.item.class !== 'fc-dropdown-header') {
      const dataTags = this.analyticsService.getGroupDataTags(this.item);
      this.analyticsService.setDataTags(dataTags, this.element.nativeElement);
    }
  }
}
