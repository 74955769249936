import { Component, Input } from '@angular/core';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { ProductPlaceholderComponent } from '../product-placeholder/product-placeholder.component';
import { AdvertisedProductComponent } from '../advertised-product/advertised-product.component';
import { AdvertiserDisclosureComponent } from '../advertiser-disclosure/advertiser-disclosure.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-product-advert',
    templateUrl: './product-advert.component.html',
    styleUrls: ['./product-advert.component.scss'],
    standalone: true,
    imports: [NgIf, AdvertiserDisclosureComponent, AdvertisedProductComponent, ProductPlaceholderComponent]
})
export class ProductAdvertComponent {
  @Input() product: ProductPageDTO;
  @Input() pageDetails: CategoryDTO | GroupDTO | BrandDto;
  @Input() showHeading: boolean;
  @Input() paging: PagingParameterDto;

}
