<div class="fc-primary-article" *ngIf="headlineArticle">
    <fc-article-sidebyside [article]="headlineArticle" [tagDisplay]="1" [imageDimension]="'16-9'" [theme]="'headline'">
    </fc-article-sidebyside>
</div>
<div class="fc-promoted-articles" *ngIf="promotedArticles">
    <fc-article-sidebyside *ngFor="let a of promotedArticles" [tagDisplay]="2" [article]="a" [theme]="'promoted'">
    </fc-article-sidebyside>
</div>
<div class="fc-other-articles" *ngIf="remainingArticles">
    <fc-article-sidebyside *ngFor="let a of remainingArticles" [article]="a" [tagDisplay]="2">
    </fc-article-sidebyside>
</div>