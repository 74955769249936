<div class="container-xl">
    <div class="row justify-content-md-center">
        <div class="col-12 col-md-5" style="margin-top: 25px;">
            <h2>Login</h2>
            <div class="form-group">
                <label for="username">Username: </label>
                <input type="text" class="form-control" [(ngModel)]="username" />
            </div>
            <div class="form-group">
                <label for="password">Password: </label>
                <input type="password" class="form-control" [(ngModel)]="password" />
            </div>
            
            <div class="alert alert-secondary">
                Please <a href="https://www.faircomparison.com/contact-us.html">contact us</a> if you do not have a log in, and we will happily provide you with the details. 
                <br />
                <br />
                If you want to return to the Fair Comparison website, click <a href="https://www.faircomparison.com">here</a>.
            </div>

            <div class="form-group">
                <button class="btn btn-primary" (click)="onSubmit()">Login</button>
            </div>
        </div>
    </div>
</div>