import { Pipe, PipeTransform } from '@angular/core';
import { ItemAttributeDTO } from 'src/app/models/generated/item/ItemAttributeDTO';

@Pipe({
    name: 'elementDisplay',
    standalone: true
})
export class ElementDisplayPipe implements PipeTransform {
  public static predicateMessages = (item: ItemAttributeDTO) => item.alwaysDisplay ||  (item.primaryMessage !== '-' && item.primaryMessage !== 'N/A');

  transform(items: ItemAttributeDTO[] ): unknown {
    if (!items || items.length === 0) {  return items;  }
    return items.filter(ElementDisplayPipe.predicateMessages);
  }
}
