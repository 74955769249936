<nav *ngIf="root && showBreadcrumb">
    <ul>
        <li class="fc-breadcrumb-item" aria-current="partnersite" *ngIf="showExternal">
            <a *ngIf="root?.landingPageOverride" [href]="root?.landingPageOverride">{{rootLabel}}</a>
        </li>
        <li class="fc-breadcrumb-item" *ngFor="let b of breadcrumb; let i = index">
            <a *ngIf="b.link && !b.externalLink" [FCRouterLink]="b.link" [id]="'fc-breadcrumb-' + i">{{ b.title }}</a>
            <a *ngIf="b.link && b.externalLink" [href]="b.externalLink">{{ b.title }}</a>
            <span *ngIf="!b.link">{{b.title}}</span>
        </li>
    </ul>
</nav>