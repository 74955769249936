import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { TopicDto } from 'src/app/models/article/TopicDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { GroupParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { GroupService } from 'src/app/services/api/group.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';
import { ArticleResolver } from 'src/app/views/article/article.resolver';

@Injectable({ providedIn: 'root' })
export class GroupResolver  {
  constructor(private groupService: GroupService, private breadcrumbService: BreadcrumbService, private seoService: SeoService, private analyticsService: AnalyticsService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<GroupDTO | TopicDto | ArticleDTO> {
    const params = Utils.GetParams<GroupParams>(route);
    return this.groupService.GetDetails(params.sector, params.category, params.group).pipe(map(group => {
      try {
        if (PageTypeHelper.isArticle(group)) {
          ArticleResolver.HandleArticle(this.breadcrumbService, this.seoService, this.analyticsService, group, params.sector, params.category);
        } else {
          this.breadcrumbService.setBreadCrumb(group.navigation, group.navigation.sector, group.navigation.category, group.navigation.group);
          this.seoService.setStandard(params.sector, params.category, group.navigation.group, group.navigation.root, null);
          if (PageTypeHelper.isGroup(group)) {
            this.analyticsService.onGroupPageView(group);
          } else if (PageTypeHelper.isTopic(group)) {
            this.analyticsService.onArticleListPageView(group);
          }
        }
      } catch (error) {
        console.error(`Unable to resolve (${params.sector}/${params.category}/${params.group}):`, error);
      }
      return group;
    }));
  }
}
