import { Params } from '@angular/router';
import { DefaultInputSettings, DefaultInputUtils } from 'src/app/models/shared/inputs/defaults/DefaultInputUtils';
import { DefaultInputDto } from './defaults/DefaultInputDto';
import { StandardInputDto } from './StandardInputDto';

export interface PersonalLoanInputDto extends StandardInputDto {
  borrowingAmount: number | null;
  loanTerm: number | null;
  loanSecurity: number | null;
  interestType: number | null;
  interestTypeDisabled: boolean;
  loanSecurityDisabled: boolean;
  newCarOnly: boolean | null;
  usedCarOnly: boolean | null;
}

export class PersonalLoanInput {
  public static Initialise(defaultInput: DefaultInputDto, queryParams: Params): PersonalLoanInputDto {
    const settings: DefaultInputSettings = {
      elements: defaultInput.elements,
      queryParams
    };

    const result: PersonalLoanInputDto = {
      kind: defaultInput.kind,
      includeAllProducts: defaultInput.includeAllProducts,
      brands: [],
      borrowingAmount: DefaultInputUtils.FindElementValue(settings, 'borrowing_amount'),
      loanTerm: DefaultInputUtils.FindElementValue(settings, 'loan_term'),
      loanSecurity: DefaultInputUtils.FindElementValue(settings, 'loan_security'),
      interestType: DefaultInputUtils.FindElementValue(settings, 'interest_type'),
      newCarOnly: DefaultInputUtils.FindElementBoolValue(settings, 'new_car'),
      usedCarOnly: DefaultInputUtils.FindElementBoolValue(settings, 'used_car'),


      interestTypeDisabled: DefaultInputUtils.FindElementEnabled(defaultInput.elements, 'interest_type'),
      loanSecurityDisabled: DefaultInputUtils.FindElementEnabled(defaultInput.elements, 'loan_security'),
    };
    return result;
  }
}
