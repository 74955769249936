import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { ProductParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ProductService } from 'src/app/services/api/product.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({
  providedIn: 'root'
})
export class ProductResolver  {
  constructor(private productService: ProductService,
              private breadcrumbService: BreadcrumbService,
              private seoService: SeoService,
              private analyticsService: AnalyticsService
    ) { }
  resolve(route: ActivatedRouteSnapshot): Observable<ProductPageDTO> {
    const params = Utils.GetParams<ProductParams>(route);

    return this.productService.GetDetails(params)
      .pipe(map(page => {
        try {
          const nav = page.navigation;
          const heading = page.brand.name + ' - ' + page.product.title;

          if (params.group) {
            this.breadcrumbService.setBreadCrumb(nav, nav.sector, nav.category, nav.group, heading);
          } else {
            this.breadcrumbService.setBreadCrumb(nav, nav.sector, nav.category, heading);
          }

          this.applySEOFormatting(nav, page);

          this.seoService.setStandard(params.sector, params.category, page, nav.root, page.image.src);
          this.analyticsService.onProductPageView(page);
        } catch (error) {
          console.error(`Unable to resolve product (${params.sector}/${params.category}/${params.brand}/${params.product}):`, error);
        }
        return page;
      }));
  }

  applySEOFormatting(navigation: NavigationDto, page: ProductPageDTO): void {
    page.canonicalLink = page.canonicalLink?.replace('[category.cannonical_link]', navigation.category.canonicalLink);
    page.canonicalLink = page.canonicalLink?.replace('[brand_url]', navigation.brandUrl);
    page.canonicalLink = page.canonicalLink?.replace('[url]', page.product.slug);

    page.metaDescription = page.metaDescription?.replace('[product_description]', page.product.description);

    page.metaTitle = page.metaTitle?.replace('[brand_name]', page.brand.name);
    page.metaTitle = page.metaTitle?.replace('[product_heading]', page.product.title);
    page.metaTitle = page.metaTitle?.replace('| [product_variance]', page.product.varianceTitle || '');
  }
}
