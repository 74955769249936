import { Component } from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';

@Component({
    selector: 'fc-table-content-loader',
    template: `
  <content-loader viewBox="0 0 340 55">
    <svg:rect x="0" y="0" width="340" height="1" />
    <svg:rect x="0" y="0" width="1" height="48" />
    <svg:rect x="0" y="48" width="340" height="1" />
    <svg:rect x="339" y="0" width="1" height="48" />
    <svg:rect x="3" y="3" width="120" height="6" rx="3"/>
    <svg:rect x="3" y="12" width="45" height="25" rx="3" />
    <svg:rect x="51" y="12" width="209" height="6" rx="3" />
    <svg:rect x="51" y="21" width="245" height="6" rx="3" />
    <svg:rect x="51" y="30" width="189" height="6" rx="3" />
    <svg:rect x="3" y="40" width="320" height="6" rx="3" />
</content-loader>`,
    styles: [''],
    standalone: true,
    imports: [ContentLoaderModule]
})
export class TableContentLoaderComponent {
  constructor() {
   }
}
