import { Component, Input } from '@angular/core';
import { ToolTipComponent } from '../../../shared/tool-tip/tool-tip.component';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'fc-product-key-values',
    templateUrl: './product-key-values.component.html',
    styleUrls: ['./product-key-values.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, ToolTipComponent, NgIf]
})
export class ProductKeyValuesComponent {
  @Input() keyValues: any[];
  @Input() accentTheme: string;

  formatKeyValue(keyValue: any): string {
    return  keyValue.primaryMessage +  ' ' + keyValue.secondaryMessage;
  }

}
