import { DOCUMENT, NgFor, NgStyle } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { PartnerMenuDto } from 'src/app/models/shared/helpers/MenuHelper';
import { PartnerSiteService } from 'src/app/services/api/partner-site.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MobileMenuChangeEvent, MobileMenuSettings } from 'src/app/templates/headers/newscorp-top-header/mobile-menu/mobile-menu.component';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { DesktopMenuComponent } from './desktop-menu/desktop-menu.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';

@Component({
    selector: 'fc-newscorp-top-header',
    templateUrl: './newscorp-top-header.component.html',
    styleUrls: ['./newscorp-top-header.component.scss'],
    standalone: true,
    imports: [
        FCRouterLinkDirective,
        NgFor,
        DesktopMenuComponent,
        DefaultBreadcrumbComponent,
        NgStyle,
        MobileMenuComponent,
    ],
})
export class NewscorpTopHeaderComponent  implements HeaderComponent   {
  menuItems: PartnerMenuDto[];
  mobileSettings: MobileMenuSettings;
  mobile: {
    menu: {
      top?: string;
      left?: string;
    },
    background: {
      top?: string;
      left?: string;
    }
  } =  {
    menu: {
        top: '0px',
        left: '100%'
    },
    background: {
      top: '0px',
      left: '100%'
    }
  };

  constructor(private partnerSiteService: PartnerSiteService,  @Inject(DOCUMENT) document: Document) {
    const url =  AppConfigService.AppEnvironment.apiEndPoint;

    this.partnerSiteService.getPartnerMenu(url).subscribe((menu) => {
      if (menu) {
        this.menuItems = menu;
        this.mobileSettings = {
          level: 0,
          items: menu,
          active: null,
          previous: null
        };
      }
    });

  }

  showMenu(mi: PartnerMenuDto, event: MouseEvent, index: number): void {
    if (event.target) {
      const targetRect: DOMRect = (event.target as any).getBoundingClientRect();
      mi.centerX  = targetRect.left + (targetRect.width / 2);
      mi.bottom = targetRect.bottom + document.documentElement.scrollTop;
      mi.visible = true;
      mi.index = index;
      const parentContainer = document.getElementById('nca-secondary-menu');
      if (parentContainer) {
        mi.parentDOMRect = parentContainer.getBoundingClientRect();
      }
    }
  }

  hideMenu(mi: PartnerMenuDto): void {
    mi.visible = false;
  }




  toggleMobileMenu(event?: MouseEvent): void {

    if (event) {
      const targetRect: DOMRect = ((event.target as any).parentElement).getBoundingClientRect();
      this.mobile.menu.top = targetRect.top + 'px';
      this.mobile.menu.left = '0';
      this.mobile.background.top = '0';
      this.mobile.background.left = '0';
    }  else {
      this.mobile.menu.top = '0px';
      this.mobile.menu.left = '100%';
      this.mobile.background.top = '0px';
      this.mobile.background.left = '100%';
      this.mobileSettings = {
        level: 0,
        items: this.menuItems,
        active: null,
        previous: null
      };
    }

  }

  onMobileExpand(change: MobileMenuChangeEvent): void {
    if (change.isBack) {
      this.mobileSettings.level = this.mobileSettings.level - 1;
      this.mobileSettings.active = change.previous;
      this.mobileSettings.items = (change.previous) ? (change.previous as any).items ?? (change.previous as any).columns : this.menuItems;
      this.mobileSettings.previous = null;
    } else {
      this.mobileSettings.level = this.mobileSettings.level + 1;
      this.mobileSettings.previous = change.previous;
      this.mobileSettings.active = change.next;
      this.mobileSettings.items = (change.next as any).items ?? (change.next as any).columns;
  
    }
  }
  
  onMobileClosed(): void {
    this.toggleMobileMenu(undefined);
  }

}


