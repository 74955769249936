import { Component, Input } from '@angular/core';
import { ContentComponentDto } from 'src/app/models/generated/content/ContentComponentDto';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { MarkedInlinePipe } from '../../../pipes/marked.pipe';
import { NgFor, NgClass } from '@angular/common';
import { ContentHeaderSummaryComponent } from '../utils/content-header-summary/content-header-summary.component';

interface RowElement {
  isHeader: boolean;
  columns: ContentComponentDto[];
}

interface ColumnElement {
  isHeader: boolean;
  fixedWidth: number | null;
}

@Component({
    selector: 'fc-content-table',
    templateUrl: './content-table.component.html',
    styleUrls: ['./content-table.component.scss'],
    standalone: true,
    imports: [ContentHeaderSummaryComponent, NgFor, NgClass, MarkedInlinePipe]
})
export class ContentTableComponent extends ContentBaseComponent  {
  columns: ColumnElement[];
  rows: RowElement[];

  protected onContentChanged(value: ContentContainerDto): void {
    this.columns = [];
    this.rows = [];

    for (let index = 0; index < value.components.length; index++) {
      const component = value.components[index];

      const colIndex = component.colNumber - 1;
      const rowIndex = component.rowNumber - 1;

      this.columns[colIndex] = {
        isHeader: component.colHeader,
        fixedWidth: component.colWidth,
      };

      if (!this.rows[rowIndex]) {
        this.rows[rowIndex] = {
          isHeader: component.rowHeader,
          columns: []
        };
      }

      this.rows[rowIndex].columns[colIndex] = component;
    }
  }

  getColWidth(element: ColumnElement): string {
    if (element.fixedWidth && element.fixedWidth >= 10) {
      return element.fixedWidth + 'px';
    }
    return '';
  }

  getStyle(component: ContentComponentDto): 'fc-table-th' | 'fc-table-td' {
    if (component.colHeader || component.rowHeader) {
      return 'fc-table-th';
    } else {
      return 'fc-table-td';
    }
  }
}
