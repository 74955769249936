import { Component, Input } from '@angular/core';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';

@Component({
    selector: 'fc-table-image',
    templateUrl: './table-image.component.html',
    styleUrls: ['./table-image.component.scss'],
    standalone: true
})
export class TableImageComponent {
  private _product: TableItemDTO | ProductPageDTO;

  scriptSrc: string;
  imageSrc: string;
  imageAltText: string;

  @Input()
  get product(): TableItemDTO | ProductPageDTO { return this._product; }
  set product(value: TableItemDTO | ProductPageDTO) {
    this._product = value;
    this.imageAltText = `Product Image For ${ PageTypeHelper.getBrandProductTitle( value?.brand, value?.product)}`;
    this.imageSrc = value?.image?.src;
  }
}
