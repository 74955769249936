import { Component, OnInit } from '@angular/core';
import { MortgagesInputDto } from 'src/app/models/shared/inputs/MortgagesInputDto';
import { InputService } from 'src/app/services/input.service';
import { BaseFilter } from 'src/app/shared/filters/base-filters';
import { OptionInputNumber, OptionInputNumberSelect, OptionInputSettings } from 'src/app/shared/filters/components/option-input/option-input.component';
import { OptionInputComponent } from '../components/option-input/option-input.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { SliderInputComponent } from '../components/slider-input/slider-input.component';
import { InputColumnComponent } from '../components/input-column/input-column.component';


@Component({
    selector: 'fc-mortgages-input',
    templateUrl: './mortgages-input.component.html',
    styleUrls: [
        '../shared-styles/fc-form-control.scss',
        '../shared-styles/fc-input-layout.scss',
    ],
    standalone: true,
    imports: [InputColumnComponent, SliderInputComponent, NgIf, FormsModule, NgFor, OptionInputComponent]
})
export class MortgagesInputComponent extends BaseFilter<MortgagesInputDto> implements OnInit {
  constructor(public inputService: InputService) {
    super(inputService)
  }
  
  interestTypeSettings: OptionInputSettings = {
    disabled: () => this.values.interestTypeDisabled,
    elementOne: {
      label: 'Fixed',
      value: 1
    }, 
    elementTwo: {
      label: 'Variable',
      value: 2
    }, 
  }

  loanPurposes: OptionInputNumber =  {
    disabled: () => this.values.loanPurposeDisabled,
    options: [
      { value: 7, label: 'Owner Occupier' },
      { value: 8, label: 'Investor' },
    ]
  };


  repaymentTypes: OptionInputNumber = {
    disabled: () => this.values.repaymentTypeDisabled,
    options: [
    { value: 5, label: 'Principle & Interest' },
    { value: 6, label: 'Interest Only' }
    ]
  };

  fixedTermPeriods: OptionInputNumber = {
    disabled: () => false,
    options:  [
      { value: null, label: 'Show All' },
      { value: -2, label: 'Upto Two Years' },
      { value: 2, label: '2 Years+' },
      { value: 3, label: '3 Years+' },
      { value: 5, label: '5 Years+' },
      { value: 10, label: '10 Years+' }
  ]};
}
