import { LoginComponent } from './auth/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { SectorComponent } from 'src/app/views/sector/sector.component';
import { AllBrandsComponent } from 'src/app/views/all-brands/all-brands.component';
import { ProductComponent } from 'src/app/views/product/product.component';
import { AllBrandsResolver } from 'src/app/views/all-brands/all-brands.resolver';
import { NotFoundComponent } from 'src/app/views/not-found/not-found.component';
import { SectorResolver } from 'src/app/views/sector/sector.resolver';

import { ProductResolver } from 'src/app/views/product/product.resolver';
import { SectorStaticPageComponent } from 'src/app/views/sector-static-page/sector-static-page.component';
import { SectorStaticPageResolver } from 'src/app/views/sector-static-page/sector-static-page.resolver';
import { environment } from 'src/environments/environment';
import { ArticleComponent } from 'src/app/views/article/article.component';
import { ArticleResolver } from 'src/app/views/article/article.resolver';
import { ArticleListComponent } from 'src/app/views/article-list/article-list.component';

import { AuthorProfileComponent } from 'src/app/views/author-profile/author-profile.component';
import { AuthorProfileResolver } from 'src/app/views/author-profile/author-profile.resolver';
import { ComparisonComponent } from 'src/app/views/comparison/comparison.component';

import { GroupResolver } from 'src/app/views/comparison/group.resolver';
import { CategoryResolver } from 'src/app/views/comparison/category.resolver';
import { BrandProductsResolver } from 'src/app/views/comparison/brand-products.resolver';
import { NotFoundResolver } from 'src/app/views/not-found/not-found.resolver';
import { FCRoute, RouteHelper } from 'src/app/models/shared/helpers/RouteHelper';
import { DynamicComponent } from 'src/app/views/dynamic/dynamic.component';

const routeHelper: RouteHelper = new RouteHelper();

const coreRoutes: Route[] = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: '404', component: NotFoundComponent }];

if (environment.client.masking.defaultRoute.length > 0) {
  coreRoutes.unshift({ path: '', redirectTo: environment.client.masking.defaultRoute, pathMatch: 'full'});
}

// Core Routes; Reuqires nothing and does not need defaults;
for (const cr of coreRoutes) {
  routeHelper.pushStandard(cr);
}

const sector = environment.client.masking.sector;

// Requires SECTOR as minimum;
const indexRoutes: FCRoute[] =
[{ slug: environment.client.masking.defaultRoute, component: SectorComponent, resolver: SectorResolver },
 { slug: `home`, component: SectorComponent, resolver: SectorResolver }
];

for (const ir of indexRoutes) {
  routeHelper.push(ir, ir.slug, sector);
}

// Disclaimer Routes: Requires SECTOR;
const disclaimerRoutes: FCRoute[] = [
  { slug: `disclaimers/:key`, component: SectorStaticPageComponent, resolver: SectorStaticPageResolver},
  { slug: `authors/:authorSlug`, component: AuthorProfileComponent, resolver: AuthorProfileResolver },
];

for (const dr of disclaimerRoutes) {
  // slug
  routeHelper.push(dr, dr.slug, sector);

  // [CATEGORY-MASK]/slug
  if (environment.client.masking.categories) {
    for (const category of environment.client.masking.categories) {
      routeHelper.push(dr, `${category}/${dr.slug}`, sector, category);
    }
  }
}


if (environment.client.masking.categories) {
    // Requires CATEGORY;
    const categoryRoutes: FCRoute[] = [
      { slug: `[CATEGORY]`, component: DynamicComponent, resolver: CategoryResolver },
      { slug: environment.client.masking.brands, component: AllBrandsComponent, resolver:  AllBrandsResolver },
      { slug: `${environment.client.masking.brands}/:brand`, component: ComparisonComponent, resolver:  BrandProductsResolver },
      { slug: `product/:brand/:product`, component: ProductComponent, resolver:  ProductResolver },
      { slug: `:group`, component: DynamicComponent, resolver: GroupResolver },
      { slug: `:articleSlug/:articleId`, component: ArticleComponent, resolver: ArticleResolver },
      { slug: `:articleSlug/:articleId/preview/:previewCode`, component: ArticleComponent, resolver: ArticleResolver },
      { slug: `:group/product/:brand/:product`,  component: ProductComponent, resolver: ProductResolver},
      { slug: `:group/:articleSlug/:articleId`, component: ArticleComponent, resolver: ArticleResolver },
      { slug: `:group/:articleSlug/:articleId/preview/:previewCode`, component: ArticleComponent, resolver: ArticleResolver }
    ];

    for (const cr of categoryRoutes) {
        for (const category of environment.client.masking.categories)  {
          if (cr.slug === '[CATEGORY]') {
            routeHelper.push(cr, category, sector, category);
          } else {
            routeHelper.push(cr, `${category}/${cr.slug}`, sector, category);
          }
        }
    }
} else {

  const routes: FCRoute[] = [
    { slug: `:category`, component: DynamicComponent, resolver: CategoryResolver },
    { slug: `:category/${environment.client.masking.brands}`, component: AllBrandsComponent, resolver:  AllBrandsResolver },
    { slug: `:category/${environment.client.masking.brands}/:brand`, component: ComparisonComponent, resolver:  BrandProductsResolver },
    { slug: `:category/product/:brand/:product`, component: ProductComponent, resolver:  ProductResolver },
    { slug: `:category/:group`, component: DynamicComponent, resolver: GroupResolver },
    { slug: `:category/:articleSlug/:articleId`, component: ArticleComponent, resolver: ArticleResolver },
    { slug: `:category/:articleSlug/:articleId/preview/:previewCode`, component: ArticleComponent, resolver: ArticleResolver },
    { slug: `:category/:group/product/:brand/:product`,  component: ProductComponent, resolver: ProductResolver},
    { slug: `:category/:group/:articleSlug/:articleId`, component: ArticleComponent, resolver: ArticleResolver },
    { slug: `:category/:group/:articleSlug/:articleId/preview/:previewCode`, component: ArticleComponent, resolver: ArticleResolver }
  ];

  for (const cr of routes) {
    routeHelper.push(cr, cr.slug, sector);
  }
}


// Final catch all;
routeHelper.push({ slug: '**', component: NotFoundComponent, resolver: NotFoundResolver, }, '**', sector);

@NgModule({
  imports: [RouterModule.forRoot(routeHelper.getRoutes(), {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
