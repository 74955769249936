

<div class="fc-header-host">
    <div class="fc-logo">
        <a [FCRouterLink]="'/'"  aria-label="Link back to the home page">
            <img src="https://ik.imagekit.io/fairaus/savrr/savrr_logo.svg" alt="Savrr Comparison & Discount Codes" width="133.5" height="30" loading="eager">
        </a>
    </div>
    <div class="fc-providedBy">
        <label>This comparison service is powered by</label>
        <a href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="31" loading="eager"
                src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
                alt="Fair Comparison - white label comparison technology provider"></a>
    </div>
    <button (click)="toggleMobileMenu(true)"><i class="far fa-bars"></i></button> 
    <div class="fc-desktop-background"></div>
    <fc-desktop-nav [showHomeLink]="true" [customMenu]="customMenuItems"></fc-desktop-nav>
    <div class="fc-breadcrumb-background"></div>
    <fc-default-breadcrumb [showOnRoot]="false" [showExternal]="false" [showPreviousOnly]="false" [showLowest]="false"></fc-default-breadcrumb>
</div>


<div class="fc-mobile-background" [ngStyle]="mobile.background" (click)="toggleMobileMenu(false)"></div>
<div class="fc-mobile-menu" [ngStyle]="mobile.menu">
    <div class="fc-mobile-header">
        <div><i class="fas fa-times" (click)="toggleMobileMenu(false)"></i></div>
        <a  [FCRouterLink]="'/'"  (click)="toggleMobileMenu(false)">
            <img src="https://ik.imagekit.io/fairaus/savrr/savrr_logo.svg" alt="Savrr Comparison & Discount Codes" width="182" height="40" loading="lazy">
        </a>
    </div>
    <fc-mobile-menu [settings]="mobileSettings" (onItemExpanded)="onMobileExpand($event)" (onMenuClosed)="onMobileClosed()"></fc-mobile-menu>
</div>