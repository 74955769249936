import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { isPlatformBrowser, isPlatformServer, JsonPipe } from '@angular/common';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { ActivatedRoute } from '@angular/router';
import { Response } from 'express';

@Component({
    selector: 'fc-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [JsonPipe]
})
export class NotFoundComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: Response,
    public activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.status(404);
    } else {
      this.breadcrumbService.clearBreadCrumb();
    }
  }
}
