import { Component, HostBinding, Input } from '@angular/core';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { ColumnComponent } from '../column/column.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'fc-column-container',
    templateUrl: './column-container.component.html',
    styleUrls: ['./column-container.component.scss'],
    standalone: true,
    imports: [NgFor, ColumnComponent]
})
export class ColumnContainerComponent {
  @HostBinding('class') layout: 'fc-column-container-stacked' | 'fc-column-container-flat';
  @HostBinding('style.--fc-lg-columns') columnCount: number;

  _columns: TableColumnDTO[];
  get columns(): TableColumnDTO[] {
    return this._columns;
  }
  @Input() set columns(value: TableColumnDTO[]) {
    this._columns = value;
    this.setHostClasses();
  }

  _stackNumber = 0;
  get stackNumber(): number {
    return this._stackNumber;
  }
  @Input() set stackNumber(value: number) {
      this._stackNumber = value;
      this.setHostClasses();
  }

  constructor() {}

  private setHostClasses(): void {
    if (this._stackNumber > 0) {
      this.layout = 'fc-column-container-stacked';
      this.columnCount = 1;
    } else {
      this.layout = 'fc-column-container-flat';
      this.columnCount =  this.columns.length;
    }
  }
}
