<fc-product-key-values [keyValues]="page.values.keyValues" [accentTheme]="page.accentTheme"></fc-product-key-values>
<fc-product-action [categoryUrl]="categoryUrl" [compareButtonText]="page.compareButtonText"></fc-product-action>
<fc-product-all-values [applySettings]="applySettings" [page]="page" [values]="page.values.allValues"></fc-product-all-values>
<fc-product-tmd *ngIf="page.targetMarketDetermination" [tmd]="page.targetMarketDetermination" [applySettings]="applySettings" [page]="page"></fc-product-tmd>
<fc-product-seperator *ngIf="page.targetMarketDetermination"  [applySettings]="applySettings" [page]="page"></fc-product-seperator>

<div class="fc-product-static-one" *ngIf="page.content.staticFooterOne">
    <div class="fc-product-static-one-container">
        <div [innerHTML]="page.content.staticFooterOne | marked"></div>
        <a class="fc-static-one-action" [FCRouterLink]="categoryUrl" id="fc-compare-all">{{page.compareButtonText}}</a>
    </div>
</div>
<div class="fc-product-static-two"  *ngIf="page.content.staticFooterTwo">
    <div class="fc-product-static-two-container">
        <div class="fc-product-static-two-content" [innerHTML]="page.content.staticFooterTwo | marked"></div>
        <fc-apply [settings]="applySettings"></fc-apply>
    </div>
</div>