import { Component, HostBinding, Input } from '@angular/core';
import { ContentComponentDto, ContentTopicDto } from 'src/app/models/generated/content/ContentComponentDto';
import { ContentTagDisplay } from 'src/app/models/generated/content/ContentContainerDto';
import { AnalyticsModeType } from 'src/app/services/analytics.service';
import { ImageDimensionType } from 'src/app/templates/content/utils/content-image/content-image.component';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { ContentLinkDirective } from '../../../../directives/content-link.directive';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { Utils } from 'src/app/models/shared/Utils';

export interface ImageDimensions {
  type: ImageDimensionType,
  src: string;
  width: number;
  height: number;
  loaderParams?: {
    [key: string]: any;
  };
}
export type ArticleThemes = 'standard' | 'headline' | 'promoted' | 'column' | 'basic';

@Component({
    selector: 'fc-article-sidebyside',
    templateUrl: './article-sidebyside.component.html',
    styleUrls: [
        './themes/standard.component.scss',
        './themes/headline.component.scss',
        './themes/promoted.component.scss',
        './themes/column.component.scss',
        './themes/basic.component.scss',
        '../article-styling.scss'
    ],
    standalone: true,
    imports: [FCRouterLinkDirective, ContentLinkDirective, NgOptimizedImage, MarkedInlinePipe, NgIf]
})
export class ArticleSideBySideComponent {
  private _article: ContentComponentDto;
  private _tagDisplay: ContentTagDisplay = ContentTagDisplay.none;
  image: ImageDimensions;

  topic: ContentTopicDto | null = null;
  isExternalLink: boolean = false;

  @Input() @HostBinding('class') theme: ArticleThemes  = 'standard';
  @Input() analyticsMode: AnalyticsModeType = 'None';
  @Input() priority: boolean | null = null;
 /// @Input() imageDimension: ImageDimensionType =  '4-3';

  private _imageDimension: ImageDimensionType = '4-3';
  @Input() set imageDimension(imageDimension : ImageDimensionType) {
    this._imageDimension  = imageDimension;
    this.setDimensions();
  }
  get imageDimension (): ImageDimensionType {
    return this._imageDimension;
  }

  get tagDisplay(): ContentTagDisplay {
    return this._tagDisplay;
  }
  @Input() set tagDisplay(value: ContentTagDisplay) {
    this._tagDisplay = value;
    this.setTopic();
  }

  get article(): ContentComponentDto {
    return this._article;
  }
  @Input() set article(value: ContentComponentDto) {
    this._article = value;
    this.setTopic();
    this.setDimensions();
  }



  setTopic(): void {
    switch (this._tagDisplay) {
      case ContentTagDisplay.none:
        this.topic = null;
        break;
      case ContentTagDisplay.category:
        this.topic = this.article?.topics?.category;
        break;
      case ContentTagDisplay.topic:
        this.topic = this.article?.topics?.primaryTopic;
        break;
    }

    if (this.topic) {
      this.isExternalLink = Utils.isUrl(this.topic.route) ?? false;
    }


  }

  setDimensions() {
    if (this._article && this._imageDimension) { 
      this.image = {
        src: this._article.image.imageSrc,
        type: this._imageDimension,
        width: this._article.image.width,
        height: this._article.image.height,
        loaderParams: {
          'ar': this._imageDimension
        }
      };

      if (this.image.type === '16-9') {
        this.image.height = this.image.width / 16 * 9;
      } else {
        this.image.height = this.image.width / 4 * 3;
      }
    }
  }
}
