<div class="ext-icon-row">
    <img src="https://ik.imagekit.io/fairaus/executivetraveller/logo-footer.svg" width="184px" height="65px" loading="lazy">
    <div class="ext-socials">
        <a href="https://www.facebook.com/ExecutiveTravellerOfficial/" target="_blank" >
            <i class="ext-icon ext-facebook"></i>
        </a>
        <a href="https://www.instagram.com/executivetraveller/" target="_blank" >
            <i class="ext-icon ext-instagram"></i>
        </a>
        <a href="https://twitter.com/Exec_Traveller" target="_blank" >
            <i class="ext-icon ext-twitter"></i>
        </a>
    </div>
</div>
<div class="ext-menu-row">
    <div *ngFor="let group of linkGroups" class="ext-menu-group" [ngStyle]="{ 'grid-column': group.gridColumn, 'grid-row': group.gridRow }">
        <a class="ext-group-link" [innerText]="group.name" [href]="group.url"></a>
        <label class="ext-group-label" [innerText]="group.name" (click)="toggleAccordian($event)"></label>
        <div class="ext-group-content">
            <ul>
                <li *ngFor="let child of group.children">
                    <a [innerText]="child.name" [href]="child.url"></a>
                </li>
            </ul>
        </div>
        
    </div>
</div>
