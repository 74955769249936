import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandCategoryDto } from 'src/app/models/generated/client/brand/BrandCategoryDto';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { CategoryParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { BrandService } from 'src/app/services/api/brand.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({ providedIn: 'root' })
export class AllBrandsResolver  {
  constructor(private brandService: BrandService,private seoService: SeoService, private breadcrumbService: BreadcrumbService, private analyticsService: AnalyticsService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<BrandCategoryDto> {

    const params = Utils.GetParams<CategoryParams>(route);
    return this.brandService.GetAllBrands(params.sector, params.category).pipe(map(allBrands => {
      try {
        this.applySEOFormatting(allBrands.navigation, allBrands);

        this.seoService.setStandard(params.sector, params.category, allBrands, allBrands.navigation.root, '');
        this.breadcrumbService.setBreadCrumb(allBrands.navigation, allBrands.navigation.sector, allBrands.navigation.category, 'Brands');
        this.analyticsService.onBrandListView(allBrands);
      } catch (error) {
        console.error(`Unable to resolve all brands (${params.sector}/${params.category}):`, error);
      }
      return allBrands;
    }));
  }


  applySEOFormatting(navigation: NavigationDto, brand: BrandCategoryDto): void {
    brand.canonicalLink = brand?.canonicalLink?.replace('[category.cannonical_link]', navigation?.category?.canonicalLink);
  }
}
