<div class="fc-modal">
  <div class="fc-modal-content">
    <header>
      <h4>Product Comparison</h4>
      <button type="button" (click)="close()"><span aria-hidden="true">&times;</span></button>
    </header>
    <section>
      <table id="fc-compare-details">
        <colgroup>
          <col style="width: 220px;">
          <col *ngFor="let i of headerItems">
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th *ngFor="let i of headerItems">{{i.brand}} - {{i.title}}</th>
          </tr>
          <tr>
            <th></th>
            <th *ngFor="let i of headerItems">{{i.varianceTitle}}</th>
          </tr>
          <tr>
            <th></th>
            <th *ngFor="let i of headerItems"><img [src]="i.imageSrc" loading="eager"></th>
          </tr>
          <tr>
            <th></th>
            <th *ngFor="let i of headerItems">
              <fc-apply [settings]="getApplySettings(i)"></fc-apply>
            </th>
          </tr>
        </thead>
        <tbody *ngFor="let g of comparisonItems" [class.fc-compare-expanded]="expandedGroup == g">
          <tr class="fc-compare-header-row" (click)="onGroupToggle(g)">
            <th [attr.colspan]="calculateColspan()">
              <i class="fc-compare-icon"></i>{{g.groupTitle}}
            </th>
          </tr>
          <tr *ngFor="let r of g.rows" class="fc-compare-content-row">
            <th>{{r.label}}</th>
            <td *ngFor="let v of r.items">
              <div class="fc-compare-primary-value" [innerHtml]="v.primaryMessage"></div>
              <div class="fc-compare-secondary-value" [innerHtml]="v.secondaryMessage"></div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th *ngFor="let i of headerItems">
              <fc-apply [settings]="getApplySettings(i)"></fc-apply>
            </th>
          </tr>
        </tfoot>
      </table>
    </section>
    <footer>
      <button type="button" class="fc-compare-close" (click)="close()">Close</button>
    </footer>
  </div>
</div>
