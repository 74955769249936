
<fc-promoted-label [product]="item?.content"></fc-promoted-label>
<div class="fc-table-container" *ngIf="item.content">
    <fc-table-image [product]="item.content"></fc-table-image>
    <fc-table-header [route]="item.url" [content]="item.content"></fc-table-header>
    <fc-apply [settings]="getApplySettings()"></fc-apply>
    <fc-compare-checkbox [item]="item.content" [paging]="settings.paging"></fc-compare-checkbox>
    <fc-more-details [route]="item.url" [content]="item.content" *ngIf="getButtonType() != 'MoreDetails'"  [paging]="settings.paging"></fc-more-details> 
    <div class="fc-columns">
        <fc-column-scroll-container [columns]="item.content.columns"></fc-column-scroll-container>
    </div>
    <div class="fc-table-summary" *ngIf="item.content.product.summary" [innerHtml]="item.content.product.summary | markedInline"></div>
</div>