import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {makeStateKey, TransferState} from '@angular/core'
import { AppComponent } from 'src/app/app.component';
import { Utils } from 'src/app/models/shared/Utils';
import { ComparisonPageSpecific } from 'src/app/views/comparison/comparison.component';
import { AppServiceConfigurationDefault } from 'src/environments/appSettings';
import { AppServiceConfiguration, ClientSettings } from 'src/environments/environmentDefinitions';

export interface ISessionStorageService {
  getOrSetSessionId(): string
}

export class InMemoryLocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {return null;}
  key(index: number): string | null {return null;}
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements ISessionStorageService {

  private storage: Storage;

  constructor(
    private readonly transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject('fc-client') private client: ClientSettings,
    @Optional() @Inject('fc-configuration') private configuration: AppServiceConfiguration,

  ) {
    this.storage = new InMemoryLocalStorage();

    AppComponent.isBrowser.subscribe(isBrowser => {
      if (isBrowser) {
        this.storage = sessionStorage;
      }
    });

    const clientKey = makeStateKey<ClientSettings>('fc-client');
    const configurationKey = makeStateKey<AppServiceConfiguration>('fc-configuration');

    if (isPlatformBrowser(this.platformId)) // get message from transferState if browser side
    {
      this.client = this.transferState.get<ClientSettings>(clientKey, {});
      this.configuration = this.transferState.get<AppServiceConfiguration>(configurationKey, AppServiceConfigurationDefault);
    }
    else // server side: get provided message and store in in transfer state
    {
        this.transferState.set<ClientSettings>(clientKey, this.client);
        this.transferState.set<AppServiceConfiguration>(configurationKey, this.configuration);
    }
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }

  getOrSetSessionId(): string {
    return this.client?.clientSessionId || '-';
  }

  getClientIp(): string {
    return this.client?.clientIp || '-';
  }

  getClientReferer(): string {
    return this.client?.clientReferrer || '-';
  }

  getClientTiPMix(): string {
    return this.client?.tipMix || '-';
  }

  getConfiguration(): AppServiceConfiguration {
    return this.configuration;
  }

  getDefaultTableId(pageSpecific: ComparisonPageSpecific): string {
    var id = 'fc-page-' + pageSpecific.pageType + '-' + pageSpecific.categoryId;
    if (pageSpecific.groupId) {
      id += '-' + pageSpecific.groupId;
    }
    if (pageSpecific.brandId) {
      id += '-' + pageSpecific.brandId;
    }

    const tableKey = makeStateKey<string>(id);

    const tableId =  this.transferState.get<string>(tableKey, Utils.GetRandomGuid());

    this.transferState.set<string>(tableKey, tableId);

    return tableId;
  }

}
