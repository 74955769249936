
<h2><i class="fad fa-bullseye-pointer"></i>Who is the product designed for?</h2>
<div class="fc-product-tmd-details">
    <div [innerHtml]="tmd.description | marked"></div>
    <div class="fc-product-tmd-link">
        <a *ngIf="tmd.merchantDocumentUrl" [href]="tmd.merchantDocumentUrl" target="_blank" rel="nofollow">Visit {{page.brand.name}} to view Target Market Determination</a>
        <span *ngIf="!tmd.merchantDocumentUrl" >Visit {{page.brand.name}} to view Target Market Determination</span>
        <span> Effective From: <b>{{tmd.displayEffectiveFrom | fcDate:'YYYY-MM-DD'}}.</b></span>
        <span *ngIf="tmd.displayVersion"> Version: <b>{{tmd.displayVersion}}</b></span>
    </div>
</div>
