
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectorDTO } from 'src/app/models/generated/SectorDTO';
import { SectorParams, Utils } from 'src/app/models/shared/Utils';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { ContentContainerComponent } from '../../templates/content/content-container.component';
import { CategoryCardComponent } from '../../shared/category-card/category-card.component';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';

@Component({
    selector: 'fc-sector',
    templateUrl: './sector.component.html',
    styleUrls: ['./sector.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        DisclaimerComponent,
        CategoryCardComponent,
        ContentContainerComponent,
        MarkedInlinePipe,
        TooltipModule 
    ],
})
export class SectorComponent implements OnInit {
  showGenericLink = environment.client.disclaimers.showGenericLink;
  params: SectorParams;
  sector: SectorDTO;
  disclosure: {
    message: string,
    route: string;
  };

  constructor(activatedRoute: ActivatedRoute, public appConfig: AppConfigService, private seoService: SeoService)
  {
    this.params = Utils.GetParams<SectorParams>(activatedRoute.snapshot);
    this.sector = activatedRoute.snapshot.data.details;
  }

  ngOnInit(): void {
    this.disclosure = {
      message: this.appConfig.legalDisclosureGeneric,
      route: this.seoService.getAdvertiserDisclosurePage()
    };
  }
}
