import { DOCUMENT, NgIf, NgStyle } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { PartnerMenuDto } from 'src/app/models/shared/helpers/MenuHelper';
import { PartnerSiteService } from 'src/app/services/api/partner-site.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MobileMenuChangeEvent, MobileMenuSettings } from 'src/app/templates/headers/newscorp-top-header/mobile-menu/mobile-menu.component';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { environment } from 'src/environments/environment';
import { MobileMenuComponent } from '../newscorp-top-header/mobile-menu/mobile-menu.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { DesktopNavComponent } from '../components/desktop-nav/desktop-nav.component';
import { CountryPickerComponent } from '../../../shared/country-picker/country-picker.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
    selector: 'fc-savrr-header',
    templateUrl: './savrr-header.component.html',
    styleUrls: ['./savrr-header.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective, DesktopNavComponent, DefaultBreadcrumbComponent, NgStyle, MobileMenuComponent]
})
export class SavrrHeaderComponent  implements HeaderComponent {
  menuItems: PartnerMenuDto[];
  showCountries: boolean = false;
  constructor(private partnerSiteService: PartnerSiteService, private SessionStorage: SessionStorageService ) {
    const url =  AppConfigService.AppEnvironment.apiEndPoint;

    if (environment.client.code == 'savrr-au') {
      this.customMenuItems =[
        { 
          label: 'Coupons', 
          route: 'https://deals.savrr.com',
          isExternal: true,
          columns: []
        }
      ]
    }

    this.partnerSiteService.getPartnerMenu(url).subscribe((menu) => {
      if (menu) {
        this.menuItems = menu;
        this.mobileSettings = {
          level: 0,
          items: menu,
          active: null,
          previous: null
        };
      }
    });

  }

  customMenuItems: PartnerMenuDto[] = [];
  mobileSettings: MobileMenuSettings;
  mobile: {
    menu: {
      top?: string;
      left?: string;
    },
    background: {
      top?: string;
      left?: string;
    }
  } =  {
    menu: {
        top: '0px',
        left: '100%'
    },
    background: {
      top: '0px',
      left: '100%'
    }
  };


  toggleMobileMenu(open: boolean): void {
    if (open) {
      this.mobile.menu.top = '0px';
      this.mobile.menu.left = '30px';
      this.mobile.background.top = '0';
      this.mobile.background.left = '0';
    }  else {
      this.mobile.menu.top = '0px';
      this.mobile.menu.left = '100%';
      this.mobile.background.top = '0px';
      this.mobile.background.left = '100%';
      this.mobileSettings = {
        level: 0,
        items: this.menuItems,
        active: null,
        previous: null
      };
    }

  }

  onMobileExpand(change: MobileMenuChangeEvent): void {
    if (change.isBack) {
      this.mobileSettings.level = this.mobileSettings.level - 1;
      this.mobileSettings.active = change.previous;
      this.mobileSettings.items = (change.previous) ? (change.previous as any).items ?? (change.previous as any).columns : this.menuItems;
      this.mobileSettings.previous = null;
    } else {
      this.mobileSettings.level = this.mobileSettings.level + 1;
      this.mobileSettings.previous = change.previous;
      this.mobileSettings.active = change.next;
      this.mobileSettings.items = (change.next as any).items ?? (change.next as any).columns;
  
    }
  }
  
  onMobileClosed(): void {
    this.toggleMobileMenu(false);
  }

  
}
