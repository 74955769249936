import { AppServiceConfiguration, AppSettingsType } from "src/environments/environmentDefinitions";
export const AppSettings: AppSettingsType = {
  dev: {
    environment: 'dev',
    apiEndPoint: 'http://localhost:54825',
  },
  publicDev: {
    environment: 'public-dev',
    apiEndPoint: 'https://api-whitelabel-dev.faircomparison.com'

  },
  staging: {
    environment: 'staging',
    apiEndPoint: 'https://api-whitelabel-staging.faircomparison.com'
  },
  production: {
    environment: 'production',
    apiEndPoint: 'https://api-whitelabel.faircomparison.com'
  }
};

export const AppServiceConfigurationDefault: AppServiceConfiguration = {
  environment: 'dev',
  source: 'client',
  tipEnvironment: null,
  showCountries: true
};
