import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
@Pipe({
    name: 'fcDate',
    standalone: true
})
export class FCDatePipe implements PipeTransform {

  transform(value: string, format: string =  'YYYY-MM-DD HH:mm', ...args: unknown[]): string {
    const date = dayjs(value);
    if (date.isValid()) {
      return date.format(format);
    }
    return '';
  }
}