import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { ContentCategoryComponent } from '../content-category/content-category.component';
import { ComparisonComponent } from '../comparison/comparison.component';
import { ArticleComponent } from '../article/article.component';
import { ArticleListComponent } from '../article-list/article-list.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'fc-dynamic',
    templateUrl: './dynamic.component.html',
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        ArticleListComponent,
        ArticleComponent,
        ComparisonComponent,
        ContentCategoryComponent,
        NgSwitchDefault,
    ],
})
export class DynamicComponent {
  mode: 'article-list' | 'article' | 'group' | 'topic' | 'category' | 'content-category' | null = null;
  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.data.subscribe(data => {
      const page = data.details;

      if (PageTypeHelper.isArticleList(page)) {
        this.mode = 'article-list';
      }  else if (PageTypeHelper.isArticle(page)) {
        this.mode = 'article';
      } else if (PageTypeHelper.isGroup(page)) {
        this.mode = 'group';
      } else if (PageTypeHelper.isTopic(page)) {
        this.mode = 'topic';
      } else if (PageTypeHelper.isCategory(page)) {
        this.mode = 'category';
      } else if (PageTypeHelper.isContentCategory(page)) {
        this.mode = 'content-category';
      }
    });
   }
}
