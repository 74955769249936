import { Component, Input, OnInit } from '@angular/core';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { FooterComponent } from 'src/app/templates/footers/footer-switcher.component';

@Component({
    selector: 'fc-oth-footer',
    templateUrl: './oth-footer.component.html',
    styleUrls: ['./oth-footer.component.scss'],
    standalone: true
})
export class OthFooterComponent implements FooterComponent {
  @Input() links: SectorStaticFooterDto[];



}
