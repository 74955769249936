<div class="fc-layout-top-header-content">
    <div class="row" style="padding: 15px 0;">
        <div class="col-3">
             <img src="https://www.faircomparison.com/images/logo.png" loading="eager" width="209" height="52" alt="Fair Comparison - white label comparison technology provider">
        </div>
        <div class="col-9">
          <ul class="list-inline fc-demo-style" >
            <li class="list-inline-item"><a class="nav-link" [FCRouterLink]="'/mobile-internet'">Mobile & Internet</a></li>
            <li class="list-inline-item"><a class="nav-link" [FCRouterLink]="'/utilities'">Utilities</a></li>
            <li class="list-inline-item"><a class="nav-link" [FCRouterLink]="'/insurance'">Insurance</a></li>
            <li class="list-inline-item"><a class="nav-link" [FCRouterLink]="'/finance'">Finance</a></li>
          </ul>
        </div>
    </div>
</div>
<div class="fc-demo-au-breadcrumb">
  <fc-default-breadcrumb [showOnRoot]="true" [showExternal]="false" [showPreviousOnly]="false"></fc-default-breadcrumb>
</div>