<h1>Page not found</h1>
<div class="fc-error-message">
    Either the URL is incorrect, there's a technical issue, or the page is no longer available.
</div>
<ul class="fc-error-debug"> 
    <li>
        <label>URL</label>
        <pre>
            {{activatedRoute.snapshot.url | json}}
        </pre>
    </li>
    <li>
        <label>Data</label>
        <pre>
            {{activatedRoute.snapshot.data | json}}
        </pre>    
    </li>
</ul>