import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';

export class User {
    username: string;
    password: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userAuthenticated = false;

    constructor(private sessionStorageService: SessionStorageService) {}

    isAuthenticated(): boolean {
        if (this.userAuthenticated || !AppConfigService.PartnerEnvironment.enableLogin) {
            return true;
        }

        const sessionValue = this.sessionStorageService.getItem('fc_temporary_user_authenticated');
        if (sessionValue === 'true') {
            return true;
        }
        return false;
    }

    login(username: string, password: string): boolean {
        const user: User = {
            username: AppConfigService.PartnerAppSettings.loginDetails?.username || '',
            password: AppConfigService.PartnerAppSettings.loginDetails?.password || ''
        };

        if (username.toLowerCase() === user.username.toLowerCase() && password === user.password) {
            this.sessionStorageService.setItem('fc_temporary_user_authenticated', 'true');
            this.userAuthenticated = true;

            return true;
        } else {
            alert('Invalid Username / Password');
            return false;
        }
    }

    logout(): void {
        this.sessionStorageService.removeItem('currentUser');
    }
}
