import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';

@Directive({
    selector: 'a[fcAnalyticsGoToSite]',
    standalone: true
})
export class AnalyticsGoToSiteDirective implements OnInit {
  @Input('fcAnalyticsGoToSite') settings: ApplySettings;

  constructor(private element: ElementRef, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    const dataTags = this.analyticsService.getGoToSiteDataTags(this.settings);
    this.analyticsService.setDataTags(dataTags, this.element.nativeElement);
  }
}