import { Params } from '@angular/router';
import { MortgagesDetailedInputComponent } from 'src/app/shared/filters/mortgages-detailed-input/mortgages-detailed-input.component';
import { DefaultInputDto } from './defaults/DefaultInputDto';
import { DefaultInputSettings, DefaultInputUtils } from './defaults/DefaultInputUtils';

import { StandardInputDto } from './StandardInputDto';
import { BootstrapOptions } from '@angular/core';

export interface MortgagesInputDto extends StandardInputDto {
    loanPurpose: number | null;
    interestType: number | null;
    repaymentType: number | null;
    borrowingAmount: number | null;
    loanTerm: number | null;
    depositRate: number | null;
    fixedTermPeriod: number | null;
    extraRepayments: boolean | null;
    redrawFacility: boolean | null;
    offsetAccount: boolean | null;
    estimatedPropertyPrice: number | null;
    depositAmount: number | null;


    interestTypeDisabled: boolean;
    loanPurposeDisabled: boolean;
    repaymentTypeDisabled: boolean;
}

class UserAdjustedValues
{
    borrowingAmountOriginal: number | null;
    depositRateOriginal: number | null;
    estimatedPropertyPriceOriginal: number | null;
    depositAmountOriginal: number | null;
}

export class MortgagesInput {
  public static Initialise(defaultInput: DefaultInputDto, queryParams: Params): MortgagesInputDto {

    const settings: DefaultInputSettings = {
      elements: defaultInput.elements,
      queryParams,
    };

    const adjusted: UserAdjustedValues = {
      borrowingAmountOriginal: null,
      depositRateOriginal: null,
      depositAmountOriginal: null,
      estimatedPropertyPriceOriginal: null
    };

    const r: MortgagesInputDto = {
      kind: defaultInput.kind,
      includeAllProducts: defaultInput.includeAllProducts,
      brands: [],
      loanPurpose: DefaultInputUtils.FindElementValue(settings, 'loan_purpose'),
      loanTerm: DefaultInputUtils.FindElementValue(settings, 'loan_term'),
      interestType: DefaultInputUtils.FindElementValue(settings, 'interest_type'),
      repaymentType: DefaultInputUtils.FindElementValue(settings, 'repayment_type'),
      borrowingAmount: DefaultInputUtils.FindElementValue(settings, 'borrowing_amount', (x) => { adjusted.borrowingAmountOriginal = x; }),
      depositRate: DefaultInputUtils.FindElementValue(settings, 'deposit_rate', (x) => { adjusted.depositRateOriginal = x; }),
      fixedTermPeriod: DefaultInputUtils.FindElementValue(settings, 'fixed_term_period'),
      extraRepayments: DefaultInputUtils.FindElementBoolValue(settings, 'extra_repayments'),
      redrawFacility: DefaultInputUtils.FindElementBoolValue(settings, 'redraw_facility'),
      offsetAccount: DefaultInputUtils.FindElementBoolValue(settings, 'offset_account'),
      estimatedPropertyPrice: DefaultInputUtils.FindElementValue(settings, 'estimated_property_price', (x) => { adjusted.estimatedPropertyPriceOriginal = x; }),
      depositAmount: DefaultInputUtils.FindElementValue(settings, 'deposit_amount', (x) => { adjusted.depositAmountOriginal = x; }), 

      interestTypeDisabled: DefaultInputUtils.FindElementEnabled(settings.elements, 'interest_type'),
      repaymentTypeDisabled: DefaultInputUtils.FindElementEnabled(settings.elements, 'repayment_type'),
      loanPurposeDisabled: DefaultInputUtils.FindElementEnabled(settings.elements, 'loan_purpose'),
    };

    if (adjusted.estimatedPropertyPriceOriginal && adjusted.depositAmountOriginal && (r.estimatedPropertyPrice && r.depositAmount)) {
      r.borrowingAmount = r.estimatedPropertyPrice - r.depositAmount;
      r.depositRate = 100.00 / r.estimatedPropertyPrice * r.depositAmount;
    } else if (adjusted.borrowingAmountOriginal && adjusted.depositRateOriginal && (r.borrowingAmount && r.depositRate)) {
      r.depositAmount = Math.round((r.borrowingAmount / (100 - r.depositRate) * r.depositRate));
      r.estimatedPropertyPrice = r.borrowingAmount + r.depositAmount;
    } else if (adjusted.estimatedPropertyPriceOriginal && (r.estimatedPropertyPrice && r.depositRate)) {
      r.depositAmount = r.estimatedPropertyPrice / 100.00 * r.depositRate;
      r.borrowingAmount = r.estimatedPropertyPrice - r.depositAmount;
    } else {
      r.borrowingAmount = adjusted.borrowingAmountOriginal ?? r.borrowingAmount;
      r.depositRate = adjusted.depositRateOriginal ?? r.depositRate;
      r.estimatedPropertyPrice = adjusted.estimatedPropertyPriceOriginal ?? r.estimatedPropertyPrice;
      r.depositAmount = adjusted.depositAmountOriginal ?? r.depositAmount;
    }

    return r;
  }
}