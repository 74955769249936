import { Component, Input } from '@angular/core';
import { ProductPageDTO, TargetMarketDeterminationDto } from 'src/app/models/generated/product/ProductDetailsDTO';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';
import { FCDatePipe } from '../../../pipes/fc-date-pipe.pipe';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-product-tmd',
    templateUrl: './product-tmd.component.html',
    styleUrls: ['./product-tmd.component.scss'],
    standalone: true,
    imports: [NgIf, FCDatePipe, MarkedPipe]
})
export class ProductTmdComponent {
  @Input() tmd: TargetMarketDeterminationDto;
  @Input() applySettings: ApplySettings;
  @Input() page: ProductPageDTO;
}
