import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'fc-input-column',
    template: `
  <label>{{label}}</label>
  <ng-content></ng-content>`,
    styleUrls: ['./input-column.component.scss'],
    standalone: true
})
export class InputColumnComponent {
  _label: string;
  get label(): string {
    return this._label;
  }
  @Input() set label(value: string) {
    this._label = value;
    this.classes = 'fc-' + value.toLowerCase().replace(' ', '-');
  }

  @HostBinding('class') classes: string;
}
