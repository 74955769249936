<div class="fc-input-two-columns">
    <fc-input-column label="Borrow Amount">
        <fc-slider-input [(value)]="values.borrowingAmount" prefix="$" min="1000" max="50000" step="1000"></fc-slider-input>
    </fc-input-column>
    <fc-input-column label="Repayment Term">
        <fc-slider-input [(value)]="values.loanTerm" suffix="Years" min="1" max="15"></fc-slider-input>
    </fc-input-column>
</div>
<div class="fc-input-two-columns" *ngIf="isVisible()">
    <fc-input-column label="Loan Security">
        <fc-option-input [(value)]="values.loanSecurity" [settings]="loanSecuritySettings"></fc-option-input>
    </fc-input-column>
    <fc-input-column label="Interest Rate Type">
        <fc-option-input [(value)]="values.interestType" [settings]="interestTypeSettings"></fc-option-input>
    </fc-input-column>
</div>