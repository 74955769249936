import { Component} from '@angular/core';
import { ContentBrandDto, ContentComponentDto } from 'src/app/models/generated/content/ContentComponentDto';
import { FadeInOut } from 'src/app/shared/animations';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'fc-content-brands',
    templateUrl: './content-brands.component.html',
    styleUrls: ['./content-brands.component.scss'],
    animations: [FadeInOut(1000, 10)],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, FCRouterLinkDirective, MarkedPipe]
})
export class ContentBrandsComponent extends ContentBaseComponent   {
  selectedComponentIndex = -1;
  selected: ContentComponentDto | null = null;
  pageSize: number = 8
  startIndex: number = 0;
  activeBrands: ContentBrandDto[];

  onContentChanged(): void {
    this.selectedComponentIndex = 0;
    if (this.content.components?.length > 0) {
      this.onComponentSelected(this.content.components[0], 0);
      this.selected = this.content.components[this.selectedComponentIndex];
    }
  }

  onComponentSelected(component: ContentComponentDto, index: number): void {
    this.selectedComponentIndex = index;
    this.selected = component;
    this.startIndex = 0;
    this.activeBrands = this.pageNext(this.selected.brands, 0);
  }

  nextItems(): void {
    if (this.selected){
      this.activeBrands = this.pageNext(this.selected.brands, this.pageSize);
    }
  }

  previousItems(): void {
    if (this.selected){
      this.activeBrands = this.pagePrevious(this.selected.brands, this.pageSize * -1);
    }
  }
 
  pageNext(array: ContentBrandDto[], step: number) {
    let pageStart: number = this.startIndex + step;
    let pageEnd: number = pageStart + this.pageSize;
    if (pageEnd <= array.length) {
      this.startIndex = pageStart;
      return array.slice(pageStart, pageEnd);
    } else {
      let remaining = array.slice(pageStart, pageEnd);
      let next = array.slice(0, this.pageSize - remaining.length);
      this.startIndex = (this.pageSize - next.length) *-1;
      return [...remaining, ...next];
    }
  }

  pagePrevious(array: ContentBrandDto[], step: number) {
    if (this.startIndex >= 0) {
      let pageStart = this.startIndex + step;
      let pageEnd = this.startIndex;
      this.startIndex = pageStart;
      if (pageStart >= 0) {  
        return array.slice(pageStart, pageEnd);
      } else {
        let end = array.slice(array.length + this.startIndex, array.length);
        let start = array.slice(0, pageEnd);
        return [...end, ...start];
      }
    } else {
      let pageEnd = array.length + this.startIndex;
      let pageStart = pageEnd - this.pageSize;
      this.startIndex = pageStart;
      return array.slice(pageStart, pageEnd);
    }
  }
}




