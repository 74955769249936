import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/models/shared/Utils';

@Injectable({ providedIn: 'root' })
export class QuickAccessLinkService {
    constructor(private router: Router ) {}
    navigate(target: string): void {
        if (Utils.isSameDomain(target)) {
            const parts = target.trim().split(/[#/]/);
            const route = parts[1];
            const fragment = parts[2];
            this.router.navigate([route], { fragment });
        } else {
            window.location.href = target;
        }
    }
}
