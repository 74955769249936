import { Injectable } from '@angular/core';
import { PartnerAppSettings } from 'src/environments/partnerAppSettings';
import { environment } from 'src/environments/environment';
import { AppSettingsEnvironment, PartnerAppSettingsEnvironment, PartnerAppSettingsType } from 'src/environments/environmentDefinitions';
import { AppSettings } from 'src/environments/appSettings';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  public static AppEnvironment: AppSettingsEnvironment;
  public static PartnerAppSettings: PartnerAppSettingsType;
  public static PartnerEnvironment: PartnerAppSettingsEnvironment;

  legalDisclosureHeader: string;
  legalDisclosureGeneric: string;
  legalDisclosureFooter: string;
  
  public LoadStartupSettings(url: string): AppSettingsEnvironment {
    AppConfigService.PartnerAppSettings = PartnerAppSettings;

    const deployedEnvironment = this.GetDeployedEnvrionment(url);

    AppConfigService.AppEnvironment = AppSettings[deployedEnvironment];
    AppConfigService.PartnerEnvironment = PartnerAppSettings[deployedEnvironment];
    return AppConfigService.AppEnvironment;
  }

  private GetDeployedEnvrionment(url: string): 'dev' | 'publicDev' | 'staging' | 'production' {
    url = url.toLocaleLowerCase();

    if (environment.environment === 'production' && !url.includes('localhost')) {
      if (url.includes('-staging.') || url.includes('staging-') || url.includes(new URL(PartnerAppSettings.staging.partnerDomainRoot.toLocaleLowerCase()).hostname)) {
        return 'staging';
      } else if (url.includes('-dev.')
        || url.includes('dev-')
        || url.includes('-testing.')
        || url.includes(new URL(PartnerAppSettings.publicDev.partnerDomainRoot.toLocaleLowerCase()).hostname)
        || url.includes('-no-')
        ) {
        return 'publicDev';
      } else {
        return 'production';
      }
    } else {
      return 'dev';
    }
  }
}
