import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SortOptionDTO } from 'src/app/models/generated/sort/SortOptionDTO';
import { DropDownListItem } from 'src/app/models/shared/ButtonListStyleSettings';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Directive({
    selector: 'li[fcAnalyticsSort]',
    standalone: true
})
export class AnalyticsSortDirective implements OnInit {
  @Input('fcAnalyticsSort') item: DropDownListItem<SortOptionDTO>;

  constructor(private element: ElementRef, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    if (this.item.class !== 'fc-dropdown-divider' ) {
    const dataTags = this.analyticsService.getSortDataTags(this.item);
    this.analyticsService.setDataTags(dataTags, this.element.nativeElement);
    }
  }
}
