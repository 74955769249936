
import { Component, HostBinding, Input } from '@angular/core';
import { GroupOptionListDTO } from 'src/app/models/generated/group/GroupOptionListDTO';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { AnalyticsCategoryDirective } from '../../directives/analytics/analytics-category.directive';
import { FCRouterLinkDirective } from '../../directives/fc-router-link.directive';
import { NgFor, NgClass, NgIf } from '@angular/common';


@Component({
    selector: 'fc-category-card',
    templateUrl: './category-card.component.html',
    styleUrls: ['./category-card.component.scss'],
    standalone: true,
    imports: [NgFor, FCRouterLinkDirective, AnalyticsCategoryDirective, NgClass, NgIf, MarkedInlinePipe]
})
export class CategoryCardComponent {
 @HostBinding('style.--fc-cc-col-count') colCount: number;
  private _items: CardSettings[];
  @Input() set items(items: CardSettings[]) {
    this._items = items;
    this.colCount = items.length;
  }
  get items(): CardSettings[] {
    return this._items;
  }
}

export interface CardSettings {
  heading: string;
  url: string;
  sortOrder: number;
  icon?: string;
  summary?: string;
  accentTheme?: string;
  imageSrc?: string;
  buttonText?: string;
  group?: GroupOptionListDTO;
}
