import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompareDTO } from 'src/app/models/generated/item/CompareDTO';
import { TableDTO } from 'src/app/models/generated/table/TableDTO';
import { TableBrandDto, TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { StandardInputDto } from 'src/app/models/shared/inputs/StandardInputDto';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { DynamicSupport, Utils } from 'src/app/models/shared/Utils';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({ providedIn: 'root' })
export class TableService {
    controller = AppConfigService.AppEnvironment.apiEndPoint + '/table';
    private brandsChange: BehaviorSubject<TableBrandDto[]> = new BehaviorSubject([]);

    constructor(private http: HttpClient) { }

    public GetTable(categoryId: number, params: PagingParameterDto, input?: StandardInputDto): Observable<TableDTO> {
        let url = `${this.controller}/${categoryId}?` + Utils.ToQueryString(params as DynamicSupport, null);
        if (!Utils.isNull(input)) {
            url = `${url}&` + Utils.ToQueryString(input as DynamicSupport, null);
        }

        return this.http.get<TableDTO>(url).pipe(
            tap(d => {
                if (d) {
                    this.brandsChange.next(d.brands);
                }
            })
        );
    }

    public GetComparison(items: TableItemDTO[]): Observable<CompareDTO[]> {
        const ids = items.map(x => 'ids=' + x.product.id).join('&');
        return this.http.get<CompareDTO[]>(this.controller + '/CompareProducts?' + ids);
    }

    public onBrandsChanged(): Observable<TableBrandDto[]> {
        return this.brandsChange.asObservable();
    }
}
