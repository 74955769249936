<div class="oth-logo">
    <img title="On The House Logo" src="https://ik.imagekit.io/fairaus/on-the-house/icons/oth-logo.svg" width="170"
        height="59">
</div>
<div class="oth-social">
    <a href="https://www.facebook.com/Onthehouse.com.au/" title="Facebook" target="_blank" rel="nofollow noopener noreferrer">
        <i class="oth-icon oth-facebook"></i>
    </a>
    <a href="https://twitter.com/onthehouse_au" title="Twitter" target="_blank" rel="nofollow noopener noreferrer">
        <i class="oth-icon oth-twitter"></i></a>
    <a href="https://www.youtube.com/user/onthehouseAU" title="Youtube" target="_blank" rel="nofollow noopener noreferrer">
        <i class="oth-icon oth-youtube"></i>
    </a>
    <a href="https://www.pinterest.com.au/onthehouseau/" title="Pinterest" target="_blank" rel="nofollow noopener noreferrer">
        <i class="oth-icon oth-pintrest"></i>
    </a>
</div>
<div class="oth-getting-started">
    <p>GETTING STARTED</p>
    <ul>
        <li><a href="https://www.onthehouse.com.au/find-agent">Find an agent</a></li>
        <li><a href="https://www.onthehouse.com.au/staticsitemap">Site Map</a></li>
    </ul>
</div>
<div class="oth-agents">
    <p>AGENTS</p>
    <ul>
        <li><a href="https://www.onthehouse.com.au/agency-join">Create an account</a></li>
        <li><a href="https://www.onthehouse.com.au/agent-contact">Contact Form for Agents</a></li>
        <li><a href="https://help.onthehouse.com.au/hc/en-au/categories/360000997976-Help-for-Agents" target="_blank" rel="nofollow noopener noreferrer">Agent Help Centre</a></li>
    </ul>
</div>
<div class="oth-info">
    <p>INFO</p>
    <ul>
        <li><a href="https://www.onthehouse.com.au/terms-of-use">Terms of use</a></li>
        <li><a href="https://corelogic.com.au/about-us/privacy-policy.html" target="_blank" rel="nofollow noopener noreferrer">Privacy policy</a></li>
        <li><a href="https://www.corelogic.com.au/about-us/copyright-disclaimer" target="_blank" rel="nofollow noopener noreferrer">Disclaimers</a></li>
    </ul>
</div>
<div class="oth-support">
    <p>SUPPORT</p>
    <ul>
        <li><a href="https://www.onthehouse.com.au/contact-us">Contact us</a></li>
        <li><a href="https://help.onthehouse.com.au/hc/en-au" target="_blank" rel="nofollow noopener noreferrer">Help Centre</a></li>
    </ul>
</div>