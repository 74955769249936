import { Component, HostBinding, Input } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import { ArticlePublishDto } from 'src/app/models/article/articleDto';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { FCRouterLinkDirective } from '../../directives/fc-router-link.directive';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';
import { MarkedInlinePipe } from 'src/app/pipes/marked.pipe';

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
    selector: 'fc-publish-profile',
    templateUrl: './publish-profile.component.html',
    styleUrls: ['./publish-profile.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, TooltipModule, MarkedInlinePipe]
})
export class PublishProfileComponent {
  readMessage: string | null;
  twitterUrl: string | null;
  linkedInUrl: string | null;
  emailUrl: string| null;

  authorUrl: string;
  factCheckedUrl: string;
  factCheckedTooltip: string;
  updatedUrl: string;
  publishedAt: string;
  updatedAt: string;

  _profile: ArticlePublishDto;
  get profile(): ArticlePublishDto {
      return this._profile;
  }
  @Input() set profile(value: ArticlePublishDto) {
      this._profile = value;

      this.updateFields();
  }
  @HostBinding('class') variant: string;

  constructor(private seoService: SeoService) {
  }

  updateFields(): void {
    this.readMessage = null;
    this.twitterUrl = null;
    this.linkedInUrl = null;

    this.factCheckedUrl = this.seoService.getFactCheckPage();

    if (this._profile) {
      this.readMessage = this.generateReadTimeMessage(this._profile.readDuration);

      if (this._profile.author) {
        this.authorUrl = this.seoService.getAuthorsPage(this.profile.author.slug, this._profile.author.externalUrl);

        if (this._profile.author?.twitter) {
          let handle = this._profile.author.twitter;
          if (handle.charAt(0) === '@') {
            handle = handle.slice(1);
          }
          this.twitterUrl = `https://twitter.com/${handle}`;
        }

        if (this._profile.author.linkedIn) {
          this.linkedInUrl = `https://www.linkedin.com/in/${this._profile.author.linkedIn}`;
        }

        if (this._profile.author.email) {
          this.emailUrl = `mailto:${this._profile.author.email}`;
        }

        if (!this._profile.author.imageSrc && !this.hasContact()) {
          this.variant = 'fc-publish-no-icon-no-contact';
        }
      }

      if (this._profile.publishedAt) {
        this.publishedAt = dayjs(this._profile.publishedAt).tz('Australia/Melbourne').format('MMMM DD, YYYY - hh:mm a');
      }
      if (this._profile.updatedAt) {
        this.updatedAt = dayjs(this._profile.updatedAt).tz('Australia/Melbourne').format('MMMM DD, YYYY - hh:mm a');
      }

      if (this._profile.factCheck && environment.client.factCheckMessage) {
        let msg = environment.client.factCheckMessage;
        msg = msg.replace('[FACTCHECKED_BY]', this._profile.factCheck.name);
        msg = msg.replace('[FACTCHECKED_JOBTITLE]', this._profile.factCheck.jobTitle);
        msg = msg.replace('[FACTCHECKED_TIMESTAMP]', dayjs(this._profile.factCheck.checkedAt).tz('Australia/Melbourne').format('MMMM DD, YYYY - hh:mm a'));
        this.factCheckedTooltip = msg;
      }
    }
  }

  hasContact(): string | null {
    return this.twitterUrl || this.emailUrl || this.linkedInUrl;
  }
  generateReadTimeMessage(readDuration: number): string | null {
    if (readDuration === 0) {
      return null;
    } else if (readDuration <= 2) {
      return `less than ${readDuration} min read`;
    } else {
      return `${readDuration} min read`;
    }
  }
}
