
import { Component, OnInit } from '@angular/core';
import { CompareService } from 'src/app/services/compare.service';
import { IStackedColumns, TableHelperService } from 'src/app/services/table-helper.service';
import { ItemBaseComponent } from 'src/app/templates/tables/standard/table-base.component';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { ColumnContainerComponent } from '../../components/column-container/column-container.component';
import { MoreDetailsComponent } from '../../components/more-details/more-details.component';
import { CompareCheckboxComponent } from '../../../../shared/compare/compare-checkbox/compare-checkbox.component';
import { ApplyComponent } from '../../../../shared/buttons/apply/apply.component';
import { TableHeaderComponent } from '../../components/table-header/table-header.component';
import { TableImageComponent } from '../../components/table-image/table-image.component';
import { NgIf } from '@angular/common';
import { PromotedLabelComponent } from '../../components/promoted-label/promoted-label.component';
@Component({
    selector: 'fc-table-default',
    templateUrl: './table-default.component.html',
    styleUrls: ['./table-default.component.scss', '../table-base.scss'],
    standalone: true,
    imports: [PromotedLabelComponent, NgIf, TableImageComponent, TableHeaderComponent, ApplyComponent, CompareCheckboxComponent, MoreDetailsComponent, ColumnContainerComponent, MarkedInlinePipe]
})
export class TableDefaultComponent extends ItemBaseComponent implements OnInit {
  stack: IStackedColumns;

  constructor(compareService: CompareService, private tableHelperService: TableHelperService) {
    super(compareService);
  }

  ngOnInit(): void {
    this.stack = this.tableHelperService.stackColumnsV2(this.item.content.columns);
    if (this.stack.stackOne.length > 0 && this.stack.stackTwo.length > 0) {
      this.columnLgColumns = 'auto 200px 200px';
      this.columnLgAreas = '\'flat stack-one stack-two\'';
    } else if (this.stack.stackOne.length) {
      this.columnLgColumns = 'auto 200px';
      this.columnLgAreas = '\'flat stack-one\'';
    } else {
      this.columnLgColumns = 'auto';
      this.columnLgAreas = '\'flat\'';
    }
  }
}
