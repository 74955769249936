import { Component, ComponentFactoryResolver, ComponentRef,  Directive,  Input,Type,ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { PageTypeHelper, PageTypes } from 'src/app/models/shared/helpers/PageTypesHelper';
import { AnalyticsModeType } from 'src/app/services/analytics.service';
import { ContentCtaGridComponent } from 'src/app/templates/content/content-cta-grid/content-cta-grid.component';
import { ArticleColumnComponent } from 'src/app/templates/article/article-column/article-column.component';
import { ArticleHeadlineOnlyComponent } from 'src/app/templates/article/article-headline-only/article-headline-only.component';
import { ArticleHeadlineComponent } from 'src/app/templates/article/article-headline/article-headline.component';
import { ArticleImgHeadlineComponent } from 'src/app/templates/article/article-img-headline/article-img-headline.component';

import { ContentAccordionComponent } from 'src/app/templates/content/content-accordion/content-accordion.component';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ContentBrandsComponent } from 'src/app/templates/content/content-brands/content-brands.component';
import { ContentBulletComponent } from 'src/app/templates/content/content-bullet/content-bullet.component';
import { ContentComparisonComponent } from 'src/app/templates/content/content-comparison/content-comparison.component';
import { ContentCtaCardComponent } from 'src/app/templates/content/content-cta-card/content-cta-card.component';
import { ContentCTAComponent } from 'src/app/templates/content/content-cta/content-cta.component';
import { ContentDefaultComponent } from 'src/app/templates/content/content-default/content-default.component';
import { ContentGridComponent } from 'src/app/templates/content/content-grid/content-grid.component';
import { ContentHtmlComponent } from 'src/app/templates/content/content-html/content-html.component';
import { ContentSingleImageComponent } from 'src/app/templates/content/content-single-image/content-single-image.component';
import { ContentTableComponent } from 'src/app/templates/content/content-table/content-table.component';
import { BackgroundComponent } from 'src/app/templates/content/utils/background/background.component';
import { ContentVerticalComponent } from 'src/app/templates/content/content-vertical/content-vertical.component';

type ArticleTypes =
'fc-article-default' | 'fc-article-headline' |'fc-article-headlines-only' |
'fc-article-three-column' | 'fc-article-two-column' | 'fc-article-two-column-promoted' |  'fc-article-two-stories';
type TextTypes = 'fc-content-default' | 'fc-content-accordion' |  'fc-content-table';
type SocialTypes = 'fc-social-youtube' | 'fc-social-twitter';
type ComparisonTypes = 'fc-content-comparison';

type DisplayTypes = TextTypes | ArticleTypes | SocialTypes | ComparisonTypes;

@Directive({
    selector: '[fcComponentHost]',
    standalone: true
})
export class ComponentDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}


@Component({
    selector: 'fc-content-container',
    template: `<ng-template fcComponentHost></ng-template>`,
    styleUrls: ['./content-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ComponentDirective]
})
export class ContentContainerComponent {
  _page: PageTypes;
  get page(): PageTypes {
      return this._page;
  }
  @Input() set page(value: PageTypes) {
      this._page = value;
      this.renderContainers();
  }

  @Input() analyticsMode: AnalyticsModeType = 'None';



  @ViewChild(ComponentDirective, { static: true }) fcComponentHost!: ComponentDirective;

  compMap: { [id: string]: Type<ContentBaseComponent>; }   = {
    'fc-content-default': ContentDefaultComponent,
    'fc-content-accordion': ContentAccordionComponent,
    'fc-content-table': ContentTableComponent,
    'fc-content-single-image': ContentSingleImageComponent,
    'fc-content-grid': ContentGridComponent,
    'fc-content-bullet': ContentBulletComponent,
    'fc-content-cta': ContentCTAComponent,
    'fc-content-cta-card': ContentCtaCardComponent,
    'fc-content-cta-grid': ContentCtaGridComponent,
    
    'fc-content-brands': ContentBrandsComponent,
    'fc-content-html': ContentHtmlComponent,
    'fc-content-vertical': ContentVerticalComponent,

    // Articles
    'fc-article-headline': ArticleHeadlineComponent,
    'fc-article-column': ArticleColumnComponent,
    'fc-article-headline-only': ArticleHeadlineOnlyComponent,
    'fc-article-img-headline': ArticleImgHeadlineComponent,

    // Comparison
    'fc-content-comparison': ContentComparisonComponent
  };

  isArticleType(value: string): value is ArticleTypes {
    return ['fc-article-headline', 'fc-article-column', 'fc-article-headline-only'].includes(value);
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  renderContainers(): void {
    const viewContainerRef = this.fcComponentHost.viewContainerRef;
    viewContainerRef.clear();

    if (this._page && PageTypeHelper.hasRichContainers(this._page)) {
      let i = 0;
      for (const c of this._page.content) {
        if (!this.isArticleType(c.template) || c.components?.length > 0) {
            if (c.backgroundColour) {
              this.generateBackgroundComponent(c, i);
            }
           this.generateComponent(c.template as any, c, i);
            i++;
        }
      }
    }
  }

  generateComponent(template: DisplayTypes, container: ContentContainerDto, index: number): void {
    const componentAttribute = this.compMap[template];
    if (componentAttribute) {
      const factory: any = this.componentFactoryResolver.resolveComponentFactory(componentAttribute);
      const component: ComponentRef<ContentBaseComponent> = this.fcComponentHost.viewContainerRef.createComponent(factory);
      component.instance.content = container;
      component.instance.index = index;
      component.instance.analyticsMode = this.analyticsMode;
      component.instance.page = this._page;
    }
  }

  generateBackgroundComponent(container: ContentContainerDto, index: number): void {
      const factory: any = this.componentFactoryResolver.resolveComponentFactory(BackgroundComponent);
      const component:ComponentRef<BackgroundComponent> = this.fcComponentHost.viewContainerRef.createComponent(factory);
      component.instance.content = container;
      component.instance.index = index;
  }
}

