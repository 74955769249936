import { Params } from '@angular/router';
import { DefaultInputSettings, DefaultInputUtils } from 'src/app/models/shared/inputs/defaults/DefaultInputUtils';
import { DefaultInputDto } from './defaults/DefaultInputDto';
import { StandardInputDto } from './StandardInputDto';

export interface CreditCardsInputDto extends StandardInputDto {
  annualFee: number | null;
  purchaseRate: number | null;
  balanceTransferRate: number | null;
  minCreditLimit: number| null;
  interestFreePeriod: number | null;
}

export class CreditCardsInput {
    public static Initialise(defaultInput: DefaultInputDto, queryParams: Params): CreditCardsInputDto {
      const settings: DefaultInputSettings = {
        elements: defaultInput.elements,
        queryParams
      };

      const result: CreditCardsInputDto = {
        kind: defaultInput.kind,
        includeAllProducts: defaultInput.includeAllProducts,
        brands: [],
        annualFee: DefaultInputUtils.FindElementValue(settings, 'annualFee'),
        purchaseRate: DefaultInputUtils.FindElementValue(settings, 'purchaseRate'),
        balanceTransferRate: DefaultInputUtils.FindElementValue(settings, 'balanceTransferRate'),
        minCreditLimit: DefaultInputUtils.FindElementValue(settings, 'minCreditLimit'),
        interestFreePeriod: DefaultInputUtils.FindElementValue(settings, 'interestFreePeriod'),
      };
      return result;
    }
}
