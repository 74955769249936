import { Component } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { NgFor } from '@angular/common';
import { ContentHeaderSummaryComponent } from '../utils/content-header-summary/content-header-summary.component';

@Component({
    selector: 'fc-content-cta-grid',
    templateUrl: './content-cta-grid.component.html',
    styleUrls: ['./content-cta-grid.component.scss'],
    standalone: true,
    imports: [ContentHeaderSummaryComponent, NgFor]
})
export class ContentCtaGridComponent  extends ContentBaseComponent {

}
