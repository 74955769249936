<div class="fc-product-details-outer-row">
    <div class="col-12 col-sm-12 col-md">
        <div class="fc-product-details-container">
            <div class="fc-product-details">
                <div class="fc-product-details-header" [ngClass]="accentTheme">
                    <h2><span><i [ngClass]="value.icon"></i></span>{{value.title}}</h2>
                </div>
                <table class="fc-content-table" style="box-shadow: none;">
                    <tr class="fc-content-table-row">
                        <td class="fc-content-table-cell fc-content-table-th"> Tier Name</td>
                        <td class="fc-content-table-cell fc-content-table-th" *ngFor="let val of value | fcTableValue:'tier' : true">
                            {{val.label}}
                        </td>
                    </tr>
                    <tr class="fc-product-details-row" *ngFor="let tier of value | fcTableValue: 'tier'; let i = index" style="text-align: center; padding: 20px;">
                        <td class="fc-table-values">
                            {{tier.name}}
                        </td>
                        <td *ngFor="let p of tier.messages" class="fc-table-values" >
                            {{p.primaryMessage}}
                            <fc-tool-tip *ngIf="p.additionalInfo !== '-'" [primary]="p.additionalInfo"></fc-tool-tip> 
                        </td>
                        <br>
                    </tr>
                    
                </table>
            </div>
        </div>
    </div>
</div>
