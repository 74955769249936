import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { StandardInputDto } from "src/app/models/shared/inputs/StandardInputDto";
import { InputService } from "src/app/services/input.service";
import { OptionInputSettings } from "src/app/shared/filters/components/option-input/option-input.component";

export interface InputComponent { 
    settings: FilterSettings;
}


export interface FilterSettings {
    showVertical: boolean
}

@Component({ template: '' })
export abstract class BaseFilter<TInputType extends StandardInputDto> implements OnInit, InputComponent, OnDestroy {
    private subs: Subscription[] = [];

    @Input() settings: FilterSettings;
    
    showAllOptions = false;
    values: TInputType;
    yesNoSettings: OptionInputSettings = {
        disabled: () => false,
        elementOne: {
            label: 'Yes',
            value: true
        },
        elementTwo: {
            label: 'No',
            value: false
        },
    }

    constructor(public inputService: InputService) {}


    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }


    isVisible() {
        return this.showAllOptions || this.settings.showVertical;
    } 

    ngOnInit(): void {
        this.subs.push(this.inputService.onInputChangeEvent().subscribe(x => {
            this.values = x as TInputType;
        }));

        this.subs.push(this.inputService.onShowAllEvent().subscribe( x => {
            this.showAllOptions = x;
        }));
    }
}