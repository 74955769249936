import { ContentScriptsDto } from "src/app/models/generated/content/ContentComponentDto";

export class RenderHelper {
    


    constructor(private doc: Document) { }
    
    renderStyleSheet(id: string, href: string, avoidBlocking: boolean) {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('link');
        sc.id = id;
        sc.rel = 'stylesheet';
        sc.href = href;

        if (avoidBlocking) {
          sc.media = 'print';
          sc.setAttribute("onload", "this.media='all'");
        }
        
        this.appendToTop(sc);
      }
    }

    renderPriorityStyle(id: string, style: string): void {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('style');
        sc.id = id;
        sc.textContent = style;
        this.appendToTop(sc);
      }
    }

    renderMeta(name: string, content: string): void {
      if (!this.doc.querySelector(`meta[content="${content}"]`)) {
        const meta = this.doc.createElement('meta');
        meta.name = name;
        meta.content = content;

        this.appendToTop(meta);
      }
    }

    private appendToTop(element: HTMLLinkElement | HTMLStyleElement | HTMLScriptElement | HTMLMetaElement): void {
      const head = this.doc.getElementsByTagName('head')[0];
      head.insertBefore(element, head.firstChild);
    }

    private isScriptAlreadyLoaded(scriptSrc: string): boolean {
      const scripts = this.doc.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') === scriptSrc) {
          return true;
        }
      }
      return false;
    }

    renderPreload(id: string, href: string, type: string = 'font/woff2'): void {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('link');
        sc.id = id;
        sc.rel = 'preload';
        sc.href = href;

        if (type) {
          sc.type = type;
        }

        sc.setAttribute('as', 'font');
        sc.crossOrigin = 'anonymous';
        this.appendToTop(sc);
      }
    }

    renderPrefetch(id: string, href: string): void {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('link');
        sc.id = id;
        sc.rel = 'dns-prefetch';
        sc.href = href;
        this.appendToTop(sc);
      }
    }

    renderScript(id: string, content: string, priority: boolean = false): void {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('script');
        sc.type = 'text/javascript';
        sc.id = id;
        sc.appendChild(this.doc.createTextNode(content));

        if (priority) {
          this.appendToTop(sc);
        } else {
          this.doc.getElementsByTagName('head')[0].appendChild(sc);

        }
      }
    }

    renderSource(id: string, src: string): void {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('script');
        sc.type = 'text/javascript';
        sc.id = id;
        sc.src = src ;
        sc.async = true;

        this.doc.getElementsByTagName('head')[0].appendChild(sc);
      }
    }

    renderScriptSource(script: ContentScriptsDto): void {
      if (!this.isScriptAlreadyLoaded(script.src)) {
        const sc = this.doc.createElement('script');
        sc.type = 'text/javascript';
        sc.src =  script.src ;
        sc.async = script.async;
        sc.defer = script.defer;
        this.doc.getElementsByTagName('head')[0].appendChild(sc);
      }
    }

    removeScriptSource(scriptSrc: string) {
      const scripts = this.doc.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') === scriptSrc) {
          scripts[i].remove();
        }
      }
    }

    renderNoScript(id: string, content: HTMLElement): void {
      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('noscript');
        sc.id = id;
        sc.appendChild(content);

        this.doc.getElementsByTagName('head')[0].appendChild(sc);
      }
    }

    renderGTMScript(gtmTag: string): void {
      const id = 'fc-gtm-script';
      const content = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','fc_dl','${gtmTag}');`;

      if (!this.doc.getElementById(id)) {
        const sc = this.doc.createElement('script');
        sc.id = id;
        sc.appendChild(this.doc.createTextNode(content));
        this.doc.getElementsByTagName('head')[0].appendChild(sc);
      }
    }
}
