import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ProductParams } from 'src/app/models/shared/Utils';


@Injectable({ providedIn: 'root' })
export class ProductService {
  url =  AppConfigService.AppEnvironment.apiEndPoint + '/Product';

  constructor(private http: HttpClient) { }

  public GetDetails(params: ProductParams): Observable<ProductPageDTO> {
    let url = `${this.url}/Details/${params.sector}/${params.category}/${params.brand}/${params.product}`;

    if (params.group) {
      url += `?group=${params.group}`;
    }

    return this.http.get<ProductPageDTO>(url);
  }
}
