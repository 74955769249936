import { Component } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';
import { NgFor } from '@angular/common';
import { ContentHeaderSummaryComponent } from '../utils/content-header-summary/content-header-summary.component';

@Component({
    selector: 'fc-content-bullet',
    templateUrl: './content-bullet.component.html',
    styleUrls: ['./content-bullet.component.scss'],
    standalone: true,
    imports: [ContentHeaderSummaryComponent, NgFor, MarkedPipe]
})
export class ContentBulletComponent  extends ContentBaseComponent { }
