<fc-article-header [content]="content" [analyticsMode]="analyticsMode">
    <div class="fc-article-headline">
    <fc-article-sidebyside  
        [tagDisplay]="content.tagDisplay" 
        [theme]="'headline'"
        [imageDimension]="'16-9'"
        [article]="content.components[0]"
        [priority]="true" 
        [analyticsMode]="analyticsMode">
    </fc-article-sidebyside>
    </div>
    <div class="fc-article-headline-three-column">
        <fc-article-sidebyside 
            *ngFor="let c of content.components | slice: 1;" 
            [tagDisplay]="content.tagDisplay" 
            [theme]="'promoted'"
            [imageDimension]="promotedDimension"
            [article]="c" [analyticsMode]="analyticsMode">
        </fc-article-sidebyside>
    </div> 
</fc-article-header>
