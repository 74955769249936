import { Component, OnInit } from '@angular/core';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { AccordianBaseComponent } from 'src/app/shared/accordian-base-compontent';
import { FooterComponent } from 'src/app/templates/footers/footer-switcher.component';
import { ExecutivetravellerHeaderComponent, ETLinkGroup } from 'src/app/templates/headers/executivetraveller-header/executivetraveller-header.component';
import { NgFor, NgStyle } from '@angular/common';

@Component({
    selector: 'fc-executivetraveller-footer',
    templateUrl: './executivetraveller-footer.component.html',
    styleUrls: ['./executivetraveller-footer.component.scss'],
    standalone: true,
    imports: [NgFor, NgStyle]
})
export class ExecutivetravellerFooterComponent extends AccordianBaseComponent implements FooterComponent, OnInit {
  linkGroups: ETLinkGroup[];
  constructor() {
    super('ext-group-content', 'ext-menu-group-expanded');
  }
  links: SectorStaticFooterDto[];

  ngOnInit(): void {
    this.linkGroups = ExecutivetravellerHeaderComponent.GetMainLinks(true);
  }
}
