<div class="fc-brands-background"></div>
<h1 [innerHtml]="details?.heading"></h1>
<div class="fc-brands-description" [innerHtml]="details?.description | marked"></div>
<fc-product-action [categoryUrl]="categoryUrl" [compareButtonText]="details.compareAllText"></fc-product-action>
<fc-disclaimer class="fc-header-disclaimer" [message]="legalDisclosureHeader" [showLink]="showHeaderLink"></fc-disclaimer>
<div class="fc-brands-alphabet-background"></div>
<div class="fc-brands-alphabet">
    <ul>
        <li class="fc-brand-alphabet-item" *ngFor="let i of alphabetGroups | keyvalue">
            <button [disabled]="i.value.length == 0" (click)="scrollTo(i.key)">{{i.key}}</button>
        </li>
    </ul>
</div>
<fc-disclaimer class="fc-section-disclaimer" *ngIf="disclosure" [message]="disclosure" [showLink]="showTableLink"></fc-disclaimer>
<div class="fc-brands-sections">
    <div *ngFor="let i of alphabetGroups | keyvalue">
        <div class="fc-brands-key" *ngIf="i.value.length > 0" [id]="'key-' + i.key">{{i.key}}</div>
        <div class="fc-brands-col">
            <fc-brand-card *ngFor="let j of i.value" [brand]="j"></fc-brand-card>
        </div>
    </div>
</div>
<!-- <fc-content-container [containers]="details.content"></fc-content-container> -->