import { Component, ContentChild, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentComponentDto } from 'src/app/models/generated/content/ContentComponentDto';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { AnalyticsModeType } from 'src/app/services/analytics.service';
import { MarkedPipe } from '../../../../pipes/marked.pipe';
import { IconComponent } from '../../../../shared/icon/icon.component';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { NgTemplateOutlet, NgIf } from '@angular/common';


type NoLink  = 'h2NoLink' | 'h3NoLink';
type IntenalLink  = 'h2Internal' | 'h3Internal';
type ExternalLink  = 'h2External' | 'h3External';

type NoLinkIcon  = 'h2NoLinkIcon' | 'h3NoLinkIcon';
type InternalIcon  = 'h2InternalIcon' | 'h3InternalIcon';
type ExternalIcon  = 'h2ExternalIcon' | 'h3ExternalIcon';


@Component({
    selector: 'fc-content-header-summary',
    templateUrl: './content-header-summary.component.html',
    styleUrls: ['./content-header-summary.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, NgIf, FCRouterLinkDirective, IconComponent, MarkedPipe]
})
export class ContentHeaderSummaryComponent implements OnInit {

  @ContentChild('details') details: any;
  @Input() content: ContentContainerDto;
  @Input() analyticsMode: AnalyticsModeType;

  @Input() isLink: boolean;

  headerTemplate:
   'noHeader' |
   NoLink | IntenalLink | ExternalLink |
   NoLinkIcon | InternalIcon | ExternalIcon = 'noHeader';
  externalUrl: string;
  internalRoute: string;

  ngOnInit(): void {
    this.setHeaderTemplate();
  }

  private setHeaderTemplate(): void {
    this.headerTemplate = 'noHeader';
    if (this.content.heading) {
      if (this.isComponent(this.content)) {
        this.externalUrl = this.content.externalUrl;
        this.internalRoute = this.content.internalRoute;
      }

      if (this.content.iconSrc) {
        if (this.isLink && this.externalUrl) {
          this.headerTemplate =  this.getH2H3<ExternalIcon>('h2ExternalIcon', 'h3ExternalIcon');
        } else if (this.isLink && this.internalRoute) {
          this.headerTemplate =  this.getH2H3<InternalIcon>('h2InternalIcon', 'h3InternalIcon');
        } else {
          this.headerTemplate =  this.getH2H3<NoLinkIcon>('h2NoLinkIcon', 'h3NoLinkIcon');
        }
      } else {
        if (this.isLink && this.externalUrl) {
          this.headerTemplate =  this.getH2H3<ExternalLink>('h2External', 'h3External');
        } else if (this.isLink && this.internalRoute) {
          this.headerTemplate =  this.getH2H3<IntenalLink>('h2Internal', 'h3Internal');
        } else {
          this.headerTemplate =  this.getH2H3<NoLink>('h2NoLink', 'h3NoLink');
        }
      }
    }
  }

  isComponent(c: ContentContainerDto | ContentComponentDto): c is ContentComponentDto {
    return (c as ContentComponentDto).externalUrl !== undefined;
  }

  getH2H3<T>(h2Ref: T, h3Ref: T): T  {
     return (this.content.headingSize === 0) ? h2Ref : h3Ref;
  }

  generateId(container: ContentContainerDto): any {
    return container.heading.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
  }
}
