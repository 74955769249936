<fc-content-header-summary [content]="content" [analyticsMode]="analyticsMode">
    <div class="fc-content-cta-grid">
        <a class="fc-content-grid-item" *ngFor="let c of content.components; index as i;" [href]="c.externalUrl" rel="nofollow noopener" target="_blank">
            <div class="fc-content-grid-overlay">
                <img class="fc-content-grid-banner" [src]="c.image.imageSrc" [width]="c.image.width" [height]="c.image.height" [alt]="c.topics.category.label + ' Banner Image'">
                <img class="fc-content-grid-topic" [src]="c.topics.category.imageSrc" [width]="c.topics.category.width" [height]="c.topics.category.height" [alt]="c.topics.category.label + ' Logo'">
            </div>
            <label [innerHTML]="c.topics.category.label"></label>
            <h3 [innerHtml]="c.heading"></h3>
        </a>
    </div>
</fc-content-header-summary>