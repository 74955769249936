import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { ContentComponentDto } from 'src/app/models/generated/content/ContentComponentDto';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { Utils } from 'src/app/models/shared/Utils';
import { BreadcrumbResult } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RichcontentService {

  constructor(@Inject(DOCUMENT) private doc: Document, private seoService: SeoService) {}

  private renderScript(content: any, id: string = 'fc-rich-schema' ): void {
    let sc = this.doc.getElementById(id) as HTMLScriptElement;
    if (sc) {
      sc.innerHTML = JSON.stringify(content);
    } else {
      sc = this.doc.createElement('script');
      sc.type = 'application/ld+json';
      sc.id = id;
      sc.appendChild(this.doc.createTextNode(JSON.stringify(content)));
      this.doc.getElementsByTagName('head')[0].appendChild(sc);
    }
  }

  private generateOrganisation(): any {
    const organisationJson: any = {};
    organisationJson['@type'] = 'Organization';
    organisationJson.url = environment.client.schema.url; // 'https://www.news.com.au/compare';
    organisationJson.logo = environment.client.schema.logo; // 'http://www.example.com/images/logo.png';

    return organisationJson;
  }

  updateBreadcrumb(result: BreadcrumbResult): void {
    // const breadcrumbJson: any = {};

    // breadcrumbJson['@type'] = 'BreadcrumbList';

    // const elementsJson: any[] = [];
    // if (result.breadcrumb.length > 0) {

    //   let i = 1;
    //   for (const b of result.breadcrumb) {


    //     if (b.link) {
    //       const url = Utils.combinePaths(environment.client.schema.url, b.link);
    //       elementsJson.push({
    //         '@type': 'ListItem',
    //         position: i,
    //         name: b.title,
    //         item: url
    //       });
    //     } else {
    //       elementsJson.push({
    //         '@type': 'ListItem',
    //         position: i,
    //         name: b.title
    //       });
    //     }

    //     i++;
    //   }
    // }

    // breadcrumbJson.itemListElement = elementsJson;
    // const graph = {
    //   '@context': 'http://schema.org',
    //   '@graph':
    //   [
    //     this.generateOrganisation(),
    //     breadcrumbJson

    //   ]
    // };

    // this.renderScript(graph);
  }


  private generateQuestionAnswer(component: ContentComponentDto): any {
    const questionAnswerJson: any = {
      '@type': 'Question',
      name: component.heading,
      acceptedAnswer: {
        '@type': 'Answer',
        text: component.content
      }
    };
    return questionAnswerJson;
  }

  generateFAQ(value: ContentContainerDto): void {
    // if (value) {
    //   const questions: any[] = [];
    //   for (const component of value.components) {
    //     questions.push(this.generateQuestionAnswer(component));
    //   }

    //   if (questions.length > 0) {
    //     const FAQTag = {
    //       '@context': 'https://schema.org',
    //       '@type': 'FAQPage',
    //       mainEntity: questions
    //     };
    //     this.renderScript(FAQTag, 'fc-schema-faq-' + value.id);
    //   }
    // }
  }


  generateArticle(article: ArticleDTO): void {
    // const featureImageSrc = article.featureImageSrc.split('?')[0];
    // const ArticleTag = {
    //   '@context': 'https://schema.org',
    //   '@type': 'NewsArticle',
    //   headline: article.heading,
    //   image: [
    //     featureImageSrc + '?tr=ar-1-1,w-900',
    //     featureImageSrc + '?tr=ar-4-3,w-900',
    //     featureImageSrc + '?tr=ar-16-9,w-900',
    //   ],
    //   datePublished: article.publishDetails.publishedAt,
    //   dateModified: article.publishDetails.updatedAt ?? article.publishDetails.publishedAt,
    //   author: [
    //     {
    //       '@type': 'Person',
    //       name: article.publishDetails.author.name,
    //       url: environment.client.schema.url + '/authors/' + article.publishDetails.author.slug
    //     }
    //   ]
    // };
    // this.renderScript(ArticleTag, 'fc-schema-article');
  }
}
