import { Pipe, PipeTransform } from '@angular/core';
import { forEachChild } from 'typescript';
import { ItemAttributeDTO } from '../models/generated/item/ItemAttributeDTO';
import { ItemAttributeTierDTO } from '../models/generated/item/ItemAttributeTierDTO';
import { ItemGroupAttributeDTO } from '../models/generated/item/ItemGroupAttributeDTO';
import { ItemBaseComponent } from '../templates/tables/standard/table-base.component';

@Pipe({
    name: 'fcTableValue',
    standalone: true
})
export class FcTableValuePipe implements PipeTransform {

  transform(item: ItemGroupAttributeDTO, filterValue: string, getColumnNames: boolean = false): ItemAttributeDTO[] | ItemAttributeTierDTO[]{
    for( let v = 0; v < item.tiers.length; v++){
      item.tiers[0].messages[0].label;
    }

    if (getColumnNames){
      let output: ItemAttributeDTO[] = [];
      item.tiers[0].messages.forEach(v => {
        output.push(v);
      })
      return output;
    } else {
      let ratePresent = false;
      let output: ItemAttributeTierDTO[] = [];
      item.tiers.forEach(p => {
        if(p.name.toLowerCase().includes(filterValue)) {
          for( let i = 0; i< p.messages.length; i++){
            if (p.messages[i].label.toLowerCase().includes("rate") && p.messages[i]?.primaryMessage !== "-") {
              ratePresent = true;
              break;
            }
          }
          if (ratePresent) {
            if (p.messages[0].primaryMessage !== "-"){
             output.push(p);
            }
          }
        }
      });

      return output;
    }
    

    
  }


}

