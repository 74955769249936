import { Directive, ElementRef, Input, OnInit} from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CardSettings } from 'src/app/shared/category-card/category-card.component';

@Directive({
    selector: 'a[fcAnalyticsCategory]',
    standalone: true
})
export class AnalyticsCategoryDirective implements OnInit {
  @Input('fcAnalyticsCategory') category: CardSettings;

  constructor(private element: ElementRef, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    const dataTags = this.analyticsService.getCategoryDataTags(this.category);
    this.analyticsService.setDataTags(dataTags, this.element.nativeElement);
  }
}
