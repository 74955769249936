import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthenticationService } from './authentication-service.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const enabled =  AppConfigService.PartnerEnvironment.enableLogin;

    if (enabled && !this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    } else {
      return true;
    }
  }
}
