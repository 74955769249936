import { Component, Input } from '@angular/core';
import { ItemGroupAttributeDTO } from 'src/app/models/generated/item/ItemGroupAttributeDTO';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { ElementDisplayPipe } from 'src/app/pipes/element-display.pipe';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { TiersElementDisplayComponent } from './tiers-element-display/tiers-element-display.component';
import { StandardElementDisplayComponent } from './standard-element-display/standard-element-display.component';
import { ProductSeperatorComponent } from '../product-seperator/product-seperator.component';
import { NgFor, NgIf, NgClass } from '@angular/common';

type widthClassType = 'fc-product-display-full' | 'fc-product-display-split-one' | 'fc-product-display-split-two';
class ItemGroupAttributeEnhanced {
  showSeperator: boolean;
  widthClass: widthClassType;
  value: ItemGroupAttributeDTO;
}

@Component({
    selector: 'fc-product-all-values',
    templateUrl: './product-all-values.component.html',
    styleUrls: ['./product-all-values.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, ProductSeperatorComponent, StandardElementDisplayComponent, NgClass, TiersElementDisplayComponent]
})
export class ProductAllValuesComponent {
  @Input() applySettings: ApplySettings;
  @Input() page: ProductPageDTO;
  
  private _values: ItemGroupAttributeDTO[];
  get values(): ItemGroupAttributeDTO[] {
      return this._values;
  }
  @Input() set values(values: ItemGroupAttributeDTO[]) {
      this._values = values;
      this.updateValuesUI(values);
  }

  items: ItemGroupAttributeEnhanced[];

  updateValuesUI(values: ItemGroupAttributeDTO[]): void {
    this.items = [];

    for (let i = 0; i < values.length; i++) {
      const current = values[i];
      const previous = values[i - 1];
      const next = values[i + 1];

      const record: ItemGroupAttributeEnhanced = {
        value: current,
        showSeperator: this.isDifferentRow(current, previous),
        widthClass: this.widthClass(current, previous, next)
      };

      if (this.isVisible(current)) {
        this.items.push(record);
      }
    }
  }

  isVisible(x: ItemGroupAttributeDTO): boolean {
    return (x.messages.filter(ElementDisplayPipe.predicateMessages).length > 0 || x.tiers.length > 0);
  }

  widthClass(current: ItemGroupAttributeDTO, previous: ItemGroupAttributeDTO, next: ItemGroupAttributeDTO): widthClassType {
    if (this.page.enableStackedLayout) {
      return 'fc-product-display-full';
    }

    if  (current.row === previous?.row && this.isVisible(previous)) {
      return 'fc-product-display-split-two';
    }

    if (current.row === next?.row && this.isVisible(next)) {
      return 'fc-product-display-split-one';
    }

    return 'fc-product-display-full';
  }

  isDifferentRow(current: ItemGroupAttributeDTO, previous: ItemGroupAttributeDTO): boolean {
    if (this.page.enableStackedLayout) {
      return true;
    } else if (previous && current.row === previous.row) {
      return false;
    }
    return true;
  }
}
