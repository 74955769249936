
<div class="sv-image" >
    <img src="https://ik.imagekit.io/fairaus/savrr/savrr_logo_grey.svg" alt="Savrr Comparison & Discount Codes" width="182" height="40" loading="lazy">

</div>
<ul class="sv-footermenu">
    <li *ngFor="let mi of menu; let i = index">
        <a [FCRouterLink]="mi.route">{{mi.label}}</a>
    </li>
</ul>

<ng-container *ngIf="!isGlobal">
    <p class="sv-copyright">&#169; {{year}} Savrr. All rights reserved.</p> 
    <ul class="sv-disclaimermenu">
        <li *ngFor="let l of links;">
            <a [FCRouterLink]="seoService.getDisclaimerPage(l.slug)" [target]="disclaimersTarget">{{l.heading}}</a>
        </li>
    </ul>     
</ng-container>

<div class="svr-content">
   <svr-footer-section [details]="au_footer"></svr-footer-section>
   <svr-footer-section [details]="global_footer"></svr-footer-section>
</div>

