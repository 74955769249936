import { Injectable } from '@angular/core';
import { TableColumnDTO, TableColumnStack } from 'src/app/models/generated/table/TableColumnDTO';

export interface IStackedColumns {
  columns: TableColumnDTO[];
  stackOne: TableColumnDTO[];
  stackTwo: TableColumnDTO[];
  stackOneShade?: string;
  stackTwoShade?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TableHelperService {
  
  stackColumns(columns: TableColumnDTO[], columnMaxOrder: number, stackOneMaxOrder: number) : IStackedColumns {
    var result: IStackedColumns = {
      columns: [],
      stackOne:[],
      stackTwo:[]
    };

    for (let index = 0; index < columns.length; index++) {
      const column = columns[index];

      if (column.sortOrder <= columnMaxOrder) {
        result.columns.push(column);
      }


      if (column.sortOrder > columnMaxOrder && column.sortOrder  <= stackOneMaxOrder) {
        result.stackOne.push(column);
      }

      if (column.sortOrder > stackOneMaxOrder) {
        result.stackTwo.push(column);
      }
    }
    result.stackOneShade = 'fc-shade-' + (result.columns.length + 1);
    result.stackTwoShade = 'fc-shade-' + (result.columns.length + 2);
    return result;
  }

  stackColumnsV2(columns: TableColumnDTO[]): IStackedColumns {
    const result: IStackedColumns = {
      columns: [],
      stackOne: [],
      stackTwo: []
    };


    for (const column of columns) {
      if (column.stackNumber) {
        switch(column.stackNumber) {
          case TableColumnStack.stackOne:
            result.stackOne.push(column);
            break;
          case TableColumnStack.stackTwo:
            result.stackTwo.push(column);
            break;
          default:
            result.columns.push(column);
        }
      } else {
        result.columns.push(column);
      }
    }

    result.stackOneShade = 'fc-shade-' + (result.columns.length + 1);
    result.stackTwoShade = 'fc-shade-' + (result.columns.length + 2);

    return result;
  }

}
