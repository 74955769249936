<ng-container *ngTemplateOutlet="{
    'noHeader': noHeader, 
    'h2NoLink': h2NoLink, 
    'h3NoLink': h3NoLink,
    'h2Internal': h2Internal,
    'h3Internal': h3Internal,
    'h2External': h2External,
    'h3External': h3External, 
    'h2NoLinkIcon': h2NoLinkIcon, 
    'h3NoLinkIcon': h3NoLinkIcon,
    'h2InternalIcon': h2InternalIcon, 
    'h3InternalIcon': h3InternalIcon, 
    'h2ExternalIcon': h2ExternalIcon, 
    'h3ExternalIcon': h3ExternalIcon
}[headerTemplate]"></ng-container>
<div *ngIf="content.summary" class="fc-rich-container-summary-row" [innerHtml]="content.summary | marked: analyticsMode" ></div>
<ng-content></ng-content>
<div *ngIf="content.footer" class="fc-rich-container-footer-row" [innerHtml]="content.footer | marked: analyticsMode" ></div>
<!--All templates to do with headings-->
<ng-template #noHeader></ng-template>
<ng-template #h2NoLink>
    <h2  [innerHtml]="content.heading" [id]="generateId(content)"></h2>
</ng-template>
<ng-template #h3NoLink>
    <h3  [innerHtml]="content.heading" [id]="generateId(content)"></h3>
</ng-template>
<ng-template #h2Internal>
    <a [FCRouterLink]="internalRoute" class="fc-content-header">
        <h2  [innerHtml]="content.heading" [id]="generateId(content)"></h2>
    </a>
</ng-template>
<ng-template #h3Internal>
    <a [FCRouterLink]="internalRoute" class="fc-content-header">
        <h3  [innerHtml]="content.heading" [id]="generateId(content)"></h3>
    </a>
</ng-template>
<ng-template #h2External>
    <a [href]="externalUrl" class="fc-content-header">
        <h2  [innerHtml]="content.heading" [id]="generateId(content)"></h2>
    </a>
</ng-template>
<ng-template #h3External>
    <a [href]="externalUrl" class="fc-content-header">
        <h3  [innerHtml]="content.heading" [id]="generateId(content)"></h3>
    </a>
</ng-template>
<!--Icon-->
<ng-template #h2NoLinkIcon>
    <h2 [id]="generateId(content)">
        <fc-icon [src]="content.iconSrc" [altText]="content.heading"></fc-icon>
        <span [innerHtml]="content.heading"></span>
    </h2>
</ng-template>
<ng-template #h3NoLinkIcon>
    <h3 [id]="generateId(content)">
        <fc-icon [src]="content.iconSrc" [altText]="content.heading"></fc-icon>
        <span [innerHtml]="content.heading"></span>
    </h3>
</ng-template>
<ng-template #h2InternalIcon>
    <a [FCRouterLink]="internalRoute" class="fc-content-header">
        <h2 [id]="generateId(content)">
            <fc-icon [src]="content.iconSrc" [altText]="content.heading"></fc-icon>
            <span [innerHtml]="content.heading"></span>
        </h2>
    </a>
</ng-template>
<ng-template #h3InternalIcon>
    <a [FCRouterLink]="internalRoute" class="fc-content-header">
        <h3 [id]="generateId(content)">
            <fc-icon [src]="content.iconSrc" [altText]="content.heading"></fc-icon>
            <span [innerHtml]="content.heading"></span>
        </h3>
    </a>
</ng-template>
<ng-template #h2ExternalIcon>
    <a [href]="externalUrl" class="fc-content-header">
        <h2 [id]="generateId(content)">
            <fc-icon [src]="content.iconSrc" [altText]="content.heading"></fc-icon>
            <span [innerHtml]="content.heading"></span>
        </h2>
    </a>
</ng-template>
<ng-template #h3ExternalIcon>
    <a [href]="externalUrl" class="fc-content-header">
        <h3 [id]="generateId(content)">
            <fc-icon [src]="content.iconSrc" [altText]="content.heading"></fc-icon>
            <span [innerHtml]="content.heading"></span>
        </h3>
    </a>
</ng-template>