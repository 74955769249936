import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorProfileDto } from 'src/app/models/article/authorProfileDto';
import { AuthorParams, Utils } from 'src/app/models/shared/Utils';
import { ArticleService } from 'src/app/services/api/article.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({ providedIn: 'root' })
export class AuthorProfileResolver  {

    constructor(private articleService: ArticleService, private breadcrumbService: BreadcrumbService, private seoService: SeoService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<AuthorProfileDto> {
        const params = Utils.GetParams<AuthorParams>(route);

        return this.articleService.GetAuthorProfile(params).pipe(map(author => {
            try {
                this.seoService.setStandard(author.navigation.sector.url, null, author.navigation.author, author.navigation.root, author.imageSrc);
                
            } catch (error) {
                console.error(`Unable to resolve article (${params.articleSlug}):`, error);
            }
            return author;
        }));
    }
}
