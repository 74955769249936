import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication-service.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'fc-login',
    templateUrl: './login.component.html',
    standalone: true,
    imports: [FormsModule]
})
export class LoginComponent implements OnInit {
    username: string;
    password: string;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
       
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

        if (this.authenticationService.isAuthenticated()) {
            this.router.navigate([this.returnUrl]);
        }
    }

    onSubmit() {
        var result = this.authenticationService.login(this.username, this.password);
        if (result) {
            this.router.navigate([this.returnUrl]);
        }
    }
}
