<div class="nca-menu-column" *ngFor="let col of menuItem.columns; let i = index">
    <h3 class="nca-column-header" >
        <a [FCRouterLink]="col.route" (click)="closeMenu()"> {{col.label}}</a>
    </h3>
    <ul class="nca-menu-items">
        <li *ngFor="let item of col.items">
            <ng-container 
                [ngTemplateOutlet]="item.imageSrc ? visual : standard"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
            
        </li>
    </ul>
</div>

<ng-template #standard let-item="item">
    <a [FCRouterLink]="item.route" [innerHTML]="item.label" class="nca-standard" (click)="closeMenu()"></a>
</ng-template>
<ng-template #visual let-item="item">
    <a [FCRouterLink]="item.route" class="nca-visual" (click)="closeMenu()">
        <div class="nca-prefix" [innerHTML]="item.suffix"></div> 
        <div class="nca-label" [innerHTML]="item.label"></div> 
        <div class="nca-img">
            <img [src]="item.imageSrc" [alt]="item.label" />
        </div>        
    </a>
</ng-template>
