import { Component, HostBinding, OnInit } from '@angular/core';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'fc-oth-header',
    templateUrl: './oth-header.component.html',
    styleUrls: ['./oth-header.component.scss'],
    standalone: true,
    imports: [NgClass, DefaultBreadcrumbComponent]
})
export class OthHeaderComponent implements HeaderComponent {
  @HostBinding('style.--fc-oth-submenu-display') subMenuDisplay: 'none' | 'block' = 'none';
  @HostBinding('style.--fc-oth-submenu-tx') tx = '770px';

  isActive = false;

  toggleAgents($event: PointerEvent): void {
    this.subMenuDisplay = this.subMenuDisplay === 'none' ? 'block' : 'none';
    const btn = ($event.target as HTMLButtonElement);
    this.tx = (btn.offsetLeft + (btn.clientWidth / 2) - (188 / 2)) +  'px';
  }

  toggleMobileMenu(): void {
    this.isActive = !this.isActive;
  }
}
