

<fc-content-header-summary [content]="content">
    <div class="fc-comparison-content">
        <fc-disclaimer *ngIf="settings?.tableSettings?.advertiserDisclosure"
        [message]="settings?.tableSettings?.advertiserDisclosure"
        [showLink]="showHeaderLink"></fc-disclaimer>
        <div class="fc-results" *ngIf="table?.displayMessage"  [innerHtml]="table?.displayMessage | markedInline"></div>
        <fc-table-switcher id="fc-product-list" [items]="items" [settings]="settings" [paging]="paging" [showDisclaimer]="false"></fc-table-switcher>
        <fc-content-cta [content]="content"></fc-content-cta>
    </div>
</fc-content-header-summary>
