import { Component, OnInit } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ContentImageComponent } from '../utils/content-image/content-image.component';

@Component({
    selector: 'fc-content-single-image',
    templateUrl: './content-single-image.component.html',
    standalone: true,
    imports: [ContentImageComponent]
})
export class ContentSingleImageComponent extends ContentBaseComponent  { }
