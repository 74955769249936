import { Component, HostBinding, OnInit } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ArticleSideBySideComponent } from '../components/article-sidebyside/article-sidebyside.component';
import { NgClass, NgFor } from '@angular/common';
import { ArticleHeaderComponent } from '../../content/utils/article-header/article-header.component';

@Component({
    selector: 'fc-article-img-headline',
    templateUrl: './article-img-headline.component.html',
    styleUrls: [
        './article-img-headline.component.scss',
        '../components/article-styling.scss'
    ],
    standalone: true,
    imports: [ArticleHeaderComponent, NgClass, NgFor, ArticleSideBySideComponent]
})
export class ArticleImgHeadlineComponent  extends ContentBaseComponent  {

  @HostBinding('style.--fc-horizontal-colCount') colCount = 3;

  onContentChanged(): void {
    this.colCount = Math.min(this.content.components?.length / 2, 3);
  }
}
