import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalSummaryDto } from 'src/app/models/dto/VerticalSummaryDto';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ContentHeaderSummaryComponent } from 'src/app/templates/content/utils/content-header-summary/content-header-summary.component';
import { CategoryCardV2Component } from 'src/app/shared/category-card-v2/category-card-v2.component';

@Component({
  selector: 'fc-content-vertical',
  standalone: true,
  imports: [CommonModule, ContentHeaderSummaryComponent, CategoryCardV2Component],
  templateUrl: './content-vertical.component.html',
  styleUrls: ['./content-vertical.component.scss']
})
export class ContentVerticalComponent extends ContentBaseComponent {
  get verticals() {
    return this.content.verticals;

  }
}
