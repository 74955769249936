import { Component, Input, OnInit } from '@angular/core';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { PartnerMenuDto } from 'src/app/models/shared/helpers/MenuHelper';
import { onInputSetCallback } from 'src/app/models/shared/inputs/defaults/DefaultInputUtils';
import { PartnerSiteService } from 'src/app/services/api/partner-site.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgFor } from '@angular/common';
import { FooterSectionComponent, IFooter } from 'src/app/templates/footers/savrr-footer/footer-section/footer-section.component';

@Component({
    selector: 'fc-savrr-footer',
    templateUrl: './savrr-footer.component.html',
    styleUrls: ['./savrr-footer.component.scss'],
    standalone: true,
    imports: [NgFor, FCRouterLinkDirective, FooterSectionComponent]
})
export class SavrrFooterComponent implements OnInit {
  @Input() links: SectorStaticFooterDto[];
  menu: PartnerMenuDto[]| null;
  disclaimersTarget = environment.client.disclaimers.target;
  year: number;

  constructor(public seoService: SeoService, private partnerSiteService: PartnerSiteService) {
    this.year = new Date().getFullYear();
  }
  ngOnInit(): void {
    const url =  AppConfigService.AppEnvironment.apiEndPoint;

    this.partnerSiteService.getPartnerMenu(url).subscribe(x => {
      this.menu = x;    
    });
  }

  get isGlobal() {
    return environment.client.code === 'savrr-gb';
  }

  au_footer: IFooter = {
    label: `Australia - © 2023 Fair Comparison Pty Ltd`,
    disclosure: {
      primary: `Savrr.com is a trading name of Fair Comparison Pty Ltd. The 'compare' pages of this website are provided by Fair Comparison Pty Ltd (ABN 48 647 552 958, credit representative number 530417) as a credit representative of QED Credit Services Pty Ltd (Australian Credit Licence 387856) to compare a range of credit card, home loan, personal loan, and car loan products. Fair Comparison Pty Ltd may receive a fee if users click through, apply and/or successfully acquire a loan or credit card product from or through a product provider.`,
      secondary: `Fair Comparison provides information relating to credit products offered by banks and other credit providers. We are not providers of loan, credit, or any other financial products. While we aim to provide information about a variety of products, we do not provide information about all products or product features available to consumers - there may be alternative options available elsewhere. We do not recommend or assist you to apply for specific products. Should you choose to apply for a product which is listed, you will deal directly with the provider of the product or its broker/representative. We aim to provide useful and up to date information, but you should always carefully check product information with the product provider prior to applying for or taking out a credit product. If you are unsure, you should seek clarification from the product provider or independent financial advice.`
    },
    links: [
      { label: 'Sponsored and Advertised Product Disclosure', route: 'https://www.savrr.com/au/disclaimers/advertiser-disclosure' },
      { label: 'Services Guide', route: 'https://www.savrr.com/au/disclaimers/services-guide' },
      { label: 'Terms & Conditions', route: 'https://www.savrr.com/au/disclaimers/terms-and-conditions' },
      { label: 'Privacy Policy', route: 'https://www.savrr.com/au/disclaimers/privacy-policy' },
    ]
  }

  global_footer: IFooter = {
    label: `Global - © 2023 Fair Comparison Ltd`,
    disclosure: {
      primary: `Savrr.com is a trading name of Fair Comparison Ltd. The ‘compare’ pages of this website are provided by Fair Comparison Ltd to compare a range of online trading platforms and products. Fair Comparison Ltd may receive a fee if users click through, apply and/or successfully apply for an online trading account or product.`,
      secondary: `Fair Comparison provides information relating to online trading platforms. We are not providers of loan, credit, or any other financial products nor are we an investment broker. While we aim to provide information about a variety of platforms or products, we do not provide information about all platforms or products available to consumers - there may be alternative options available elsewhere. We do not recommend or assist you to apply for specific platforms or products. Should you choose to apply for a platform or product which is listed, you will deal directly with the platform or its broker/representative. We aim to provide useful and up-to-date information, but you should always carefully check information with the platform provider prior to opening an account or making a financial decision. If you are unsure, you should seek clarification from the platform or independent financial advice.`
    },
    links: [
      { label: 'Terms & Conditions', route: 'https://www.savrr.com/vn/disclaimers/terms-and-conditions' },
      { label: 'Privacy Policy', route: 'https://www.savrr.com/vn/disclaimers/privacy-policy' },
    ]
  }
}
