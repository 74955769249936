
<fc-content-header-summary [content]="content" [analyticsMode]="analyticsMode">
    <table>
        <colgroup>
            <col *ngFor="let column of columns;" [style.width]="getColWidth(column)">
        </colgroup>
        <tbody>
            <tr *ngFor="let row of rows;">
                <td *ngFor="let component of row?.columns;" [innerHtml]="component?.content | markedInline: analyticsMode" [ngClass]="getStyle(component)"></td>
            </tr>
        </tbody>
    </table>
</fc-content-header-summary>
