<a *ngFor="let category of categories; trackBy: identify" [href]="category.route" [title]="category.label">
    <div class="svr-category-host" >
        <div class="svr-header-overlay">
            <img class="svr-header"
                [ngSrc]="category.image.src" 
                [alt]="category.image.alt" 
                [height]="category.image.height" 
                [width]="category.image.width" 
                [title]="category.label"
                [loaderParams]="{ 
                    rendered: 300, 
                    intrinsic: 1248, 
                    ar: '16-9'
                }">
            <img class="svr-logo" [src]="category.logo.src" [alt]="category.label + ' logo'" 
                [height]="category.logo.height" 
                [width]="category.logo.width" 
                [title]="category.label">
        </div>
        <h2  [innerHTML]="category.label"></h2>
        <div class="svr-summary" [innerHTML]="category.summary"></div>
    </div>
</a>