import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SortOptionDTO } from 'src/app/models/generated/sort/SortOptionDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { OtherGroupsSettings } from 'src/app/models/shared/HeaderTemplateSettings';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { ComparisonPage, ComparisonParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { TableService } from 'src/app/services/api/table.service';
import { InputService } from 'src/app/services/input.service';
import { QuickAccessLinkService } from 'src/app/services/quick-access-link.service';
import { ScrollerService } from 'src/app/services/scroller.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { TableSettings } from 'src/app/templates/tables/standard/table-base.component';
import { environment } from 'src/environments/environment';
import { PartnerAppSettings } from 'src/environments/partnerAppSettings';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { CompareFooterComponent } from '../../shared/compare/compare-footer/compare-footer.component';
import { BrandsSummaryComponent } from '../../shared/brands-summary/brands-summary.component';
import { ContentContainerComponent } from '../../templates/content/content-container.component';
import { TableSwitcherComponent } from '../../templates/tables/table-switcher.component';
import { InputSwitcherComponent } from '../../shared/filters/input-switcher.component';
import { DefaultSortComponent } from '../../shared/buttons/sort/sort.component';
import { ProductAdvertComponent } from '../../shared/containers/product-advert/product-advert.component';
import { QuickLinksComponent } from '../../shared/quick-links/quick-links.component';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { ProductActionComponent } from '../product/product-action/product-action.component';
import { GroupSwitcherComponent } from '../../shared/group-actions/group-switcher/group-switcher.component';
import { NgIf, NgClass } from '@angular/common';


export interface ComparisonPageSpecific {
  pageType: 'Category' | 'Group' | 'Brand';
  heading: string;
  sourceUid: string;
  categoryId: number;
  groupId?: number;
  brandId?: number;
  compareAllText?: string;
}

@Component({
    selector: 'fc-comparison',
    templateUrl: './comparison.component.html',
    styleUrls: ['./comparison.component.scss'],
    standalone: true,
    imports: [NgIf, GroupSwitcherComponent, ProductActionComponent, DisclaimerComponent, QuickLinksComponent, ProductAdvertComponent, NgClass, DefaultSortComponent, InputSwitcherComponent, TableSwitcherComponent, ContentContainerComponent, BrandsSummaryComponent, CompareFooterComponent, MarkedInlinePipe]
})
export class ComparisonComponent implements AfterViewInit, OnDestroy  {
  showAdvertiserDisclosure: boolean = PartnerAppSettings.advertiserDisclosure?.enabled ?? true;
  disclaimerSettings = {
    message: '',
    showHeaderLink: environment.client.disclaimers.showHeaderLink,
    headerScrollLocation: environment.client.disclaimers.headerScrollLocation
  };

  showVertical = environment.client.showSideFilter;
  subscriptions: Subscription[] = [];
  page: ComparisonPage;
  params: ComparisonParams;
  tableSettings: TableSettings;
  paging: PagingParameterDto;
  groupSettings: OtherGroupsSettings;

  showMobileFilter = false;
  inputTemplate: string;
  enableCategoryBrand = false;
  items: TableItemDTO[] = [];
  promotedItem: TableItemDTO;

  showLoadMore = true;
  loadingTable = true;

  sortOptionId: number | null = null;

  pageSpecific: ComparisonPageSpecific;
  resultMessage: string;
  scrollTarget: string | null;

  constructor(
    private activatedRoute: ActivatedRoute, private tableService: TableService, private inputService: InputService, private analyticsService: AnalyticsService,
    private sessionService: SessionStorageService, private scrollerService: ScrollerService, private QuickAccessLinkService: QuickAccessLinkService) {
      this.subscriptions.push(activatedRoute.data.subscribe(data => {
        this.page =  data.details;
        this.params =  Utils.GetParams<ComparisonParams>(activatedRoute.snapshot);

        this.disclaimerSettings.message = this.page.legalDisclosureHeader;

        if (Utils.isBrandComparisonPage(this.page)) {
          this.pageSpecific = {
            pageType: 'Brand',
            heading: this.page.heading + ' ' + this.page.navigation.category.title,
            sourceUid: this.page.navigation.category.url + '/' + this.page.navigation.brandUrl,
            categoryId: this.page.categoryId,
            brandId: this.page.id,
            compareAllText: this.page.compareAllText
          };

        } else if (Utils.isGroupComparisonPage(this.page)) {
          this.pageSpecific = {
            pageType: 'Group',
            heading: this.page.heading,
            sourceUid: this.page.navigation.group.url,
            categoryId: this.page.categoryId,
            groupId: this.page.id
          };
        } else {
          this.pageSpecific = {
            pageType: 'Category',
            heading: this.page.heading,
            sourceUid: this.page.navigation.category.url,
            categoryId: this.page.id
          };
        }
        this.inputService.clearDefault();
        this.inputTemplate = this.page.tableSettings?.defaultInput?.templateName ?? '';
        this.enableCategoryBrand = this.page.tableSettings?.defaultInput?.enableCategoryBrandFilter ?? false;

        if (this.page.tableSettings?.defaultInput) {
          this.inputService.setDefault(this.page.tableSettings.defaultInput, true);
        }
        
     
        this.groupSettings = {
          group: this.page.grouping
        },

        this.defaultPaging();
        this.initialiseValues();
      }));

      this.subscriptions.push(this.activatedRoute.fragment.subscribe(x => {
        this.scrollTarget = x;
      }));

      // Legacy. Consider removing, but need to check if it's still used by OnTheHouse;
      this.subscriptions.push(this.activatedRoute.queryParams.subscribe(x => {
        if (x.v) {
          this.scrollTarget = x.v;
        }
      }));
   }
  ngAfterViewInit(): void {
    if (this.scrollTarget) {
      this.scrollerService.scrollIntoView(this.scrollTarget);
      this.scrollTarget = null;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
  
  private defaultPaging(): void {
    this.paging = {
      tableId: this.paging?.tableId ? this.paging.tableId : this.sessionService.getDefaultTableId(this.pageSpecific), // Utils.GetRandomGuid(),
      groupId: this.pageSpecific.groupId,
      brandId: this.pageSpecific.brandId,
      pageNumber: 1,
      pageSize: 10,
      sortOptionId: this.sortOptionId
     };
  }

  private initialiseValues(): void {
    this.tableSettings = {
      pageDetails: this.page,
      paging: this.paging,
      tableSettings: this.page.tableSettings,
      sectorUrl: this.params.sector,
      categoryUrl: this.params.category,
      groupUrl: this.params.group,
      page: this.pageSpecific.pageType,
      sourceUid: this.pageSpecific.sourceUid,
      visibility: {
        showCompare: true
      }
    };

    this.subscriptions.push(this.inputService.onRefreshTableEvent().subscribe(x => {
      this.analyticsService.onUpdateResults({
        id: this.paging.tableId,
        pageNumber: this.paging.pageNumber,
        pageSize: this.paging.pageSize,
      }, this.inputService.getInput());
      this.loadTable(true);
    }));

    this.subscriptions.push(this.inputService.onShowFilterMenu().subscribe(x => {
      this.showMobileFilter = x;
    }));

    this.analyticsService.onInitialTableLoad({
      id: this.paging.tableId,
      pageNumber: this.paging.pageNumber,
      pageSize: this.paging.pageSize,
    });

    this.loadTable(true);
  }

  private loadTable(clearList: boolean): void {
    if (clearList) {
      this.loadingTable = true;
      this.defaultPaging();
    }

    this.tableService.GetTable(this.pageSpecific.categoryId, this.paging, this.inputService.getInput()).subscribe(x => {
      if (x) {
        this.items = (clearList) ? x.items :this.items.concat(x.items);
        this.promotedItem = x.headlineItem;
        this.paging.pageNumber++;
        this.showLoadMore = x.hasMoreItems;
        this.resultMessage = x.displayMessage;
      }
      this.loadingTable = false;
    });
  }


  promotedClass(): 'fc-advert-no-promoted' | 'fc-advert-brand' | '' {
      return this.pageSpecific.pageType === 'Brand' ? 'fc-advert-brand' :  this.promotedItem == null  ? 'fc-advert-no-promoted' : '';
  }

  loadMoreItems(): void {
    this.analyticsService.onLoadMore({
      id: this.paging.tableId,
      pageNumber: this.paging.pageNumber,
      pageSize: this.paging.pageSize,
    });
    this.loadTable(false);
  }

  onSortChanged(option: SortOptionDTO): void {
    this.sortOptionId = option.id;
    this.loadTable(true);
  }

}
