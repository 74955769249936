import { IMAGE_LOADER, ImageLoaderConfig } from "@angular/common";

export interface SavrrImageLoaderConfig extends ImageLoaderConfig {
    loaderParams?: {
        rendered: number;
        intrinsic: number;
        ar?:string
    };
}

const source = 'https://ik.imagekit.io/fairaus';
const savrrConverter = (config: SavrrImageLoaderConfig) => {
    if (!config.src.startsWith(source)) {
        return source;
    } 

    config.src = config.src.replace(source, '').split('?')[0];


    let transformations: string[] = [];
    if (config.loaderParams?.rendered && config.loaderParams.intrinsic) {
        let renderWidth = config.loaderParams?.rendered;
        if (config.loaderParams.intrinsic * 2 == config.width) {
            renderWidth =  renderWidth* 2;
        }

        transformations.push(`w-${renderWidth}`);
    }

    if (config.loaderParams?.ar) {
        transformations.push(`ar-${config.loaderParams.ar}`);
    }

    if (transformations.length === 0)
    {
        return `${source}${config.src}`;
    }
    return `${source}/tr:${transformations.join(',')}${config.src}`;
};

export function provideSavrrLoader(){
    return {
        provide: IMAGE_LOADER,
        useValue: savrrConverter
      }
}