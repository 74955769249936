import { Component } from '@angular/core';
import { TableDTO } from 'src/app/models/generated/table/TableDTO';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { Utils } from 'src/app/models/shared/Utils';
import { TableService } from 'src/app/services/api/table.service';
import { InputService } from 'src/app/services/input.service';
import { ScreenSizeService } from 'src/app/services/screenSize.service';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { TableSettings } from 'src/app/templates/tables/standard/table-base.component';
import { environment } from 'src/environments/environment';
import { MarkedInlinePipe } from '../../../pipes/marked.pipe';
import { ContentCTAComponent } from '../content-cta/content-cta.component';
import { TableSwitcherComponent } from '../../tables/table-switcher.component';
import { DisclaimerComponent } from '../../../shared/disclaimer/disclaimer.component';
import { NgIf } from '@angular/common';
import { ContentHeaderSummaryComponent } from '../utils/content-header-summary/content-header-summary.component';
import { PageType, PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';

@Component({
    selector: 'fc-content-comparison',
    templateUrl: './content-comparison.component.html',
    styleUrls: ['./content-comparison.component.scss'],
    standalone: true,
    imports: [ContentHeaderSummaryComponent, NgIf, DisclaimerComponent, TableSwitcherComponent, ContentCTAComponent, MarkedInlinePipe]
})
export class ContentComparisonComponent extends ContentBaseComponent  {

  table: TableDTO;
  items: any[];

  constructor(private tableService: TableService, private inputService: InputService, screenSizeService: ScreenSizeService) {
    super(screenSizeService);
  }
  showHeaderLink = environment.client.disclaimers.showTableLink;
  paging: PagingParameterDto;
  settings: TableSettings;

  onContentChanged(): void {
    this.paging = {
      pageNumber: 1,
      pageSize: this.content.comparisonTableSettings.pageSize,
      groupId: this.content.comparisonTableSettings.groupId,
      brandId: this.content.comparisonTableSettings.brandId,
      tableId: Utils.GetRandomGuid(),
      tableTemplateId: this.content.comparisonTableSettings.tableTemplateId
    };

    const input = this.inputService.initiliseDefault(this.content.comparisonTableSettings.defaultInput, false);

    this.tableService.GetTable(this.content.comparisonTableSettings.categoryId, this.paging, input).subscribe(x => {
      this.table = x;
      this.items = x.items;

      let sourceUid: string;
      let pageType: PageType;

      if (PageTypeHelper.isSector(this.page)) {
        sourceUid = this.page.navigation.sector.url;
        pageType = 'Sector';
      } else {
        sourceUid = this.page.navigation.article?.url;
        pageType = 'Article';
      }

      this.settings = {
        pageDetails: this.page,
        page: pageType,
        paging: this.paging,
        sourceUid: sourceUid,
        sectorUrl: this.page.navigation.sector.url,
        categoryUrl: this.content.comparisonTableSettings.categoryUrl,
        groupUrl: this.content.comparisonTableSettings.groupUrl,
        tableSettings: {
          tableTemplate: this.content.comparisonTableSettings.tableTemplate,
          advertiserDisclosure: this.content.comparisonTableSettings.advertiserDisclosure,
          advertiserDisclosureHeading: this.content.comparisonTableSettings.advertiserDisclosureHeading,
          defaultInput: this.content.comparisonTableSettings.defaultInput,
          sorting: this.content.comparisonTableSettings.sorting,
        },
        visibility: {
          showCompare: false
        }
      };
    });
  }
}
