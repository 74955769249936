import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectorDTO } from 'src/app/models/generated/SectorDTO';
import { SectorParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { SectorService } from 'src/app/services/api/sector.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({ providedIn: 'root' })
export class SectorResolver  {
  constructor(private sectorService: SectorService,
              private breadcrumbService: BreadcrumbService,
              private seoService: SeoService, private analyticsService: AnalyticsService){ }

  resolve(route: ActivatedRouteSnapshot): Observable<SectorDTO> {
    const params = Utils.GetParams<SectorParams>(route);

    return this.sectorService.GetSectorDetails(params.sector).pipe(map(sector => {
      try {
        this.breadcrumbService.setBreadCrumb(sector.navigation, sector.navigation.sector);
        this.seoService.setStandard(params.sector, null, sector.navigation.sector, sector.navigation.root, sector.imageSrc);
        this.analyticsService.onSectorPageView(sector);

      } catch (error) {
        console.error(`Unable to resolve sector (${params.sector}):`, error);
      }
      return sector;
    }));
  }
}
