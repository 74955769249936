import { CommercialStatus, PromotedPosition } from 'src/app/models/generated/table/TableWeightingDTO';
import { PageType } from 'src/app/models/shared/helpers/PageTypesHelper';
import { DynamicSupport, Utils } from 'src/app/models/shared/Utils';

export interface ClickEventRecordDto {
    page_id: string | undefined;
    page_title: string | undefined;
    page_type: PageType | undefined;
    page_tip: string | undefined | null;

    brand_id: string;
    product_id: string;
    product_value: number;
    product_status: CommercialStatus | undefined;

    table_id: string | undefined;
    table_position: number | undefined;
    table_promoted_id: number | undefined | null;
    table_promoted_position: PromotedPosition | undefined | null;
    table_sort: number| undefined | null;

    go_to_site_uid: string | undefined;
    go_to_site_sourceSlug: string | undefined;
    go_to_site_referrer: string | undefined;
    go_to_site_placement: string | undefined;

    user_ip_address: string;
    user_session_id: string;
    user_client_id: string;
    user_partner_session_id: string | null;
    user_partner_client_id: string | null;
}

export class EncodeClickEventRecord
{

    private static getAlias(key: keyof ClickEventRecordDto): string {
        switch (key) {  
            case 'page_id': return 'PAD'; 
            case 'page_title': return 'PET';
            case 'page_type': return 'PAT';  
           
            case 'page_tip': return 'PTI';

            case 'brand_id': return 'BID';

            case 'product_id': return 'POD';
            //case 'product_vertical_primary': return 'PVP';
            //case 'product_vertical_secondary': return 'PVS';
            case 'product_value': return 'PWT';
            case 'product_status': return 'PST';

            case 'table_id': return 'TID';
            case 'table_position': return 'TPO';
            case 'table_promoted_position': return 'TPP';
            case 'table_promoted_id': return 'TPD';
            case 'table_sort': return 'TSO';

            case 'go_to_site_uid': return 'UID';
            case 'go_to_site_sourceSlug': return 'PSG';
            case 'go_to_site_referrer': return 'PER';
            case 'go_to_site_placement': return 'GTP';


            case 'user_ip_address': return 'IPA';
            case 'user_session_id': return 'SID';
            case 'user_client_id': return 'CLD';
            case 'user_partner_session_id': return 'PSID';
            case 'user_partner_client_id': return 'PCID';
        }
    }
    
    private static encode(text: string)  {
        const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n: any) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code: any) => textToChars("az8").reduce((a, b) => a ^ b, code);
        return text
          .split("")
          .map(textToChars)
          .map(applySaltToChar)
          .map(byteHex)
          .join("");
    };

    public static alisRecord(data: DynamicSupport): string {
        const result: string[] = [];
        for (const propertyName in data) {
            if (data.hasOwnProperty(propertyName) && data[propertyName] != null && data[propertyName] != '-') {
              result.push(`${this.getAlias(propertyName as any)}=${encodeURIComponent(data[propertyName])}`);
            }
        }

        const joinedResult = result.join("&");
        const encodedResult = this.encode(joinedResult);
        
        return encodedResult;
    }
}