import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ICategoryCard } from 'src/app/models/dto/ICategoryCard';
import { provideSavrrLoader } from 'src/app/services/loaders/savrrImageLoader';

@Component({
  selector: 'fc-category-card-v2',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  providers: [provideSavrrLoader()],
  templateUrl: './category-card-v2.component.html',
  styleUrls: ['./category-card-v2.component.scss']
})
export class CategoryCardV2Component {
  @Input({required: true}) categories: ICategoryCard[] | null = [];

  @Input() @HostBinding('class.svr-capped') isCapped: boolean = false;
  @Input() @HostBinding('class.svr-is-detailed') isDetailed: boolean = false;
  @Input() @HostBinding('style.--svr-items') itemCount: number = 4;

  identify(index: number, category: ICategoryCard) {
    return category.label;
  }
}
