import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/models/shared/Utils';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

export interface ETLink {
  name: string;
  url: string;
}

export interface ETLinkGroup extends ETLink {
  gridColumn: string;
  gridRow: string;
  children: ETLink[];
}

@Component({
    selector: 'fc-executivetraveller-header',
    templateUrl: './executivetraveller-header.component.html',
    styleUrls: ['./executivetraveller-header.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, DefaultBreadcrumbComponent]
})
export class ExecutivetravellerHeaderComponent implements HeaderComponent, OnInit {

  endpoint  = 'https://www.executivetraveller.com';


  isActive = false;
  links: ETLink[] = [
    { name: 'Home', url: `${this.endpoint}/` },
    { name: 'News', url: `${this.endpoint}/news` },
    { name: 'Reviews', url: `${this.endpoint}/reviews` },
    { name: 'Expert Guides', url: `${this.endpoint}/tags/guides` },
    { name: 'Compare', url:`${this.endpoint}/compare`},
    { name: 'Lifestyle', url: `${this.endpoint}/tags/lifestyle` },
    { name: 'Galleries', url: `${this.endpoint}/gallery` },
    { name: 'Community', url: `${this.endpoint}/community` },
    { name: 'About', url: `${this.endpoint}/etc/about-us` }
  ];

  linkGroups: ETLinkGroup[];

  public static GetMainLinks(includeCompany: boolean): ETLinkGroup[] {
    const endpoint  = 'https://www.executivetraveller.com';
    const values =[
      {
        name: 'News',
        url: '${endpoint}/news',
        gridColumn: '2',
        gridRow: '1 /span 2',
        children: [
          {
            name: 'Airlines',
            url: `${endpoint}/news/latest/airline`
          },
          {
            name: 'Airports',
            url: `${endpoint}/news/latest/airport`
          },
          {
            name: 'Hotels',
            url: `${endpoint}/news/latest/hotel`
          },
          {
            name: 'Lounges',
            url: `${endpoint}/news/latest/lounge`
          },
          {
            name: 'Frequent Flyer',
            url: `${endpoint}/news/latest/frequent-flyer`
          },
          {
            name: 'Reward Programs',
            url: `${endpoint}/news/latest/reward-program`
          },
          {
            name: 'Technology',
            url: `${endpoint}/news/latest/technology`
          },
          {
            name: 'Motoring',
            url: `${endpoint}/news/latest/motoring`
          },
          {
            name: 'Other',
            url: `${endpoint}/news/latest/other`
          }
        ]
      },
      {
        name: 'Reviews',
        url: '${endpoint}/reviews',
        gridColumn: '3',
        gridRow: '1',
        children: [
          {
            name: 'Airlines',
            url: `${endpoint}/reviews/latest/airline`
          },
          {
            name: 'Lounges',
            url: `${endpoint}/reviews/latest/lounge`
          },
          {
            name: 'Hotels',
            url: `${endpoint}/reviews/latest/hotel`
          },
          {
            name: 'Credit Cards',
            url: `${endpoint}/reviews/latest/credit-card`
          }
        ]
      },
      {
        name: 'Expert Guides',
        url: '${endpoint}/tags/guides',
        gridColumn: '3',
        gridRow: '2',
        children: [
          {
            name: 'Qantas Guides',
            url: `${endpoint}/tags/qantas+guides`
          },
          {
            name: 'Virgin Australia Guides',
            url: `${endpoint}/tags/virgin-australia+guides`
          },
          {
            name: 'Singapore Airlines Guides',
            url: `${endpoint}/tags/singapore-airlines+guides`
          },
          {
            name: 'Cathay Pacific Guides',
            url: `${endpoint}/tags/cathay-pacific+guides`
          },
          {
            name: 'Credit Card Guides',
            url: `${endpoint}/tags/credit-cards+guides`
          }
        ]
      },
      {
        name: 'Credit Cards',
        url: '${endpoint}/tags/credit-card',
        gridColumn: '4',
        gridRow: '1',
        children: [
          {
            name: 'Credit Card News',
            url: `${endpoint}/tags/credit-card`
          },
          {
            name: 'Credit Card Reviews',
            url: `${endpoint}/reviews/latest/credit-card`
          },
          {
            name: 'Qantas Credit Cards',
            url: `${endpoint}/tags/qantas+credit-card`
          },
          {
            name: 'Virgin Australia Credit Cards',
            url: `${endpoint}/tags/virgin-australia+credit-card`
          }
        ]
      },
      {
        name: 'Lifestyle',
        url: '${endpoint}/tags/lifestyle',
        gridColumn: '4',
        gridRow: '2',
        children: [
          {
            name: 'Motoring',
            url: `${endpoint}/tags/motoring`
          },
          {
            name: 'Luxury Leisure Travel',
            url: `${endpoint}/news/latest/luxury-leisure`
          },
          {
            name: 'Travel Gear',
            url: `${endpoint}/tags/travel-gear`
          },
          {
            name: 'Business',
            url: `${endpoint}/tags/business`
          },
          {
            name: 'Watches',
            url: `${endpoint}/tags/watches`
          },
          {
            name: 'Technology',
            url: `${endpoint}/tags/technology`
          },
          {
            name: 'Health & Wellness',
            url: `${endpoint}/tags/health-wellness`
          },
          {
            name: 'Golf',
            url: `${endpoint}/tags/golf`
          },
          {
            name: 'Wines & Spirits',
            url: `${endpoint}/tags/wine+spirits`
          }
        ]
      },
      {
        name: 'Galleries',
        url: '${endpoint}/gallery',
        gridColumn: '5',
        gridRow: '1 /span 2',
        children: [
          {
            name: 'Airlines & Seating',
            url: `${endpoint}/gallery/latest/airlines-seating`
          },
          {
            name: 'Lounges & Airports',
            url: `${endpoint}/gallery/latest/lounges-airports`
          },
          {
            name: 'Hotels',
            url: `${endpoint}/gallery/latest/hotel`
          },
          {
            name: 'Technology',
            url:  `${endpoint}/gallery/latest/technology-5`
          },
          {
            name: 'Motoring',
            url: `${endpoint}/gallery/latest/motoring`
          },
          {
            name: 'Watches',
            url: `${endpoint}/gallery/latest/watches-5`
          },
          {
            name: 'Style',
            url: `${endpoint}/gallery/latest/style`
          }
        ]
      },
      {
        name: 'Community',
        url: `${endpoint}/community`,
        gridColumn: '6',
        gridRow: '1 /span 2',
        children: [
          {
            name: 'All Topics',
            url:  `${endpoint}/community`
          },
          {
            name: 'Qantas & Jetstar',
            url: `${endpoint}/community/topics/42/qantas-jetstar`
          },
          {
            name: 'Virgin Australia',
            url: `${endpoint}/community/topics/18/virgin-australia`
          },
          {
            name: 'Air New Zealand',
            url: `${endpoint}/community/topics/50/air-new-zealand`
          },
          {
            name: 'Cathay Pacific',
            url: `${endpoint}/community/topics/17/cathay-pacific`
          },
          {
            name: 'Emirates',
            url: `${endpoint}/community/topics/43/emirates`
          },
          {
            name: 'Etihad Airways',
            url: `${endpoint}/community/topics/58/etihad-airways`
          },
          {
            name: 'Qatar Airways',
            url: `${endpoint}/community/topics/46/qatar-airways`
          },
          {
            name: 'Singapore Airlines',
            url: `${endpoint}/community/topics/59/singapore-airlines`
          },
          {
            name: 'Credit Cards',
            url: `${endpoint}/community/topics/27/general-credit-card-travel-money-discussion`
          },
          {
            name: 'Destinations',
            url: `${endpoint}/community/topics/26/destinations`
          },
          {
            name: 'General Travel News',
            url: `${endpoint}/community/topics/30/general-travel-news-discussion`
          }
        ]
      }
    ];

    if (includeCompany) {
      values.unshift(      {
        name: 'COMPANY',
        url: `${endpoint}`,
        gridColumn: '1',
        gridRow: '1 /span 2',
        children: [
          {
            name: 'About Us',
            url: `${endpoint}/etc/about-us`
          },
          {
            name: 'Contact',
            url: `${endpoint}/etc/about-us#contact`
          },
          {
            name: 'Advertising & Media Kit',
            url: `${endpoint}/etc/about-us#advertising`
          },
          {
            name: 'Privacy Policy',
            url: `${endpoint}/etc/privacy`
          },
          {
            name: 'Terms of Use',
            url: `${endpoint}/etc/terms-conditions`
          }
        ]
      });
    }

    return values;
  }

  ngOnInit(): void {
    this.linkGroups = ExecutivetravellerHeaderComponent.GetMainLinks(false);
  }
  toggleMobileMenu(): void {
    this.isActive = !this.isActive;
  }
}
