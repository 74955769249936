import { Injectable } from '@angular/core';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { PartnerSiteService } from 'src/app/services/api/partner-site.service';
import { DefaultScriptInitilizer } from 'src/app/services/partner-specific/default/default-scripts';
import { ExecTravellerInitilizer } from 'src/app/services/partner-specific/executivetraveller/executivetraveller-scripts';
import { iSelectScriptInitilizer } from 'src/app/services/partner-specific/iselect/iselect-scripts';
import { NewsAuScriptInitilizer } from 'src/app/services/partner-specific/newsau/newsau-scripts';
import { SavrrInitilizer } from 'src/app/services/partner-specific/savrr/savrr-scripts';
import { environment } from 'src/environments/environment';
import { EnvironmentOptionsType } from 'src/environments/environmentDefinitions';
export interface IScriptInitilizer {
  helper: RenderHelper;
  renderStyleSheet(): void;
  renderProrityStyle(): void;
  renderPreLoad(): void;
  renderPreFetch(): void;
  renderScripts(): void;
  renderTemplates(e: EnvironmentOptionsType): void;
}

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  constructor(
    private partnerSiteService: PartnerSiteService,
    defaultInitilizer: DefaultScriptInitilizer,
    iSelect: iSelectScriptInitilizer,
    newsau: NewsAuScriptInitilizer,
    EXT: ExecTravellerInitilizer,
    Savrr: SavrrInitilizer
    ) {
    this.initialiser = null;
    if (environment.client.code === 'iselect') {
      this.initialiser = iSelect;
    } else if (environment.client.code === 'newsau') {
      this.initialiser = newsau;
    } else if (environment.client.code === 'executivetraveller') {
      this.initialiser = EXT;
    } else if (environment.client.code === 'savrr-au' || environment.client.code === 'savrr-vn' || environment.client.code === 'savrr-gb') {
      this.initialiser = Savrr;
    }
    else {
      this.initialiser = defaultInitilizer
    }

   
  }

  private initialiser: IScriptInitilizer | null;

  onStart(apiEndPoint: string): void {
    this.partnerSiteService.onTemplateChanged().subscribe(x => {
      if (this.initialiser && x) {
        // The order of this is important - certain tags add at the top. This should written in desc order; Most important last - highest priority
        if (environment.environment === 'production') {
          this.initialiser.renderScripts();
        }

        
        this.initialiser.renderStyleSheet();
        this.initialiser.renderProrityStyle();

        const tags = x?.verificationMeta ?? [];
        for (const t of tags) {
          this.initialiser.helper.renderMeta(t.name, t.content);
        }

        const gtmTag = x?.trackingTags['FC-GTM-KEY'];
        if (gtmTag) {
          this.initialiser.helper.renderGTMScript(gtmTag);
        }

        this.initialiser.renderPreLoad();
        this.initialiser.renderPreFetch();

        this.initialiser.helper.renderPrefetch('fc-assets', 'https://assets.comparisonpartner.com/');
        this.initialiser.helper.renderPrefetch('fc-ik', 'https://ik.imagekit.io/');
        this.initialiser.helper.renderPrefetch('fc-api', apiEndPoint + '/');
      }
    });
  }

  loadTemplates(e: EnvironmentOptionsType): void {
    if (this.initialiser) {
      this.initialiser.renderTemplates(e);
    }
  }
}