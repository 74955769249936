<div class="ext-background"></div>
<div class="ext-logo">
    <img title="Executive Traveller Logo" src="https://ik.imagekit.io/fairaus/executivetraveller/logo-mobile.svg" width="40px" height="34px" loading="eager">
</div>
<div class="ext-menu" [ngClass]="{ 'active': isActive}">
    <div class="ext-main-menu" *ngIf="!isActive">
        <ul>
            <li *ngFor="let a of links">
                <a [href]="a.url" [innerText]="a.name"></a>
            </li>
        </ul>
    </div>
    <div class="ext-full-menu" *ngIf="isActive">
        <div *ngFor="let group of linkGroups">
            <a class="ext-group-title" [innerText]="group.name" [href]="group.url"></a>
            <ul>
                <li *ngFor="let child of group.children">
                    <a [innerText]="child.name" [href]="child.url"></a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="ext-mobile-menu"><div class="ext-burger" (click)="toggleMobileMenu()"></div></div>
<div class="ext-mobile-background" [ngClass]="{ 'active': isActive}" (click)="toggleMobileMenu()"></div>
<div class="ext-breadcrumb">
    <fc-default-breadcrumb [showOnRoot]="false" [showExternal]="false" [showPreviousOnly]="true"></fc-default-breadcrumb>
</div>