import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectorStaticFooterDto, SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { AppConfigService } from 'src/app/services/app-config.service';

export enum ContainerPlacementType {
  PartnerSite,
  Sector,
  Category,
  Group,
  AllBrand,
  Brand,
  Article
}


@Injectable({
  providedIn: 'root'
})
export class ContentService {
  url =  AppConfigService.AppEnvironment.apiEndPoint + '/Content';
  constructor(private http: HttpClient) { }

  GetStaticPage(sector: string, category: string | null, page: string): Observable<SectorStaticPageDto> {
    let path = `${this.url}/${sector}/${page}`;
    if (category) {
      path += `?category=${category}`;
    }
    return this.http.get<SectorStaticPageDto>(path);
  }

  GetStaticFooterLinks(): Observable<SectorStaticFooterDto[]> {
    return this.http.get<SectorStaticFooterDto[]>(`${this.url}/Footer-Links`);
  }
}
