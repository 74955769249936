import { Component, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { IStackedColumns, TableHelperService } from 'src/app/services/table-helper.service';
import { debounceTime } from 'rxjs/operators';
import { ColumnComponent } from '../column/column.component';
import { NgFor, NgIf } from '@angular/common';

interface ScrollerSettings {
  limits: {
    panelSize: number;
    scrollEnd: number;
    panel: number;
    panelCount: number;
  };
  viewportWidth: number;
  colCount: number;
  colWidth: number;
  gap: number;
  left: number;
}

@Component({
    selector: 'fc-column-scroll-container',
    templateUrl: './column-scroll-container.component.html',
    styleUrls: ['./column-scroll-container.component.scss'],
    standalone: true,
    imports: [NgFor, ColumnComponent, NgIf]
})
export class ColumnScrollContainerComponent implements OnInit {
  config: ScrollerSettings = {
    limits: {
      panelSize: 780,
      scrollEnd: 780,
      panel: 1,
      panelCount: 1,
    },
    viewportWidth: 780,
    colCount: 0,
    colWidth: 180,
    gap: 5,
    left: 0
  };
  stack: IStackedColumns;



  @HostBinding('style.--fc-vs-viewport') viewport: string;
  @HostBinding('style.--fc-vs-colCount') colCount: number;
  @HostBinding('style.--fc-vs-colWidth') colWidth: string;
  @HostBinding('style.--fc-vs-gap') gap: string;
  @HostBinding('style.--fc-vs-margin-left') marginLeft: string;
  @HostBinding('style.--fc-vs-margin-right') marginRight: string;
  @HostBinding('style.--fc-vs-display-left') displayLeft: 'none' | 'block' = 'none';
  @HostBinding('style.--fc-vs-display-right') displayRight: 'none' | 'block' = 'block';


  private handleResize = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.handleResize.next();
    this.calculateViewportWidth();
  }


  @Input() set columns(value: TableColumnDTO[]) {
    this.stack = this.tableHelperService.stackColumnsV2(value);
    const numOfStacks = (this.stack.stackOne.length > 0) ? 1 : 0;

    this.config.colCount = (this.stack.columns.length + numOfStacks);
    this.calculateViewportWidth();
  }

  constructor(private hostElement: ElementRef, public tableHelperService: TableHelperService) { }

  ngOnInit(): void {
    this.handleResize
      .pipe(debounceTime(300))
      .subscribe(() => this.calculateViewportWidth());
  }

  private updateHost(): void {
    this.viewport = `${this.config.viewportWidth}px`;
    this.colCount = this.config.colCount;
    this.colWidth = `${this.config.colWidth}px`;
    this.gap = `${this.config.gap}px`;
    this.marginLeft = `${this.config.left}px`;
    this.displayLeft = (this.config.left  < 0) ? 'block' : 'none';
    this.displayRight = this.canScrollRight() ? 'block' : 'none';
    this.marginRight = this.config.colCount * this.config.colWidth + 'px';
  }



  canScrollRight(): boolean {
    return this.config.limits.panelCount > 1 &&
    this.config.limits.panel < this.config.limits.panelCount;
  }

  private calculateViewportWidth(): void {
    const styles = getComputedStyle(this.hostElement.nativeElement);
    this.config.viewportWidth = parseInt(styles.width, 10);

    this.config.limits.panel = 1;
    this.config.left = 0;

    if (this.config.viewportWidth > this.getScrollBreakpoint()) {
      this.config.colWidth = (this.config.viewportWidth - ((this.config.colCount - 1) * this.config.gap)) / this.config.colCount;
      this.config.limits.scrollEnd = this.calculateScrollEnd();

      this.config.limits.panelSize = this.config.viewportWidth;
      this.config.limits.panelCount = 1;
    } else {
      this.config.colWidth = 180;
      this.config.limits.scrollEnd = this.calculateScrollEnd();

      this.config.limits.panelSize = Math.floor(this.config.viewportWidth / this.config.colWidth) * (this.config.colWidth + this.config.gap); // Number of whole panels + there size and subsequent gap;

      if (this.config.limits.panelSize > this.config.viewportWidth) {
        this.config.limits.panelSize = this.config.colWidth + this.config.gap;
      }

      this.config.limits.panelCount = Math.ceil(this.config.limits.scrollEnd / this.config.limits.panelSize);
    }

    this.updateHost();
  }

  private getScrollBreakpoint(): 650 | 800 {
    return(this.config.colCount <= 4) ? 650 : 800;
  }

  private calculateScrollEnd(): number {
   return (this.config.colCount * this.config.colWidth) + ((this.config.colCount - 1) * this.config.gap);
  }

  onScroll(direction: 'right' | 'left'): void {
    const offsetMargin = 30;

    if (this.config.left === 0) {
      this.config.left = offsetMargin;
    }

    if (direction === 'right') {
      this.config.left -= this.config.limits.panelSize;
      this.config.limits.panel++;
    } else {
      this.config.left += this.config.limits.panelSize;
      this.config.limits.panel--;
    }

    if (this.config.left === offsetMargin) {
      this.config.left  = 0;
    }

    this.updateHost();
  }
}

