import { Component, HostBinding, Input } from '@angular/core';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { ItemSettings, TableSettings } from 'src/app/templates/tables/standard/table-base.component';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/models/shared/Utils';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { TableDefaultComponent } from './standard/table-default/table-default.component';
import { TableScrollerDefaultComponent } from './standard/table-scroller-default/table-scroller-default.component';
import { CompactSideTableComponent } from './standard/compact-side-table/compact-side-table.component';
import { TableContentLoaderComponent } from './components/table-content-loader/table-content-loader.component';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass } from '@angular/common';

@Component({
    selector: 'fc-table-switcher',
    styleUrls: ['./table-switcher.component.scss'],
    template: `

    <fc-disclaimer *ngIf="showDisclaimer && settings?.tableSettings?.advertiserDisclosure"
      [message]="settings?.tableSettings?.advertiserDisclosure"
      [showLink]="showHeaderLink"></fc-disclaimer>

    <div *ngIf="isLoading" class="fc-table-items">
      <fc-table-content-loader></fc-table-content-loader>
      <fc-table-content-loader></fc-table-content-loader>
      <fc-table-content-loader></fc-table-content-loader>
      <fc-table-content-loader></fc-table-content-loader>
      <fc-table-content-loader></fc-table-content-loader>
    </div>
    <div *ngIf="items" class="fc-table-items">
        <div *ngFor="let i of items; let position = index" [ngSwitch]="settings?.tableSettings.tableTemplate" [ngClass]="settings?.tableSettings.tableTemplate">
            <fc-compact-side-table *ngSwitchCase="'fc-compact-side-table'" [item]="getItem(i, position)" [settings]="settings"></fc-compact-side-table>
            <fc-table-scroller-default *ngSwitchCase="'fc-table-scroller-default'"  [item]="getItem(i, position)" [settings]="settings"></fc-table-scroller-default>
            <fc-table-default *ngSwitchDefault [item]="getItem(i, position)" [settings]="settings"></fc-table-default>
        </div>
  </div>
  <div *ngIf="items?.length == 0 && !isLoading" class="fc-table-no-items">Sorry, no applicable products identified</div>`,
    standalone: true,
    imports: [NgIf, DisclaimerComponent, TableContentLoaderComponent, NgClass, NgFor, NgSwitch, NgSwitchCase, CompactSideTableComponent, TableScrollerDefaultComponent, NgSwitchDefault, TableDefaultComponent]
})
export class TableSwitcherComponent {
  @Input() isLoading: boolean;
  @Input() showDisclaimer = true;

  @HostBinding('style.--fc-item-count') itemCount = 10;
  private _items: TableItemDTO[];
  @Input() set items(items: TableItemDTO[]) {
    this._items = items;
    this.itemCount = items?.length ?? 10;
  }
  get items(): TableItemDTO[] {
  return this._items;
  }


  _settings: TableSettings;
  get settings(): TableSettings {
    return this._settings;
  }
  @Input() set settings(value: TableSettings) {
    this._settings = value;
  }
  @Input() paging: PagingParameterDto;

  showHeaderLink = environment.client.disclaimers.showTableLink;

  getItem(content: TableItemDTO, position: number): ItemSettings {
    return {
      content,
      position,
      url: this.getRoute(content),
      classes: this.getClasses(content, position)
    };
  }
  private getAdvertisedClass(content: TableItemDTO): 'fc-sp' |  'fc-pp' | 'fc-ap' {
      if (content.weighting.promotedId > 0 && content.weighting.promotedPosition) {
        return content.disclaimer.accentTheme as any ?? 'fc-pp';
      }
      return 'fc-sp';
  }

  private getClasses(content: TableItemDTO, position: number): string[] {
    const styes: string[] = [];
    styes.push(this.getAdvertisedClass(content));
    const previous = this.items[position - 1];
    if (previous) {
      styes.push('fc-prev-' + this.getAdvertisedClass(previous));
    }

    const next = this.items[position + 1];
    if (next) {
      styes.push('fc-next-' + this.getAdvertisedClass(next));
    } else {
      styes.push('fc-last');
    }

    return styes;
  }


  private getRoute(content: TableItemDTO): string | null {
    return Utils.getRoutePath(this.settings.sectorUrl, this.settings.categoryUrl, this.settings.groupUrl, content?.brand.slug, content?.product.slug);
  }
}
