<ng-container *ngIf="!isExternalLink">
    <a class="fc-tag" [FCRouterLink]="topic?.route" [innerHtml]="topic?.label"></a>
    <h3 class="fc-header">
        <a [fcContentLink]="article" [analyticsMode]="analyticsMode" [mode]="'block'" [innerHtml]="article.heading"></a>
    </h3>
    <a class="fc-image" [fcContentLink]="article" [analyticsMode]="analyticsMode" [mode]="'block'">
        <img [ngSrc]="image.src" [ngSrcset]="'200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w'"
            [priority]="priority ?? article.image.priority" [width]="image.width" [height]="image.height"
            [loaderParams]="image.loaderParams" />
    </a>
    <div class="fc-summary" [innerHtml]="article.content | markedInline"></div>
</ng-container>
<ng-container *ngIf="isExternalLink">
    <a class="fc-tag" [href]="topic?.route" [innerHtml]="topic?.label"></a>
    <h3 class="fc-header">
        <a [href]="article.internalRoute" [innerHtml]="article.heading"></a>
    </h3>
    <a class="fc-image" [href]="article.internalRoute">
        <img [ngSrc]="image.src" [ngSrcset]="'200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w'"
            [priority]="priority ?? article.image.priority" [width]="image.width" [height]="image.height"
            [loaderParams]="image.loaderParams" />
    </a>
    <div class="fc-summary" [innerHtml]="article.content | markedInline"></div>
</ng-container>