import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'fc-powered-by-logo',
    templateUrl: './powered-by-logo.component.html',
    styleUrls: ['./powered-by-logo.component.scss'],
    standalone: true
})
export class PoweredByLogoComponent{ 
  logoSrc = environment.logoSrc ? environment.logoSrc : 'https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg'
}
