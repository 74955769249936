import { Component, Input, OnInit } from '@angular/core';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { StarRatingComponent } from '../../../../shared/star-rating/star-rating.component';
import { NgIf } from '@angular/common';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';

@Component({
    selector: 'fc-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective, NgIf, StarRatingComponent]
})
export class TableHeaderComponent {
  @Input() content: TableItemDTO;
  @Input() route: string;

  


}
