import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ArticleParams, AuthorParams } from 'src/app/models/shared/Utils';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { TopicDto } from 'src/app/models/article/TopicDto';
import { AuthorProfileDto } from 'src/app/models/article/authorProfileDto';

@Injectable({ providedIn: 'root' })
export class ArticleService {

    url = AppConfigService.AppEnvironment.apiEndPoint + '/article';

    constructor(private http: HttpClient) { }

    GetArticle(params: ArticleParams, preview: string | undefined): Observable<ArticleDTO> {
        let endpoint = `${this.url}/${params.articleId}`;

        // if (params.group) {
        //     endpoint += `/${params.group}`;
        // }

        if (preview) {
            endpoint += `?preview=${preview}`;
        }

        return this.http.get<ArticleDTO>(endpoint);
    }

    GetArticleList(slugPrefix: string, params: ArticleParams): Observable<TopicDto> {
        let endpoint = `${this.url}/list/${slugPrefix}/${params.sector}`;

        if (params.category) {
            endpoint += `/${params.category}`;
        }

        if (params.group) {
            endpoint += `/${params.group}`;
        }
        return this.http.get<TopicDto>(endpoint);
    }

    GetAuthorProfile(params: AuthorParams): Observable<AuthorProfileDto> {
        const endpoint = `${this.url}/author/${params.sector}/${params.authorSlug}`;
        return this.http.get<AuthorProfileDto>(endpoint);
    }
}
