import { Component } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ArticleSideBySideComponent } from '../components/article-sidebyside/article-sidebyside.component';
import { NgClass, NgFor } from '@angular/common';
import { ArticleHeaderComponent } from '../../content/utils/article-header/article-header.component';

@Component({
    selector: 'fc-article-column',
    templateUrl: './article-column.component.html',
    styleUrls: ['./themes/one-column.scss', './themes/two-columns.scss', './themes/three-columns.scss'],
    standalone: true,
    imports: [ArticleHeaderComponent, NgClass, NgFor, ArticleSideBySideComponent]
})
export class ArticleColumnComponent  extends ContentBaseComponent { 

  getTheme() {
    return this.content.accentTheme ?? 'fc-one-column';
  }
}
