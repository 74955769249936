import { Component, Input, OnInit } from '@angular/core';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';
import { ApplyComponent } from '../../../shared/buttons/apply/apply.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { ProductSeperatorComponent } from '../product-seperator/product-seperator.component';
import { ProductTmdComponent } from '../product-tmd/product-tmd.component';
import { NgIf } from '@angular/common';
import { ProductAllValuesComponent } from '../product-all-values/product-all-values.component';
import { ProductActionComponent } from '../product-action/product-action.component';
import { ProductKeyValuesComponent } from '../product-key-values/product-key-values.component';

@Component({
    selector: 'fc-product-content',
    templateUrl: './product-content.component.html',
    styleUrls: ['./product-content.component.scss'],
    standalone: true,
    imports: [ProductKeyValuesComponent, ProductActionComponent, ProductAllValuesComponent, NgIf, ProductTmdComponent, ProductSeperatorComponent, FCRouterLinkDirective, ApplyComponent, MarkedPipe]
})
export class ProductContentComponent implements OnInit {
  @Input() page: ProductPageDTO;
  @Input() applySettings: ApplySettings;
  @Input() categoryUrl: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
