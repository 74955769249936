<a *ngFor="let content of items"
    [ngClass]="content.accentTheme" 
    [FCRouterLink]="content.url" 
    [id]="'fc-card-detailed-' + content.sortOrder"
    [fcAnalyticsCategory]="content">
    <h2 [innerHtml]="content.heading | markedInline"></h2>
    <div class="fc-summary" [innerHtml]="content.summary | markedInline"> </div>
    <div class="fc-action">
        <button>{{content.buttonText}}<i class="fas fa-chevron-right"></i></button>
    </div>
 
    <div class="fc-icon" *ngIf="content.icon">
        <img *ngIf="content.icon.endsWith('.svg')" [src]="content.icon" [alt]="content.heading" loading="eager">
        <i *ngIf="!content.icon.endsWith('.svg')" [ngClass]="content.icon"></i>
    </div>

    
</a>