<a class="fc-publish-profile-image" *ngIf="profile.author?.imageSrc"><img width="32" height="32" [src]="profile.author?.imageSrc" loading="eager" /></a>
<a class="fc-publish-profile-author" [FCRouterLink]="authorUrl"  target="_blank" [href]="authorUrl">{{profile.author?.name}}</a>
<span class="fc-publish-profile-updatedBy" *ngIf="profile.updatedBy">, updated By <a [FCRouterLink]="updatedUrl">{{profile.updatedBy}}</a></span>
<div class="fc-publish-profile-contact" *ngIf="hasContact()">
    <ul >
        <li *ngIf="twitterUrl"><a [href]="twitterUrl"  target="_blank"><i class="fab fa-twitter">&nbsp;</i>{{profile.author?.twitter}}</a></li>
        <li *ngIf="emailUrl"><a [href]="emailUrl"><i class="fas fa-envelope"></i></a></li>
        <li *ngIf="linkedInUrl"><a [href]="linkedInUrl" target="_blank"><i class="fab fa-linkedin"></i></a></li>
    </ul>
</div>
<div class="fc-publish-profile-read-time" *ngIf="readMessage">
    <i class="fas fa-clock"></i>{{readMessage}}
</div>
<div class="fc-publish-fact-checked" *ngIf="factCheckedTooltip">
    <a [FCRouterLink]="factCheckedUrl" [tooltip]="factCheckedTooltip | markedInline"  target="_blank">
        <i class="fad fa-check-double"></i> Fact Checked
    </a>
</div>
<span class="fc-publish-profile-publishedAt">
    <time [attr.datetime]="publishedAt">{{publishedAt}}</time>
</span>
<span class="fc-publish-profile-updatedAt" *ngIf="updatedAt">, updated At <time [attr.datetime]="updatedAt">{{updatedAt}}</time></span>