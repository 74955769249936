import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) { }

  openModal(component: any): ComponentRef<any> {
    const componentRef = this.resolver
      .resolveComponentFactory(component as any)
      .create(this.injector);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    return componentRef;
  }
}
