import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { SectorParams, Utils } from 'src/app/models/shared/Utils';
import { ContainerPlacementType } from 'src/app/services/api/content-service.ts.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { RichcontentService } from 'src/app/services/richcontent.service';
import { environment } from 'src/environments/environment';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { ContentContainerComponent } from '../../templates/content/content-container.component';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { NgIf } from '@angular/common';
import { PublishProfileComponent } from '../../shared/publish-profile/publish-profile.component';

@Component({
    selector: 'fc-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
    standalone: true,
    imports: [PublishProfileComponent, NgIf, DisclaimerComponent, ContentContainerComponent, MarkedInlinePipe]
})
export class ArticleComponent implements OnInit {
  showGenericLink = environment.client.disclaimers.showGenericLink;
  params: SectorParams;
  page: ArticleDTO;
  placement: ContainerPlacementType;
  disclosure: string;
  constructor(activatedRoute: ActivatedRoute, private appConfig: AppConfigService, private richContentService: RichcontentService) {
    this.params = Utils.GetParams<SectorParams>(activatedRoute.snapshot);
    activatedRoute.data.subscribe(x => {
      this.page = x.details;
      this.richContentService.generateArticle(this.page);
    });
  }

  ngOnInit(): void {
    this.placement = ContainerPlacementType.Article;
    this.disclosure =  this.appConfig.legalDisclosureGeneric;
  }

}
