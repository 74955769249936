import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { IScriptInitilizer } from 'src/app/services/script.service';


@Injectable({ providedIn: 'root' })
export class ExecTravellerInitilizer implements IScriptInitilizer {
    helper: RenderHelper;
    constructor(@Inject(DOCUMENT) private doc: Document) {
        this.helper = new RenderHelper(doc);
    }

    renderStyleSheet(): void { }
    renderProrityStyle(): void {}
    renderPreLoad(): void {}
    renderPreFetch(): void {}
    renderTemplates(): void {}
    renderScripts(): void {
        this.helper.renderSource('ext-gtag', 'https://www.googletagmanager.com/gtag/js?id=G-CTJE3JH5W6');
        let scriptTag = 'window.dataLayer = window.dataLayer || [];';
        scriptTag += 'function gtag(){dataLayer.push(arguments);}';
        scriptTag += 'gtag(\'js\', new Date());';
        scriptTag += 'gtag(\'config\', \'G-CTJE3JH5W6\');';
        this.helper.renderScript('google-globaltag', scriptTag);

        this.renderClicky();
    }

    private renderClicky(): void {
        this.helper.renderSource('ext-clicky', 'https://static.getclicky.com/263879.js');

        const img = this.doc.createElement('img');
        img.width = 1;
        img.height = 1;
        img.src = 'https://in.getclicky.com/263879ns.gif';

        this.helper.renderNoScript('ext-clicky-noscript', img);
    }
}

