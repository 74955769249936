import { DOCUMENT, NgIf, NgFor } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { PartnerMenuDto } from 'src/app/models/shared/helpers/MenuHelper';
import { PartnerSiteService } from 'src/app/services/api/partner-site.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DesktopMenuComponent } from '../../newscorp-top-header/desktop-menu/desktop-menu.component';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { CountryPickerComponent } from 'src/app/shared/country-picker/country-picker.component';

@Component({
    selector: 'fc-desktop-nav',
    templateUrl: './desktop-nav.component.html',
    styleUrls: ['./desktop-nav.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, NgFor, DesktopMenuComponent, CountryPickerComponent]
})
export class DesktopNavComponent {

  private _customMenu: PartnerMenuDto[] | null  = null;
  private _systemMenu: PartnerMenuDto[] = [];

  showCountries: boolean = false;

  @Input() showHomeLink: boolean = false;
  @Input() set customMenu(name: PartnerMenuDto[] | null ) {
    this._customMenu = name;
    this.menuItems = this.mergeMenuItems(this._systemMenu, name);
  }


  menuItems: PartnerMenuDto[];

  constructor(private partnerSiteService: PartnerSiteService,  sessionStorage: SessionStorageService) {
    const url =  AppConfigService.AppEnvironment.apiEndPoint;
    this.showCountries = sessionStorage.getConfiguration().showCountries;


    this.partnerSiteService.getPartnerMenu(url).subscribe((menu) => {
      if (menu) {
        this._systemMenu = menu;
        this.menuItems = this.mergeMenuItems(menu, this._customMenu);
      }
    });
  }

  mergeMenuItems(system: PartnerMenuDto[], custom: PartnerMenuDto[] | null): PartnerMenuDto[] {
    if (!custom) {
      return system;
    }

    return [...system, ...custom];



  }

  showMenu(mi: PartnerMenuDto, event: MouseEvent, index: number): void {
    if (event.target) {
      const targetRect: DOMRect = (event.target as any).getBoundingClientRect();
      mi.centerX  = targetRect.left + (targetRect.width / 2);
      mi.bottom = targetRect.bottom + document.documentElement.scrollTop;
      mi.visible = true;
      mi.index = index;
      const parentContainer = document.getElementById('fc-partner-menu');
      if (parentContainer) {
        mi.parentDOMRect = parentContainer.getBoundingClientRect();
      }
    }
  }

  hideMenu(mi: PartnerMenuDto): void {
    mi.visible = false;
  }

}
