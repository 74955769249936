import { Component, Input, OnInit } from '@angular/core';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';

@Component({
    selector: 'fc-product-action',
    templateUrl: './product-action.component.html',
    styleUrls: ['./product-action.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective]
})
export class ProductActionComponent implements OnInit {
  route: string;

  @Input() set pageDetails(value: CategoryDTO | GroupDTO | BrandDto | ProductPageDTO) {
    this.route = `/${value.navigation.sector.url}/${value.navigation.category.url}`;
  }

  @Input() set categoryUrl(value: string) {
    this.route = value;
  }


  @Input() compareButtonText: string;
  constructor() { }

  ngOnInit(): void {
  }

}
