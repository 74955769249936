import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { NavigationOutlineStageDto, NavigationStageDto } from 'src/app/models/generated/navigation/NavigationStageDto';
import { RichcontentService } from 'src/app/services/richcontent.service';
import { environment } from 'src/environments/environment';

export interface Breadcrumb {
  link: string | null;
  externalLink: string | null | undefined;
  title: string;
  excludeAble: boolean;
}

export interface BreadcrumbResult {
  navigation: NavigationDto | null;
  breadcrumb: Breadcrumb[];
  lastLink: boolean;
}

export type BreadcrumbParam = string | NavigationStageDto | NavigationOutlineStageDto;

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  sectorUrl: string;
  categoryUrl: string;
  groupUrl: string;

  constructor(private richcontentService: RichcontentService) {
  }

  private breadcrumbEmitter: BehaviorSubject<BreadcrumbResult | null> = new BehaviorSubject<BreadcrumbResult | null>(null);

  private isNavigationStage(stage: BreadcrumbParam): stage is NavigationStageDto {
    return (stage as NavigationStageDto).url !== undefined;
  }

  private isNavigationOutlineStage(stage: BreadcrumbParam): stage is NavigationOutlineStageDto {
    return (stage as NavigationOutlineStageDto).url !== undefined;
  }

  onBreadcrumbChanged(): Observable<BreadcrumbResult| null>  {
    return this.breadcrumbEmitter.asObservable();
  }


  private setBreadcrumb(offset: number, navigation: NavigationDto, ...params: BreadcrumbParam[]): void {
    if (navigation.sector?.url) {
      this.sectorUrl = navigation.sector?.url;
    }

    if (navigation.category?.url) {
      this.categoryUrl = `/${navigation.sector.url}/${navigation.category.url}`;
    }

    if (navigation.group?.url) {
      this.groupUrl = `${this.categoryUrl}/${navigation.group.url}`;
    }

    const result: BreadcrumbResult = {
      navigation,
      breadcrumb: [],
      lastLink: true
    };

    let previousLink: Breadcrumb | null = null;

    for (let i = 0; i < params.length; i++) {
      const stage = params[i];

      if (this.isNavigationStage(stage)) {
        if (i === params.length - offset) {
          result.breadcrumb.push({ link: null, title: stage.title,
            externalLink: stage.landingPageOverride, excludeAble: stage.excludeAble });
        } else {
          let link: Breadcrumb;

          if (previousLink == null) {
            link = { link: stage.url, title: stage.title, externalLink: stage.landingPageOverride, excludeAble: stage.excludeAble };
          } else {
            link = { link: `${previousLink.link}/${stage.url}`, title: stage.title, externalLink: null, excludeAble: false };
          }

          previousLink = link;
          result.breadcrumb.push(link);
        }
      } else if (this.isNavigationOutlineStage(stage))  {
        result.breadcrumb.push({ link: null, title: stage.title, externalLink: null, excludeAble: stage.excludeAble });
      }
      else {
        result.breadcrumb.push({ link: null, title: stage, externalLink: null, excludeAble: true });
      }
    }

    this.richcontentService.updateBreadcrumb(result);
    if (result.breadcrumb.length > 0) {
      this.breadcrumbEmitter.next(result);
    }
  }

  setBreadCrumbAllLinks(navigation: NavigationDto, ...params: BreadcrumbParam[]): void {
    this.setBreadcrumb(0, navigation, ...params);
  }
  setBreadCrumb(navigation: NavigationDto, ...params: BreadcrumbParam[]): void {
    this.setBreadcrumb(1, navigation, ...params);
  }

  clearBreadCrumb(): void {
    this.breadcrumbEmitter.next({ navigation: null, breadcrumb: [], lastLink: false});
  }
}
