
<div class="fc-group-action">
    <button type="button" (click)="toggleExpanded()"> {{settings.placeHolder}}</button>
    <i (click)="toggleExpanded()"></i>
    <ul id="fc-group-dropdown" (click)="toggleExpanded()">
        <li class="fc-dropdown-header">Most Popular</li>
        <li *ngFor="let i of popularItems" [ngClass]="i.class"><a [FCRouterLink]="getRouterLink(i)" [id]="'fc-group-dropdown-' + i.option?.id" [fcAnalyticsGroupfilter]="i">{{i.label}}</a></li>
        <li class="fc-dropdown-header" *ngIf="otherItems.length > 0">{{options.dropDownOtherPlaceholder}}</li>
        <li *ngFor="let i of otherItems" [ngClass]="i.class"><a [FCRouterLink]="getRouterLink(i)" [id]="'fc-group-dropdown-' + i.option?.id" [fcAnalyticsGroupfilter]="i">{{i.label}}</a></li>
    </ul>
</div>
<div class="fc-group-action-background" (click)="toggleExpanded()"></div>