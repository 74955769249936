import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TopicDto } from 'src/app/models/article/TopicDto';
import { AppConfigService } from 'src/app/services/app-config.service';
import { environment } from 'src/environments/environment';
import { MarkedPipe } from '../../pipes/marked.pipe';
import { DisplayArticlesComponent } from '../../shared/display-articles/display-articles.component';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['./article-list.component.scss'],
    standalone: true,
    imports: [NgIf, DisclaimerComponent, DisplayArticlesComponent, MarkedPipe]
})
export class ArticleListComponent implements OnInit {
  showGenericLink = environment.client.disclaimers.showGenericLink;
  page: TopicDto;
  disclosure: string;

  constructor(activatedRoute: ActivatedRoute, public appConfig: AppConfigService) {
    activatedRoute.data.subscribe(x => {
      this.page = x.details;
    });
  }

  ngOnInit(): void {
    this.disclosure = this.appConfig.legalDisclosureGeneric;
  }
}
