<div class="oth-background"></div>
<div class="oth-logo">
    <a href="https://www.onthehouse.com.au">
        <img title="On The House Logo" src="https://ik.imagekit.io/fairaus/on-the-house/icons/oth-logo-with-cl.png" 
        width="282" height="45">
    </a>

</div>
<div class="oth-menu" [ngClass]="{ 'active': isActive}">
    <ul class="oth-main-menu">
        <li><a href="https://www.onthehouse.com.au/suburb-research">Suburb Research</a></li>
        <li><a href="https://www.onthehouse.com.au/find-agent">Find an Agent</a></li>
        <li><a href="https://www.onthehouse.com.au/news" target="_self" rel="noopener noreferrer">News</a></li>
        <li class="oth-for-agents"><button (click)="toggleAgents($event)">For Agents</button></li>
    </ul>
    <div class="oth-submenu">
        <span class="oth-arrow"></span>
        <ul>
            <li><a href="https://www.onthehouse.com.au/list-on-onthehouse">List on OnTheHouse</a></li>
            <li><a href="https://www.onthehouse.com.au/agent-contact">Contact Us</a></li>
            <li><a href="https://help.onthehouse.com.au/hc/en-au/categories/360000997976-Help-for-Agents"
                    target="_blank" rel="nofollow noopener noreferrer">Agent Help</a></li>
            <li><a href="https://agentcentre.onthehouse.com.au/">Agent Centre (Admin)</a></li>
        </ul>
    </div>
</div>



<div class="oth-mobile-menu "><div class="oth-burger" (click)="toggleMobileMenu()"></div></div>
<div class="oth-mobile-background" [ngClass]="{ 'active': isActive}"></div>

<div class="oth-breadcrumb">
    <fc-default-breadcrumb [showOnRoot]="true" [showExternal]="true" [showPreviousOnly]="false" [showLowest]="true"></fc-default-breadcrumb>
</div>


