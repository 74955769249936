<nav id="fc-partner-menu">
    <ul>
        <li *ngIf="showHomeLink">
            <a [FCRouterLink]="'/'"><i class="fc-home fad fa-home"></i></a>
        </li>
        <li *ngFor="let mi of menuItems; let i = index" 
            (mouseenter)="showMenu(mi, $event, i)" 
            (mouseleave)="hideMenu(mi)">
            <a *ngIf="!mi.isExternal" [FCRouterLink]="mi.route">{{mi.label}}</a>
            <a *ngIf="mi.isExternal" [href]="mi.route">{{mi.label}}</a>
            <fc-desktop-menu [menuItem]="mi" [menuCount]="menuItems.length" [attr.id]="'fc-partner-menu-' + i"></fc-desktop-menu>
        </li>
        <li class="fc-country" *ngIf="showCountries">
            <fc-country-picker></fc-country-picker>
        </li>
    </ul>
</nav>