import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandCategoryDto } from 'src/app/models/generated/client/brand/BrandCategoryDto';
import { BrandSummaryDto } from 'src/app/models/generated/client/brand/BrandSummaryDto';
import { CategoryParams, Utils } from 'src/app/models/shared/Utils';
import { AppConfigService } from 'src/app/services/app-config.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { MarkedPipe } from '../../pipes/marked.pipe';
import { BrandCardComponent } from '../../shared/brand-card/brand-card.component';
import { NgFor, NgIf, KeyValuePipe } from '@angular/common';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { ProductActionComponent } from '../product/product-action/product-action.component';

@Component({
    selector: 'fc-all-brands',
    templateUrl: './all-brands.component.html',
    styleUrls: ['./all-brands.component.scss'],
    standalone: true,
    imports: [ProductActionComponent, DisclaimerComponent, NgFor, NgIf, BrandCardComponent, KeyValuePipe, MarkedPipe]
})
export class AllBrandsComponent implements OnInit {
  showTableLink = environment.client.disclaimers.showTableLink;
  showHeaderLink = environment.client.disclaimers.showHeaderLink;

  categoryUrl: string;
  params: CategoryParams;
  details: BrandCategoryDto;
  alphabetGroups: { [id: string]: BrandSummaryDto[]; } = {};
  legalDisclosureHeader: string;
  disclosure: string;

  constructor(activatedRoute: ActivatedRoute, private appConfig: AppConfigService, private seoService: SeoService) {
    const alphabet = '#abcdefghijklmnopqrstuvwxyz'
      .toLocaleUpperCase()
      .split('');

    alphabet.forEach(a => {
      this.alphabetGroups[a] = [];
    });

    this.params = Utils.GetParams<CategoryParams>(activatedRoute.snapshot);
    this.categoryUrl = `/${this.params.sector}/${this.params.category}`;
    this.details = activatedRoute.snapshot.data.details;
    this.legalDisclosureHeader = this.details.legalDisclosureHeader;
    this.details.brands.forEach(b => {
      const firstLetter = b.title.substring(0, 1).toLocaleUpperCase();
      if (+firstLetter) {
        this.alphabetGroups['#'].push(b);
      } else {
        this.alphabetGroups[firstLetter].push(b);
      }
    });


  }
  ngOnInit(): void {
    this.disclosure = this.appConfig.legalDisclosureGeneric;

  }

  scrollTo(i: string): void {
    document.getElementById('key-' + i)?.scrollIntoView({block: "start", behavior: 'smooth'});
  }

  scrollToLegalDisclosure(): void {
    document.getElementById('fc-footer-Legal-text')?.scrollIntoView({behavior: 'smooth'});
  }
}
