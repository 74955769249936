

 <a  *ngIf="applyVisible"
    [target]="buttonSettings?.target" 
    [rel]="buttonSettings?.rel" 
    [FCRouterLink]="buttonSettings?.url" 
    [queryParams]="buttonSettings?.queryParams"
    [id]="buttonSettings?.id"
    (click)="onTrackClick()"
    [ngClass]="buttonSettings?.class"
    [innerText]="buttonSettings?.label"
    [fcAnalyticsGoToSite]="buttonSettings?.analyticSettings">
</a> 
<button *ngIf="confirmApplyVisible" (click)="onConfirm()" [ngClass]="buttonSettings?.class" [innerText]="buttonSettings?.label"></button>