import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSettings } from 'src/app/shared/buttons/apply/apply.component';
import { AnalyticsGoToSiteDirective } from 'src/app/directives/analytics/analytics-go-to-site.directive';
import { FCRouterLinkDirective } from 'src/app/directives/fc-router-link.directive';

@Component({
  selector: 'fc-modal',
  standalone: true,
  imports: [CommonModule, FCRouterLinkDirective, AnalyticsGoToSiteDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './confirm-apply.component.html',
  styleUrls: ['./confirm-apply.component.scss']
})
export class ConfirmApplyComponent {
  @Input({ required: true}) brand: string;
  @Input({ required: true}) requirements: string[];
  @Input({ required: true}) buttonSettings: ButtonSettings;

  @Output() onClose = new EventEmitter<void>();
  @Output() onTrackClick = new EventEmitter<void>();

}
