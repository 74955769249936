import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface IFooter {
  label: string;
  disclosure: {
      primary: string;
      secondary: string;
  };
  links: IFooterLinks[];
}

export interface IFooterLinks {
  label: string;
  route: string;
}

@Component({
    selector: 'svr-footer-section',
    standalone: true,
    templateUrl: './footer-section.component.html',
    styleUrls: ['./footer-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule]
})
export class FooterSectionComponent {
  @Input({ required: true}) details: IFooter | null = null;
  isSecondaryVisible = false;

  toggleSecondary() {
    this.isSecondaryVisible = !this.isSecondaryVisible;
  }
}
