
<h2>{{ content.heading }}</h2>
<ul class="fc-category-list" *ngIf="content.components.length > 1">
    <li *ngFor="let component of content.components; let i = index "
        (click)="onComponentSelected(component, i)" 
        [ngClass]="{ 'active': component === selected }" >
        {{component.heading}}
    </li>
</ul>
<div class="fc-summary" *ngIf="content.summary" [innerHTML]="content.summary | marked"></div>
<div class="fc-brands-list">
    <div class="fc-scroll-left-col" (click)="previousItems()"></div>
    <div class="fc-brands-col">
        <a *ngFor="let brand of activeBrands" @fadeInOut [FCRouterLink]="brand.route" [attr.aria-label]="brand.label + ' Products List'">
            <img [src]="brand.imageSrc" alt="{{ brand.label }} Logo">
        </a> 
    </div>
    <div class="fc-scroll-right-col" (click)="nextItems()"></div>
</div>
<div class="fc-footer" *ngIf="content.footer" [innerHTML]="content.footer | marked"></div>