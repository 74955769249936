<ul *ngIf="settings">
    <li class="is-back" *ngIf="settings.level > 0" (click)="expandItem(null, true)">
        Back
    </li>
    <li class="is-header" *ngIf="settings.active"><a [FCRouterLink]="settings.active?.route" (click)="onMenuClosed.emit()"> {{settings.active?.label}}</a></li>
    <li class="is-item" *ngFor="let item of settings.items; let i = index">
        <ng-container 
            [ngTemplateOutlet]="getOutlet(item)"
            [ngTemplateOutletContext]="{item: item}">
        </ng-container>
    </li>
    <li class="is-country-picker"> 
        <fc-country-picker [openTop]="true"></fc-country-picker>
    </li>
    <li class="is-poweredby">
        <fc-powered-by-logo></fc-powered-by-logo>

    </li>
</ul>


<ng-template #expandable let-item="item">
    <label (click)="expandItem(item, false)">{{item.label}}</label>
</ng-template>
<ng-template #link let-item="item">
    <a [FCRouterLink]="item.route" [innerHTML]="item.label" class="nca-standard" (click)="onMenuClosed.emit()"></a>
</ng-template>
<ng-template #visual let-item="item">
    <a [FCRouterLink]="item.route" class="nca-visual" (click)="onMenuClosed.emit()">
        <div class="nca-label" [innerHTML]="item.label"></div> 
        <div class="nca-img">
            <img [src]="item.imageSrc" [alt]="item.label" />
        </div>        
    </a>
</ng-template>
