
import { Component, OnInit } from '@angular/core';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { ActivatedRoute } from '@angular/router';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { ProductParams, Utils } from 'src/app/models/shared/Utils';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { Subscription } from 'rxjs';
import { MarkedPipe } from '../../pipes/marked.pipe';
import { ProductContentComponent } from './product-content/product-content.component';
import { ProductAdvertComponent } from '../../shared/containers/product-advert/product-advert.component';
import { ProductActionComponent } from './product-action/product-action.component';

@Component({
    selector: 'fc-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    standalone: true,
    imports: [
        ProductActionComponent,
        ProductAdvertComponent,
        ProductContentComponent,
        MarkedPipe,
    ],
})

export class ProductComponent implements OnInit {
  subscriptions: Subscription[] = [];
  params: ProductParams;
  page: ProductPageDTO;
  applySettings: ApplySettings;
  heading: string;
  categoryUrl: string;

  constructor(activatedRoute: ActivatedRoute) {
    this.params = Utils.GetParams(activatedRoute.snapshot);
    this.subscriptions.push(activatedRoute.data.subscribe(data => {
       this.page = data.details;
       this.heading = this.page.brand.name + ' - ' + this.page.product.title;
    }));
  }

  ngOnInit(): void {
    this.categoryUrl = `/${this.params.sector}/${this.params.category}`;
    this.applySettings = {
      placement: 'product',
      page: PageTypeHelper.getPageDataLayer(this.page),
      brand: this.page.brand,
      product: this.page.product,
      table: null,
      columns: null
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
