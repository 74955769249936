<div class="fc-modal">
    <div class="fc-modal-content">
        <div class="fc-modal-heading">
            By visiting <strong>{{brand}}</strong> you confirm: 
        </div>
        <div class="fc-modal-section">
            <ul>
                <li *ngFor="let req of requirements">{{req}}</li>
            </ul>
        </div>
        <div class="fc-modal-footer">
            <a  [target]="buttonSettings?.target" 
                [rel]="buttonSettings?.rel" 
                [FCRouterLink]="buttonSettings?.url" 
                [queryParams]="buttonSettings?.queryParams"
                [id]="buttonSettings?.id"
                (click)="onTrackClick.emit()"
                [ngClass]="buttonSettings?.class"
                [innerText]="'Accept and Visit Site'"
                [fcAnalyticsGoToSite]="buttonSettings?.analyticSettings">
            </a> 
            <button (click)="onClose.emit()">Cancel</button>

        </div>
    </div>
</div>