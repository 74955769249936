import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { QuickAccessLinksDto } from 'src/app/models/generated/category/CategoryDTO';
import { QuickAccessLinkService } from 'src/app/services/quick-access-link.service';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'fc-quick-links',
    templateUrl: './quick-links.component.html',
    styleUrls: ['./quick-links.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass]
})
export class QuickLinksComponent implements OnInit {

  @Input() links: QuickAccessLinksDto[];
  @Input() label: string;
  constructor(private quickLinkService: QuickAccessLinkService) { }

  ngOnInit(): void {
  }

  getIcon(link: QuickAccessLinksDto ): string {
    if (link.icon) {
      return `'${link.icon}'`;
    } else  {
      return '';
    }
  }

  onClick(link: QuickAccessLinksDto): void {
    this.quickLinkService.navigate(link.target);
  }
}
