import { StandardInputDto } from './StandardInputDto';
import { DefaultInputDto } from './defaults/DefaultInputDto';
import { DefaultInputSettings, DefaultInputUtils } from './defaults/DefaultInputUtils';
import { Params } from '@angular/router';

export interface SavingsAccountInputDto extends StandardInputDto{
    initialSavingAmount: number | null;
    monthlyDepositAmount: number | null;
    savingDuration: number | null;
    pensionAccount: boolean | null;
    childrenAccount: boolean | null;
    isCategory: boolean;
}

export class SavingsAccountInput {
    public static Initialise(defaultInput: DefaultInputDto, isCategory: boolean, queryParams: Params): SavingsAccountInputDto {
        const settings: DefaultInputSettings = {
            elements: defaultInput.elements,
            queryParams
        };

        return {
            kind: defaultInput.kind,
            includeAllProducts: defaultInput.includeAllProducts,
            brands: [],
            initialSavingAmount: DefaultInputUtils.FindElementValue(settings, 'initial_saving_amount'),
            monthlyDepositAmount: DefaultInputUtils.FindElementValue(settings, 'monthly_deposit'),
            savingDuration: DefaultInputUtils.FindElementValue(settings, 'saving_duration'),
            childrenAccount: DefaultInputUtils.FindElementBoolValue(settings, 'children_account'),
            pensionAccount: DefaultInputUtils.FindElementBoolValue(settings, 'pension_account'),
            isCategory,
        };
    }
}