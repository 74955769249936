
<div class="fc-legal-background"></div>
<div class="fc-legal"  id="fc-footer-Legal-text" [innerHtml]="disclaimer | marked" *ngIf="showDisclaimer"></div>
<fc-powered-by-logo></fc-powered-by-logo>
<ul>
    <li *ngFor="let l of links;">
        <a [FCRouterLink]="seoService.getDisclaimerPage(l.slug)" [target]="disclaimersTarget">{{l.heading}}</a>
    </li>
</ul>
<div class="fc-partner-background"></div>
<div class="fc-partner"><ng-template fcFooterHost></ng-template></div>