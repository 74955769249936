import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { GroupOptionDTO } from 'src/app/models/generated/group/GroupOptionDTO';
import { CompareItemDTO } from 'src/app/models/generated/item/CompareValueDTO';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { SectorDTO } from 'src/app/models/generated/SectorDTO';
import { SortOptionDTO } from 'src/app/models/generated/sort/SortOptionDTO';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { CommercialStatus, PromotedPosition, TableWeightingDTO } from 'src/app/models/generated/table/TableWeightingDTO';
import { DropDownListItem } from 'src/app/models/shared/ButtonListStyleSettings';
import { StandardInputDto } from 'src/app/models/shared/inputs/StandardInputDto';
import { PageTypeHelper, PageTypes } from 'src/app/models/shared/helpers/PageTypesHelper';
import { PagingParameterDto } from 'src/app/models/shared/PagingParameterDto';
import { Utils } from 'src/app/models/shared/Utils';
import { BasicDataLayer, BrandDataLayer, DataTags, IAnalyticsClientService, PageDataLayer, ProductDataLayer, TableDataLayer } from 'src/app/services/analytics.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { CardSettings } from 'src/app/shared/category-card/category-card.component';
import { RouteHelper } from 'src/app/models/shared/helpers/RouteHelper';
import { TopicDto } from 'src/app/models/article/TopicDto';


type brand_sponsor_type = 'Paid Client' | 'Unpaid Client';
type product_sponsor_type = 'Advertised' | 'Sponsored' | 'Standard' | 'Unpaid' ;

type yahoo_ec_type = 'landingpage' | null;

type gtag_type = 'event';
type gtag_record = 'conversion' | 'purchase';

type facebook_type = 'init' | 'track' | 'trackCustom';

type category_type = 'credit-cards' | 'home-loans' | 'car-loans' | 'personal-loans';
type all_tracked_type = 'money' | category_type;

interface ITealiumDataLayer {
    net_content_type?: 'index' | 'product' | 'story' | 'comparison';
    net_platform?: 'web' | 'mobile';
    net_pn?: string;
    visit_id?: string;
    net_sec1?: 'compare';
    net_sec2?: string;
    net_sec3?: string;
    net_sec4?: string;
    net_sec5?: string;
    net_sec6?: string ;

    product_id?: string ;
    product_name?: string ;

    // Article NetSect
    article_details?: string ;
    net_article_date?: string ;
    net_article_updated_time?: string ;
    net_article_id?: string ;
    net_article_headline?: string ;
    net_article_byline?: string ;
    net_article_source?: string ;

    //
    net_subsec?: string;
    net_subsubsec?: string;
}

interface INewsMetrics {
  ev: (data: any) => void;
  npv: (data: any, refresh: boolean) => void;
}

interface IGooglePixel {
  gtag: (type: gtag_type, record: gtag_record, data: any) => void;
}

interface IYahooPixel {
  dotq: any[];
}

interface IFacebookPixel {
  fbq: (type: facebook_type, key: any, data?: any) => void;
}

interface NewsWindow {
    utag_data?: ITealiumDataLayer;
    utag: any;
    utag_cfg_ovrd: any;
    metrics: INewsMetrics;
    mready: any[];
}

type AllTrackedPages = {
  [code in all_tracked_type]: string;
};

type ClickCategories = {
  [code in category_type]: string;
};

@Injectable({ providedIn: 'root' })
export class INewsAuTracking implements IAnalyticsClientService {

  FLOODLIGHT_VISIT_SEND_TO: AllTrackedPages = {
    'money': 'DC-12588586/visit0/homep0+standard',
    'credit-cards': 'DC-12588586/visit0/credi0+standard',
    'personal-loans': 'DC-12588586/visit0/perso0+standard',
    'home-loans':  'DC-12588586/visit0/homel0+standard' ,
    'car-loans': 'DC-12588586/visit0/carlo0+standard',
  };

  FLOODLIGHT_PURCHASE_SEND_TO: ClickCategories = {
    'credit-cards': 'DC-12588586/conve0/credi0+transactions',
    'personal-loans': 'DC-12588586/conve0/perso0+transactions',
    'home-loans': 'DC-12588586/conve0/homel0+transactions',
    'car-loans': 'DC-12588586/conve0/carlo0+transactions',
  };

  ADWORDS_CONVERSION_SEND_TO: ClickCategories = {
    'credit-cards': 'AW-10998708370/MQSYCP7wyuUDEJLxy_wo',
    'personal-loans': 'AW-10998708370/IO0qCK_vyuUDEJLxy_wo',
    'home-loans': 'AW-10998708370/XnGPCMmdhOYDEJLxy_wo',
    'car-loans': 'AW-10998708370/cLvECLvpyuUDEJLxy_wo',
  };

  FACEBOOK_TRACK_CUSTOM_LABEL: ClickCategories = {
    'credit-cards':  'Credit Card Click Out',
    'personal-loans': 'Personal Loans Click Out',
    'home-loans': 'Home Loans Click Out',
    'car-loans': 'Car Loans Click Out'
  };

  YAHOO_EA_TYPE: ClickCategories = {
    'credit-cards': 'creditcard',
    'personal-loans': 'personalloan',
    'car-loans': 'clickcar',
    'home-loans': 'clickhome'
  };

  constructor(@Inject(DOCUMENT) private doc: Document, private sessionStorageSer: SessionStorageService) { }
  isFacebookInit = false;

  getSessionId(): string | null  {
    return null;
  }

  getClientId(): string | null  {
    return null;
  }


  private renderScript(id: string, content: string): void {
    const  sc = this.doc.createElement('script');

    sc.type = 'text/javascript';
    sc.id = id;
    sc.appendChild(this.doc.createTextNode(content));

    this.doc.getElementsByTagName('head')[0].appendChild(sc);
  }


  private createElementScript(): void {
    const overrideScriptTag = this.doc.getElementById('newsau-utag_cfg_ovrd');
    if (!overrideScriptTag) {
      let overrideScript = 'window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};';
      overrideScript +=  `window.utag_cfg_ovrd.noview = true;`;

      this.renderScript('newsau-utag_cfg_ovrd', overrideScript);
    }

    const existingScript = this.doc.getElementById('newsau-tag');
    if (!existingScript) {
      const tealiumComamndText = 'window.utag_data = window.utag_data || {};';
      this.renderScript('newsau-tag', tealiumComamndText);
    }
  }

  private isUtagEnabled(window: any): window is NewsWindow {
    return (window as NewsWindow).utag_data !== undefined;
  }

  private updateDataLayer(label: string, dataLayer: ITealiumDataLayer): void {
    if (!this.isUtagEnabled(window)) {
      this.createElementScript();
    }

    if (this.isUtagEnabled(window)) {
      window.utag_data = window.utag_data || {};

      delete window.utag_data.net_sec2;
      delete window.utag_data.net_sec3;
      delete window.utag_data.net_sec4;
      delete window.utag_data.net_sec5;
      delete window.utag_data.net_sec6;
      delete window.utag_data.product_id;
      delete window.utag_data.product_name;
      delete window.utag_data.article_details;
      delete window.utag_data.net_article_date;
      delete window.utag_data.net_article_updated_time;
      delete window.utag_data.net_article_id;
      delete window.utag_data.net_article_headline;
      delete window.utag_data.net_article_byline;
      delete window.utag_data.net_article_source;

      delete window.utag_data.net_subsec;
      delete window.utag_data.net_subsubsec;

      for (const key in dataLayer) {
        if (Object.prototype.hasOwnProperty.call(dataLayer, key)) {
          const element = dataLayer[key as keyof ITealiumDataLayer];
          if (element) {
            (window.utag_data as any)[key as keyof ITealiumDataLayer] = element;
          }
        }
      }
      window.utag?.view();
    }
  }


  private isGtagEnabled(window: any): window is IGooglePixel {
    return (window as IGooglePixel).gtag !== undefined;
  }
  private updateGTag(type: gtag_type, record: gtag_record, data: any): void {
    if (this.isGtagEnabled(window)) {
      window.gtag(type, record, data);
    }
  }

  private getFloodLightVisit(sectorUrl: string,  categoryUrl: string | null): string | null {
    if (sectorUrl && !categoryUrl) {
      return this.FLOODLIGHT_VISIT_SEND_TO.money;
    } else {
      return this.FLOODLIGHT_VISIT_SEND_TO[categoryUrl as category_type];
    }
  }

  private getFloodLightPurchaseSendTo(categoryUrl: string | null): string | null {
    return (categoryUrl) ?  this.FLOODLIGHT_PURCHASE_SEND_TO[categoryUrl as category_type] :  null;
  }

  private getAdwordsConversionSendTo(categoryUrl: string | null): string | null {
    return (categoryUrl) ?  this.ADWORDS_CONVERSION_SEND_TO[categoryUrl as category_type] :  null;
  }

  private isYahooEnabled(window: any): window is IYahooPixel {
    return (window as IYahooPixel).dotq !== undefined;
  }

  private updateYahooPixel(ec: yahoo_ec_type, ea: string | null): void {
    if (this.isYahooEnabled(window)) {
      const record: any = {
        projectId: '10000',
        properties: {
          pixelId: '10190205',
          qstrings: {
            et: 'custom'
          }
        }
      };

      if (ec) {
        record.properties.qstrings.ec = ec;
      }

      if (ea) {
        record.properties.qstrings.ea = ea;
      }

      window.dotq.push(record);
    }
  }

  getYahooEA(categoryUrl: string): string | null {
    return (categoryUrl) ? this.YAHOO_EA_TYPE[categoryUrl as category_type] : null;
  }

  private isFacebookEnabled(window: any): window is IFacebookPixel {
    return (window as IFacebookPixel).fbq !== undefined;
  }

  private updateFacebookPixel(track: 'PageView', customTrack: string | null, data: any ): void {
    if (this.isFacebookEnabled(window)) {
      if (!this.isFacebookInit) {
        window.fbq('init', '416759627293130');
        this.isFacebookInit = true;
      }

      window.fbq('track', track);

      if (customTrack) {
        window.fbq('trackCustom', customTrack, data);
      }
    }
  }

  getFacebookLabel(categoryUrl: string | null): string | null {
    return categoryUrl ? this.FACEBOOK_TRACK_CUSTOM_LABEL[categoryUrl as category_type] : null;
  }


  private pushMReadyEvents(label: string, data: any, isNPV: boolean): void {
    if (window && (window as any).utag_data) {
      const newsWindow = window as unknown as NewsWindow;
      newsWindow.mready = newsWindow.mready || [];
      newsWindow.mready.push((metrics: INewsMetrics) => {
        isNPV ? metrics.npv(data, true) : metrics.ev(data);
      });
    }
  }



  private buildTealium(pageHeading: string, navigation: NavigationDto, specificPage: PageTypes | null): ITealiumDataLayer {

    const tealiumLayer: ITealiumDataLayer =  {
        visit_id: this.sessionStorageSer.getOrSetSessionId(),
        net_content_type: 'index',
        net_pn: pageHeading + ' index',
        net_platform: Utils.DetectPlatform(),
        net_sec1: 'compare',
        net_sec2: navigation.sector.url.toLowerCase()
    };

    if (navigation.category){
      tealiumLayer.net_content_type = 'comparison';
      tealiumLayer.net_sec3 = navigation.category.url.toLowerCase();
    }

    // We are on a brands page:
    if (navigation.brandUrl) {
        tealiumLayer.net_content_type = 'comparison';
        tealiumLayer.net_sec4 = 'brands';
        tealiumLayer.net_sec5 = navigation.brandUrl.toLowerCase();
    }

    // Group Page:
    if (navigation.group) {
      tealiumLayer.net_content_type = 'comparison';
      tealiumLayer.net_sec4 = navigation.group.url.toLowerCase();
    }

    // We are on a product page
    if (specificPage && PageTypeHelper.isProduct(specificPage)) {
        tealiumLayer.net_content_type = 'product';
        if (navigation.group) {
            tealiumLayer.net_sec5 = specificPage.brand.slug.toLowerCase();
            tealiumLayer.net_sec6 = specificPage.product.slug.toLowerCase();
        } else {
            tealiumLayer.net_sec4 = specificPage.brand.slug.toLowerCase();
            tealiumLayer.net_sec5 = specificPage.product.slug.toLowerCase();
        }

        tealiumLayer.product_id = specificPage.product.id.toString().toLowerCase();
        tealiumLayer.product_name = specificPage.product.title.toLowerCase();
    }

    if (specificPage && PageTypeHelper.isArticle(specificPage)) {
      const today = dayjs();
      const daysSincePublish = today.diff(specificPage.publishDetails.publishedAt, 'day');
      const daysSinceUpdate = specificPage.publishDetails.updatedAt ? today.diff(specificPage.publishDetails.updatedAt, 'day') : null;

      tealiumLayer.net_content_type = 'story';
      tealiumLayer.net_sec3 = 'articles';
      tealiumLayer.net_sec4 = specificPage.articleCategory.toLowerCase();
      tealiumLayer.article_details = specificPage.articleType.toLowerCase() + '|' + daysSincePublish + '|' + daysSinceUpdate;
      tealiumLayer.net_article_date = specificPage.publishDetails.publishedAt.toLowerCase() ?? null;
      tealiumLayer.net_article_updated_time = specificPage.publishDetails.updatedAt?.toLowerCase() ?? null;

      tealiumLayer.net_article_id = 'fca-' + specificPage.id.toString().toLowerCase();
      tealiumLayer.net_article_headline = specificPage.heading.toLowerCase();
      tealiumLayer.net_article_byline = specificPage.publishDetails?.author?.name?.toLowerCase();
      tealiumLayer.net_article_source = 'news compare';
    }

    if (specificPage && PageTypeHelper.isArticleList(specificPage)) {
      tealiumLayer.net_sec3 = 'articles';
    }

    if (specificPage && PageTypeHelper.isStaticPage(specificPage)) {
      tealiumLayer.net_sec3 = 'disclaimers';
      tealiumLayer.net_sec4 = specificPage.navigation.sectorStaticPage?.toLowerCase();
    }

    return tealiumLayer;
  }


  private updateCampaignViewPage(sectorUrl: string,  categoryUrl: string | null): void {
    // Update Floodlight Tracking;
    this.updateGTag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: this.getFloodLightVisit(sectorUrl, categoryUrl)
    });

    // Update Yahoo Pixel
    this.updateYahooPixel('landingpage', null);

    // Update Facebook View
    this.updateFacebookPixel('PageView', null, null);
  }


  onSectorPageView(pageDetails: SectorDTO, lastClickedElement: any): void {
    this.updateDataLayer('onSectorPageView', this.buildTealium(pageDetails.heading, pageDetails.navigation, null));
    this.updateCampaignViewPage(pageDetails.navigation.sector.url, null);
  }

  onCategoryPageView(pageDetails: CategoryDTO, lastClickedElement: any): void {
    this.updateDataLayer('onCategoryPageView', this.buildTealium(pageDetails.heading, pageDetails.navigation, null));
    this.updateCampaignViewPage(pageDetails.navigation.sector.url, pageDetails.navigation.category.url);
  }

  onGroupPageView(pageDetails: GroupDTO, lastClickedElement: any): void {
    this.updateDataLayer('onCategoryPageView', this.buildTealium(pageDetails.heading, pageDetails.navigation, null));
    this.updateCampaignViewPage(pageDetails.navigation.sector.url, pageDetails.navigation.category.url);
  }

  onBrandPageView(pageDetails: BrandDto, lastClickedElement: any): void {
    this.updateDataLayer('onBrandPageView', this.buildTealium(pageDetails.heading, pageDetails.navigation, null));
    this.updateCampaignViewPage(pageDetails.navigation.sector.url, pageDetails.navigation.category.url);
  }

  onProductPageView(page: ProductPageDTO, lastClickedElement: any): void {
    this.updateDataLayer('onProductPageView', this.buildTealium(page.product.title, page.navigation, page));
    this.updateCampaignViewPage(page.navigation.sector.url, page.navigation.category.url);
  }

  onArticleListPageView(articles: TopicDto, lastClickedElement: any): void {
    this.updateDataLayer('onArticleListPageView', this.buildTealium(articles.heading, articles.navigation, articles));
  }

  onArticlePageView(article: ArticleDTO, lastClickedElement: any): void {
    this.updateDataLayer('onArticlePageView', this.buildTealium(article.heading, article.navigation, article));
  }

  onStaticPageView(disclaimer: SectorStaticPageDto, lastClickedElement: any): void {
    this.updateDataLayer('onStaticPageView', this.buildTealium(disclaimer.heading, disclaimer.navigation, disclaimer));
  }

  getGoToSiteDataTags(settings: ApplySettings): DataTags {
    return null;
  }

  getCategoryDataTags(category: CardSettings): DataTags {
    return [
      { key: 'data-tgev', value: 'event380'},
      { key: 'data-tgev-metric', value: 'npv'},
      { key: 'data-tgev-order', value: category.sortOrder.toString() },
      { key: 'data-tgev-container', value: 'product-category'},
      { key: 'data-tgev-label', value: category.url.toLowerCase().replace('/money/', '') }
    ];
  }

  getSortDataTags(item: DropDownListItem<SortOptionDTO>): DataTags {
    return [
      { key: 'data-tgev', value: 'event10'},
      { key: 'data-tgev-metric', value: 'ev'},
      { key: 'data-tgev-container', value: 'sort'},
      { key: 'data-tgev-label', value: item.label },
      { key: 'data-tgev-order', value: item.order?.toString() ?? '-' }
    ];
  }

  getGroupDataTags(item: DropDownListItem<GroupOptionDTO>): DataTags {
    return [
      { key: 'data-tgev', value: 'event10'},
      { key: 'data-tgev-metric', value: 'npv'},
      { key: 'data-tgev-container', value: 'product-filter'},
      { key: 'data-tgev-label', value: item.label },
      { key: 'data-tgev-order', value: item.order?.toString() ?? '-' }
    ];
  }

  private linkTags(label: string, href: string): DataTags {
    return null;
    // Until we can extract the article ID this is not required;
    // return [
    //   { key: 'data-tgev', value: 'event119'},
    //   { key: 'data-tgev-metric', value: Utils.isSameDomain(href) ?  'npv' : 'ev'},
    //   { key: 'data-tgev-container', value: 'bodylink'},
    //   { key: 'data-tgev-label', value: label },
    //   { key: 'data-tgev-order', value: id }
    // ];
  }

  getArticleLinkTags(label: string, href: string): DataTags {
    return this.linkTags(label, href);
  }

  getComparisonLinkTags(label: string, href: string): DataTags {
    return this.linkTags(label, href);
  }

  onCompare(navigation: NavigationDto, items: BasicDataLayer[], table: TableDataLayer, lastClickedElement: any, columns: TableColumnDTO[] | null): void {
   const compareGroupId = Utils.GetRandomGuid();
    for (const item of items) {
      const data = {
        events: ['event384'],
        eVar180: this.eVar180(table, compareGroupId),
        eVar181: this.eVar181(item.product, table),
        eVar182: item.product.id.toString(),
        eVar186: this.eVar186(item.product, columns),
        eVar189: this.eVar189(item.brand, item.product)
      };

      this.pushMReadyEvents('onCompare', data, false);
    }
  }

  onInitialTableLoad(table: TableDataLayer): void {
    const data = {
      events: ['event381'],
      eVar180: this.eVar180(table, null)
    };
    this.pushMReadyEvents('onInitialTableLoad', data, false);
  }

  onLoadMore(table: TableDataLayer): void {
    const data = {
      events: ['event382'],
      eVar180: this.eVar180(table, null)
    };
    this.pushMReadyEvents('onLoadMore', data, false);
  }

  onUpdateResults(table: TableDataLayer, inputs: StandardInputDto | undefined): void {
    const data = {
      events: ['event383'],
      eVar180: this.eVar180(table, null),
      eVar187: (inputs as any).borrowingAmount ?? null
    };
    this.pushMReadyEvents('onUpdateResults', data, false);
  }

  onMoreDetails(settings: ApplySettings, columns: TableColumnDTO[] | null): void {
    const data = {
      events: ['event385'],
      eVar180: this.eVar180(settings.table, null),
      eVar181: this.eVar181(settings.product, settings.table),
      eVar182: settings.product.id.toString(),
      eVar186: this.eVar186(settings.product, columns),
      eVar189: this.eVar189(settings.brand, settings.product)
    };
    this.pushMReadyEvents('onMoreDetails', data, true);
  }

  onItemCompareChecked(table: TableDataLayer): void {
    const data = {
      events: ['event426'],
      eVar180: this.eVar180(table, null)
    };
    this.pushMReadyEvents('onItemCompareChecked', data, false);
  }

  onGoToSite(settings: ApplySettings, clientClickId: string | null, columns: TableColumnDTO[] | null): void {
    const data = {
      events: ['event386'],
      eVar180: this.eVar180(settings.table, null),
      eVar181: this.eVar181(settings.product, settings.table),
      eVar182: settings.product.id.toString(),
      eVar183: clientClickId,
      eVar186: this.eVar186(settings.product, columns),
      eVar189: this.eVar189(settings.brand, settings.product)
    };
    this.pushMReadyEvents('onGoToSite', data, false);

    const transactionValue = settings.product.value;

    let destinationKey: string | null = null;

    if (settings.product.verticalPrimary === 'AU Credit Cards') {
      destinationKey = 'credit-cards';
    } else if (settings.product.verticalPrimary === 'AU Personal Loans' && settings.product.verticalSecondary === 'AU Car Loans') {
      destinationKey = 'car-loans';
    } else if (settings.product.verticalPrimary === 'AU Personal Loans' && settings.product.verticalSecondary !== 'AU Personal Loans') {
      destinationKey = 'personal-loans';
    } else if (settings.product.verticalPrimary === 'AU Home Loans') {
      destinationKey = 'home-loans';
    }
    
    if (destinationKey) {
      // Update Floodlight Tracking;
      this.updateGTag('event', 'purchase', {
          allow_custom_scripts: true,
          value: transactionValue,
          transaction_id: clientClickId,
          send_to: this.getFloodLightPurchaseSendTo(destinationKey)
      });

      // Update Google Adwords
      this.updateGTag('event', 'conversion', {
        send_to: this.getAdwordsConversionSendTo(destinationKey),
        value: transactionValue,
        currency: 'AUD'
      });

      // Update Yahoo Pixel
      this.updateYahooPixel(null, this.getYahooEA(destinationKey));

      // Update Facebook View
      this.updateFacebookPixel('PageView', this.getFacebookLabel(destinationKey), { currency: 'AUD', value: transactionValue});
    }
  }




    // Output as: <product_table_id>|<product_table_size>|<compare_group_id>;
    private eVar180(table: TableDataLayer | null, compareGroupId: string | null): string | null {
      return table ?  `${table.id}|${table.pageNumber * table.pageSize}|${compareGroupId}` : null;
    }

    // Output as: <brand_sponsor_type>|<product_sponsor_type>|<product_position>;
    private eVar181(productDetails: ProductDataLayer, table: TableDataLayer | null): string | null {
      const brandSponsorType = this.formatBrandSponsored(productDetails) ?? null;
      const productSponsorType = this.formatProductSponsored(productDetails, table) ?? null;
      return `${brandSponsorType}|${productSponsorType}|${table?.position ?? null}`;
    }

    private formatBrandSponsored(product: ProductDataLayer): brand_sponsor_type {
      let commercialStatus: CommercialStatus = product.status;
      return commercialStatus === CommercialStatus.Brand || commercialStatus === CommercialStatus.Product ? 'Paid Client' : 'Unpaid Client';
    }

    private formatProductSponsored(product: ProductDataLayer, table: TableDataLayer | null): product_sponsor_type {
      // If we have no commerical status or the brand is sponsored then this prduct has to be unpaid;
      if (product.status === CommercialStatus.None || product.status === CommercialStatus.Brand) {
        return 'Unpaid';
      }

      if (!table)
      {
        return 'Standard';
      }

      // Healdine is advertised
      if (table.promotedPosition === PromotedPosition.Headline || table.promotedPosition === PromotedPosition.P1) {
        return 'Advertised';
      // If default then position is standard
      } else if (table.promotedPosition === PromotedPosition.Default) {
        return 'Standard';
      } else {
      // Must be sposored;
        return 'Sponsored';
      }
    }

    // Output as: <brand_name>|<product_name>|<product_variance>;
    private eVar189(brand: BrandDataLayer, product: ProductDataLayer): string {
      if (!brand || !product) {
        return '';
      }
      let productTitle =  product.title;


      const variance = product.varianceTitle?.replace('|', '/') ?? null;

      return `${brand.name}|${productTitle}|${variance}` ;
    }

    // Output as: <annual_fee>;
    private eVar186(product: ProductDataLayer, columns:  TableColumnDTO[] | null): string | null {
      if (!columns) {
        return null;
      }

      const hasAnnualFee = columns.find(x => x.label.toLowerCase() === 'annual fee');
      const aggregrateMessages = hasAnnualFee?.primaryMessage + ' ' + hasAnnualFee?.secondaryMessage ?? '';
      const fees = aggregrateMessages.match(/\$(0|[1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?/g);

      let maxNumber: number | null = 0;
      let feeToReturn: string | null = null;
      if (fees) {
        for (const f of fees) {
           const feeAsNumber = +f.replace('$', '');
           if (feeAsNumber >= maxNumber) {
            feeToReturn = f;
            maxNumber = feeAsNumber;
           }
        }
      }
      return feeToReturn;
    }

}
