import { CommonModule, NgOptimizedImage, APP_BASE_HREF, IMAGE_LOADER, ImageLoaderConfig } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_ID, ApplicationConfig, importProvidersFrom } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { AppRoutingModule } from "src/app/app-routing.module";
import { Utils } from "src/app/models/shared/Utils";
import { ApiInterceptor } from "src/app/services/interceptor/api.interceptor";
import { environment } from "src/environments/environment";


const devSrc: string = 'https://ik.imagekit.io/fairaus/dev';
const stagingSrc: string = 'https://ik.imagekit.io/fairaus/staging';
const productionSrc: string = 'https://ik.imagekit.io/fairaus';

const formatLoader = (path: string, config:ImageLoaderConfig) => {
    var baseImageSrc = config.src.replace(path, '');
    if (!config.loaderParams) {
        return `${path}/tr:w-${config.width}/${baseImageSrc}`;
    }
    return `${path}/tr:w-${config.width},${Utils.FlattenObject(config.loaderParams)}${baseImageSrc}`;
}

export const ImageKitLoader = (config: ImageLoaderConfig) => {
    if (config.src.startsWith(devSrc)) {
        return formatLoader(devSrc, config);
    } else if (config.src.startsWith(stagingSrc)) {
        return formatLoader(stagingSrc, config);
    } else if (config.src.startsWith(productionSrc)) {
        return formatLoader(productionSrc, config);
    } else {
        return config.src;
    }
}
  

export const baseConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(CommonModule, BrowserModule, FormsModule, ReactiveFormsModule, AppRoutingModule, ContentLoaderModule, NgOptimizedImage),
        { provide: APP_ID,  useValue: 'serverApp' },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: environment.client.masking.baseHref },
        { provide: IMAGE_LOADER,  useValue: ImageKitLoader },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
}




