
import { Component } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ContentLinkDirective } from '../../../directives/content-link.directive';

@Component({
    selector: 'fc-content-cta',
    templateUrl: './content-cta.component.html',
    styleUrls: ['./content-cta.component.scss'],
    standalone: true,
    imports: [ContentLinkDirective]
})
export class ContentCTAComponent extends ContentBaseComponent {}
