import { Injectable } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Utils } from 'src/app/models/shared/Utils';
import { InMemoryLocalStorage } from 'src/app/services/session-storage.service';


export interface ILocalStorageService {
  getOrSetUserId(): string
}


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements ILocalStorageService {

  private storage: Storage;

  constructor() {
    this.storage = new InMemoryLocalStorage();
    AppComponent.isBrowser.subscribe(isBrowser => {
      if (isBrowser) {
        this.storage = sessionStorage;
      }
    });
  }

  [name: string]: any;

  length: number;

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }

  getOrSetUserId(): string {
    let userId = this.storage.getItem('FC_WL_UserId');

    if (!userId) {
      userId = Utils.GetRandomGuid();
      this.storage.setItem('FC_WL_UserId', userId);
    }

    return userId;
  }

}
