import { Component, HostBinding, Input } from '@angular/core';
import { ContentContainerDto } from 'src/app/models/generated/content/ContentContainerDto';
import { ScreenSizeService } from 'src/app/services/screenSize.service';

@Component({
    selector: 'fc-background',
    template: '',
    standalone: true,
})
export class BackgroundComponent {

  _content: ContentContainerDto;
  get content(): ContentContainerDto {
      return this._content;
  }
  @Input() set content(value: ContentContainerDto) {
      this._content = value;
      this.setPositioning();
  }

  _index: number;
  get index(): number {
      return this._index;
  }
  @Input() set index(value: number) {
      this._index = value;
      this.setPositioning();
  }

  
  @HostBinding('style.background-color') backgroundColour: string;
  @HostBinding('style.grid-row') gridRow: string;
  @HostBinding('style.grid-column') gridColumn: string;

  constructor(private screenSizeService: ScreenSizeService) { }

  ngOnInit(): void {
    this.setPositioning();
  }

  setPositioning(): void
  {
    const screenSize = this.screenSizeService.estimateSize();
    this.backgroundColour = this.content.backgroundColour;
    if (screenSize === 'sm' || screenSize === 'md' ) {
      this.gridRow = `${this.index + 1}  / span 1`;
      this.gridColumn = `col-padding-left 1 / span 3`;
    } else {
    this.gridColumn = `col-padding-left 1 / span 14`;
      this.gridRow = `${this._content.y + 1}  / span ${this._content.rows}`;
    }
  }

}
