import { Component } from '@angular/core';
import { PersonalLoanInputDto } from 'src/app/models/shared/inputs/PersonalLoanInputDto';
import { BaseFilter } from 'src/app/shared/filters/base-filters';
import { OptionInputSettings } from 'src/app/shared/filters/components/option-input/option-input.component';
import { OptionInputComponent } from '../components/option-input/option-input.component';
import { NgIf } from '@angular/common';
import { SliderInputComponent } from '../components/slider-input/slider-input.component';
import { InputColumnComponent } from '../components/input-column/input-column.component';

@Component({
    selector: 'fc-personal-loans-input',
    templateUrl: './personal-loans-input.component.html',
    styleUrls: [
        '../shared-styles/fc-input-layout.scss',
    ],
    standalone: true,
    imports: [InputColumnComponent, SliderInputComponent, NgIf, OptionInputComponent]
})
export class PersonalLoansInputComponent extends BaseFilter<PersonalLoanInputDto>{
  interestTypeSettings: OptionInputSettings = {
    disabled: () => this.values.interestTypeDisabled,
    elementOne: {
      label: 'Fixed',
      value: 1
    },
    elementTwo: {
      label: 'Variable',
      value: 2
    },
  };

  loanSecuritySettings: OptionInputSettings = {
    disabled: () => this.values.loanSecurityDisabled,
    elementOne: {
      label: 'Secured',
      value: 10
    },
    elementTwo: {
      label: 'Unsecured',
      value: 11
    },
  };
}
