import { Component, Input, OnChanges } from '@angular/core';
import { TableItemDTO } from 'src/app/models/generated/table/TableItemDTO';
import { NgChanges } from 'src/app/models/shared/Utils';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'fc-promoted-label',
    templateUrl: './promoted-label.component.html',
    styleUrls: ['./promoted-label.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, NgClass]
})
export class PromotedLabelComponent implements OnChanges {

  constructor(private seoService: SeoService) {
  }
  @Input() product: TableItemDTO;

  title: string | null = null;
  subTitle: string | null;
  url = '';
  mobileTitle: string | null;

  disclaimersTarget = environment.client.disclaimers.target;

  hasMobile: string | null;

  ngOnChanges(changes: NgChanges<PromotedLabelComponent>): void {
    if (changes.product?.currentValue) {
      this.setTitles();
    }
  }

  setTitles(): void {
    this.url = this.seoService.getAdvertiserDisclosurePage();
    if (this.product.weighting.promotedId > 0 && this.product.weighting.promotedPosition) {
      if (this.product.disclaimer) {
        this.title = this.product.disclaimer.title;
        this.subTitle =  this.product.disclaimer.description;
        this.mobileTitle = this.product.disclaimer.mobileDescription ?? this.product.disclaimer.description;

        if (this.product.disclaimer.mobileDescription) {
          this.hasMobile = 'fc-has-mobile-description';
        }
      }  else {
        this.title = 'Promoted';
        this.subTitle = null;
        this.mobileTitle = null;
      }
    }
  }
}
