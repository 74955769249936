<div class="fc-header-background"></div>
<h1 [innerHtml]="pageSpecific.heading | markedInline"></h1>
<div class="fc-header-description" [innerHtml]="page.description | markedInline"></div>
 <fc-group-switcher *ngIf="!pageSpecific.compareAllText" [groupSettings]="groupSettings" [pageDetails]="page"></fc-group-switcher>
 <fc-product-action *ngIf="pageSpecific.compareAllText" [pageDetails]="page" [compareButtonText]="pageSpecific.compareAllText"></fc-product-action>
 <div *ngIf="page.secondaryDescription" class="fc-header-secondary-description" [innerHtml]="page.secondaryDescription | markedInline"></div>
 
<fc-disclaimer *ngIf="disclaimerSettings.message" [message]="disclaimerSettings.message" [showLink]="disclaimerSettings.showHeaderLink" [scrollLocation]="disclaimerSettings.headerScrollLocation" ></fc-disclaimer>
<fc-quick-links *ngIf="page.quickAccessLinks" [label]="page.quickLinkLabel" [links]="page.quickAccessLinks"></fc-quick-links>
<fc-product-advert [product]="promotedItem" [pageDetails]="page" [ngClass]="promotedClass()" [showHeading]="true" [paging]="paging" ></fc-product-advert>
<div class="fc-results-text" [innerHtml]="resultMessage | markedInline"></div>
<div class="fc-results-background"></div>
<fc-sort [options]="page.tableSettings.sorting" (sortOptionChanged)="onSortChanged($event)"></fc-sort>
<fc-input-switcher [templateName]="inputTemplate" [showVertical]="showVertical" [enableCategoryBrand]="enableCategoryBrand"></fc-input-switcher>
<div class="fc-input-background"></div>
<div class="fc-table-background"></div>
<fc-table-switcher 
    id="fc-product-list"
    [isLoading]="loadingTable" 
    [items]="items"
    [settings]="tableSettings">
</fc-table-switcher>
<button ngClass="fc-btn-load-more" *ngIf="showLoadMore" (click)="loadMoreItems()">Load More</button>
<fc-content-container [page]="page" [analyticsMode]="'Comparison'"></fc-content-container>
<fc-brands-summary [params]="params"></fc-brands-summary>
<fc-compare-footer [pageDetails]="page" [paging]="paging"></fc-compare-footer>