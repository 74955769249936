import { ArticleDTO } from 'src/app/models/article/articleDto';
import { TopicDto } from 'src/app/models/article/TopicDto';
import { CategoryDTO, ContentCategoryDto } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { IPage, SectorDTO } from 'src/app/models/generated/SectorDTO';
import { TableBrandDto, TableProductDto } from 'src/app/models/generated/table/TableItemDTO';
import { ComparisonPage, Utils } from 'src/app/models/shared/Utils';
import { PageDataLayer, ProductDataLayer } from 'src/app/services/analytics.service';

type ComparisonType = CategoryDTO | GroupDTO | BrandDto;
type ArticleType = TopicDto | ArticleDTO ;
type GeneralType = SectorDTO | ProductPageDTO | SectorStaticPageDto ;
type RichContentTypes = SectorDTO | ArticleDTO;

export type PageTypes = ComparisonType | ArticleType | GeneralType;
export type PageType = 'Sector'| 'Category' | 'Group' | 'Brand' | 'BrandList' | 'ProductPage' | 'Article' | 'ArticleList';


export class PageTypeHelper {

    static getPageDataLayer(pageDetails: ProductPageDTO | ComparisonPage): PageDataLayer {
        let page: PageDataLayer;
        if (PageTypeHelper.isProduct(pageDetails)) {
            page = {
                id: pageDetails.product.id.toString(),
                type: 'ProductPage',
                title: PageTypeHelper.getBrandProductTitle(pageDetails.brand, pageDetails.product)
            };
        } else {
            page = {
                id: pageDetails.id.toString(),
                type: 'Category',
                title: pageDetails.title ?? pageDetails.heading
            };

            if (Utils.isBrandComparisonPage(pageDetails)) {
                page.type = 'Brand';
            } else if (Utils.isGroupComparisonPage(pageDetails)) {
                page.type = 'Group';
            } 
        }
        return page;
    }

    static getBrandProductTitle(brand: TableBrandDto, product: ProductDataLayer): string {
        return brand.name + ' - ' + this.getProductTitle(product);
    }

    static getProductTitle(product: ProductDataLayer): string {
        let title = product.title;
        if (product.varianceTitle) {
          title += ' - ' + product.varianceTitle;
        }

        return title;
    }
    static  isProduct(sp: PageTypes | ProductPageDTO | ComparisonPage): sp is ProductPageDTO {
        return (sp as ProductPageDTO).targetMarketDetermination !== undefined;
    }

    static isArticleList(sp: PageTypes): sp is ArticleDTO {
        return (sp as TopicDto).articles !== undefined;
    }

    static isArticle(sp: PageTypes): sp is ArticleDTO {
        return (sp as ArticleDTO).publishedContentId !== undefined;
    }

    static isSector(sp: PageTypes): sp is SectorDTO  {
        return (sp as SectorDTO).comparisonCategories !== undefined;
      }

    static isStaticPage(sp: PageTypes): sp is SectorStaticPageDto {
        return (sp as SectorStaticPageDto).categoryRenderingTemplate !== undefined;
    }

    static isComparisonPage(sp: PageTypes): sp is ComparisonPage {
        return (sp as ComparisonPage).tableSettings !== undefined;
    }
    static isCategory(sp: CategoryDTO | ContentCategoryDto): sp is CategoryDTO {
        return sp.navigation.category.type === 1;
    }

    static isContentCategory(sp: CategoryDTO | ContentCategoryDto): sp is ContentCategoryDto {
        return sp.navigation.category.type === 2;
    }

    static isGroup(sp: GroupDTO | TopicDto): sp is GroupDTO  {
        return sp.navigation.group?.type === 1;
    }
    static isTopic(sp: GroupDTO | TopicDto): sp is TopicDto  {
        return sp.navigation.group?.type === 2;
    }


    static hasRichContainers(sp: PageTypes): sp is RichContentTypes {
        return (sp as IPage).content !== undefined;
    }
}
