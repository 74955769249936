import { Component, Input, OnInit } from '@angular/core';
import { ItemGroupAttributeDTO } from 'src/app/models/generated/item/ItemGroupAttributeDTO';
import { FcTableValuePipe } from '../../../../pipes/fc-table-value.pipe';
import { ToolTipComponent } from '../../../../shared/tool-tip/tool-tip.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'fc-tiers-element-display',
    templateUrl: './tiers-element-display.component.html',
    styles: [],
    standalone: true,
    imports: [NgClass, NgFor, NgIf, ToolTipComponent, FcTableValuePipe]
})
export class TiersElementDisplayComponent {
  @Input() value: ItemGroupAttributeDTO;
  @Input() accentTheme: string;
}
