import { Component, HostBinding, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';
import { MarkedInlinePipe } from 'src/app/pipes/marked.pipe';

@Component({
    selector: 'fc-tool-tip',
    template: `<i *ngIf="message" [ngClass]="classes" [tooltip]="message | markedInline" hide-delay="1000" theme="light" tooltip-class="fc-tooltip-contents"></i> `,
    styleUrls: ['./tool-tip.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, TooltipModule, MarkedInlinePipe]
})
export class ToolTipComponent {
  message: string;
  classes: string[];

  _primary: string;
  @Input() set primary(value: string) {
    this._primary = value?.replace(';', '<br />');
    this.setDetails();
  }

  _secondary: string;
  @Input() set secondary(value: string) {
    this._secondary = value?.replace(';', '<br />');
    this.setDetails();
  }
  @HostBinding('style.--fc-tooltop-margin-left') @Input() marginLeft = '5px';

  private setDetails(): void {
    this.classes = ['fc-info-tooltip'];
    this.message = '';
    if (this._primary) {
      this.message = this._primary;
      this.classes.push('primary');
    } else if (this._secondary) {
      this.message = this._secondary;
      this.classes.push('light');
    }

  }
}
