import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser, NgIf } from '@angular/common';
import { AuthenticationService } from 'src/app/auth/authentication-service.service';
import { FooterSwitcherComponent } from './templates/footers/footer-switcher.component';
import { RouterOutlet } from '@angular/router';
import { TopHeaderSwitcherComponent } from './templates/headers/top-header-switcher.component';

@Component({
    selector: 'fc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, TopHeaderSwitcherComponent, RouterOutlet, FooterSwitcherComponent]
})

export class AppComponent implements OnInit {
  static isBrowser = new BehaviorSubject<boolean>(false);

  isAuthenticated: boolean;
  
  constructor(private authenticationService: AuthenticationService, @Inject(PLATFORM_ID) platformId: any) {
     AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit(): void {
    this.isAuthenticated = this.authenticationService.isAuthenticated();
  }
}
