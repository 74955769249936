import { Data, Route, Router } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { environment } from 'src/environments/environment';
import { Type } from '@angular/core';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
export interface FCRoute {
    slug: string;
    component?: Type<any>;
    resolver: Type<any>;
    outlet?: string;
}

export interface Navigate {
    route: string;
    params: any | null;
}

export class RouteHelper {

    private routes: Route[] = [];


    // static navigate(router: Router, target: string): void {
    //     const paramIndex = target.indexOf('?');
    //     if (paramIndex === -1) {
    //         router.navigate([target]);
    //     } else {
    //         const route = target.substring(0, paramIndex);
    //         const qp: any = {};
    //         new URLSearchParams(target.substring(paramIndex, target.length))
    //             .forEach((value, key) => {
    //                 qp[key] = value;
    //             });

    //         router.navigate([route], { queryParams: qp});
    //     }
    // }


    pushStandard(route: Route): void {
        this.routes.push(route);
    }

    isNotErrorOrNative(native: Route): boolean {
        return native.path !== '**' &&  native.path !== environment.client.masking.defaultRoute;
    }

    push(route: FCRoute, path: string, sectorMask: string | null = null, categoryMask: string | null = null): void {
        // Convert the route to native;
        const native = this.convertRoute(route, path, sectorMask, categoryMask);

        let maskRoot = environment.client.masking.defaultRoute.length > 0;
        if (!environment.client.masking.hasLandingPage) {
            const noLandingPage = Object.assign({}, native);

            if (this.isNotErrorOrNative(native)) {
                this.routes.push(noLandingPage);
            }
            maskRoot = true;
        }

        // if we have a default set, we need to have a masked url set;
        if (maskRoot && this.isNotErrorOrNative(native) ) {
           // const masked = Object.assign({}, native);
            native.path = native.path?.length === 0 ? environment.client.masking.sector : environment.client.masking.sector + '/' + native.path;
            this.routes.push(native);
        } else {
            this.routes.push(native);
        }
    }

    getRoutes(): Route[] {
        return this.routes;
    }

    private convertRoute(route: FCRoute, path: string, sectorMask: string | null = null, categoryMask: string | null = null): Route {
        const native: Route = {
            path,
            canActivate: [AuthGuard],
            runGuardsAndResolvers: 'always',
            component: route.component,
            resolve: { details: route.resolver }, 
            outlet: route.outlet
        };

        // Apply the route data;
        if (categoryMask && sectorMask) {
            native.data = { maskedSector: sectorMask, maskedCategory: categoryMask };
        } else if (sectorMask) {
            native.data = { maskedSector: sectorMask};
        } else {
            native.data = {};
        }

        native.data.routeHit = path;
        return native;
    }  
}
