import { Component, Input, OnInit } from '@angular/core';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { ArticleSideBySideComponent } from '../../templates/article/components/article-sidebyside/article-sidebyside.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fc-display-articles',
    templateUrl: './display-articles.component.html',
    styleUrls: ['./display-articles.component.scss'],
    standalone: true,
    imports: [NgIf, ArticleSideBySideComponent, NgFor]
})
export class DisplayArticlesComponent implements OnInit {
  headlineArticle: ArticleDTO | null = null;
  promotedArticles: ArticleDTO[] = [];
  remainingArticles: ArticleDTO[] = [];

  private _articles: ArticleDTO[];
  get articles(): ArticleDTO[] {
    return this._articles;
  }
  @Input() set articles(value: ArticleDTO[]) {
    this._articles = value;


    if (this._articles.length >= 7) {
      this.headlineArticle = this._articles[0];
      this.promotedArticles = this._articles.slice(1, 4);
      this.remainingArticles = this._articles.slice(4);
    } else if (this._articles.length > 1) {
      this.headlineArticle = this._articles[0];
      this.remainingArticles = this._articles.slice(1);
    } else {
      this.remainingArticles = this._articles;
    }



   
  }

  constructor() { }

  ngOnInit(): void {
  }

}
