import { Component } from '@angular/core';
import { CompareService } from 'src/app/services/compare.service';
import { ItemBaseComponent } from 'src/app/templates/tables/standard/table-base.component';
import { MarkedInlinePipe } from '../../../../pipes/marked.pipe';
import { ColumnScrollContainerComponent } from '../../components/column-scroll-container/column-scroll-container.component';
import { MoreDetailsComponent } from '../../components/more-details/more-details.component';
import { CompareCheckboxComponent } from '../../../../shared/compare/compare-checkbox/compare-checkbox.component';
import { ApplyComponent } from '../../../../shared/buttons/apply/apply.component';
import { TableHeaderComponent } from '../../components/table-header/table-header.component';
import { TableImageComponent } from '../../components/table-image/table-image.component';
import { NgIf } from '@angular/common';
import { PromotedLabelComponent } from '../../components/promoted-label/promoted-label.component';

@Component({
    selector: 'fc-table-scroller-default',
    templateUrl: './table-scroller-default.component.html',
    styleUrls: ['./table-scroller-default.component.scss', '../table-base.scss'],
    standalone: true,
    imports: [PromotedLabelComponent, NgIf, TableImageComponent, TableHeaderComponent, ApplyComponent, CompareCheckboxComponent, MoreDetailsComponent, ColumnScrollContainerComponent, MarkedInlinePipe]
})
export class TableScrollerDefaultComponent extends ItemBaseComponent  {
  constructor(compareService: CompareService) {
    super(compareService);
  }
}
