import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-slider-input',
    templateUrl: './slider-input.component.html',
    styleUrls: [
        './slider-input.component.scss',
        '../../shared-styles/fc-form-control.scss',
        '../../shared-styles/fc-input-group.scss'
    ],
    standalone: true,
    imports: [NgIf, FormsModule]
})
export class SliderInputComponent {
  @Input() suffix: string;
  @Input() prefix: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number = 1;
  @Input() anyText: string;
  @Input() setText: string;

  _value: number;
  get value(): number {
      return this._value;
  }
  @Input() set value(v: number) {
      this._value = v;
      this.updateFormattedValue(v.toString(), false);
  }

  formattedValue: string;
  @Output() valueChange = new EventEmitter<number>();

  onChange(newValue:number) {
    this.value = newValue;
    this.updateFormattedValue(newValue.toString(), false);
    this.valueChange.emit(newValue);
  }

  updateFormattedValue(newValue: string, updateValue: boolean) {
    const num = this.getNumber(newValue);
    if (updateValue && num > 0 && this.value != num) {
      this.onChange(num);
    }
    this.formattedValue = num.toLocaleString();
  }


  onKeyChange(event: KeyboardEvent): void {
    this.updateFormattedValue((event.target as any).value, true);
    (event.target as any).value = this.formattedValue;
  }

  getNumber(formatValue: any): number{
    const arr = formatValue.split('');
    const out = new Array();
    for(var cnt=0;cnt<arr.length;cnt++){
      if(isNaN(arr[cnt])==false || arr[cnt] === '.'){
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(''));
  }

  getPrefixText(): string {
    if (this.anyText && !this.value && this.value != 0) {
      return this.anyText;
    }

    if (this.setText && this.value) {
      return this.setText.replace('{0}', this.value.toString());
    }

    return '';
  }
}
