import { Injectable } from '@angular/core';
import { ArticleDTO } from 'src/app/models/article/articleDto';
import { TopicDto } from 'src/app/models/article/TopicDto';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandCategoryDto } from 'src/app/models/generated/client/brand/BrandCategoryDto';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { GroupOptionDTO } from 'src/app/models/generated/group/GroupOptionDTO';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { SectorDTO } from 'src/app/models/generated/SectorDTO';
import { SortOptionDTO } from 'src/app/models/generated/sort/SortOptionDTO';
import { TableColumnDTO } from 'src/app/models/generated/table/TableColumnDTO';
import { CommercialStatus, PromotedPosition } from 'src/app/models/generated/table/TableWeightingDTO';
import { DropDownListItem } from 'src/app/models/shared/ButtonListStyleSettings';
import { PageType, PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { StandardInputDto } from 'src/app/models/shared/inputs/StandardInputDto';
import { ISelectAdobeTracking } from 'src/app/services/partner-specific/iselect/iSelect-tracking';
import { INewsAuTracking } from 'src/app/services/partner-specific/newsau/newsau-tracking';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { ApplySettings } from 'src/app/shared/buttons/apply/apply.component';
import { CardSettings } from 'src/app/shared/category-card/category-card.component';
import { environment } from 'src/environments/environment';

export type DataTags =  { key: string, value: string }[] | null | undefined;


export interface PageDataLayer {
  id: string;
  title: string;
  type: PageType;
  tip?: string | null;
}

export interface BrandDataLayer  {
  id: number;
  name: string;
}

export interface ProductDataLayer  {
  id: number;
  title: string;

  varianceTitle: string;
  verticalPrimary: string;
  verticalSecondary: string;

  status: CommercialStatus;
  value: number;

}

export interface BasicDataLayer  {
  brand: BrandDataLayer;
  product: ProductDataLayer;
}

export interface TableDataLayer {
  id: string;
  pageSize: number;
  pageNumber: number;
  position?: number;
  promotedPosition?: PromotedPosition | null;
  promotedId?: number | null;
  sortOptionId?: number | null | undefined;
}


export type GoToSitePlacement = 'table' | 'header' | 'compare' | 'product';
export interface GoToSiteDataLayer {
  uid: string;
  placement: GoToSitePlacement;
  sourceSlug: string;
  referrer: string;
}


export interface ArticleLinkTag {
  label: string;
  href: string;
}

export interface IAnalyticsClientService {
  getSessionId(): string | null;
  getClientId(): string | null;

  // Page Views;
  onSectorPageView(pageDetails: SectorDTO, lastClickedElement: any): void;
  onCategoryPageView(pageDetails: CategoryDTO, lastClickedElement: any): void;
  onGroupPageView(pageDetails: GroupDTO, lastClickedElement: any): void;
  onBrandPageView(pageDetails: BrandDto, lastClickedElement: any): void;
  onProductPageView(product: ProductPageDTO, lastClickedElement: any): void;
  onArticleListPageView(articles: TopicDto, lastClickedElement: any): void;
  onArticlePageView(article: ArticleDTO, lastClickedElement: any): void;
  onStaticPageView(disclaimer: SectorStaticPageDto, lastClickedElement: any): void;

  // Data Tags;
  getCategoryDataTags(category: CardSettings): DataTags;
  getSortDataTags(item: DropDownListItem<SortOptionDTO>): DataTags;
  getGroupDataTags(item: DropDownListItem<GroupOptionDTO>): DataTags;
  getGoToSiteDataTags(settings: ApplySettings): DataTags;
  getArticleLinkTags(label: string, href: string): DataTags;
  getComparisonLinkTags(label: string, href: string): DataTags;

  // Events;
  onInitialTableLoad(table: TableDataLayer): void;
  onCompare(navigation: NavigationDto,  items: BasicDataLayer[], table: TableDataLayer, lastClickedElement: any, columns: TableColumnDTO[] | null): void;
  onLoadMore(table: TableDataLayer): void;
  onUpdateResults(table: TableDataLayer, inputs: StandardInputDto | undefined): void;
  onMoreDetails(settings: ApplySettings, columns: TableColumnDTO[] | null): void;
  onItemCompareChecked(table: TableDataLayer): void;
  onGoToSite(settings: ApplySettings, clientClickId: string | null, columns: TableColumnDTO[] | null): void;
}

export interface FCWindow extends Window {
  fc_dl: any[];
}

export type AnalyticsModeType = 'None' | 'Comparison' | 'Article';


@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  _nativeElement: any;

  private partnerSpecific: IAnalyticsClientService | null;

  constructor(private storage: SessionStorageService, iSelect: ISelectAdobeTracking, newsau: INewsAuTracking) {
    this.partnerSpecific = null;
    if (environment.client.code === 'iselect') {
      this.partnerSpecific = iSelect;
    } else if (environment.client.code === 'newsau'){
      this.partnerSpecific = newsau;
    }

    if (environment.environment !== 'production') {
      this.partnerSpecific = null;
    }

  }

  private onPageInit(pageDetails: PageDataLayer): void {
    const response: any = {
      event: 'fc.pageInit'
    };

    for (const key in pageDetails) {
      if (pageDetails.hasOwnProperty(key)) {
        let value = (pageDetails as any)[key];
        if (isNaN(value as number)) {
          value = `${value}`;
        }
        response[`page.${key}`] = value;
      }
    }
    response[`page.tip`] = this.storage.getConfiguration().tipEnvironment;
    if (!((window as any) as FCWindow).fc_dl) {
      ((window as any) as FCWindow).fc_dl = [];
    }
    ((window as any) as FCWindow).fc_dl.push(response);
  }


  setLastClickedElement(nativeElement: any): void {
    this._nativeElement = nativeElement;
  }

  getSessionId(): string | null {
    return this.partnerSpecific?.getSessionId() || null;
  }
  getClientId(): string | null {
    return this.partnerSpecific?.getClientId() || null;
  }

  onSectorPageView(pageDetails: SectorDTO): void {
    this.onPageInit({ id: pageDetails.id.toString(), title: pageDetails.title, type: 'Sector'});
    this.partnerSpecific?.onSectorPageView(pageDetails, this._nativeElement);
  }

  onCategoryPageView(pageDetails: CategoryDTO): void {
    this.onPageInit({ id: pageDetails.id.toString(), title: pageDetails.title, type: 'Category'});
    this.partnerSpecific?.onCategoryPageView(pageDetails, this._nativeElement);
  }

  onGroupPageView(pageDetails: GroupDTO): void {
    this.onPageInit({ id: pageDetails.id.toString(), title: pageDetails.title, type: 'Group'});
    this.partnerSpecific?.onGroupPageView(pageDetails, this._nativeElement);
  }

  onBrandListView(allBrands: BrandCategoryDto): void {
    this.onPageInit({ id: allBrands.id.toString(), title: allBrands.title, type: 'BrandList'});
  }
  onBrandPageView(pageDetails: BrandDto): void {
    this.onPageInit({ id: pageDetails.id.toString(), title: pageDetails.heading, type: 'Brand'});
    this.partnerSpecific?.onBrandPageView(pageDetails, this._nativeElement);
  }

  onProductPageView(product: ProductPageDTO): void {
    this.onPageInit({ id: product.product.id.toString(), title: PageTypeHelper.getBrandProductTitle(product.brand, product.product), type: 'ProductPage'});
    this.partnerSpecific?.onProductPageView(product, this._nativeElement);
  }

  onArticleListPageView(articles: TopicDto): void {
    this.onPageInit({ id: articles.id.toString(), title: articles.heading, type: 'ArticleList'});
    this.partnerSpecific?.onArticleListPageView(articles, this._nativeElement);
  }

  onArticlePageView(article: ArticleDTO): void {
    this.onPageInit({ id: article.shortUid, title: article.heading, type: 'Article'});
    this.partnerSpecific?.onArticlePageView(article, this._nativeElement);
  }

  onStaticPageView(staticPage: SectorStaticPageDto): void {
    this.partnerSpecific?.onStaticPageView(staticPage, this._nativeElement);
  }

  getGoToSiteDataTags(settings: ApplySettings): DataTags {
    return this.partnerSpecific?.getGoToSiteDataTags(settings);
  }

  getCategoryDataTags(category: CardSettings): DataTags {
    return this.partnerSpecific?.getCategoryDataTags(category);
  }

  getArticleLinkTags(label: string, href: string): DataTags {
    return this.partnerSpecific?.getArticleLinkTags(label, href);
  }

  getSortDataTags(item: DropDownListItem<SortOptionDTO>): DataTags {
    return this.partnerSpecific?.getSortDataTags(item);
  }

  getGroupDataTags(item: DropDownListItem<GroupOptionDTO>): DataTags {
    return this.partnerSpecific?.getGroupDataTags(item);
  }

  getAnalyticsModeTags(analyticsMode: AnalyticsModeType, data: ArticleLinkTag): DataTags {
    let tags: DataTags = null;
    switch (analyticsMode) {
      case 'Article':
        tags = this.partnerSpecific?.getArticleLinkTags(data.label, data.href);
        break;
      case 'Comparison':
        tags = this.partnerSpecific?.getComparisonLinkTags(data.label, data.href);
        break;
    }
    return tags;
  }


  onCompare(navigation: NavigationDto, items: BasicDataLayer[], table: TableDataLayer, columns: TableColumnDTO[] | null): void {
    this.partnerSpecific?.onCompare(navigation, items, table, this._nativeElement, columns);
  }

  onInitialTableLoad(table: TableDataLayer): void {
    this.partnerSpecific?.onInitialTableLoad(table);
  }

  onLoadMore(table: TableDataLayer): void {
    this.partnerSpecific?.onLoadMore(table);
  }

  onUpdateResults(table: TableDataLayer, inputs: StandardInputDto | undefined): void {
    this.partnerSpecific?.onUpdateResults(table, inputs);
  }

  onMoreDetails(settings: ApplySettings, columns: TableColumnDTO[] | null): void {
    this.partnerSpecific?.onMoreDetails(settings, columns);
  }

  onItemCompareChecked(table: TableDataLayer): void {
    this.partnerSpecific?.onItemCompareChecked(table);
  }

  onGoToSite(settings: ApplySettings, clientClickId: string | null, columns: TableColumnDTO[] | null): void {
    this.partnerSpecific?.onGoToSite(settings, clientClickId, columns);
  }

  setDataTags(dataTags: DataTags, element: HTMLElement): void {
    if (dataTags && dataTags.length > 0) {
      for (const tag of dataTags) {
        element.setAttribute(tag.key, tag.value);
      }
    }
  }
}
