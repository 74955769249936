import { Component, Input, OnInit } from '@angular/core';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { ComparisonPage, Utils } from 'src/app/models/shared/Utils';
import { IconComponent } from '../../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-product-placeholder',
    templateUrl: './product-placeholder.component.html',
    styleUrls: ['./product-placeholder.component.scss'],
    standalone: true,
    imports: [NgIf, IconComponent]
})
export class ProductPlaceholderComponent {
  icon: string;
  imageSrc: string;


  private _pageDetails: ComparisonPage | ProductPageDTO;
  @Input() set pageDetails(pageDetails: ComparisonPage | ProductPageDTO) {
    this._pageDetails = pageDetails;
    if (PageTypeHelper.isComparisonPage(pageDetails)) {
      this.icon = pageDetails.icon;

      if (Utils.isBrandComparisonPage(pageDetails)) {
        this.imageSrc = pageDetails.imageSrc;
      }
    }
  }
  get pageDetails(): ComparisonPage | ProductPageDTO {
    return this._pageDetails;
  }
}
