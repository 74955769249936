import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MenuType, MenuUtils } from 'src/app/models/shared/helpers/MenuHelper';
import { FCRouterLinkDirective } from '../../../../directives/fc-router-link.directive';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { CountryPickerComponent } from 'src/app/shared/country-picker/country-picker.component';
import { PoweredByLogoComponent } from 'src/app/templates/headers/components/powered-by-logo/powered-by-logo.component';

export interface MobileMenuSettings {
  level: number;
  items: MenuType[];
  active: MenuType | null;
  previous: MenuType | null;
}

export interface MobileMenuChangeEvent {
  isBack: boolean;
  next: MenuType | null;
  previous: MenuType | null;
}


@Component({
    selector: 'fc-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    standalone: true,
    imports: [NgIf, FCRouterLinkDirective, NgFor, NgTemplateOutlet, CountryPickerComponent, PoweredByLogoComponent]
})
export class MobileMenuComponent {
  @Input() settings: MobileMenuSettings;

  @Output() onItemExpanded  = new EventEmitter<MobileMenuChangeEvent>();
  @Output() onMenuClosed  = new EventEmitter()

  
  @ViewChild('expandable') expandable: TemplateRef<any>;
  @ViewChild('link') link: TemplateRef<any>;
  @ViewChild('visual') visual: TemplateRef<any>;

  expandItem(next: MenuType | null, isBack: boolean) {
    if (isBack) {
      this.onItemExpanded.emit({ isBack, previous: this.settings.previous, next: null});
    } else {
      this.onItemExpanded.emit({ isBack, next, previous: this.settings.active,});
    }
  }

  getOutlet(item: MenuType) : TemplateRef<any> {
    if (MenuUtils.isPartnerMenu(item)) {
      return this.expandable;
    } else if (MenuUtils.isMenuColumn(item)) {
      return this.expandable;
    }

    return MenuUtils.isVisialMenu(item) ? this.visual : this.link;



    
  }
}
