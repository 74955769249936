import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPage } from 'src/app/models/generated/SectorDTO';
import { Utils, ComparisonParams } from 'src/app/models/shared/Utils';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { MarkedInlinePipe } from '../../pipes/marked.pipe';
import { ContentContainerComponent } from '../../templates/content/content-container.component';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-content-category',
    templateUrl: './content-category.component.html',
    styleUrls: ['./content-category.component.scss'],
    standalone: true,
    imports: [NgIf, DisclaimerComponent, ContentContainerComponent, MarkedInlinePipe]
})
export class ContentCategoryComponent implements OnInit {
  showGenericLink = environment.client.disclaimers.showGenericLink;
  page: IPage;
  params: ComparisonParams;
  disclosure: {
    message: string,
    route: string;
  };
  subscriptions: any[] = [];

  constructor(activatedRoute: ActivatedRoute, public appConfig: AppConfigService, private seoService: SeoService) { 
    this.subscriptions.push(activatedRoute.data.subscribe(data => {
      this.page = data.details;
      this.params = Utils.GetParams<ComparisonParams>(activatedRoute.snapshot);
    }));

  }

  ngOnInit(): void {
    this.disclosure = {
      message: this.appConfig.legalDisclosureGeneric,
      route: this.seoService.getAdvertiserDisclosurePage()
    };
  }
}
