import { Component, HostBinding, OnInit } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { ContentLinkDirective } from '../../../directives/content-link.directive';
import { NgClass, NgFor } from '@angular/common';
import { ArticleHeaderComponent } from '../../content/utils/article-header/article-header.component';

@Component({
    selector: 'fc-article-headline-only',
    templateUrl: './article-headline-only.component.html',
    styleUrls: ['./article-headline-only.component.scss', '../components/article-styling.scss'],
    standalone: true,
    imports: [ArticleHeaderComponent, NgClass, NgFor, ContentLinkDirective]
})
export class ArticleHeadlineOnlyComponent extends ContentBaseComponent {
  @HostBinding('style.--fc-horizontal-colCount') colCount = 3;

  onContentChanged(): void {
    this.colCount = this.content.components.length / 2;
  }
}
