import { Component, ComponentFactoryResolver, Directive, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { ContentService } from 'src/app/services/api/content-service.ts.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SeoService } from 'src/app/services/seo.service';
import { DefaultFooterComponent } from 'src/app/templates/footers/default-footer/default-footer.component';
import { ExecutivetravellerFooterComponent } from 'src/app/templates/footers/executivetraveller-footer/executivetraveller-footer.component';
import { IselectFooterComponent } from 'src/app/templates/footers/iselect-footer/iselect-footer.component';
import { NewcorpFooterComponent } from 'src/app/templates/footers/newcorp-footer/newcorp-footer.component';
import { OthFooterComponent } from 'src/app/templates/footers/oth-footer/oth-footer.component';
import { SavrrFooterComponent } from 'src/app/templates/footers/savrr-footer/savrr-footer.component';
import { environment } from 'src/environments/environment';
import { FooterTypeName } from 'src/environments/environmentDefinitions';
import { MarkedPipe } from '../../pipes/marked.pipe';
import { FCRouterLinkDirective } from '../../directives/fc-router-link.directive';
import { NgFor, NgIf } from '@angular/common';
import { PoweredByLogoComponent } from '../headers/components/powered-by-logo/powered-by-logo.component';

// Header Component
// tslint:disable-next-line: no-empty-interface
export interface FooterComponent { 
  links: SectorStaticFooterDto[]; 
}

// Directive Used to inject item at appriate time.
@Directive({
    selector: '[fcFooterHost]',
    standalone: true
})
export class FooterDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
    selector: 'fc-footer-switcher',
    templateUrl: './footer-switcher.component.html',
    styleUrls: ['./footer-switcher.component.scss'],
    standalone: true,
    imports: [
        PoweredByLogoComponent,
        NgFor,
        NgIf,
        FCRouterLinkDirective,
        FooterDirective,
        MarkedPipe,
    ],
})
export class FooterSwitcherComponent implements OnInit {
  availableTemplates: { [id: string]: Type<FooterComponent>; } = {
    'fc-newcorp-footer': NewcorpFooterComponent,
    'fc-iselect-footer': IselectFooterComponent,
    'fc-executivetraveller-footer': ExecutivetravellerFooterComponent,
    'fc-default-footer': DefaultFooterComponent,
    'fc-oth-footer': OthFooterComponent,
    'fc-savrr-footer': SavrrFooterComponent, 
  };

  disclaimersTarget = environment.client.disclaimers.target;
  links: SectorStaticFooterDto[];
  disclaimer: string;
  showDisclaimer: boolean = environment.client.code  !== 'savrr-gb';
  @ViewChild(FooterDirective, { static: true }) fcFooterHost!: FooterDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, public seoService: SeoService,
              public contentService: ContentService, private appConfig: AppConfigService) { }

  ngOnInit(): void {

    this.contentService.GetStaticFooterLinks().subscribe(x => {
      this.disclaimer = this.appConfig.legalDisclosureFooter;
      this.links = x;

      this.renderComponent(environment.client.setup.footer);

    });
  }

  renderComponent(templateName: FooterTypeName): void {
    const viewContainerRef = this.fcFooterHost.viewContainerRef;
    viewContainerRef.clear();

    if (templateName) {
      const component = this.availableTemplates[templateName];
      if (component) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        var x = viewContainerRef.createComponent<FooterComponent>(factory);
        x.instance.links = this.links;
      }


    }
  }
}
