import { VerticalSummaryDto } from 'src/app/models/dto/VerticalSummaryDto';
import { ContentComponentDto, ContentImageDto, ContentScriptsDto, ContentTopicDto } from 'src/app/models/generated/content/ContentComponentDto';
import { ContainerComparisonTableSettingsDto, TableSettingsDto } from 'src/app/models/generated/sort/SortDTO';

export enum ContentTagDisplay {
    none,
    category,
    topic
}
export interface ContentContainerDto extends ContentImageDto {
    id: number;
    heading: string;
    headingSize: number;
    iconSrc: string;
    summary: string;
    footer: string;
    template: string;
    accentTheme: string;
    components: ContentComponentDto[];

    structureType: number;
    backgroundColour: string;

    cols: number;
    rows: number;
    x: number;
    y: number;

    image: ContentImageDto;
    verticals: VerticalSummaryDto | null;
    externalUrl: string | null;
    internalRoute: string | null;
    internalRouteText: string | null;

    comparisonTableSettings: ContainerComparisonTableSettingsDto;

    tagDisplay: ContentTagDisplay;
    tags: ContentTopicDto[];
    scripts: ContentScriptsDto[];


}
