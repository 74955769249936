import { LocationStrategy } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Directive({
    selector: 'a[FCRouterLink], article[FCRouterLink]',
    standalone: true
})
export class FCRouterLinkDirective extends RouterLink implements OnChanges,OnDestroy {
  isSameDomain = false;
  @Input() set FCRouterLink(commands: string) {
    if (Utils.isSameDomain(commands)) {
      this.routerLink = Utils.getMaskedCommand(commands);
      this.isSameDomain = true;
    } else {
      this.href = commands;
      this.isSameDomain = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSameDomain) {
      super.ngOnChanges(changes);
    }
  }


  constructor(router: Router, route: ActivatedRoute, locationStrategy: LocationStrategy,  private elRef: ElementRef, private analyticsService: AnalyticsService, renderer: Renderer2 ) {
    super(router, route,null, renderer, elRef, locationStrategy);
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey', '$event.altKey'])
  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean, altKey: boolean): boolean {
    // clone the checks being made in super()
    this.analyticsService.setLastClickedElement(this.elRef.nativeElement);

    if (button !== 0 || ctrlKey || metaKey || shiftKey || altKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    return super.onClick(button, ctrlKey, shiftKey, altKey, metaKey);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
