
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryDTO, ContentCategoryDto } from 'src/app/models/generated/category/CategoryDTO';
import { CategorySummaryDto } from 'src/app/models/generated/category/CategorySummaryDTO';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({providedIn: 'root' })
export class CategoryService {
  url =  AppConfigService.AppEnvironment.apiEndPoint + '/Category';

  constructor(private http: HttpClient) { }

  GetDetails(sector: string, category: string): Observable<CategoryDTO | ContentCategoryDto> {
    return this.http.get<CategoryDTO | ContentCategoryDto>(`${this.url}/${sector}/${category}`);
  }

  GetSummary(categoryId: number): Observable<CategorySummaryDto> {
    return this.http.get<CategorySummaryDto>(`${this.url}/Summary/${categoryId}`);
  }
}
