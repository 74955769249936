import { Component, Input, OnInit } from '@angular/core';
import { CategoryDTO } from 'src/app/models/generated/category/CategoryDTO';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { GroupDTO } from 'src/app/models/generated/group/GroupDTO';
import { ProductPageDTO } from 'src/app/models/generated/product/ProductDetailsDTO';
import { OtherGroupsSettings } from 'src/app/models/shared/HeaderTemplateSettings';
import { GroupDropdownComponent } from '../group-dropdown/group-dropdown.component';
import { ProductActionComponent } from '../../../views/product/product-action/product-action.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fc-group-switcher',
    templateUrl: './group-switcher.component.html',
    styles: [],
    standalone: true,
    imports: [NgIf, ProductActionComponent, GroupDropdownComponent]
})
export class GroupSwitcherComponent implements OnInit {

  @Input() groupSettings: OtherGroupsSettings;
  @Input() pageDetails: CategoryDTO | GroupDTO | BrandDto | ProductPageDTO;

  
  constructor() { }

  ngOnInit(): void {


  }

  hasGroups() {
    return this.groupSettings?.group?.options?.length > 0;
}

}
