export enum PromotedPosition {
    Headline = -1,
    P1 = 1,
    P2 = 2,
    P3 = 3,
    P4 = 4,
    Default = 10
}

export enum CommercialStatus  {
    None = 0,
    Brand = 10,
    Product = 20,
}

export class TableWeightingDTO {
    promotedId: number;
    defaultPosition: PromotedPosition;
    promotedPosition: PromotedPosition | null;
    position: number;
}
