<div class="svr-links" *ngIf="details">
    <span [innerHTML]="details.label"></span>
    <ul class="svr-footer">
        <li *ngFor="let l of details.links;">
            <a [href]="l.route" [title]="'Footer link to Savrr ' + l.label + 'Guide'" [innerHTML]="l.label"></a>
        </li>
    </ul>
</div>
<div class="svr-disclaimer" >
    <div class="svr-primary" [innerHTML]="details?.disclosure?.primary"></div>
    <label (click)="toggleSecondary()">See more</label>
    <div class="svr-secondary" [class.show]="isSecondaryVisible"  [innerHTML]="details?.disclosure?.secondary"></div>
</div>