<div class="fc-scroll-viewport">
   <div class="fc-scroll-grid">
      <div class="fc-scroll-container" *ngFor="let c of stack.columns; let i = index">
         <fc-column [column]="c" [stackNumber]="0" [index]="i"></fc-column>
      </div>
      <div class="fc-scroll-container" *ngIf="stack.stackOne.length > 0">
         <fc-column *ngFor="let c of stack.stackOne; let i = index"  [column]="c" [stackNumber]="1" [index]="i"></fc-column>
      </div>
   </div>
   <div class="fc-scroll-left" (click)="onScroll('left');"></div>
   <div class="fc-scroll-right" (click)="onScroll('right');"></div>
</div>