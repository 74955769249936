import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandDto } from 'src/app/models/generated/client/brand/BrandDto';
import { NavigationDto } from 'src/app/models/generated/navigation/NavigationDto';
import { BrandsParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { BrandService } from 'src/app/services/api/brand.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BrandProductsResolver  {
  constructor(private brandService: BrandService, private breadcrumbService: BreadcrumbService, private seaService: SeoService, private analyticsService: AnalyticsService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<BrandDto> {
    const params = Utils.GetParams<BrandsParams>(route);
    return this.brandService.GetDetails(params.sector, params.category, params.brand).pipe(map(brand => {
      const nav  = brand.navigation;
      try {
        this.applySEOFormatting(nav, brand);
        this.seaService.setStandard(params.sector, params.category, brand, nav.root, null);
        this.breadcrumbService.setBreadCrumb(nav, nav.sector, nav.category, { url: environment.client.masking.brands, title: this.brandTitle(), excludeAble: false},  brand.heading + ' ' + brand.navigation.category.title);
        this.analyticsService.onBrandPageView(brand);
      } catch (error) {
        console.error(`Unable to resolve brand (${params.sector}/${params.category}/${params.brand}):`, error);
      }
      return brand;
    }));
  }

  brandTitle() {
    return environment.client.masking.brands.charAt(0).toUpperCase() + environment.client.masking.brands.slice(1);
  }

  applySEOFormatting(navigation: NavigationDto, brand: BrandDto): void {
    brand.canonicalLink = brand.canonicalLink.replace('[category.cannonical_link]', navigation.category.canonicalLink);
  }
}
