import { CommonModule, NgOptimizedImage } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, mergeApplicationConfig } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, provideClientHydration } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { AppRoutingModule } from "src/app/app-routing.module";
import { baseConfig } from "src/app/app.config.shared";
import { ClientTemplateAppInitializer } from "src/app/services/app_initializer/client-template-app-initializer";
import { BrowserStateInterceptor } from "src/app/services/interceptor/browerstate.interceptor";

const clientConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(CommonModule, BrowserModule, FormsModule, ReactiveFormsModule, AppRoutingModule, ContentLoaderModule, NgOptimizedImage),
        provideClientHydration(),
        provideAnimations(),
        { provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
        { provide: APP_INITIALIZER, multi: true, deps: [ClientTemplateAppInitializer], useFactory: (i: ClientTemplateAppInitializer) => i.onStart(true) },
    ]
}

export const config = mergeApplicationConfig(baseConfig, clientConfig);