import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryDTO, ContentCategoryDto } from 'src/app/models/generated/category/CategoryDTO';
import { PageTypeHelper } from 'src/app/models/shared/helpers/PageTypesHelper';
import { CategoryParams, Utils } from 'src/app/models/shared/Utils';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CategoryService } from 'src/app/services/api/category.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { CompareService } from 'src/app/services/compare.service';
import { SeoService } from 'src/app/services/seo.service';

@Injectable({ providedIn: 'root' })
export class CategoryResolver  {
 constructor(private categoryService: CategoryService, private seoService: SeoService,
             private breadcrumbService: BreadcrumbService, private analyticsService: AnalyticsService, private compareService: CompareService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<CategoryDTO | ContentCategoryDto> {
    const params = Utils.GetParams<CategoryParams>(route);
    this.compareService.removeAllItems();

    return this.categoryService.GetDetails(params.sector, params.category)
    .pipe(map(category => {
      try {
        this.breadcrumbService.setBreadCrumb(category.navigation, category.navigation.sector, category.navigation.category);
        this.seoService.setStandard(params.sector, params.category, category.navigation.category, category.navigation.root, null);

        if (route.routeConfig) {
          if (PageTypeHelper.isCategory(category)) {
            this.analyticsService.onCategoryPageView(category);
          } else if (PageTypeHelper.isContentCategory(category)) {

          }
        }
      } catch(error) { 
        console.error(`Unable to resolve category (${params.sector}/${params.category}):`, error);
      }
      return category;
    }));
  }
}