import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PartnerSeoDto } from 'src/app/models/generated/seo/PartnerSeoDto';
import { StandardSeoDto } from 'src/app/models/generated/seo/StandardSeoDto';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SeoService {
  private sector: string | null;
  private category: string | null;

  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private doc: any) {}

  getDisclaimerPage(slug: string): string {
    if (this.category && environment.client.disclaimers.includeCategory) {
      return `/${this.sector}/${this.category}/disclaimers/${slug}`;
    }
    return `/${this.sector}/disclaimers/${slug}`;
  }

  getClickPage(): string {
    if (this.category && environment.client.disclaimers.includeCategory) {
      return `/${this.sector}/${this.category}/clk`;
    }
    return `/${this.sector}/clk`;
  }

  getAuthorsPage(slug: string, externalUrl: string | null): string {
    if (externalUrl) {
      return externalUrl;
    }


    if (this.category && environment.client.disclaimers.includeCategory) {
      return `/${this.sector}/${this.category}/authors/${slug}`;
    }

    return `/${this.sector}/authors/${slug}`;
  }


  getAdvertiserDisclosurePage(): string {
    return this.getDisclaimerPage('advertiser-disclosure');
  }

  getFactCheckPage(): string {
    return this.getDisclaimerPage('fact-checked');
  }


  setTitle(newTitle: string): void {
    this.title.setTitle( newTitle );
  }

  setBasicNoIndex(metaTitle: string, sector: string, category: string | null, robotsTag: string = 'noIndex, nofollow', canonical: string | null = null): void {
    this.sector = sector;
    this.category = category;

    this.setTitle(metaTitle);
    this.meta.updateTag({name: 'robots', content: robotsTag});
    this.updateLinkUrl('icon', environment.client.favicon);

    if (canonical) {
      this.updateLinkUrl('canonical', canonical);
    }
  }

  setStandard(sector: string, category: string | null, standardSeo: StandardSeoDto, partnerSeo: PartnerSeoDto, primaryImageSrc: string | null): void {
    this.sector = sector;
    this.category = category;

    this.setTitle(standardSeo.metaTitle);
    this.updateLinkUrl('canonical', standardSeo.canonicalLink);
    this.updateLinkUrl('icon', environment.client.favicon);

    // Standard
    this.meta.updateTag({name: 'description', content: standardSeo.metaDescription});
    this.meta.updateTag({name: 'robots', content: standardSeo.robotsTag});

    this.meta.updateTag({name: 'og:title', content: standardSeo.metaTitle});
    this.meta.updateTag({name: 'og:type', content: 'Website'});
    this.meta.updateTag({name: 'og:description', content: standardSeo.metaDescription});

    this.meta.updateTag({name: 'og:description', content: standardSeo.metaDescription});
    this.meta.updateTag({name: 'og:site_name', content: partnerSeo.siteName});
    this.meta.updateTag({name: 'og:url', content: standardSeo.canonicalLink});

    this.meta.updateTag({name: 'twitter:title', content: standardSeo.metaTitle});
    this.meta.updateTag({name: 'twitter:description', content: standardSeo.metaDescription});
    this.meta.updateTag({name: 'twitter:site', content: partnerSeo.twitterHandle});

    if (!primaryImageSrc) {
      this.meta.updateTag({name: 'og:image', content: partnerSeo.logoSrc});
      this.meta.updateTag({name: 'twitter:image', content: partnerSeo.logoSrc});
    } else {
      this.meta.updateTag({name: 'og:image', content: primaryImageSrc});
      this.meta.updateTag({name: 'twitter:image', content: primaryImageSrc});
    }
  }

  updateLinkUrl(linkRel: string, url: string): void{
    const head = this.doc.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.doc.querySelector(`link[rel='${linkRel}']`) || null;
    if (!element) {
      element = this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', linkRel);
    element.setAttribute('href', url);
  }

  // getClickEventSeo(): ClickEventSeoDto {
  //   return {
  //     Ip: null,
  //     Rfr: this.doc?.referrer
  //   };
  // }
}
