import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { NgIf, NgStyle, DecimalPipe } from '@angular/common';

@Component({
    selector: 'fc-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, DecimalPipe]
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number;
  @Input() numberOfReviews: number;
  @Input() compact: boolean = true;

  @Input() url:string;

   message = AppConfigService.PartnerAppSettings.reviews?.message;

  constructor() { }

  ngOnInit(): void {
  }


  getWidthPercentage() {
    var perc = 100.0 / 5.0 * this.rating; 
    return  { width: Math.round(perc) + '%' };
  }

}
