import { Params } from '@angular/router';
import { DefaultInputElementDto } from './DefaultInputElementDto';

export interface  DefaultInputSettings{
    elements: DefaultInputElementDto[];
    queryParams: Params;
}

export type onInputSetCallback<T> = null | ((a: T | null) => void);

export class DefaultInputUtils {

    private static GenericFind<T>(settings: DefaultInputSettings, key: string, parseValue: (c: string) => T, onInputSet: onInputSetCallback<T> = null): T | null {
        let defaultValue: T | null = null;
        const e = settings.elements?.find(x => x.key === key);
        if (e) {
            defaultValue = parseValue(e.value);
        }

        if (settings.queryParams && settings.queryParams[key]) {
            if (onInputSet) {
                onInputSet(defaultValue);
            }
            return parseValue(settings.queryParams[key]);
        }
        return defaultValue;
    }

    private static parseToBool(value: string): boolean {
        let result = false;
        switch (value){
            case 'true':
            case 'True':
            case '1':
                result = true;
        }

        return result;
    }

    public static FindElementValue(settings: DefaultInputSettings, key: string, onInputSet: onInputSetCallback<number> = null): number | null {
        return this.GenericFind<number>(settings, key, (x) => +x, onInputSet);
    }

    public static FindElementBoolValue(settings: DefaultInputSettings, key: string, onInputSet: onInputSetCallback<boolean> = null): boolean | null {
        return this.GenericFind<boolean>(settings, key, (x) => this.parseToBool(x), onInputSet);
    }

    public static FindElementEnabled(elements: DefaultInputElementDto[], key: string): boolean {
        const e  = elements?.find(x => x.key === key);
        return (!e) ? false : !!e.disableInput;
    }
}
