import { Component, OnInit } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { MarkedPipe } from '../../../pipes/marked.pipe';
import { ContentLinkDirective } from '../../../directives/content-link.directive';
import { IconComponent } from '../../../shared/icon/icon.component';
import { NgFor, NgIf } from '@angular/common';
import { ContentHeaderSummaryComponent } from '../utils/content-header-summary/content-header-summary.component';

@Component({
    selector: 'fc-content-grid',
    templateUrl: './content-grid.component.html',
    styleUrls: ['./content-grid.component.scss'],
    standalone: true,
    imports: [ContentHeaderSummaryComponent, NgFor, NgIf, IconComponent, ContentLinkDirective, MarkedPipe]
})
export class ContentGridComponent  extends ContentBaseComponent {



}
