import { Component } from '@angular/core';
import { MortgagesInputComponent } from 'src/app/shared/filters/mortgages-input/mortgages-input.component';
import { OptionInputComponent } from '../components/option-input/option-input.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { SliderInputComponent } from '../components/slider-input/slider-input.component';
import { InputColumnComponent } from '../components/input-column/input-column.component';

@Component({
    selector: 'fc-mortgages-detailed-input',
    templateUrl: './mortgages-detailed-input.component.html',
    styleUrls: [
        '../shared-styles/fc-form-control.scss',
        '../shared-styles/fc-input-layout.scss',
    ],
    standalone: true,
    imports: [InputColumnComponent, SliderInputComponent, NgIf, FormsModule, NgFor, OptionInputComponent]
})
export class MortgagesDetailedInputComponent extends MortgagesInputComponent {

  getMax(): number {
    return (this.values.estimatedPropertyPrice) ? this.values.estimatedPropertyPrice - 10000 : 10000000;
  }

  reCalculateValue(value: number, element: 'estimatedPropertyPrice' | 'depositAmount' | 'depositRate' | 'borrowingAmount'): void {
    this.values[element] = value;

    if (element === 'estimatedPropertyPrice' || element === 'depositAmount') {
      // Need to recalculate the depositRate and borrowingAmount;
      if (this.values.estimatedPropertyPrice && this.values.depositAmount) {
        this.values.borrowingAmount = Math.max(this.values.estimatedPropertyPrice - this.values.depositAmount, 0);
        this.values.depositRate = Math.max(+((100 / this.values.estimatedPropertyPrice) * this.values.depositAmount).toFixed(2));
      }
    } else if (element === 'depositRate') {
      // Need to recalculate the depositAmount and borrowingAmount;
      if (this.values.depositAmount != null && this.values.borrowingAmount != null && this.values.estimatedPropertyPrice != null && this.values.depositRate != null) {
        this.values.depositAmount = Math.max((this.values.depositRate / 100) * this.values.estimatedPropertyPrice, 0);
        this.values.borrowingAmount = Math.max(this.values.estimatedPropertyPrice - this.values.depositAmount, 0);
      }
    } else if (element === 'borrowingAmount') {
      // Need to recalculate the depositAmount and depositRate;
      if (this.values.depositAmount != null && this.values.borrowingAmount != null && this.values.estimatedPropertyPrice != null && this.values.depositRate != null) {
        this.values.depositAmount = Math.max(this.values.estimatedPropertyPrice - this.values.borrowingAmount, 0);
        this.values.depositRate = Math.max(+((100 / this.values.estimatedPropertyPrice) * this.values.depositAmount).toFixed(2), 0);
      }
    }
  }
}
