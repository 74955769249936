import { Component, OnInit } from '@angular/core';
import { ContentBaseComponent } from 'src/app/templates/content/content-base.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';

@Component({
    selector: 'fc-content-cta-card',
    templateUrl: './content-cta-card.component.html',
    styleUrls: ['./content-cta-card.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective]
})
export class ContentCtaCardComponent extends ContentBaseComponent {
}
