import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/services/api/navigation.service';
import { PartnerSiteService } from 'src/app/services/api/partner-site.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ScriptService } from 'src/app/services/script.service';


@Injectable({ providedIn: 'root' })
export class ClientTemplateAppInitializer {
  private isBrowser: boolean;

  constructor(private appConfig: AppConfigService, private injector: Injector,
              @Inject(PLATFORM_ID) platformId: any, private navigationService: NavigationService,
              private scriptService: ScriptService, private partnerSiteService: PartnerSiteService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  onStart(isClient: boolean): () => Subscription {
    return () => {
      const startupValue = this.isBrowser ? document.location.hostname : this.injector.get('host');

      const settings = this.appConfig.LoadStartupSettings(startupValue);

      this.navigationService.onStart(settings.apiEndPoint);
      this.scriptService.onStart(settings.apiEndPoint);

      if (isClient) {
          this.scriptService.loadTemplates(settings.environment);
      }

      return this.partnerSiteService
        .fetchTemplate(settings.apiEndPoint)
        .subscribe(x => {
          this.appConfig.legalDisclosureHeader = x.legalDisclosureHeader;
          this.appConfig.legalDisclosureGeneric = x.legalDisclosureGeneric;
          this.appConfig.legalDisclosureFooter = x.legalDisclosureFooter;

          return null;
        });
    }
  }
}
