import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SectorStaticPageDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { ScrollerService } from 'src/app/services/scroller.service';
import { MarkedPipe } from '../../pipes/marked.pipe';
import { CategoryCardComponent } from '../../shared/category-card/category-card.component';

@Component({
    selector: 'fc-sector-static-page',
    templateUrl: './sector-static-page.component.html',
    styleUrls: ['./sector-static-page.component.scss'],
    standalone: true,
    imports: [CategoryCardComponent, MarkedPipe]
})
export class SectorStaticPageComponent implements OnInit, AfterViewInit,  OnDestroy {
  page: SectorStaticPageDto;
  scrollTo: string;
  subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute, private scroller: ScrollerService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.data.subscribe(data => {
      this.page = data.details ;
      this.scrollTo = this.activatedRoute.snapshot.queryParams.scroll;
    }));

  }

  ngAfterViewInit(): void {
    this.scroller.scrollIntoView(this.scrollTo);
  }

}
