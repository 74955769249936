
import { Params } from '@angular/router';
import { DefaultInputDto } from 'src/app/models/shared/inputs/defaults/DefaultInputDto';

import { DefaultInputSettings, DefaultInputUtils } from 'src/app/models/shared/inputs/defaults/DefaultInputUtils';
import { RepaymentType } from './RepaymentType';
import { StandardInputDto } from './StandardInputDto';

export interface BalanceTransferInputDTO extends StandardInputDto {
    repaymentType: RepaymentType ;
    cards: SingleCardDTO[];
    fixedMonthlyRepayment: number | null;
    savingsFormat: SavingsFormatType;
    savingsFixedDuration: number | null;
}

export enum SavingsFormatType {
  againstInitialDuration,
  againseSetDuration,
  untilBalanceCleared
}

export interface SingleCardDTO {
    balance?: number;
    rate?: number ;
    fixedMonthlyRepaymentShare?: number;
    minRepaymentPercentage: number;
    minRepaymentThreshold: number;
    annualFee?: number;
}

export class BalanceTransferInput {
    public static Initialise(defaultInput: DefaultInputDto, queryParams: Params): BalanceTransferInputDTO {
      const settings: DefaultInputSettings = {
        elements: defaultInput.elements,
        queryParams
      };

      return {
        kind: defaultInput.kind,
        includeAllProducts: defaultInput.includeAllProducts,
        brands: [],
        repaymentType: DefaultInputUtils.FindElementValue(settings, 'savings_type' ) || RepaymentType.CC_PayMin,
        savingsFormat: DefaultInputUtils.FindElementValue(settings, 'savings_format' ) || SavingsFormatType.againstInitialDuration,
        savingsFixedDuration: DefaultInputUtils.FindElementValue(settings, 'savings_fixed_duration' ) || 25,
        fixedMonthlyRepayment:  DefaultInputUtils.FindElementValue(settings, 'repayment'),
        cards: [
          {
            balance: DefaultInputUtils.FindElementValue(settings, 'amount' ) || 0,
            rate: DefaultInputUtils.FindElementValue(settings, 'rate' ) || 0,
            minRepaymentPercentage: DefaultInputUtils.FindElementValue(settings, 'min_repayment_fee') || 0,
            minRepaymentThreshold: DefaultInputUtils.FindElementValue(settings, 'min_repayment_threshold' ) || 0,
            annualFee: DefaultInputUtils.FindElementValue(settings, 'annual_fee') || 0,
          }
        ]
      };
    }
}